import { render, staticRenderFns } from "./ScrollTopButton.vue?vue&type=template&id=f3b53b88&scoped=true"
import script from "./ScrollTopButton.vue?vue&type=script&lang=js"
export * from "./ScrollTopButton.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f3b53b88",
  null
  
)

export default component.exports