<template>
    <v-dialog v-model="settingGameDialog" :persistent="formLoading" content-class="br-16" scrollable max-width="575px">
        <v-card>
          <v-card-title class="justify-center">
            <h3 class="mr-2">{{ `${settingGame.name} (${settingGame.provider ? settingGame.provider.name : ''})` }}</h3>
            <v-img max-width="50px" height="50px" :src="settingGame.logo">
                                    
            </v-img>
          </v-card-title>
          <v-card-text class="py-2">
                <div class="d-flex align-center justify-space-between item-down">
                    <span class="just-text">
                        {{ $t('commission') }}
                    </span>
                    <InputNumber 
              class="hundred-input" 
              v-model="settingGame.fee_percentage"
              :step="1"
              :min="0"
              :max="100"
              suffix="%"
              mode="decimal" 
              showButtons
              />
                </div>
                <template v-if="settingGame.aggregator">
                <div class="justify-center d-flex subtitle-text">
                    <h4>{{ $t('aggrregators') }}</h4>
                  </div>
                <div class="d-flex align-center justify-space-between item-down">
                    <span class="just-text">
                        {{ settingGame.aggregator }}
                    </span>
                    <v-switch hide-details v-model="settingGame.active" :label="this.$t('active')" color="green accent-4"
                      inset class="mt-0 ml-1 custom-switch "></v-switch>
                </div>
            </template>
          </v-card-text>
          <!-- Close and Save -->
          <v-card-actions>
            <Button @click="saveNewGameSettings"
              class="p-button-sm p-button-success fw"
              :loading="formLoading"
              :disabled="formLoading"
              :label="$t('save')"  />
          </v-card-actions>
        </v-card>
      </v-dialog>
</template>

<script>
import axios from 'axios';
    export default {
        name: 'GameProviderSettingDialog',
        props:{
            settingGame: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                //settingGameDialog: false,
                formLoading: false,
            }
        },
        computed:{
            settingGameDialog: {
                get() {
                  return this.$store.state.settingGameDialog;
                },
                set(value) {
                  this.$store.commit('openGameProviderSettings', value);
                },
        },
        },
        watch: {
            //settingGame(newValue, oldValue){
            //    this.settingGameDialog = this.settingGame.dialog;
            //},
        },
        mounted() {
        },
        methods: {
          // async getGameInfo() {
          //   try {
          //     this.loadingFlag = true;
          //     this.formLoading = true;
          //     await this.$store.dispatch("gameModule/awaitGetGame", this.settingGame.game_id);
              
          //   } catch (error) {
          //     this.$toast.add({
          //       severity: "error",
          //       summary: this.$t("just_error"),
          //       detail: error.message,
          //       life: 4000,
          //     })
          //   } finally{
          //     this.loadingFlag = false;
          //     this.formLoading = fa;
          //   }
          // },
            async saveNewGameSettings(){
              this.formLoading = true;
              let endpoint;
      let requestData
        endpoint = `/main-api/games/${this.settingGame.game_id}`;
        requestData = new FormData();
        requestData.append("active", this.settingGame.active || false);
        requestData.append("is_wagerable", this.settingGame.is_wagerable || false);
        requestData.append("game_type", this.settingGame.game_type || "");
        if (this.settingGame.description) {
          requestData.append("description", this.settingGame.description || "");
        }
        requestData.append("fee_percentage", this.settingGame.fee_percentage || 0);
        requestData.append("wagering_coefficient", this.settingGame.wagering_coefficient || 1);
        requestData.append("name", this.settingGame.name || "");
        requestData.append("order", this.settingGame.order || 0);
          requestData.append("sub", JSON.stringify(this.settingGame.sub) || []);
          if (this.settingGame.blocks && this.settingGame.blocks.length > 0) {
          requestData.append("blocks", JSON.stringify(this.settingGame.blocks || []));
        }
        requestData.append("logo", this.settingGame.logo);
              try {
        await axios.put(endpoint, requestData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            "Content-Type": "multipart/form-data",
          },
        });
          this.$store.commit('openGameProviderSettings', false);
          this.$toast.add({
          severity: 'success', summary: this.$t(`game_edit_success`), life: 4000,
        })
        this.$emit('save-done', this.settingGame.provider, true)
      } catch (error) {
        this.$toast.add({
          severity: 'error', summary: this.$t(`game_edit_error`), life: 4000,
        })
        console.log(error);
      } finally {
        this.formLoading = false;
      }
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>