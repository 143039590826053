import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import ru from "vuetify/es5/locale/ru";
import colors from "vuetify/lib/util/colors";
Vue.use(Vuetify);

const opts = {
  lang: {
    locales: { ru },
    current: "ru",
  },
  theme: {
    themes: {
      light: {
        primary: colors.purple.darken1, // #E53935

        //secondary: colors.red.lighten4, // #FFCDD2
        //accent: colors.indigo.base, // #3F51B5
      },
    },
  },
};

export default new Vuetify(opts);
