//transaction.js;
import axios from "axios";
const state = {
  gameSettings: {
    slots: [],
    roulette: [],
    crash: [],
    fishing: [],
  },
};

const mutations = {
  set(state, { type, items }) {
    state[type] = items;
  },
  cacheGameSettings(state, { gameType, items }) {
    state.gameSettings[gameType] = items;
  },
};

const getters = {
  getGameSettings: (state) => state.gameSettings,
};

const actions = {
  async getGameSettings({ state, commit, dispatch }, filters) {
    let res = false;
    try {
      const gameType = filters.game_type;
      const params = new URLSearchParams();
      for (const key in filters) {
        if (Object.hasOwnProperty.call(filters, key)) {
          const value = filters[key];

          // Check value
          if (value != null && value !== "" && value !== "All Games") {
            params.append(key, value);
          }
        }
      }
      const queryString = `?${params.toString()}`;

      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
      };
      const response = await axios.get(
        `/main-api/games/admin/filter${queryString}`,
        config
      );
      if (response.status === 200) {
        res = true;
        const gamesWithGameProperty = response.data.items;
        const gamesWithoutGameProperty = gamesWithGameProperty.map(
          (item) => item.Game
        );
        const formattedData = {
          items: gamesWithoutGameProperty,
          page: response.data.page,
          pages: response.data.pages,
          size: response.data.size,
          total: response.data.total,
        };
        // state.games = formattedData;
        commit("cacheGameSettings", { gameType, items: formattedData });
        // commit("set", { type: "games", items: formattedData });
        // return formattedData;
      }
    } catch (error) {
      return res;
    }
    return res;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
