//gameModule.js;
import axios from "axios";
const state = {
  loadingModuleFlag: false,
  unsavedDialog: false,
  providerForFilter: null,
  providerFromSettings: null,
  gameFromSettings: null,
  filters: {},
  currentGame: {
    id: null,
    name: null,
    prov_name: null,
  },
  gameArray: {
    items: [],
  },
};

const mutations = {
  set(state, { type, items }) {
    state[type] = items;
  },
  SET_FILTERS(state, activeFilters) {
    state.filters = { ...activeFilters };
  },
  SET_LOADING_FLAG(state, value) {
    state.loadingModuleFlag = value;
  },
  SET_PROVIDER_FILTER(state, value) {
    state.providerForFilter = value;
  },
  SET_PROVIDER_SETTINGS(state, value) {
    state.providerFromSettings = value;
  },
  SET_GAME_SETTINGS(state, value) {
    state.gameFromSettings = value;
  },
  // cacheGameModule(state, { gameProvider, items }) {
  //   state.gameArray[gameProvider] = items;
  // },
  cacheGameModule(state, { items }) {
    state.gameArray = items;
  },
  // APPEND_CURRENT_GAMES(state, { gameProvider, newGames }) {
  //   state.gameArray[gameProvider].items = state.gameArray[
  //     gameProvider
  //   ].items.concat(newGames.items);
  //   state.gameArray[gameProvider].page = newGames.page;
  //   state.gameArray[gameProvider].pages = newGames.pages;
  //   state.gameArray[gameProvider].size = newGames.size;
  //   state.gameArray[gameProvider].total = newGames.total;
  // },
  APPEND_CURRENT_GAMES(state, { newGames }) {
    state.gameArray.items = state.gameArray.items.concat(newGames.items);
    state.gameArray.page = newGames.page;
    state.gameArray.pages = newGames.pages;
    state.gameArray.size = newGames.size;
    state.gameArray.total = newGames.total;
  },
};

const getters = {
  providerFromSettings: (state) => state.providerFromSettings,
  gameFromSettings: (state) => state.gameFromSettings,
  getFilters: (state) => state.filters,
  getGameArray: (state) => state.gameArray,
  getCurrentGame: (state) => state.currentGame,
};

const actions = {
  // async getFromGameModule({ state, commit, dispatch }, filters) {
  //   let gameProvider;
  //   if (filters.provider === "") {
  //     gameProvider = "allGames";
  //   } else {
  //     gameProvider = filters.provider;
  //   }
  //   let res = false;
  //   try {
  //     const params = new URLSearchParams();
  //     for (const key in filters) {
  //       if (Object.hasOwnProperty.call(filters, key)) {
  //         const value = filters[key];

  //         // Check value
  //         if (
  //           value != null &&
  //           value !== "" &&
  //           value !== "All Games" &&
  //           value !== "all_games" &&
  //           key !== "findGame" &&
  //           key !== "allGames"
  //         ) {
  //           params.append(key, value);
  //         }
  //       }
  //     }
  //     const queryString = `?${params.toString()}`;

  //     const config = {
  //       headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
  //     };
  //     const response = await axios.get(
  //       //`/int-api/game_sessions/games${queryString}`,

  //       `/main-api/games/admin/filter${queryString}`,
  //       config
  //     );

  //     if (response.status === 200) {
  //       const gamesWithGameProperty = response.data.items;
  //       const gamesWithoutGameProperty = gamesWithGameProperty.map(
  //         (item) => item.Game
  //       );

  //       const formattedData = {
  //         items: gamesWithoutGameProperty,
  //         page: response.data.page,
  //         pages: response.data.pages,
  //         size: response.data.size,
  //         total: response.data.total,
  //       };
  //       // state.games = formattedData;
  //       if (filters.page >= 2) {
  //         commit("APPEND_CURRENT_GAMES", {
  //           gameProvider,
  //           newGames: formattedData,
  //         });
  //       } else {
  //         commit("cacheGameModule", { gameProvider, items: formattedData });
  //       }
  //       return formattedData;
  //     }
  //   } catch (error) {
  //     // console.error(error);
  //     state.gameArray[gameProvider] = null;
  //     return res;
  //   }
  // },
  async getFromGameModule({ state, commit, dispatch }, { filters, agent }) {
    let res = false;
    try {
      const params = new URLSearchParams();
      for (const key in filters) {
        if (Object.hasOwnProperty.call(filters, key)) {
          const value = filters[key];

          // Check value
          if (
            value != null &&
            value !== "" &&
            value !== "All Games" &&
            value !== "all_games"
          ) {
            params.append(key, value);
          }
        }
      }
      const queryString = `?${params.toString()}`;

      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
      };
      let endpoint;
      agent
        ? (endpoint = `/main-api/games/agent/filter${queryString}`)
        : (endpoint = `/main-api/games/admin/filter${queryString}`);
      const response = await axios.get(
        //`/int-api/game_sessions/games${queryString}`,
        endpoint,
        config
      );

      if (response.status === 200) {
        const gamesWithGameProperty = response.data.items;
        const gamesWithoutGameProperty = gamesWithGameProperty.map(
          (item) => item.Game
        );

        const formattedData = {
          items: gamesWithoutGameProperty,
          page: response.data.page,
          pages: response.data.pages,
          size: response.data.size,
          total: response.data.total,
        };
        // state.games = formattedData;
        if (filters.page >= 2) {
          commit("APPEND_CURRENT_GAMES", {
            newGames: formattedData,
          });
        } else {
          commit("cacheGameModule", { items: formattedData });
        }
        return formattedData;
      }
    } catch (error) {
      // console.error(error);
      state.gameArray = null;
      return res;
    }
  },
  async awaitGetGame({ commit }, id) {
    try {
      const response = await axios.get(`/main-api/games/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      if (response.status === 200) {
        commit("set", {
          type: "currentGame",
          items: response.data,
        });
      }
    } catch (error) {
      console.error("Error fetching games:", error);
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
