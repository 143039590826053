import axios from "axios";

const Api = {
  async setVerificationStatus(
    docNumber = null,
    approved = false,
    userId = null
  ) {
    if (docNumber !== null && userId !== null) {
      const data = {
        doc: docNumber,
        status: approved ? 2 : 3,
        user_id: userId,
      };
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
      };
      let res = false;
      await axios
        .post(`/main-api/users/set_verify`, data, config)
        .then((response) => {
          res = response.status === 200 && response.data.status === "200";
        })
        .catch((error) => {
          console.log(error);
        });
      return res;
    } else {
      return -1;
    }
  },
  async getPlayerSessions(filters) {
    const params = new URLSearchParams();
    for (const key in filters) {
      if (Object.hasOwnProperty.call(filters, key)) {
        const value = filters[key];
        // Check value
        if (value != null && value !== "") {
          params.append(key, value);
        }
      }
    }
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
    };
    const queryString = `?${params.toString()}`;
    const response = await axios.get(
      `/int-api/game_sessions/filter${queryString}`,
      config
    );
    if (response.status === 200) {
      return response.data;
    }
  },
  async getSessionSpins(data) {
    let spins = [];
    const filters = data;
    let query_string = "?";
    Object.keys(filters).forEach((key, index) => {
      //console.log(key, filters[key])
      query_string += key.toString() + "=" + filters[key].toString() + "&";
    });
    query_string += "token=" + localStorage.getItem("jwt");
    await axios
      .get(`/api/getSessionSpins` + query_string)
      .then((response) => {
        //console.log('playerSessions response = ',response.data)
        if (response.status === 200) {
          spins = response.data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return spins;
  },
  async getAffiliateStat(data) {
    if (!data.filters.aff) {
      return;
    }
    const filters = data.filters;
    let query_string = "?";
    Object.keys(filters).forEach((key, index) => {
      if (filters[key]) {
        query_string += key.toString() + "=" + filters[key].toString() + "&";
      }
    });
    query_string += "token=" + localStorage.getItem("jwt");
    let stats = [];
    await axios
      .get(`/api/getAffiliateData` + query_string)
      .then((response) => {
        if (response.status === 200) {
          stats = response.data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return stats;
  },
  async blockPlayerWithdraw(userId, processingState = true) {
    let status = false;
    let data = {
      user_id: userId,
      processing_state: processingState,
    };
    await axios
      .post(`/main-api/users/block_withdraw`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        status = response.status >= 200 && response.status < 300;
      })
      .catch((error) => {
        console.log(error);
      });
    return status;
  },
};
export default Api;
