<template>
    <v-menu 
    v-model="menu"
    offset-y
    :close-on-content-click="false" 
        nudge-left="0" 
        nudge-right="0"
        >
        <template v-slot:activator="{ on }">
          <div class="" v-on="on" @click="$emit('start-loading')">
          <span class="p-input-icon-right fw">
            <i class="pi pi-angle-down" />
            <!-- :value="getCurrencyValue" -->
            <!-- disabled -->
              <InputText 
              :value="getValue"
                readonly 
                class="p-inputtext-sm" 
                :class="{ 'fw' : fullWidth }"
                :placeholder="placeholder">
              </InputText>
              </span>
              </div>
        </template>
        <div class="p-listbox p-component custom-dropdown"
        :style="{ 'max-width': maxWidthMenu ? maxWidthMenu + 'px' : 'auto' }"
        >
            <div class="p-listbox-header">
                <div class="p-dropdown-filter-container">
                    <span class="p-input-icon-right fw">
                        <i class="pi"
                        :class="{ 'pi-spin': loadingStart, 'pi-search': !loadingStart }"
                        />
                          <InputText 
                          v-model="search"
                          :disabled="loadingStart"
                            class="p-inputtext-sm fw" 
                            >
                          </InputText>
                          </span>
                </div>
            </div>
            <div class="p-listbox-list-wrapper">
                <template v-if="!loadingStart">
                    <div class="p-listbox-list">
                        <!--  -->
                        <div
                      class="p-listbox-item"
                      :class="{ 'p-highlight': item[optionValue] === value }"
                      v-for="(item, index) in filteredOptions"
                      :key="index"
                      @click="selectItem(item[optionValue])"
                    >
                      {{ item[optionLabel] }}
                    </div>
                    <div class="d-flex justify-center"
                    v-if="options.items && options.page !== options.pages"
                    v-intersect="loadNextPage"
                    >
                        <v-progress-circular
                        v-if='loading'
                indeterminate
                color="primary"
              ></v-progress-circular>
                    </div>
                    </div>
                </template>
                      <v-progress-circular
                          v-else
                  indeterminate
                  color="primary"
                ></v-progress-circular>
            </div>
        </div>
          <!-- <Listbox v-model="addPlayer.currency" :options="currencies"
          :filter="true"
          @change="currencyMenu = false"
          class="custom-dropdown"
          :emptyFilterMessage="$t('no_data')"
           /> -->

      </v-menu>
</template>

<script>
    export default {
        name: "PagginationListbox",
        computed: {
            getValue() {
                if (this.value) {
                    return this.options.items.find(item => item[this.optionValue] === this.value)[this.optionLabel];
                } else {
                    return this.placeholder;
                }
                },
                filteredOptions() {
                    if (this.search) {
                        return this.options.items.filter((item) =>
                          item[this.optionLabel].toLowerCase().includes(this.search.toLowerCase())
                        );
                    } else {
                        return this.options.items;
                    }
                }
        },
        data() {
            return {
                menu: false,
                search: null
            }
        },
        props: {
            maxWidthMenu:{
                type: Number,
            },
            loading: Boolean,
            loadingStart: Boolean,
            fullWidth: Boolean,
            optionValue: String,
            optionLabel: String,
            placeholder: String,
            value: {
                type: [String, Number, Object],
                required : true
            },
            placeholder: {
                type: String,
                default: ''
            },
            options: {
                type: Object,
      required: true,
      validator(value) {
        if (!value || typeof value !== 'object') return false;
  
  const {
    items = [],
    page = 1,
    pages = 1,
    size = 0,
    total = 0
  } = value;
  
  return (
    Array.isArray(items) &&
    (typeof page === 'number' || page === undefined) &&
    (typeof pages === 'number' || pages === undefined) &&
    (typeof size === 'number' || size === undefined) &&
    (typeof total === 'number' || total === undefined)
  );
      },
      default: () => ({
        items: [],
        page: 0,
        pages: 0,
        size: 0,
        total: 0
      })
            }
        },
        methods: {
            loadNextPage(entries, obs){
                if (entries[0].isIntersecting && !this.loading && !this.loadingStart) {
                    this.$emit('load-next-page');
                }
            },
            selectItem(item) {
                if (item == null) {
                    console.log('Wrong item or Value');
                } else{
                    this.$emit('input', item); 
                    this.$emit('change', item)
                    this.menu = false;
                }
        }
  }
    }
</script>