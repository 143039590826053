import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import router from "../router/router";
import redirector from "./modules/redirector";
import phone from "./modules/phone";
import bonuses from "@/store/modules/bonuses";
import promocodes from "@/store/modules/promocodes";
import gameBlocks from "@/store/modules/gameBlocks";
import gameModule from "@/store/modules/gameModule";
import localization from "@/store/modules/localization";
import roles from "@/store/modules/roles";
import reports from "@/store/modules/reports";
import levels from "@/store/modules/levels";
import agents from "@/store/modules/agents";
import banners from "@/store/modules/banners";
import transaction from "@/store/modules/transaction";
import customScript from "@/store/modules/customScript";
import geo from "@/store/modules/geo";
import playerAvatars from "@/store/modules/playerAvatars";
import dashboard from "@/store/modules/dashboard";
import linksHref from "@/store/modules/linksHref";
import admins from "@/store/modules/admins";
import paggination from "@/store/modules/paggination";
import minBetSettings from "@/store/modules/minBetSettings";
import gameFee from "@/store/modules/gameFee";
import gameSettings from "@/store/modules/gameSettings";
import maintenance from "@/store/modules/maintenance";
import aggregators from "@/store/modules/aggregators";
import themes from "@/store/modules/themes";
import providers from "@/store/modules/providers";
import jackpots from "@/store/modules/jackpots";
import notifications from "@/store/modules/notifications";
import segments from "@/store/modules/segments";
import qs from "query-string";
import { i18n } from "@/plugins/i18n";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    admin: {
      id: null,
    },
    compensations: [],
    players: {
      items: [],
      size: 0,
    },
    domain: window.location.hostname.includes("take")
      ? process.env.VUE_APP_API_DOMAIN_FIRST
      : process.env.VUE_APP_API_DOMAIN_SECOND,
    selectedPlayer: {},
    playerPayments: [],
    playerSessions: [],
    playerAuthSessions: [],
    playerProviders: [],
    playerTags: [],
    playerAdminLogs: [],
    allPayments: [],
    selectedPayment: {},
    games: [],
    gameProviders: [],
    paymentWays: [],
    affiliates: [],
    selectedfAffiliate: {},
    affiliateOffers: [],
    affiliateRequests: [],
    cashBoxData: [],
    cashBoxTotals: {},
    total: 0,
    cashBoxHeaders: {
      players_today: 0,
      total_reg_count: 0,
      today_deps_amount_sum: 0,
      total_deps_amount_sum: 0,
      today_withdraws_amount_sum: 0,
      total_withdraws_amount_sum: 0,
      usersBalance: 0,
    },
    currencies: [],
    allAuthSessions: [],
    adminAuthSessions: [],
    playersBonuses: [],
    pageName: "",
    gameSession: [],
    cachedSessions: {},
    showSession: false,
    isDark: false,
    tokenUser: null,
    settingGameDialog: false,
    multiaccs: [],
  },
  getters: {
    getMultiaccs: (state) => state.multiaccs,
    tokenUser: (state) => state.tokenUser,
    admin: (state) => state.admin,
    agent: (state) => state.agent,
    getPlayers: (state) => state.players,
    getGameSession: (state) => state.gameSession,
    getCompensation: (state) => state.compensations,
    getSelectedPlayer: (state) => state.selectedPlayer,
    getPlayerPayments: (state) => state.playerPayments,
    getPlayerSessions: (state) => state.playerSessions,
    playerAuthSessions: (state) => state.playerAuthSessions,
    getPlayerProviders: (state) => state.playerProviders,
    getAllPayments: (state) => state.allPayments,
    getSelectedPayment: (state) => state.selectedPayment,
    getGames: (state) => state.games,
    getTotal: (state) => state.total,
    gameProviders: (state) => state.gameProviders,
    getPaymentWays: (state) => state.paymentWays,
    affiliates: (state) => state.affiliates,
    selectedAffiliate: (state) => state.selectedfAffiliate,
    affiliateOffers: (state) => state.affiliateOffers,
    affiliateRequests: (state) => state.affiliateRequests,
    cashBoxData: (state) => state.cashBoxData,
    cashBoxTotals: (state) => state.cashBoxTotals,
    cashBoxHeaders: (state) => state.cashBoxHeaders,
    playerTags: (state) => state.playerTags,
    playerAdminLogs: (state) => state.playerAdminLogs,
    currency: (state) => state.currency,
    getCurrencies: (state) => state.currencies,
    getFilteredCurrencies(state) {
      return state.currencies.filter((el) => el !== "ALL");
    },
    allAuthSessions: (state) => state.allAuthSessions,
    adminAuthSessions: (state) => state.adminAuthSessions,
    playersBonuses: (state) => state.playersBonuses,
  },
  mutations: {
    openGameProviderSettings(state, value) {
      state.settingGameDialog = value;
    },
    set(state, { type, items }) {
      if (type === "gameSession") {
        state[type] = items;
        if (items.length && items[0].game_session_id) {
          state.cachedSessions[items[0].game_session_id] = items;
        }
      } else {
        state[type] = items;
      }
    },
    logOut(state) {
      // let adminClear = {
      //   id: null,
      // };
      // let agentClear = null;
      // state.tokenUser = null;
      // state.admin = adminClear;
      // state.agent = agentClear;
      localStorage.clear();
      location.reload();
    },
    setPageName(state, value) {
      state.pageName = value;
    },
    setShowSession(state, value) {
      state.showSession = value;
    },
    setIsDark(state, value) {
      state.isDark = value;
    },
    APPEND_CURRENT_GAMES: (state, newGames) => {
      state.games.items = state.games.items.concat(newGames.items);
      state.games.page = newGames.page;
      state.games.pages = newGames.pages;
      state.games.size = newGames.size;
      state.games.total = newGames.total;
    },
  },
  actions: {
    async singIn({ state, commit, dispatch }, data) {
      let res = false;
      let error = "";
      await axios
        .post(
          `/api/auth/token`,
          qs.stringify({
            username: data.username,
            password: data.password,
            fingerprint: JSON.stringify(data.finger),
            ip: data.ip,
          }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((response) => {
          //console.log('singUp response = ',response.data)
          if (response && response.status === 200) {
            localStorage.clear();
            res = true;
            const tokenUser = response.data.access_token;
            localStorage.setItem("jwt", tokenUser);
          }
        })
        .catch((err) => {
          console.log("error");
          // console.log("router", process.env.BASE_URL);
          if (err.response.status === 500) {
            error = "Server";
          } else if (err.response.status === 403) {
            if (err.response.data.detail.loc === "auth") {
              error = "Invalid";
            }
          }
        });
      return [res, error];
    },
    async agentSignIn({ state, commit, dispatch }, data) {
      let res = false;
      let error = "";
      await axios
        .post(
          `/api/agent_module/auth/token`,
          qs.stringify({
            username: data.username,
            password: data.password,
            fingerprint: JSON.stringify(data.finger),
          }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((response) => {
          if (response) {
            res = true;
            // console.log("response", response.data);
            const token = response.data.access_token;
            localStorage.clear();
            localStorage.setItem("jwt", token);
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.detail.loc === "auth"
          ) {
            error = "Invalid";
          } else if (err.response.status === 500) {
            error = "Server";
          } else if (
            err.response &&
            err.response.data &&
            err.response.data.detail.loc === "is_active"
          ) {
            error = "Agent not active";
          }
        });
      return [res, error];
    },
    async getAgentStatus({ state, commit }) {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
      };
      let admin = {};
      await axios
        .get(`/api/agent_module/agents/me`, config)
        .then((response) => {
          if (response.status === 200) {
            const tokenUser = localStorage.getItem("jwt");
            const resul = parseJwt(tokenUser);
            const resultStringify = JSON.stringify(resul);
            // console.log("resul", resul.user_type);
            // localStorage.setItem("tokenUser", resultStringify);
            commit("set", { type: "tokenUser", items: resul.user_type });
            commit("set", { type: "admin", items: response.data });
            admin = response.data;
            function parseJwt(token) {
              var base64Url = token.split(".")[1];
              var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
              var jsonPayload = decodeURIComponent(
                atob(base64)
                  .split("")
                  .map(function (c) {
                    return (
                      "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
                    );
                  })
                  .join("")
              );
              return JSON.parse(jsonPayload);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });

      return admin;
    },
    async getStatus({ state, commit }) {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
      };
      await axios
        .get(`/api/admins/me`, config)
        .then((response) => {
          if (response.status === 200) {
            const tokenUser = localStorage.getItem("jwt");
            const resul = parseJwt(tokenUser);
            const resultStringify = JSON.stringify(resul.perms);
            commit("set", { type: "admin", items: response.data });
            commit("set", { type: "tokenUser", items: resultStringify });
            function parseJwt(token) {
              var base64Url = token.split(".")[1];
              var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
              var jsonPayload = decodeURIComponent(
                atob(base64)
                  .split("")
                  .map(function (c) {
                    return (
                      "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
                    );
                  })
                  .join("")
              );
              return JSON.parse(jsonPayload);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getTokenUser({ state, commit }) {
      if (localStorage.getItem("jwt")) {
        let result;
        const token = localStorage.getItem("jwt");
        var base64Url = token.split(".")[1];
        var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        var jsonPayload = decodeURIComponent(
          atob(base64)
            .split("")
            .map(function (c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );
        const decodeTokenResult = JSON.parse(jsonPayload);
        const stringifyDecodedTokenResutl = JSON.stringify(
          decodeTokenResult.perms
        );
        if (decodeTokenResult.user_type === "agent") {
          result = decodeTokenResult.user_type;
        } else {
          result = decodeTokenResult.perms;
        }
        // console.log("result", result);
        commit("set", { type: "tokenUser", items: result });
        return result;
      }
    },
    async getGameSession({ state, commit }, filters) {
      // const filters = data.filters;
      const sessionId = filters.game_session_id;
      // console.log("sessionId", sessionId);
      // console.log(
      //   "state.cachedSessions[sessionId]",
      //   state.cachedSessions[sessionId]
      // );
      if (state.cachedSessions[sessionId]) {
        commit("set", {
          type: "gameSession",
          items: state.cachedSessions[sessionId],
        });
        return;
      }
      const params = new URLSearchParams();
      for (const key in filters) {
        if (Object.hasOwnProperty.call(filters, key)) {
          const value = filters[key];
          if (value != null && value !== "") {
            params.append(key, value);
          }
        }
      }
      const queryString = `?${params.toString()}`;
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
      };

      await axios
        .get(`/int-api/spins/filter${queryString}`, config)
        .then((response) => {
          if (response.status === 200) {
            commit("set", { type: "gameSession", items: response.data });
          }
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },

    async playersFilters({ state, commit, dispatch }, query) {
      let err = false;
      // const filters = data.filters;
      // let query_string = "?";
      // Object.keys(filters).forEach((key, index) => {
      //   if (filters[key].toString() !== "") {
      //     query_string += key.toString() + "=" + filters[key].toString() + "&";
      //   }
      // });
      // console.log("query", query);
      await axios
        .get(`/main-api/users/by_filter?${query}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((response) => {
          if (response) {
            commit("set", { type: "players", items: response.data });
          }
        })
        .catch((error) => {
          console.log(error);
          err = true;
        });
      return err;
    },
    async playerInfo({ state, commit, dispatch }, data) {
      let info = {};
      await axios
        .get(`/main-api/users/` + data.id.toString() + `/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            info = response.data;
            commit("set", { type: "selectedPlayer", items: response.data });
          }
        })
        .catch((error) => {
          console.log(error);
        });
      return info;
    },
    async getPlayerTags({ state }, filters) {
      let tags = [];
      const params = new URLSearchParams();
      for (const key in filters) {
        if (Object.hasOwnProperty.call(filters, key)) {
          const value = filters[key];
          // Check value
          if (value != null && value !== "") {
            params.append(key, value);
          }
        }
      }
      const queryString = `?${params.toString()}`;
      await axios
        .get(`/api/admins/tags${queryString}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            tags = response.data;
            tags.sort((a, b) => {
              return new Date(b.date) - new Date(a.date);
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
      return tags;
    },
    async playerPayments({ state, commit, dispatch }, filters) {
      const params = new URLSearchParams();
      for (const key in filters) {
        if (Object.hasOwnProperty.call(filters, key)) {
          const value = filters[key];
          // Check value
          if (value != null && value !== "") {
            params.append(key, value);
          }
        }
      }
      const queryString = `?${params.toString()}`;
      await axios
        .get(`/main-api/payments/filter${queryString}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            const responseData = response.data;

            // const items = responseData.items;

            // items.sort((a, b) => {
            //   return new Date(b.created_at) - new Date(a.created_at);
            // });
            // responseData.items = items;
            commit("set", { type: "playerPayments", items: responseData });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async playerProviders({ state, commit, dispatch }, data) {
      await axios
        .get(
          `/api/getUserProviders?id=` +
            data.id.toString() +
            "&token=" +
            localStorage.getItem("jwt")
        )
        .then((response) => {
          if (response.status === 200) {
            let providers = response.data;
            for (let i = 0; i < response.data.length; i++) {
              providers[i].value = providers[i].provider;
              providers[i].text = providers[i].provider;
            }
            commit("set", { type: "playerProviders", items: providers });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async allPayments({ state, commit, dispatch }, data) {
      const filters = data.filters;
      let query_string = "?";
      Object.keys(filters).forEach((key, index) => {
        query_string += key.toString() + "=" + filters[key] + "&";
      });
      query_string += "token=" + localStorage.getItem("jwt");
      await axios
        .get(`/api/getPayments` + query_string)
        .then((response) => {
          if (response.status === 200) {
            let results = response.data;
            results.sort(function (a, b) {
              // Turn your strings into dates, and then subtract them
              // to get a value that is either negative, positive, or zero.
              return new Date(b.date) - new Date(a.date);
            });
            commit("set", { type: "allPayments", items: results });
            //this.state.playerPayments = response.data
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getOnePayment({ state, commit, dispatch }, data) {
      await axios
        .get(`/main-api/payments/` + data.payId, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((response) => {
          if (response.status === 200 && response.data !== null) {
            commit("set", { type: "selectedPayment", items: response.data });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getOneMorePageGames({ state, commit, dispatch }, filters) {
      let res = false;
      try {
        const params = new URLSearchParams();
        for (const key in filters) {
          if (Object.hasOwnProperty.call(filters, key)) {
            const value = filters[key];

            // Check value
            if (value != null && value !== "" && value !== "All Games") {
              params.append(key, value);
            }
          }
        }
        const queryString = `?${params.toString()}`;

        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
        };
        const response = await axios.get(
          `/main-api/games/admin/filter${queryString}`,
          config
        );

        if (response.status === 200) {
          const gamesWithGameProperty = response.data.items;
          const gamesWithoutGameProperty = gamesWithGameProperty.map(
            (item) => item.Game
          );

          const formattedData = {
            items: gamesWithoutGameProperty,
            page: response.data.page,
            pages: response.data.pages,
            size: response.data.size,
            total: response.data.total,
          };
          commit("APPEND_CURRENT_GAMES", formattedData);
          return formattedData;
        }
      } catch (error) {
        // console.error(error);
        return res;
      }
    },
    async getGames({ state, commit, dispatch }, filters) {
      let res = false;
      try {
        const params = new URLSearchParams();
        for (const key in filters) {
          if (Object.hasOwnProperty.call(filters, key)) {
            const value = filters[key];

            // Check value
            if (value != null && value !== "" && value !== "All Games") {
              params.append(key, value);
            }
          }
        }
        const queryString = `?${params.toString()}`;

        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
        };
        const response = await axios.get(
          //`/int-api/game_sessions/games${queryString}`,

          `/main-api/games/admin/filter${queryString}`,
          config
        );

        if (response.status === 200) {
          const gamesWithGameProperty = response.data.items;
          const gamesWithoutGameProperty = gamesWithGameProperty.map(
            (item) => item.Game
          );

          const formattedData = {
            items: gamesWithoutGameProperty,
            page: response.data.page,
            pages: response.data.pages,
            size: response.data.size,
            total: response.data.total,
          };
          // state.games = formattedData;
          commit("set", { type: "games", items: formattedData });
          return formattedData;
        }
      } catch (error) {
        // console.error(error);
        state.games = null;
        return res;
      }
    },
    async updatePaymentSystemStatus({ commit }, requestData) {
      let res;
      try {
        const apiUrl = `main-api/payments/systems/${requestData.id}?status=${requestData.status}&autopayout=${requestData.autopayout}`;

        const headers = {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        };
        // PATCH
        const response = await axios.patch(apiUrl, null, { headers });
        if (response.status === 200) {
          res = true;
        }
      } catch (error) {
        res = false;
      }
      return res;
    },
    async awaitUpdateMethod({ commit, dispatch }, method) {
      let res = false;
      const methodData = {
        currencies: method.currencies,
        fix: method.fix,
        fee: method.fee,
        payment_min_limit: method.payment_min_limit,
        payment_max_limit: method.payment_max_limit,
      };
      await axios
        .put(`/main-api/payments/systems/${method.id}`, methodData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            res = true;
            dispatch("getPaymentWays");
          }
        })
        .catch((error) => {});
      return res;
    },
    async getPaymentWays({ state, commit, dispatch }, data) {
      return axios // Add the return statement here
        .get(`/main-api/payments/admin/systems`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            let formattedData = response.data.map((item) => {
              return {
                ...item.PaymentSystem,
                payment_count: item.payment_count,
                payment_sum: item.payment_sum,
                currency: item.currency,
                isActive_loading: false,
                isPayout_loading: false,
              };
            });
            commit("set", { type: "paymentWays", items: formattedData });
            return formattedData; // This will make sure the calling function gets the data
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getCashBoxData({ state }, data) {
      let res = false;
      if (localStorage.getItem("jwt")) {
        let params = {
          start_date: data.dateStart,
          end_date: data.dateEnd,
          currency: data.currency,
          page: data.page || 1, // default to 1 if no page is provided
          size: data.size || 10, // default to 10 if no size is provided
        };

        await axios
          .get(`/int-api/game_sessions/bank`, {
            params: params,
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              //debugger;
              // state.cashBoxData = response.data.items;
              state.cashBoxData = response.data;
              state.cashBoxTotals = response.data.totals;
              state.cashBoxHeaders = response.data.total_header_stats;
              res = true;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
      return res;
    },
    async getCashboxRevenue({ state }, data) {
      if (localStorage.getItem("jwt")) {
        await axios
          .get(`/api/getRevenue?token=${localStorage.getItem("jwt")}`)
          .then((response) => {
            if (response.status === 200) {
              state.cashBoxRevenue = response.data;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    async getCurrencies({ state }, data) {
      let endpoint;
      // console.log("data", data);
      if (data === "agent") {
        endpoint = "informational/all_currencies";
      } else {
        endpoint = "informational/admin/all_currencies";
      }
      await axios
        .get(`/main-api/${endpoint}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            state.currencies = response.data.currencies;
          }
        })
        .catch((error) => {
          console.log("error = ", error);
        });
    },

    async createPlayerTag({ state }, data) {
      let res = false;
      let json = {
        user_id: data.id,
        text: data.text,
      };
      await axios
        .post(`/api/admins/tags`, json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            res = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      return res;
    },
    async blockAllUsers({ state }, players) {
      let res = false;
      await axios
        .post(`/main-api/users/set_multiple_blocks`, players, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            res = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      return res;
    },
    async getPlayerAuthSessions({ state }, filters) {
      const params = new URLSearchParams();
      for (const key in filters) {
        if (Object.hasOwnProperty.call(filters, key)) {
          const value = filters[key];

          // Check value
          if (value != null && value !== "") {
            params.append(key, value);
          }
        }
      }
      const queryString = `?${params.toString()}`;
      let authSessions = [];
      if (localStorage.getItem("jwt")) {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
        };
        await axios
          .get(`/main-api/informational/auth_sessions${queryString}`, config)
          .then((response) => {
            if (response.status === 200) {
              authSessions = response.data;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
      return authSessions;
    },
    async createCompensation({ state, dispatch }, data) {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
      };
      await axios
        .post(
          `/main-api/payments/compensations`,
          {
            log_type: "compensation",
            user_id: data.id,
            amount: data.amount,
            text: data.text,
          },
          config
        )
        .then((response) => {})
        .catch((err) => {
          console.log(err);
          throw err;
        });
    },
    async awaitGetCompensation({ state, commit }, data) {
      //playerAdminLogs
      let logs = [];
      if (localStorage.getItem("jwt")) {
        await axios
          .get(`/main-api/payments/compensations/${data.id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              logs = response.data;
              state.compensations = response.data;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
      return logs;
    },
    async blockUser({ state }, data) {
      let res = false;
      await axios
        .post(
          `/main-api/users/set_block`,
          {
            user_id: data.user_id,
            block: data.block,
            block_end: data.block_end,
            tz_offset: data.tz_offset,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        )
        .then((response) => {
          res = response.status === 200 || response.status === 201;
        })
        .catch((error) => {
          console.log(error);
        });
      return res;
    },
    async unBlockUser({ state }, data) {
      let res = false;
      await axios
        .post(`/api/addUnblock`, {
          token: localStorage.getItem("jwt"),
          userId: data.id,
        })
        .then((response) => {
          res = response.status === 200 || response.status === 201;
        })
        .catch((error) => {
          console.log(error);
        });
      return res;
    },
    async getMultiAccounts({ commit, state }, data) {
      await axios
        .get(`/main-api/multiaccs/${data.id}/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            commit("set", { type: "multiaccs", items: response.data });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getAllAuthSessions({ commit, state }, data) {
      let err = false;
      const filters = data.filters;
      let query_string = "?";
      Object.keys(filters).forEach((key, index) => {
        if (filters[key].toString() !== "") {
          query_string += key.toString() + "=" + filters[key].toString() + "&";
        }
      });
      if (localStorage.getItem("jwt")) {
        await axios
          //   .get(`/main-api/informational/auth_sessions`, {
          //     params: {
          //       ip: data.ip,
          //       hash: data.hash,
          //       page: data.page,
          //       perPage: data.perPage,
          //     },

          .get(`/main-api/informational/auth_sessions` + query_string, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              const data = response.data;
              let formattedData = data.items.map((item) => {
                return {
                  ...item,
                  loadingItem: false,
                };
              });
              const concatData = {
                items: formattedData,
                total: data.total,
                size: data.size,
                page: data.page,
                pages: data.pages,
              };
              commit("set", { type: "allAuthSessions", items: concatData });
            }
          })
          .catch((error) => {
            console.log(error);
            err = true;
          });
      }
      return err;
    },
    async getAdminAuthSessions({ state }, data) {
      const filters = data.filters;
      const params = new URLSearchParams();
      for (const key in filters) {
        if (Object.hasOwnProperty.call(filters, key)) {
          const value = filters[key];

          // Check value
          if (value != null && value !== "") {
            params.append(key, value);
          }
        }
      }
      const queryString = `?${params.toString()}`;

      let res = false;
      const token = localStorage.getItem("jwt");
      if (token) {
        const headers = { Authorization: `Bearer ${token}` };
        try {
          const response = await axios.get(
            `/api/admins/auth_sessions${queryString}`,
            { headers }
          );
          if (response.status === 200) {
            state.adminAuthSessions = response.data;
            res = true;
          }
        } catch (error) {
          console.log(error);
        }
      }
      return res;
    },
    async getPlayerBonuses({ state }, data) {
      if (localStorage.getItem("jwt")) {
        let info = {};
        await axios
          .get(`/api/bonuses/user/${data.user_id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              state.playersBonuses = response.data;
              info = response.data;
            }
          })
          .catch((error) => {
            console.log(error);
          });
        return info;
      }
    },
  },
  modules: {
    redirector: redirector,
    levels,
    roles,
    geo,
    dashboard,
    reports,
    banners,
    customScript,
    playerAvatars,
    localization,
    promocodes,
    gameBlocks,
    gameModule,
    phone: phone,
    bonuses: bonuses,
    transaction,
    linksHref,
    maintenance,
    admins,
    paggination,
    minBetSettings,
    gameSettings,
    gameFee,
    aggregators,
    providers,
    jackpots,
    agents,
    themes,
    notifications,
    segments,
  },
});
