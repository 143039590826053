//admins.js;
import axios from "axios";
const state = {
  admins: [],
  newPlayer: {},
};

const mutations = {
  set(state, { type, items }) {
    state[type] = items;
  },
};

const getters = {
  getAdmins: (state) => state.admins,
};

const actions = {
  async awaitGetAdmins({ commit }) {
    return (
      axios
        /*
         */
        .get(`/api/admins/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            state.admins = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        })
    );
  },
  async awaitAddNewPlayer({ commit }, username) {
    let password = "";
    await axios
      .post(`/api/users/?username=${username}`, null, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          password = response.data.password;
        }
      })
      .catch((error) => {
        throw error;
      });
    return password;
  },
  async awaitAddAdmin({ commit }, data) {
    let res = false;
    const adminData = {
      login: data.login,
      password: data.password,
      role_id: data.role_id,
      is_active: data.is_active,
    };
    await axios
      .post(`/api/admins/`, adminData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          // commit("set", { type: "newPlayer", items: response.data });
          res = true;
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return res;
  },
  async awaitEditAdmin({ commit }, data) {
    const adminData = {
      login: data.login,
      password: data.password,
      current_password: data.current_password,
      role_id: data.role_id,
      is_active: data.is_active,
    };
    await axios.put(`/api/admins/${data.id}`, adminData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
  },
  async awaitDeleteAdmin({ commit }, admin_id) {
    let res = false;
    await axios
      .delete(`/api/admins/${admin_id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          res = true;
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return res;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
