<template>
    <div>
        <Message :closable="false" severity="error" class="fw my-0">
          {{ $t(textError) }}
      </Message>
      <Button @click="toRefreshRequest" :disabled="loadingFlag" :loading="loadingFlag" :label="$t('refresh')"
                  icon="pi pi-refresh" class="mt-1 p-button-sm p-button-success" />
    </div>
</template>

<script>
    export default {
        name: 'NoWorkPage',
        props:{
            loadingFlag:{
                type: Boolean,
                required: true,
            },
            textError: {
                type: String,
                required: true,
            },
        },
        methods: {
            toRefreshRequest(){
                this.$emit('refresh-request')
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>