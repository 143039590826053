<template>
  <Card class="sticky-filters card-custom">
            <template #title>
              <Toolbar v-if="!dataError">
                <template #start>
                  <Dropdown v-model="providerFilters.name" 
                        :options="availableProviders"
                        optionLabel="name"
                        class="custom-dropdown"
                        optionValue="string_id"
                        dataKey="id"
                              :placeholder="$t('provider')" :filter="true" :emptyFilterMessage="$t('no_data')">
                              <template #value="slotProps">
                                <div class="country-item country-item-value" v-if="slotProps.value">
                                    <div>{{getProviderNameByString(slotProps.value) }}</div>
                                </div>
                                <span v-else>
                                    {{slotProps.placeholder}}
                                </span>
                            </template>
                              <template #option="slotProps">
                                <div class="provider-complete">
                                  <div class="provider-complete__logo">
                                      <img :src="slotProps.option.logo" />
                                  </div>
                                  <div class="provider-complete__name">
                                      <div>{{slotProps.option.name}}</div>
                                  </div>
                                </div>
                            </template>
                            </Dropdown>
                            <Button @click="clearFilters" v-if="providerFilters.name" :disabled="loadingFlag" :loading="loadingFlag" :label="$t('clear')"
                              icon="pi pi-trash" class="p-button-sm p-button-danger ml-1" />
                </template>
              </Toolbar>
              <v-row>
                <v-col class="py-0">
                  <v-chip-group>
                    <v-chip class="active-filter-chip" v-for="(value, key) in filteredActiveFilters" :key="key"
                      @click="removeFilter(key)">
                      <template>
                        {{ getFilterValueTitle(key) }}: {{ getFilterValueText(key, value) }}
                      </template>
                      <v-icon @click.stop="removeFilter(key)">mdi-close</v-icon>
                    </v-chip>
                  </v-chip-group>
                </v-col>
              </v-row>
            </template>
            <template #content>
                <template v-if="!dataError">
                  <template v-if="!loadingFlag">
                    <h2 v-if="provider && provider.length === 0" class="text-uppercase my-1 c-red"> {{ $t('game_studios_not_found')
                    }} </h2>
                    <div class="provider-grid" v-else>
                      <div class="provider-grid__header">
                        <div class="provider-grid__title-header">{{ $t('supported_providers') }}</div>
                        <div class="provider-grid__actions-header">
                          <span>{{ $t('total_games') }}</span>
                          <span>{{ $t('aggrregator_name') }}</span>
                          <span>{{ $t('actions') }}</span>
                        </div>
                      </div>
              
                      <v-expansion-panels class="providers-expansion">
                        <v-expansion-panel v-for="(item, index) in filteredProviders" :key="index" 
                        :class="{'_no-active' : !item.is_enabled }"
                        
                        >
                          <v-expansion-panel-header @click="getGamesByProvider(item)">
                            <div class="provider-grid__game">
                              <div class="provider-grid__titles">
                                  <span class="text-capitalize font-weight-bold">
                                    {{ item ? item.name : '' }}
                                  </span>
                                
                            </div>
                              <div class="provider-grid__content">
                                <div class="provider-grid__total">
                                  <span class="custom-badge _green ml-1"
                >
                  <span>{{ $t('total') }}: {{ item.total_games }} </span>
                                  </span>
                                </div>
                                <div class="provider-grid__aggregators">
                                  <span class="custom-badge _main"
                                >
                  <span> {{ item.aggregator }}  {{ item.fee }}%</span>
                                  </span>
                                </div>
                              <div @click.stop class="provider-grid__actions">
                                <!-- <v-btn @click.stop="saveNewFee(item)" :loading="item.loadingSubmit" class="c-white square-btn mr-1"
                                  v-if="item.isChangeFee === true">
                                  <v-icon color="var(--white)">mdi-plus-box</v-icon>
                                </v-btn> -->
                                <!-- <v-text-field dense outlined hide-details type="number" color="var(--main-flamingo)" v-model="item.fee"
                                  prefix="%" @input="changeFeeInput(item)" :rules="[v => v >= 0 || $i18n.t('just_error')]" /> -->
                                  <!-- @input="changeFeeInput(item)" -->
                                  <!-- <ConfirmPopup v-if="item.isChangeFee === true"></ConfirmPopup> -->
                                  <v-menu
                      v-model="item.settingMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                    >
                      <template v-slot:activator="{ on }">
                          <Button icon="pi pi-pencil" 
                          :disabled="item.loadingSubmit" :loading="item.loadingSubmit" 
                           class="p-button-sm p-button-rounded p-button-warning" v-on="on" />
                       
                      </template>
                      <div class="input-menu">
                      <!-- <InputText  
                      type="number" 
                      @input="toChangeFee(item)"
                      style="max-width: 100px"
                      v-model="item.fee" 
                      class="p-inputtext-sm" :placeholder="$t('commission_fee')">
                    </InputText> -->
                    <InputNumber 
                    class="hundred-input" 
                    v-model="item.fee" 
                    :step="0.25"
                    :min="0"
                    :max="100"
                    suffix="%"
                    @input="toChangeFee(item)"
                    mode="decimal" 
                    showButtons
                    />
                    <Button @click.stop="saveNewFee(item)" :disabled="!item.isChangeFee" :loading="item.loadingSubmit" 
                    class="p-button-sm p-button-success mt-2" :label="$t('save')" icon="pi pi-save"  />
                  </div>
                    </v-menu>
                                  
                                  <!-- <Button icon="pi pi-pencil" 
                                  aria:haspopup="true" aria-controls="overlay_panel"
                                  :disabled="item.isChangeFee" :loading="item.isChangeFee"
                                   class="p-button-sm p-button-rounded p-button-warning" @click="toOpenOverlay" /> -->
                                <v-switch @click.stop v-model="item.is_enabled" :loading="item.loadingSubmit" hide-details
                                color="green accent-4" inset class="ml-3 pa-0 custom-switch "
                                style="margin-top: 0px;"
                                @change="toggleProvider(item)"></v-switch>
                                <!-- <InputSwitch @click.stop 
                                :loading="item.loadingSubmit" 
                                v-model="item.is_enabled" 
                                @change="toggleProvider(item)"
                                /> -->
                              </div>
                            </div>
                          </div>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content :class="{ '_scroll' : game.items && game.items.length > 5 }">
                            <template v-if="!item.isLoadingGame">
                              <v-list-item class="sublist-item" v-for="(game, index) in getProviderGames(item.name)" :key="index">
                                  <div class="d-flex align-center" style="flex: 1 1 auto;">
                                    <div class="item__title"
                                     >
                                     <span class="link-hover" @click="commitItem(item.name, game.name, true)">
                                       {{ game.name }}
                                     </span>
                                    </div>
                                    <!-- <v-text-field dense outlined hide-details type="number" class="item__value no-active"
                                       color="var(--main-flamingo)" v-model="comissonGame" prefix="%">
                                    </v-text-field> -->
                                    <v-chip label small color="var(--main-flamingo)">
                                      <span class="c-white">
                                      {{ game.aggregator }} 
                                      
                                      {{ game.fee_percentage }}%
                                    </span>
                                  </v-chip>
                                    <div style="flex: 0 0 35px;" class="ml-3">
                                      <Button icon="pi pi-pencil" 
                                      
                                  :disabled="item.loadingSubmit" :loading="item.loadingSubmit" 
                                   class="p-button-sm p-button-rounded p-button-warning" @click="toOpenGameSetting(game, item)" />
                                    </div>
                                  </div>
                                </v-list-item>
                                <div v-if="game.page !== game.pages">
                                  <Button v-if="!isIntersectGames" @click="applyGameFilters(item)" :disabled="item.loadingItem" :loading="item.loadingItem" 
                                  :label="$t('more')"
                                    icon="pi pi-save" class="p-button-sm p-button-success mb-1" />
                                    <ProgressSpinner v-else v-intersect="showMoreGamesIntersect" style="width:50px;height:50px" strokeWidth="4" />
                              </div>
                            </template>
                            <template v-else>
                              <v-list-item class="sublist-item d-flex flex-column align-start mt-2">
                                <v-skeleton-loader 
                                v-for="i in 1"
                                :key="i"
                                type="card"
                                min-width="200"
                                height="25"
                                class="mb-3"
                                />
                            </v-list-item>
                            </template>
                              <!-- <div v-if="game.total > 25">
                                <games-paggination 
                                :pagginationObject="filters" 
                                :requestData="game" 
                                @apply-filters="applyGameFilters(item)" 
                                />
                              </div> -->
                              <!-- @save-done="getGamesByProvider" -->
                              <game-provider-setting-dialog 
                               :settingGame="settingGame"
                               />
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </div>
                  </template>
                  <ProgressBar v-else mode="indeterminate" style="height: .5em" />
                </template>
                <no-work-page 
                v-else
                  @refresh-request="applyFilters" 
                  :loadingFlag="loadingFlag"
                  :textError="textError"
                  />
            </template>
          </Card>
  </template>
  
  <script>
  import NoWorkPage from '@/components/NoWorkPage'
  import GamesPaggination from '@/views/GamesPaggination'
  import GameProviderSettingDialog from './GameProviderSettingDialog'
  import axios from 'axios';
  import { mapGetters } from 'vuex';
  export default {
    name: "GameProviders",
    components:{
      GamesPaggination,
      GameProviderSettingDialog,
      NoWorkPage,
    },
    computed: {
      ...mapGetters({
        provider: 'providers/getFilteredProviders',
        gameFilters: 'paggination/getFilters',
        game: 'gameFee/getGamesByProvider',
      }),
      filteredProviders(){
                if (this.provider) {
                    const filterArray = this.availableProviders = this.provider
                    // console.log('filterArray', filterArray);
                    if (this.providerFilters.name) {
                        return filterArray.filter(item => item.string_id === this.providerFilters.name)
                    } else{
                        return filterArray
                    }
                } else{
                    return []
                }
      },
      filteredActiveFilters() {
        return Object.fromEntries(
          Object.entries(this.activeFilters).filter(
            ([key, value]) => key !== 'size' && key !== 'page' && (key === 'is_active' || value !== false)
          )
        );
      },
    },
    data() {
      return {
        loadingFlag: true,
        loadingSubmit: false,
        filters: {
          size: 50,
          page: 1,
          provider: null,
        },
        availableProviders: [],
        providerFilters: {
          page: 1,
          size: 100,
          name: '',
        },
        startPage: 1,
        startSize: 50,
        filterPages: 50,
        activeFilters: {},
        filterProviderArray: [],
        activeFilters: {},
        settingGame: {},
        dataError: false,
        textError: 'game_fee_error',
        isIntersectGames: false,
      }
    },
    mounted() {
      this.applyFilters();
    },
    beforeRouteLeave(to, from, next) {
  this.$store.commit('paggination/SET_FILTERS', {});
  next();
},
    methods: {
      getProviderNameByString(string_id){
        return this.availableProviders.find(provider => provider.string_id === string_id).name
      },
      commitItem(providerName, gameName, isGame = false){
        if (isGame) {
          this.$store.commit('gameModule/SET_GAME_SETTINGS', gameName);
          this.$store.commit('gameModule/SET_PROVIDER_SETTINGS', providerName);
        } else{
          this.$store.commit('gameModule/SET_GAME_SETTINGS', 'null');
          this.$store.commit('gameModule/SET_PROVIDER_SETTINGS', providerName);
        }
        this.$router.push('/games');
      },
      toOpenGameSetting(game, provider){
        // console.log('game', game);
        const gameData = {
          dialog: true,
          name: game.name,
          game_id: game.id,
          game_type: game.game_type,
          active: game.active,
          game_name: game.name,
          aggregator: game.aggregator,
          provider: provider,
          fee_percentage: game.fee_percentage,
          wagering_coefficient: game.wagering_coefficient,
          description: game.description,
          active: game.active,
          logo: game.logo,
          sub: game.sub,
          blocks: game.blocks,
          order: game.order,
        };
        this.settingGame = gameData;
        this.$store.commit('openGameProviderSettings', true);
      },
      toOpenOverlay(event){
        // console.log('event', event);
        this.$refs.providerOverlay.toggle(event);
      },
      toChangeFee(provider){
        // console.log(provider);
         if (provider.fee < 0 || provider.fee > 100) {
           provider.isChangeFee = false;
         } else {
           provider.isChangeFee = true;
         }
      },
      getProviderGames(providerName) {
        // game from selected provider
        if (this.game.items) {
          if (providerName === 'All Games' || !providerName) {
            return this.game.items;
          }
          return this.game.items.filter(game => game.prov_name === providerName);
        }
        return [];
      },
      //========================================================================================================================================================
  
      getFilterValueTitle(key) {
        // title
        switch (key) {
          case 'name':
            return this.$t('provider')
        }
  
        return key;
      },
      getFilterValueText(key, value) {
        // text
        switch (value) {
          case true:
            return this.$t('yes')
          case false:
            return this.$t('no')
        }
  
        return value;
      },
      //========================================================================================================================================================
      async toggleProvider(provider) {
        provider.loadingSubmit = true;
        const endpoint = `/api/providers/${provider.id}`;
        let requestData = new FormData();
        requestData.append("is_enabled", provider.is_enabled || false);
        requestData.append("name", provider.name || '');
        requestData.append("order", provider.order || 0);
        requestData.append("fee", provider.fee || 0);
        requestData.append("logo", provider.logo);
        try {
          const response = await axios.put(endpoint, requestData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          });
          if (response) {
            this.$toast.add({ severity: 'success', summary: this.$t('provider_edit_success'), life: 4000 })
          }
        } catch (error) {
          this.applyFilters();
          this.$toast.add({ severity: 'error', summary: this.$t('provider_edit_error'), life: 4000 })
        } finally {
          provider.loadingSubmit = false;
        }
      },
      async saveNewFee(provider) {
        if (provider.fee >= 0) {
          provider.loadingSubmit = true;
          const endpoint = `/api/providers/${provider.id}`;
          let requestData = new FormData();
          requestData.append("is_enabled", provider.is_enabled || false);
          requestData.append("name", provider.name || '');
          requestData.append("order", provider.order || 0);
          requestData.append("fee", provider.fee || 0);
          requestData.append("logo", provider.logo);
          try {
            const response = await axios.put(endpoint, requestData, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
            });
            if (response) {
              this.$toast.add({ severity: 'success', summary: this.$t('provider_edit_success'), life: 4000 })
            }
          } catch (error) {
            this.applyFilters();
            this.$toast.add({ severity: 'error', summary: this.$t('provider_edit_error'), life: 4000 })
          } finally {
            provider.isChangeFee = false;
            provider.loadingSubmit = false;
            provider.settingMenu = false;
          }
        }
      },
      //========================================================================================================================================================
      async getGamesByProvider(provider, isDialog = false) {
        const providerName = provider.string_id;
        if (this.filters.provider !== providerName || isDialog) {
          provider.isLoadingGame = true;
          this.filters.size = this.startSize;
          this.filters.page = this.startPage;
          this.filters.provider = providerName;
          await this.$store.dispatch('gameFee/getGamesByProvider', this.filters);
          provider.isLoadingGame = false;
        }
      },
      showMoreGamesIntersect(entries, observer) {
      // check instersect block
      if (entries[0].isIntersecting && this.isIntersectGames) {
        // call
        // const provider = this.filterProviderArray.find(prov => prov.string_id === this.filters.provider);
        this.applyGameFilters();
      }
    },
    async applyGameFilters(provider){
      // this.game = await this.$store.dispatch('getGames', this.gameFilters);
      this.filters.page += this.page;
      await this.$store.dispatch('gameFee/getGamesByProvider', this.filters);
      this.isIntersectGames = true;
      },
      async applyFilters() {
        this.providerFilters.page = this.startPage;
        this.providerFilters.size = this.startSize;
        this.toProvidersArray();
        for (const [key, value] of Object.entries(this.providerFilters)) {
          if (value !== '') {
            this.$set(this.activeFilters, key, value);
          } else {
            this.$delete(this.activeFilters, key, value);
          }
        }
      },
      async toProvidersArray() {
        this.loadingFlag = true;
        if (!this.provider.length) {
                try {
                    await this.$store.dispatch('providers/awaitGameProviders', this.providerFilters);
                    this.dataError = false;
                } catch (error) {
                  this.dataError = true;
                    this.$toast.add({
          severity: 'error', summary: this.$t("just_error"), life: 4000,
        })
                }
            }
        this.loadingFlag = false;
      },
      async clearFilters() {
        Object.keys(this.providerFilters).forEach((key, index) => {
          this.providerFilters[key] = ''
        })
        Object.keys(this.activeFilters).forEach((key, index) => {
          this.$delete(this.activeFilters, key);
        })
        this.filters.size = this.startSize;
        this.filters.page = this.startPage;
        //this.filters.ip = null;
        this.toProvidersArray();
      },
      async removeFilter(key) {
        this.$delete(this.activeFilters, key);
        if (typeof this.providerFilters[key] === 'boolean') {
          this.$set(this.providerFilters, key, false);
        } else {
          this.$set(this.providerFilters, key, '');
        }
        this.toProvidersArray();
      },
      //========================================================================================================================================================
  
    }
  }
  </script>
  
  <style lang="scss" scoped>
  //@mixin gridTable {
  //  
  //}
  .provider-grid {
    display: flex;
    flex-direction: column;
    &__header {
      color: var(--surface-700);
      font-size: .8rem;
      font-weight: 600;
      //display: grid;
      //grid-template-columns: 120px repeat(2, 1fr);
      display: flex;
      align-items: center;
      @media (min-width:500px){
        padding: 0 4px;
          
      }
    }
  
    &__title-header {
      display: flex;
      flex: 1 1 auto;
    }
    &__actions-header{
      flex: 0 0 295px;
      display: flex;
      align-items: center;
      gap: 20px;
      span{

      }
    }
    &__game {
      padding: 3px 10px;
      display: grid;
      grid-template-columns: 100px repeat(2, 1fr);
  
      //align-items: center;
      @media (max-width:700px) {
        display: flex;
        padding: 3px 5px;
      }
  
    
  
      &:nth-of-type(even) {
        background-color: rgb(251 251 251) !important;
      }
  
      &:nth-of-type(odd) {
        background-color: rgb(255, 255, 255) !important;
      }
    }
    
  
    &__aggregators {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      max-height: 40px;
      margin-right: 12px;
      @media (max-width:768px){
        margin-right: 6px;
      }
    }
    &__total{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-right: 12px;
      @media (max-width:768px){
        margin-right: 6px;
      }
    }
    &__content {
      
      flex: 1 1 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    &__titles{
      display: flex;
      align-items: center;
      grid-column: span 2;
      
    }
    &__actions{
      display: flex;
      align-items: center;
      .v-input{
        max-width: 90px;
      }
    }
    &__comissions {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    &__edit {}
  }
  
  .providers {
    &__title {
      color: var(--black);
  
      //justify-content:center;align-items: center;
      @media (max-width:500px) {
        flex-direction: column;
        text-align: left;
        align-items: flex-start;
        justify-content: center;
      }
  
      .v-chip {
  
        @media (min-width:500px) {
          &:last-child {
  
            margin-left: 4px;
          }
        }
  
        @media (max-width:500px) {
          &:last-child {
  
            margin-top: 4px;
          }
        }
      }
    }
  
    &__arrow {
      margin-right: 5px;
      transition: transform 0.3s !important;
  
      .v-list-group--active & {
        transform: rotate(-180deg);
      }
    }
  }
  
  .v-list-group__header__append-icon {
    .v-icon {
  
      color: var(--main-flamingo);
    }
  }
  
  .sublist-item {
    align-items: center;
    padding-left: 5px !important;
    padding-right: 32px !important;
    min-height: auto!important;
    
    &:not(:last-child) {
      padding-bottom: 3px !important;
      margin: 0 0 3px 0!important;
      border-bottom: 1px solid var(--text-color);
    }
    &:first-child{
      margin-top: 5px!important;
    }
    .v-list-item__icon {
      max-width: 30px;
  
      i {
        font-size: 20px;
        margin-right: 8px;
      }
    }
  
    .item__title {
      font-size: 14px;
      color: var(--black);
      flex: 1 1 100%;
      text-align: left;
    }
  
    .item__value {}
  }
  
  .filtersCard {
    width: 100%;
    position: -webkit-sticky;
    position: sticky;
    z-index: 2;
    top: 58px;
    border-radius: 0 !important;
  
    @media screen and (max-width: 960px),
    (max-height: 500px) and (orientation: landscape) {
      position: static;
    }
  }
  
  .input-menu {
    display: flex;
    flex-direction: column;
    background-color: var(--surface-a);
    box-shadow: none;
    padding: 10px;
    z-index: 3;
    position: relative;
    width: 100%;
  }
  </style>
  