<template>
    <v-row class="paggination ma-0" :class="{ 'paggination_static' : isStatic}">
        <v-col class="paggination__wrapper">
          <div class="paggination__quantity-lines">
            <div class="paggination__select">
              <span style="font-size: 12px">
                {{ $i18n.t('pages') }}
              </span>
              <!-- <Dropdown class="ml-2" v-model="filterPages" @change="updateFilters" :options="pageOptions"
                :emptyFilterMessage="$t('no_data')">
              </Dropdown> -->
              <v-menu
                    v-model="dropdownMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    style="max-width:5rem"
                  >
                    <template v-slot:activator="{ on }">
                      <span v-on="on" class="p-float-label p-input-icon-right">
                        <InputText readonly class="p-inputtext-sm" style="width:5rem" :placeholder="filterPages">
                        </InputText>
                        <i class="pi pi-filter" />
                      </span>
                    </template>
                    <Listbox v-model="filterPages" @change="updateFilters" :options="pageOptions" :emptyFilterMessage="$t('no_data')" />
                  </v-menu>
            </div>
            <div class="v-data-footer__pagination" style="font-size: 12px;">
              <div>{{ getPageRangeText() }}</div>
            </div>
          </div>
          <div class="paggination__actions">
            <Button icon="pi pi-angle-double-left" :disabled="(loadingFlag || requestData.page - 1 <= 0) || requestData.length === 0"
              class="p-button-rounded p-button-text p-button-plain" @click="firstPageFilter" />
            <Button icon="pi pi-angle-left" :disabled="(loadingFlag || requestData.page - 1 <= 0) || requestData.length === 0"
              class="p-button-rounded p-button-text p-button-plain" @click="previousFilters" />
            <span class="mx-2 font-weight-bold" style="font-size: 14px">
              {{ requestData.page || 1 }} / {{ requestData.pages || 1 }} 
            </span>
            <Button icon="pi pi-angle-right" :disabled="(loadingFlag || requestData.page >= requestData.pages) || requestData.length === 0"
              class="p-button-rounded p-button-text p-button-plain" @click="nextFilters" />
            <Button icon="pi pi-angle-double-right"
              :disabled="(loadingFlag || requestData.page >= requestData.pages) || requestData.length === 0"
              class="p-button-rounded p-button-text p-button-plain" @click="lastPageFilter" />

          </div>
        </v-col>
      </v-row>
</template>

<script>
    export default {
        name: 'GamesPaggination',
        props:{
            pagginationObject:{
                type: Object,
                required: true,
            },
            requestData:{
                // type: Object,
                required: true,
                //default: () => ({
                //    items: [],
                //    page: null,
                //    pages: null,
                //    size: null,
                //    total: null
                //})
            },
            isStatic: {
              type: Boolean,
              default: true,
            },
            allOptions: {
              type: Boolean,
              default: false,
            },
        },
        watch:{
          '$route': {
            handler() {
              this.$store.commit('paggination/SET_LOADING_FLAG', false);
            },
          },
          'pagginationObject': function(newValue, oldValue) {
            this.filterPages = this.pagginationObject.size;
          },
          // filterPages(newValue, oldValue) {
          //   if (newValue === 'All' || newValue === 'Все') {
          //     this.filterPages = this.requestData.total;
          //   }
          // }
        },
        data() {
            return {
              dropdownMenu: false,
                filterPages: 25,
                startPage: 1,
                startSize: 25,
                filters:{},
                // pageOptions: [10, 25, 100],
            }
        },
        beforeRouteEnter (to, from, next) {
            next(vm => {
              vm.$store.commit('paggination/SET_LOADING_FLAG', false);
            }) 
        },
        mounted() {
          this.$store.commit('paggination/SET_LOADING_FLAG', false);
        },
        computed:{
          pageOptions() {
            if (this.allOptions) {
              return [10, 25, 100, this.$t('all')];
              } else{
                return [10, 25, 100];

            }
          },
            loadingFlag() {
                
                return this.$store.state.paggination.loadingFlag;
                
            },
            isTrue(){
                return true;
            },
            isFalse(){
                return false;
            },
        },
        methods:{
            getPageRangeText() {
            const currentPage = this.pagginationObject.page || 1;
            const itemsPerPage = this.pagginationObject.size || 25;
            const totalItems = this.requestData.total || 100;
            // range list
            const startItem = (currentPage - 1) * itemsPerPage + 1;
            const endItem = Math.min(currentPage * itemsPerPage, totalItems);
                
            return `${startItem}-${endItem} ${this.$t('in')} ${totalItems}`;
            },
            async updateFilters() {
              const pages = this.$t('all')
              if (this.filterPages === pages) {
              this.filterPages = this.requestData.total;
            }
              this.dropdownMenu = false;
              this.$store.commit('paggination/SET_LOADING_FLAG', this.isTrue);
              this.filters.size = this.pagginationObject.size = this.filterPages;
              this.filters.page = this.pagginationObject.page = this.startPage;
              this.setFilters();
            },
            async nextFilters() {
                this.$store.commit('paggination/SET_LOADING_FLAG', this.isTrue);
                this.filters.page = this.pagginationObject.page += this.startPage;
                this.filters.size = this.pagginationObject.size = this.filterPages;
                this.setFilters();
            },
            async firstPageFilter() {
                this.$store.commit('paggination/SET_LOADING_FLAG', this.isTrue);
                this.filters.page = this.pagginationObject.page = this.startPage;
                this.filters.size = this.pagginationObject.size = this.filterPages;
                this.setFilters();
            },
            async lastPageFilter() {
                this.$store.commit('paggination/SET_LOADING_FLAG', this.isTrue);
                this.filters.page = this.pagginationObject.page = this.requestData.pages;
                this.filters.size = this.pagginationObject.size = this.filterPages;
                this.setFilters();
            },
            async previousFilters() {
                this.$store.commit('paggination/SET_LOADING_FLAG', this.isTrue);
                this.filters.page = this.pagginationObject.page -= this.startPage;
                this.filters.size = this.pagginationObject.size = this.filterPages;
                this.setFilters();
            },
            setFilters(){
                this.$store.commit('paggination/SET_FILTERS', this.pagginationObject);
                this.$emit('apply-filters');
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>