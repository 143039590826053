import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":_vm.formLoading,"content-class":"br-16","scrollable":"","max-width":"575px"},model:{value:(_vm.settingGameDialog),callback:function ($$v) {_vm.settingGameDialog=$$v},expression:"settingGameDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"justify-center"},[_c('h3',{staticClass:"mr-2"},[_vm._v(_vm._s(`${_vm.settingGame.name} (${_vm.settingGame.provider ? _vm.settingGame.provider.name : ''})`))]),_c(VImg,{attrs:{"max-width":"50px","height":"50px","src":_vm.settingGame.logo}})],1),_c(VCardText,{staticClass:"py-2"},[_c('div',{staticClass:"d-flex align-center justify-space-between item-down"},[_c('span',{staticClass:"just-text"},[_vm._v(" "+_vm._s(_vm.$t('commission'))+" ")]),_c('InputNumber',{staticClass:"hundred-input",attrs:{"step":1,"min":0,"max":100,"suffix":"%","mode":"decimal","showButtons":""},model:{value:(_vm.settingGame.fee_percentage),callback:function ($$v) {_vm.$set(_vm.settingGame, "fee_percentage", $$v)},expression:"settingGame.fee_percentage"}})],1),(_vm.settingGame.aggregator)?[_c('div',{staticClass:"justify-center d-flex subtitle-text"},[_c('h4',[_vm._v(_vm._s(_vm.$t('aggrregators')))])]),_c('div',{staticClass:"d-flex align-center justify-space-between item-down"},[_c('span',{staticClass:"just-text"},[_vm._v(" "+_vm._s(_vm.settingGame.aggregator)+" ")]),_c(VSwitch,{staticClass:"mt-0 ml-1 custom-switch",attrs:{"hide-details":"","label":this.$t('active'),"color":"green accent-4","inset":""},model:{value:(_vm.settingGame.active),callback:function ($$v) {_vm.$set(_vm.settingGame, "active", $$v)},expression:"settingGame.active"}})],1)]:_vm._e()],2),_c(VCardActions,[_c('Button',{staticClass:"p-button-sm p-button-success fw",attrs:{"loading":_vm.formLoading,"disabled":_vm.formLoading,"label":_vm.$t('save')},on:{"click":_vm.saveNewGameSettings}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }