<template>
  <div class="sign_up">
    <v-card @keyup.enter="signUp" class="br-16  mx-auto bg-c-main-flamingo" style="width: 320px;position:relative;z-index:2">
            <div class="nav">
              <router-link :to="{ path: '/login', query: { tab: '0' } }" class="nav-item _log" >
              {{ $t('admin') }}
            </router-link>
            <!-- :class="{ '_no-active': $route.path !== '/login?tab=0' }" -->
              <router-link :to="{ path: '/login', query: { tab: '1' } }" class="nav-item _log"
              >
              <!-- :class="{ '_no-active': $route.path !== '/login?tab=1' }" -->
                  {{ $t('agent') }}
                  {{ $route.props }}
              </router-link>
            </div>
              <v-card-title class="justify-center font-weight-bold c-white">
                {{ $i18n.t('authorization') }}
              </v-card-title>
              <v-card-text>
                <v-col>
                  <span class="p-input-icon-left mb-3 fw">
                    <i class="pi pi-user" />
                    <InputText  type="text" class="fw" v-model="username" :placeholder="$t('login')" />
                </span>
                    <span class="p-input-icon-left mb-3 fw">
                      <!-- <i class="pi pi-lock" /> -->
                      <!-- <InputText type="text" class="fw" v-model="password" :placeholder="$t('password')" /> -->
                      <Password v-model="password" class="fw" :placeholder="$t('password')" toggleMask :feedback="false"></Password>
                    </span>
                  <Button
                      :label="$t('authorization')"
                      @click="signUp"
                      :disabled="loadingFlag"
                      :loading="loadingFlag"
                      class="p-button-success p-button-sm fw"
                  >
                  </Button>
  
                </v-col>
              </v-card-text>
          </v-card>
          <div class="bg-bubble-main">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </div> 
  </div>
</template>

<script>

  import fingerprint from "../plugins/fingerprint";
  import axios from 'axios';

  export default {
    name: "Login",
    data(){
      return{
        currentTab: Number(this.$route.query.tab || 0),
        username: '',
        password: '',
        loadingFlag: false,
        errorFlag: false,
      }
    },
    async mounted(){
      if(localStorage.getItem('jwt')){
        await this.$router.push('/players')
      }
    },
    watch: {
    '$route.query.tab': function(newTab, oldTab) {
      // console.log(`Tab changed from ${oldTab} to ${newTab}`);
      this.currentTab = Number(newTab || 0);
    }
  },
    methods:{
      async signUp(){
        //if(this.errorFlag) this.errorFlag = false
        this.loadingFlag = true
        this.errorFlag = false
          this.$store.state.roles.permissions = null;
          if (this.currentTab === 1) {
            // console.log('agent');
            const [res, error] = await this.$store.dispatch('agentSignIn', {
              username: this.username,
              password: this.password,
              finger: await fingerprint.get(),
            });
            if (res) {
              await this.$store.dispatch("getAgentStatus");
              this.$router.push('/agents')
              if (this.$store.getters.getCurrencies.length === 0) {
            await this.$store.dispatch('getCurrencies', 'agent');
          }
            } else if (error) {
          if (error === 'Invalid') {
            
            this.$toast.add({ severity: 'error', summary: this.$t('error_message'), life: 4000 })
          } else if (error === 'Server') {
            this.$toast.add({ severity: 'error', summary: this.$t('just_error'), life: 4000 })
          }else if (error === 'Agent not active') {
            this.$toast.add({ severity: 'error', summary: this.$t('agent_not_active'), life: 4000 })
          }
            }
            this.loadingFlag = false
            return;
          } else{
            const [res, error] = await this.$store.dispatch('singIn', {
              username: this.username,
            password: this.password,
            finger: await fingerprint.get(),
            ip: '',
          });
          if (res) {
            await this.$store.dispatch("getStatus");
            await this.$router.push('/dashboard');
            if (this.$store.getters.getCurrencies.length === 0) {
              await this.$store.dispatch('getCurrencies');
            }
          } else if (error) {
            if (error === 'Invalid') {
              
              this.$toast.add({ severity: 'error', summary: this.$t('error_message'), life: 4000 })
            } else if (error === 'Server') {
              this.$toast.add({ severity: 'error', summary: this.$t('just_error'), life: 4000 })
            }
          } {
          }
          //setTimeout(()=>{this.errorFlag = false}, 000)
        }
        this.loadingFlag = false

      }
    }
  }
</script>

<style lang="scss" scoped>
  .input_class{
    margin-bottom: 20px; margin-top: 10px; border: solid black 3px; max-width: 300px
  }
  .login_card{
    background-color: var(--main-flamingo);
    border-radius: 45px;
    margin-top: 10vh;
    width: 650px;
    height: 600px;
    //min-height: 600px;
    //min-width: 500px;
    //position: center;
    box-shadow: 5px 5px 20px rgba(0,0,0, .3);
  }
  .sign_up{
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: absolute;
    display: flex;
    justify-content:center;
    align-items: center;
    background-color: var(--surface-a);
  }
  @media (max-width: 600px){
    .login_card{
      width: 90vw;
      height: 80vh;
      font-size: 14px!important;
    }
  }
  .bg-bubble-main{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    z-index: 1;
    li{
      position: absolute;
      list-style: none;
      display: block;
      width: 40px;
      height: 40px;
      // background-color: rgba(255, 255, 255, 0.25);
      background: url('../assets/images/bubles/heart.svg') 0 / 100% no-repeat;
      bottom: -160px;
      opacity: .3;
      -webkit-animation: bubles 25s infinite;
      animation:         bubles 25s infinite;
      
      -webkit-transition-timing-function: linear;
      transition-timing-function: linear;
      &:nth-child(1){
        left: 10%;
        background: url('../assets/images/bubles/spades.svg') 0 / 100% no-repeat;
      }
      
      &:nth-child(2){
        left: 20%;
        background: url('../assets/images/bubles/diamonds.svg') 0 / 100% no-repeat;
        width: 60px;
        height: 60px;
        
        animation-delay: 2s;
        animation-duration: 17s;
      }
      
      &:nth-child(3){
        left: 25%;
        animation-delay: 4s;
        background: url('../assets/images/bubles/clubs.svg') 0 / 100% no-repeat;
      }
      
      &:nth-child(4){
        left: 40%;
        width: 50px;
        height: 50px;
        background: url('../assets/images/bubles/spades.svg') 0 / 100% no-repeat;
        animation-duration: 22s;
        
        // background-color: rgba(255, 255, 255, 0.25);
      }
      
      &:nth-child(5){
        left: 70%;
        background: url('../assets/images/bubles/clubs.svg') 0 / 100% no-repeat;
      }
      
      &:nth-child(6){
        left: 80%;
        width: 80px;
        height: 80px;
        
        animation-delay: 3s;
        // background-color: rgba(255, 255, 255, 0.25);
      }
      
      &:nth-child(7){
        left: 32%;
        width: 90px;
        height: 90px;
        
        animation-delay: 7s;
      }
      
      &:nth-child(8){
        left: 55%;
        width: 20px;
        height: 20px;
        
        animation-delay: 15s;
        animation-duration: 40s;
      }
      
      &:nth-child(9){
        left: 25%;
        width: 10px;
        height: 10px;
        
        animation-delay: 2s;
        animation-duration: 40s;
        // background-color: rgba(255, 255, 255, 0.25);
      }
      
      &:nth-child(10){
        left: 90%;
        width: 160px;
        height: 160px;
        
        animation-delay: 11s;
      }
    }
  }
  
  @-webkit-keyframes bubles {
    0%   { transform: translateY(0); }
    100% { transform: translateY(-1500px) rotate(600deg); }
  }
  @keyframes bubles {
    0%   { transform: translateY(0); }
    100% { transform: translateY(-1500px) rotate(600deg); }
  }
  
</style>
