<template>
  <v-main>
    <div class="px-2 mt-2">
      <v-row>
        <div class="column-three">
          <v-card class="elevation-0 card-view _players">
            <v-card-text class="py-2 px-1 d-flex" style="font-size: 20px">
              <v-row class="box-column">
                <v-col class="d-flex align-center">
                  <div class="d-flex align-center justify-center box-icon">
                    <v-icon color="var(--white)">mdi-account-group</v-icon>
                  </div>
                </v-col>
              </v-row>
              <v-row class="d-flex flex-column c-white">
                <v-col class="d-flex align-center justify-center">
                  <span class=" font-weight-bold">{{ $i18n.t('players') }}</span>
                </v-col>
              </v-row>
              <div class="d-flex flex-column c-white row">
                <v-col class="d-flex align-center justify-end">
                  <v-chip v-if="!loadingFlag" dark color="var(--blue)">
                    {{ $i18n.t('today') }}: {{ cashBoxHeaders.players_today.toLocaleString() }}
                  </v-chip>
                  <v-skeleton-loader v-else type="chip" />
                </v-col>
                <v-col class="d-flex align-center justify-end">
                  <v-chip v-if="!loadingFlag" dark color="var(--blue)">
                    {{ $i18n.t('total') }}: {{ cashBoxHeaders.total_reg_count || cashBoxHeaders.total_reg_count !== null
                    ?
                    cashBoxHeaders.total_reg_count.toLocaleString() : '---' }}
                  </v-chip>
                  <v-skeleton-loader v-else type="chip" />
                </v-col>
              </div>
            </v-card-text>
          </v-card>
        </div>
        <div class="column-three">
          <v-card class="elevation-0 card-view _deposits">
            <v-card-text class="py-2 px-1 d-flex" style="font-size: 20px">
              <v-row>
                <v-col class="d-flex align-center">
                  <div class="d-flex align-center justify-center box-icon">
                    <v-icon color="var(--white)">mdi-finance</v-icon>
                  </div>
                </v-col>
              </v-row>
              <v-row class="d-flex flex-column c-white">
                <v-col class="d-flex align-center justify-center">
                  <span class=" font-weight-bold"> {{ $i18n.t('deposits') }}</span>
                </v-col>
              </v-row>
              <v-row class="d-flex flex-column c-white">
                <v-col class="d-flex align-center justify-end">
                  <v-chip v-if="!loadingFlag" dark color="var(--main-flamingo)">
                    {{ $i18n.t('today') }}: {{ cashBoxHeaders.today_deps_amount_sum.toLocaleString() + " " +
                    filters.currency }}
                  </v-chip>
                  <v-skeleton-loader v-else type="chip" />
                </v-col>
                <v-col class="d-flex align-center justify-end">
                  <v-chip v-if="!loadingFlag" dark color="var(--main-flamingo)">
                    {{ $i18n.t('total') }}: {{ cashBoxHeaders.total_deps_amount_sum.toLocaleString() + " " +
                    filters.currency }}
                  </v-chip>
                  <v-skeleton-loader v-else type="chip" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
        <div class="column-three">
          <v-card class="elevation-0 card-view _cashouts">
            <v-card-text class="py-2 px-1 d-flex" style="font-size: 20px">
              <v-row>
                <v-col class="d-flex align-center">
                  <div class="d-flex align-center justify-center box-icon">
                    <v-icon color="var(--white)">mdi-bank</v-icon>
                  </div>
                </v-col>
              </v-row>
              <v-row class="d-flex flex-column c-white">
                <v-col class="d-flex align-center justify-center">
                  <span class=" font-weight-bold"> {{ $i18n.t('withdraws') }}</span>
                </v-col>
              </v-row>
              <v-row class="d-flex flex-column c-white">
                <v-col class="d-flex align-center justify-end">
                  <v-chip v-if="!loadingFlag" dark color="var(--green-dark)">
                    {{ $i18n.t('today') }}: {{ cashBoxHeaders.today_withdraws_amount_sum.toLocaleString() + " " +
                    filters.currency }}
                  </v-chip>
                  <v-skeleton-loader v-else type="chip" />
                </v-col>
                <v-col class="d-flex align-center justify-end">
                  <v-chip v-if="!loadingFlag" dark color="var(--green-dark)">
                    {{ $i18n.t('total') }}: {{ cashBoxHeaders.total_withdraws_amount_sum.toLocaleString() + " " +
                    filters.currency }}
                  </v-chip>
                  <v-skeleton-loader v-else type="chip" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

        </div>
      </v-row>
      <div class="main-content">
          <Card class="mb-15">
            <template #title v-if="!errorFlag">
              <div class="text-left">
                <Dropdown v-model="filters.currency" @change="changeCurrency" :options="filteredCurrencies"
                  :placeholder="$t('currency')" :filter="true" :emptyFilterMessage="$t('no_data')">
                </Dropdown>
              </div>
            </template>
            <template #content>
              <template v-if="!errorFlag">
              <v-row tag="section">
                <v-col>
                <div class="d-flex jusftify-start align-center">
                  <div class="mr-1">{{ $i18n.t('choose_period') }}:</div>
                  <div>
                    <date-range-picker id="datepicker_id" :showDropdowns="true" ref="picker" :locale-data="locale_data"
                      v-model="filters.dateRange" format="mm.dd.yyyy" opens="right"
                      :max-date="today.toISOString()">
                    </date-range-picker>
                    <!-- <Calendar
                    :showButtonBar="true"
                     :viewDate="today" :touchUI="$isMobile() ? true : false" :showIcon="true" v-model="filters.dateRange"
                      :maxDate="today" dateFormat="mm.dd.yy" :locale="en" selectionMode="range" :manualInput="false" /> -->
                  </div>
                </div>
              </v-col>
            </v-row>
                <v-row tag="section">
                  <v-col>
                    
                    <!-- <v-data-table
          dense
          striped
          hover
          :items="cashBox.items"
          :headers="fieldsDataTable"
          :items-per-page="cashBox.size"
          hide-default-footer
          fixed-header
          :loading-text="this.$t('loading') + '...'"
          data-key="data"
          :footer-props="{
            'items-per-page-options': [10, 25, 100, -1],
            'items-per-page-text': this.$i18n.t('pages'),
            'next-icon': 'mdi-chevron-right',
            'prev-icon': 'mdi-chevron-left',
            'show-current-page': true,
            'show-first-last-page': true,
            }"
          no-data-text="no info"
          style="margin-top: 10px"
    
          :loading="loadingFlag"
    
      >
        <template v-slot:body.prepend v-if="!$isMobile() && !loadingFlag">
          <tr style="color:var(--white);background-color: var(--main-flamingo)!important;" class="text-left">
            <td style="font-size: 18px; font-weight: 800;" >
              Total:
            </td>
            <td>{{ totals.total_reg_count }}</td>
            <td>{{ totals.total_first_deps_count }}</td>
            <td>
              {{ totals.total_deps_count }}
            </td>
            <td style="white-space: nowrap">
              {{ totals.total_deps_amount_sum + " " + filters.currency }}
            </td>
            <td style="white-space: nowrap">
              {{ totals.total_withdraws_amount_sum + " " + filters.currency}}
            </td>
            <td style="white-space: nowrap">
              {{ totals.total_payment_system_fee + " " + filters.currency}}
            </td>
            <td style="white-space: nowrap">
              {{ totals.ggr + " " + filters.currency }}
            </td>
            <td style="white-space: nowrap">
              {{ totals.total_bonuses + " " + filters.currency }}
            </td>
    
            <td style="white-space: nowrap">
              {{ totals.ngr1 + " " + filters.currency}}
            </td>
            <td style="white-space: nowrap">
              {{ totals.revenue + " " + filters.currency }}
            </td>
          </tr>
        </template>
    
    <template v-slot:item.date="{item}">
          <div style="white-space: nowrap">
            {{ new Date(item.date).toLocaleDateString() }}
          </div>
        </template>
    
    <template v-slot:item.deps_amount_sum="{item}">
          <div style="white-space: nowrap">
            {{ item.deps_amount_sum.toLocaleString() + " " + filters.currency }} 
          </div>
        </template>
    
    <template v-slot:item.withdraws_amount_sum="{item}">
          <div style="white-space: nowrap">
            {{ item.withdraws_amount_sum.toLocaleString() + " " + filters.currency }} 
          </div>
        </template>
    
    <template v-slot:item.payment_system_fee="{item}">
          <div style="white-space: nowrap">
            {{ item.payment_system_fee.toLocaleString() + " " + filters.currency }} 
          </div>
        </template>
    
    <template v-slot:item.bonuses="{item}">
          <div style="white-space: nowrap">
            {{ item.bonuses.toLocaleString() + " " + filters.currency }} 
          </div>
        </template>
    
    <template v-slot:item.ggr="{item}">
          <div style="white-space: nowrap">
            {{ item.ggr.toLocaleString() + " " + filters.currency }} 
          </div>
        </template>
    
    <template v-slot:item.ngr1="{item}">
          <div style="white-space: nowrap">
            {{ item.ngr1.toLocaleString() + " " + filters.currency }} 
          </div>
        </template>
    
    <template v-slot:item.depositsAmount="{item}">
          <div style="white-space: nowrap">
            {{ item.depositsAmount.toLocaleString() + " " + filters.currency }} 
          </div>
        </template>
    
    <template v-slot:item.withdrawsAmount="{item}">
          <div style="white-space: nowrap">
            {{ item.withdrawsAmount.toLocaleString() + " " + filters.currency }} 
          </div>
        </template>
    
    <template v-slot:item.platformFee="{item}">
          <div style="white-space: nowrap">
            {{ item.platformFee.toLocaleString() + " " + filters.currency }} 
          </div>
        </template>
    
    <template v-slot:item.payFee="{item}">
          <div style="white-space: nowrap">
            {{ item.payFee.toLocaleString() + " " + filters.currency }} 
          </div>
        </template>
    
    <template v-slot:item.revenue="{item}">
          <div style="white-space: nowrap">
            {{ item.revenue.toLocaleString() + " " + filters.currency }} 
          </div>
        </template>
    
    <template v-slot:footer="{itemsPerPageOptions, itemsPerPage, page, totalItems }">
        </template>
    </v-data-table> -->
                    <DataTable 
                    :value="cashBox.items" 
                    :rows="cashBox.size" 
                    :loading="loadingFlag" 
                    :header="fieldsDataTable"
                    responsiveLayout="scroll"
                      stripedRows class="p-datatable-sm footer-table">
                      <Column field="date" :header="$t('date')" :styles="{'min-width': '10rem'}">
    
                        <template #footer>
                          <td class="d-flex justify-start" style="flex: 0 1 150px;font-size: 18px; font-weight: 800;">
                            Total:
                          </td>
                        </template>
    
                      </Column>
                      <Column field="reg_count" :header="$t('registrations')" >
    
                        <template #footer>
                          {{ totals.total_reg_count }}
                        </template>
                      </Column>
                      <Column field="first_deps_count" :header="$t('first_deposits')" :styles="{'min-width': '10rem'}">
    
                        <template #footer>
                          {{ totals.total_first_deps_count }}
                        </template>
                      </Column>
                      <Column field="deps_count" :header="$t('deposit_count')" :styles="{'min-width': '10rem'}">
    
                        <template #footer>
                          {{ totals.total_deps_count }}
                        </template>
                      </Column>
                      <Column field="deps_amount_sum" :header="$t('deposit_sum')" :styles="{'min-width': '10rem'}">
    
                        <template #body="{ data }">
                          {{ data.deps_amount_sum }} {{ filters.currency }}
                        </template>
    
                        <template #footer>
                          {{ totals.total_deps_amount_sum + " " + filters.currency }}
                        </template>
                      </Column>
                      <Column field="withdraws_amount_sum" :header="$t('withdraw_sum')" :styles="{'min-width': '10rem'}">
    
                        <template #body="{ data }">
                          {{ data.withdraws_amount_sum }} {{ filters.currency }}
                        </template>
    
                        <template #footer>
                          {{ totals.total_withdraws_amount_sum + " " + filters.currency }}
                        </template>
                      </Column>
                      <Column field="payment_system_fee" :header="$t('payment_fee')" :styles="{'min-width': '10rem'}">
    
                        <template #body="{ data }">
                          {{ data.payment_system_fee }} {{ filters.currency }}
                        </template>
    
                        <template #footer>
                          {{ totals.total_payment_system_fee + " " + filters.currency }}
                        </template>
                      </Column>
                      <Column field="ggr" :header="$t('ggr')" :styles="{'min-width': '10rem'}">
    
                        <template #body="{ data }">
                          {{ data.ggr }} {{ filters.currency }}
                        </template>
    
                        <template #footer>
                          {{ totals.ggr + " " + filters.currency }}
                        </template>
                      </Column>
                      <Column field="bonuses" :header="$t('bonuses')" :styles="{'min-width': '10rem'}">
    
                        <template #body="{ data }">
                          {{ data.bonuses }} {{ filters.currency }}
                        </template>
    
                        <template #footer>
                          {{ totals.total_bonuses + " " + filters.currency }}
                        </template>
                      </Column>
                      <Column field="ngr1" :header="$t('ngr')" :styles="{'min-width': '10rem'}">
    
                        <template #body="{ data }">
                          {{ data.ngr1 }} {{ filters.currency }}
                        </template>
    
                        <template #footer>
                          {{ totals.ngr1 + " " + filters.currency }}
                        </template>
                      </Column>
                    </DataTable>
                  </v-col>
                </v-row>
              </template>
              <no-work-page 
              v-else
                @refresh-request="toSubmitData" 
                :loadingFlag="loadingFlag"
                :textError="textError"
                />
          </template>
          </Card>
          <Toolbar class="paggination py-0 px-0 my-0 mr-2" v-if="!errorFlag">
            <template #end>
              <v-col class="d-flex align-center justify-end">
                <div class="v-data-footer__select">
                  <span style="font-size: 12px;">
                    {{ $i18n.t('pages') }}
                  </span>
                  <Dropdown class="ml-2" v-model="filterPages" @change="updateFilters" :options="pageOptions"
                  :emptyFilterMessage="$t('no_data')">
                </Dropdown>
                </div>
                <div class="v-data-footer__pagination" style="font-size: 12px;">
                 {{ getPageRangeText() }}
                </div>
                <Paginator :first="cashBox.page" :rows="1" :totalRecords="cashBox.pages"
    @first="firstPageFilter" @prev="previousFilters" @next="nextFilters" @last="lastPageFilter" />
              </v-col>
            </template>
        </Toolbar>
        <!-- <v-row class="paggination ma-0">
          <v-col class="paggination__wrapper">
            <div class="paggination__quantity-lines">
              <div class="v-data-footer__select">
                <span style="font-size: 12px;">
                  {{ $i18n.t('pages') }}
                </span>
                <Dropdown class="ml-2" v-model="filterPages" @change="updateFilters" :options="pageOptions"
                  :emptyFilterMessage="$t('no_data')">
                </Dropdown>
              </div>
              <div class="v-data-footer__pagination" style="font-size: 12px;">
                {{ getPageRangeText() }}
              </div>
            </div>
            <div class="paggination__actions">
              <Button icon="pi pi-angle-double-left" :disabled="loadingFlag || sessions.page - 1 <= 0"
                class="p-button-rounded p-button-text p-button-plain" @click="firstPageFilter" />
              <Button icon="pi pi-angle-left" :disabled="loadingFlag || sessions.page - 1 <= 0"
                class="p-button-rounded p-button-text p-button-plain" @click="previousFilters" />
              <span class="mx-2 font-weight-bold" style="font-size: 14px">
                {{ sessions.page }} / {{ sessions.pages }}
              </span>
              <Button icon="pi pi-angle-right" :disabled="loadingFlag || sessions.total / sessions.size < sessions.page"
                class="p-button-rounded p-button-text p-button-plain" @click="nextFilters" />
              <Button icon="pi pi-angle-double-right"
                :disabled="loadingFlag || sessions.total / sessions.size < sessions.page"
                class="p-button-rounded p-button-text p-button-plain" @click="lastPageFilter" />
    
            </div>
          </v-col>
        </v-row> -->
        </div>
      </div>
  </v-main>
</template>

<script>
import NoWorkPage from '@/components/NoWorkPage'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
//you need to import the CSS manually (in case you want to override it)
import moment from 'moment'
import { mapGetters } from 'vuex';

export default {
  name: "Cashflow",
  components: { DateRangePicker, NoWorkPage },
  computed: {
    ...mapGetters({
      cashBoxHeaders: 'cashBoxHeaders',
      currencies: 'getCurrencies',
      cashBox: 'cashBoxData',
      totals: 'cashBoxTotals',
      onlineCount: 'onlinePlayersCount'
    }),
    filteredCurrencies() {
      return this.currencies.filter(currency => currency !== 'ALL');
    },
    // ranges: {
    //   'Today': [moment(), moment()],
    //   'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    //   'Last 7 days': [moment().subtract(6, 'days'), moment()],
    //   'Last 30 days': [moment().subtract(29, 'days'), moment()],
    //   'This month': [moment().startOf('month'), moment().endOf('month')],
    //   'Last month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    // },
  },
  data() {
    return {
      // today: moment(),
      today: new Date(),
      loadingFlag: false,
      selectedRB: 2,
      en: {
                firstDayOfWeek: 0,
                dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
                dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                dayNamesMin: ["Su","Mo","Tu","We","Th","Fr","Sa"],
                monthNames: [ "January","February","March","April","May","June","July","August","September","October","November","December" ],
                monthNamesShort: [ "Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ],
                today: 'Today',
                clear: 'Clear',
                dateFormat: 'mm/dd/yy',
                weekHeader: 'Wk'
            },
      fieldsDataTable: [
        {
          value: 'date', text: this.$i18n.t('date'), sortable: true, sort: function (a, b) {
            return new Date(a) - new Date(b);
          }
        },
        { value: 'reg_count', text: this.$i18n.t('registrations'), sortable: true },
        { value: 'first_deps_count', text: this.$i18n.t('first_deposits'), sortable: true },
        { value: 'deps_count', text: this.$i18n.t('deposit_count'), sortable: true },
        { value: 'deps_amount_sum', text: this.$i18n.t('deposit_sum'), sortable: true },
        { value: 'withdraws_amount_sum', text: this.$i18n.t('withdraw_sum'), sortable: true },
        { value: 'payment_system_fee', text: this.$i18n.t('payment_fee'), sortable: true },
        { value: 'ggr', text: this.$i18n.t('ggr'), sortable: true },
        { value: 'bonuses', text: this.$i18n.t('bonuses'), sortable: true },
      ],
      dateRange: {},
      locale_data: {
        direction: 'ltr',
        format: 'mm.dd.yyyy',
        separator: ' - ',
        applyLabel: this.$i18n.t('applyLabel'),
        cancelLabel: this.$i18n.t('cancelLabel'),
        weekLabel: this.$i18n.t('weekLabel'),
        customRangeLabel: this.$i18n.t('customRangeLabel'),
        daysOfWeek: [this.$i18n.t('mon'), this.$i18n.t('tue'), this.$i18n.t('wed'), this.$i18n.t('thu'), this.$i18n.t('fri'), this.$i18n.t('sat'), this.$i18n.t('sun')],
        monthNames: [this.$i18n.t('jan'), this.$i18n.t('feb'), this.$i18n.t('mar'), this.$i18n.t('apr'), this.$i18n.t('may'), this.$i18n.t('jun'), this.$i18n.t('jul'), this.$i18n.t('aug'), this.$i18n.t('sep'), this.$i18n.t('oct'), this.$i18n.t('nov'), this.$i18n.t('dec')],
        firstDay: 1,
      },
      errorFlag: false,
      textError: 'cashbox_error',
      filters: {
        dateRange: {},
        currency: 'USD',
      },
      currentLocale: this.$i18n.locale || 'en',
      pageOptions: [10, 25, 100],
      filterPages: 25,
      page: 1,
      size: 10,
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$store.commit('setPageName', 'cashflow')
    });
  },
  async mounted() {
    this.filters.dateRange = { startDate: moment().subtract(6, 'days'), endDate: moment() }
  },
  watch: {
    async 'filters.dateRange'() {
      await this.rangeChange()
    },
    async 'filters.currency'() {
      await this.changeCurrency()
    }

  },
  methods: {
    getPageRangeText() {
      const currentPage = this.page;
      const itemsPerPage = this.filterPages;
      const totalItems = this.cashBox.total;
      // диапазон отображаемых записей
      const startItem = (currentPage - 1) * itemsPerPage + 1;
      const endItem = Math.min(currentPage * itemsPerPage, totalItems);

      return `${startItem}-${endItem} ${this.$t('in')} ${totalItems}`;
    },
    async nextFilters() {
        this.filters.page = this.page += 1;
        this.filters.size = this.filterPages;
        this.toSubmitData(this.filters.page, this.filters.size);
    },
    async firstPageFilter() {
        this.filters.page = this.page = 1;
        this.filters.size = this.filterPages;
        this.toSubmitData(this.filters.page, this.filters.size);
    },
    async lastPageFilter() {
      if (!this.loadingFlag) {
        this.loadingFlag = true
        this.filters.page = this.page = this.cashBox.pages;
        this.filters.size = this.filterPages;
        await this.toSubmitData(this.filters.page, this.filters.size);
        this.loadingFlag = false
      }
    },
    async previousFilters() {
      if (!this.loadingFlag) {
        this.loadingFlag = true
        this.filters.page = this.page -= 1;
        this.filters.size = this.filterPages;
        await this.toSubmitData(this.filters.page, this.filters.size);
        this.loadingFlag = false
      }
    },

    updateFilters() {
      this.filters.page = this.page = 1;
      this.filters.size = this.filterPages;
      this.toSubmitData(this.filters.page, this.filters.size);
    },
    getChartSize(heightFlag) {
      //var height = ((window.screen.height - 300 ) / 6);
      if (heightFlag) {
        return 200
      } else {
        return 1024;
      }

    },
    changeCurrency() {
      this.toSubmitData(this.filters.page, this.filters.size);
    },
    rangeChange() {
      this.toSubmitData(this.filters.page, this.filters.size);

    },
    async toSubmitData(page, size) {
      this.loadingFlag = true;
      let startDate = moment(this.filters.dateRange.startDate).format('YYYY-MM-DD');
      let endDate = moment(this.filters.dateRange.endDate).format('YYYY-MM-DD');
      const res = await this.$store.dispatch('getCashBoxData', {
        dateStart: startDate,
        dateEnd: endDate,
        page: page,
        size: size,
        currency: this.filters.currency,
      });
      if (!res) {
        this.errorFlag = true;
        this.$toast.add({ severity: 'error', summary: this.$t('just_error'), life: 4000 });  
      }
      this.loadingFlag = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.header-button {}

.dark_green_color {
  color: #0a5d00
}

.blue_color {
  color: #3c3ccc
}

#btn-radios-2 {
  width: 100%
}
</style>
