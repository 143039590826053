<template>
  <v-main>
    <!--<v-col v-if="$store.getters['redirector/token'] === null">
      <v-card style="max-width: 400px;margin:0 auto;margin-top: 100px" v-on:keyup.enter="auth">
        <v-card-title>{{$i18n.t('authorization')}}</v-card-title>
        <v-card-text>
          <v-text-field label="{{$i18n.t('login')}}" v-model="login"></v-text-field>
          <v-text-field label="{{$i18n.t('password')}}" type="password" v-model="pass"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-col style="text-align: right;" class="pa-0">
            <v-btn style="text-transform: none" color="primary" :loading="loading" @click="auth">
              {{$i18n.t('loginButton')}}
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-col>-->
    <v-row v-if="loading" class="mt-12">
      <v-col>
        <v-progress-circular indeterminate color="var(--main-flamingo)" size="150">
          {{$i18n.t('loading')}}
        </v-progress-circular>
      </v-col>
    </v-row>
    <v-col v-if="!loading" class="d-flex flex-column align-center">
      <div class="d-flex align-center">
        <span class="mr-2">{{$i18n.t('redirector_addr')}}:</span>
          <a :href="redirectorUrl" target="_blank">{{redirectorUrl}}</a>
          <span>ip: 212.121.212.1</span>
          <v-icon class="ml-4" @click="changeBaseUrlDialog = true" color="var(--red)">edit</v-icon>
          
      </div>

      <v-row class="mt-4 " align="center">
        <h3 class="mr-4">{{$i18n.t('activeMirrors')}}:</h3>
        <v-btn class="bg-c-red c-white" style="text-transform: none" @click="createDialog = true">
          <v-icon>add</v-icon>
          {{$i18n.t('add')}}
        </v-btn>
      </v-row>
      <v-row
          v-for="activeMirror in mirrors.active"
          style="background: var(--green-success);align-items: center"
          :key="activeMirror.url"
      >
        <v-col style="max-width: max-content">
          {{activeMirror.url}}
        </v-col>
        <v-col style="text-align: left">
          <v-btn dark color="var(--red)" @click="toggleDeleteDialog(activeMirror)">
            <v-icon>delete</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <div class="mt-4 text-left">
        <h3>{{$i18n.t('deletedMirrors')}}</h3>
      </div>
      <v-row
          v-for="deletedMirror in mirrors.deleted"
          style="background: var(--red)"
          :key="deletedMirror.url"
      >
        <v-col>
          {{deletedMirror.url}}
        </v-col>
      </v-row>
    </v-col>
    <v-dialog v-model="deleteDialog" max-width="fit-content" content-class="br-16" :transition="false">
      <v-card  style="width: 500px">
        <v-card-title>
          {{$i18n.t('deleteLink')}}?
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="deletingUrl.url" label="Url" disabled></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-col style="text-align: right">
            <v-btn color="red" :loading="modalLoading" style="text-transform: none" dark @click="deleteUrl">
              <v-icon>delete</v-icon>
              {{$i18n.t('delete')}}
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="createDialog" content-class="br-16" max-width="fit-content">
      <v-card style="width: 500px">
        <v-card-title>
          {{$i18n.t('addLink')}}
        </v-card-title>
        <v-card-text>
          <v-text-field outlined hide-details color="var(--main-flamingo)" dense v-model="newUrl" label="Url" ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-col style="text-align: right">
            <v-btn color="var(--green-success)" :loading="modalLoading" style="text-transform: none" dark @click="addUrl">
              <v-icon>add</v-icon>
              {{$i18n.t('add')}}
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="changeBaseUrlDialog" content-class="br-16" max-width="fit-content">
      <v-card>
        <v-card-title>
          {{$i18n.t('changeBaseUrl')}}
        </v-card-title>
        <v-card-text>
          {{$i18n.t('enterNewRedirectorLink')}}
          <v-text-field outlined hide-details color="var(--main-flamingo)" dense v-model="newBaseUrl" prefix="https://"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-col style="text-align: right">
            <v-btn color="var(--green-success)" :loading="modalLoading" style="text-transform: none" dark @click="changeBaseUrl">
              <v-icon>save</v-icon>
              {{$i18n.t('save')}}
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-main>
</template>


<script>
  import {mapGetters} from 'vuex';

  export default {
    name: "Redirector",
    computed: {
      ...mapGetters({
        mirrors: 'redirector/mirrors',
        redirectorUrl: 'redirector/redirectorUrl'
      })
    },
    data() {
      return {
        loading: false,
        login: '',
        pass: '',
        deleteDialog: false,
        deletingUrl: {
          url: '',
          id: 0,
        },
        createDialog: false,
        newUrl: '',
        modalLoading: false,
        changeBaseUrlDialog: '',
        newBaseUrl: ''
      }
    },
    async mounted() {
      this.$store.commit('setPageName', '')
      this.loading = true;
      await this.$store.dispatch('redirector/getAllMirrors');
      this.loading = false;
    },
    methods: {
      async auth() {
        this.loading = true;
        await this.$store.dispatch('redirector/auth', {login: this.login, pass: this.pass});
        this.loading = false;
      },
      toggleDeleteDialog(url) {
        this.deletingUrl = url;
        this.deleteDialog = true;
      },
      async addUrl() {
        this.modalLoading = true;
        let res = await this.$store.dispatch('redirector/addMirror', {url: this.newUrl});
        this.modalLoading = false;
        this.createDialog = !res;
        if (res) {
          this.newUrl = '';
        }
      },
      async deleteUrl() {
        this.modalLoading = true;
        let res = await this.$store.dispatch('redirector/deleteMirror', {id: this.deletingUrl.id});
        this.modalLoading = false;
        this.deleteDialog = !res;
        if (res) {
          this.deletingUrl = {
            url: '',
            id: 0,
          };
        }
      },
      async changeBaseUrl() {
        this.modalLoading = true;
        let res = await this.$store.dispatch('redirector/changeBaseUrl', {url: this.newBaseUrl});
        this.modalLoading = false;
        this.changeBaseUrlDialog = !res;
        if (res) {
          this.newBaseUrl = '';
        }

      }
    }
  }
</script>

<style scoped>

</style>