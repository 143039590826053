<template>
    <div>
        <DataTable 
            :value="aggregators"
            :rows="10" 
            :loading="loadingFlag" 
            responsiveLayout="scroll"
              :scrollable="true" 
              stripedRows
              class="p-datatable-sm">
              <template #empty>
                {{ $t('no_data') }}
              </template>
              <Column sortable field="id" header="ID" :showFilterMenu="false">
                <template #body="{data}">
                    <div>{{ data.id }}</div>
                </template>
                <template #filter>
                  <!-- <InputText type="text"
                  @input="debounceFilters"
                  @keydown.enter="applyFilters" v-model="filters.user_ids[0]" class="p-inputtext-sm"
                    placeholder="ID" /> -->
                </template>
              </Column>
              <Column sortable field="name" :header="$t('name')" :showFilterMenu="false">
                <template #body="{data}">
                    <div class="d-flex align-center">
                        <b>{{ data.name }}</b>
                    </div>
    
                </template>
              </Column>
              <Column sortable field="is_enabled" :header="$t('active')" :showFilterMenu="false" >
                <template #body="{data}">
                    <v-switch
                    v-model="data.is_enabled"
                                :loading="data.loadingItem"
                                @change="toggleAggregator(data)" 
                                hide-details 
                                color="green accent-4"
                                inset class="mt-0 ml-1 custom-switch" />
                </template>
              </Column>
              <Column sortable field="allowed_to_agents" :header="$t('allowed_to_agents')" :showFilterMenu="false" >
                <template #body="{data}">
                    <v-switch
                    v-model="data.allowed_to_agents"
                                :loading="data.loadingItem"
                                @change="toggleAggregator(data)" 
                                hide-details 
                                color="green accent-4"
                                inset class="mt-0 ml-1 custom-switch" />
                </template>
              </Column>
              <Column field="refresh" :header="$t('refresh_games')" :showFilterMenu="false" >
                <template #body="{data}">
                    <Button :loading="data.loadingItem" v-tooltip.left="$t('tooltip_refresh_games')" icon="pi pi-refresh" class="p-button-rounded p-button-success" @click="refreshGames(data)" />
                    <Button :loading="data.loadingItem" icon="pi pi-pencil" class="p-button-rounded p-button-warning ml-2" @click="selectAggregator(data)" />
                </template>
              </Column>
            </DataTable>
            <edit-aggregator 
        @aggregator-close="dialogClose"
        @aggregator-update="getAggregators"
        :aggregatorDialog="aggregatorDialog" :aggregator="editedAggregator" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EditAggregator from './EditAggregator.vue';
    export default {
        components:{
            EditAggregator
        },
        name: "GameAggregators",
        computed: {
            ...mapGetters({
                aggregators: 'aggregators/getAggregators',
            })
        },
        data() {
            return {
                loadingFlag: false,
                aggregatorDialog: false,
                editedAggregator: {

                },
            }
        },
        methods:{
            selectAggregator(aggr){
                this.editedAggregator = JSON.parse(JSON.stringify(aggr));;
                this.aggregatorDialog = true;
            },
            dialogClose(){
                this.aggregatorDialog = false;
                // this.editedAggregator = {};
            },
            async toggleAggregator(aggr){
                aggr.loadingItem = true;
                const aggregator = {
                        id: aggr.id,
                      string_id: aggr.string_id,
                      name: aggr.name,
                      is_enabled: aggr.is_enabled,
                      allowed_currencies: aggr.allowed_currencies,
                      allowed_to_agents: aggr.allowed_to_agents,
                    };
                try {
                    await this.$store.dispatch('aggregators/editAggregator', aggregator);
                    this.getAggregators();
                } catch (error) {
                    this.$toast.add({severity:'error', summary: this.$t('just_error'), detail: error.message, life: 4000})
                } finally{
                    aggr.loadingItem = false;
                }
            },
            async refreshGames(aggr){
                aggr.loadingItem = true;
                try {
                    await this.$store.dispatch('aggregators/refreshAggregatorGames', aggr.id);
                    this.$toast.add({severity:'success', summary: this.$t('games_refreshed'), life: 4000});
                    // this.getAggregators();
                } catch (error) {
                    this.$toast.add({severity:'error', summary: this.$t('just_error'), detail: error.message, life: 4000})
                } finally{
                    aggr.loadingItem = false;
                }
            },
            async getAggregators() {
                
                    this.editedAggregator = {};
                    try {
                        this.loadingFlag = true;
                        await this.$store.dispatch('aggregators/getAggregators');
                        
                    } catch (error) {
                        this.$toast.add({severity:'error', summary: this.$t('just_error'), detail: error.message, life: 3000})
                    } finally{
                        this.loadingFlag = false;
                    }
                
            }
        },
        async mounted() {
            if (!this.aggregators.length) {
                this.getAggregators();
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>