import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,[_c(VCardTitle,{staticClass:"font-weight-bold justify-center"},[_c('span',[_vm._v(_vm._s(_vm.$i18n.t("info_new_player")))])]),_c(VCardText,{staticClass:"pb-0 pt-1"},_vm._l((_vm.info),function(key,index){return _c('div',{key:index,staticClass:"d-flex align-center"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t(key.title))+": ")]),_c(VSpacer),_c('span',{staticClass:"font-weight-bold c-text"},[(key.value !== 0)?_c('span',[_vm._v(_vm._s(key && key.value ? key.value : '---'))]):_c('span',[_vm._v(_vm._s(key && key.value ? key.value : '0'))])])],1)}),0),_c(VCardActions,[_c('span',{staticClass:"p-buttonset fw"},[_c('Button',{staticClass:"p-button-sm p-button-warning _set",attrs:{"label":_vm.$t('add_more')},on:{"click":_vm.addMorePlayer}}),_c('Button',{staticClass:"p-button-sm p-button-success _set",attrs:{"label":_vm.$t('save_close')},on:{"click":_vm.saveAndClose}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }