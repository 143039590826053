//localization.js;
import axios from "axios";
const state = {
  localizations: [],
  legalLocalizations: [],
};

const mutations = {
  SET_LOCALIZATIONS(state, local) {
    state.localizations = local;
  },
  SET_LEGAL_LOCALIZATIONS(state, local) {
    state.legalLocalizations = local;
  },
};

const getters = {
  getLocalizations: (state) => state.localizations,
  getLegalLocalizations: (state) => state.legalLocalizations,
};

const actions = {
  async awaitGetLocalizations({ commit }, domain) {
    let res = false;
    await axios
      .get(`/main-api/translations/?domain=${domain}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          res = true;
          commit("SET_LOCALIZATIONS", response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return res;
  },

  async awaitCreateLocalizations({ commit, dispatch }, localization) {
    let res = false;
    await axios
      .post(`/main-api/translations/`, localization, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          res = true;
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 409) {
          error = "Resource already exists";
        }
      });
    return res;
  },
  async awaitUpdateLocalizations(
    { commit, dispatch },
    { domain, language_code, localData }
  ) {
    await axios
      .put(
        `/main-api/translations/${language_code}?domain=${domain}`,
        localData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {})
      .catch((err) => {
        throw err;
      });
  },
  async awaitUpdateOrders({ commit, dispatch }, localData) {
    await axios
      .put(`/main-api/translations/update_order`, localData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {})
      .catch((err) => {});
  },
  async deleteLocalization({ commit, dispatch }, localData) {
    try {
      const token = localStorage.getItem("jwt");

      await axios.delete(
        `/main-api/translations/${localData.language_code}?domain=${localData.domain}`,
        {
          data: {
            language_code: localData.language_code,
            domain: localData.domain,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {}
  },
  //========================================================================================================================================================
  async awaitGetLegalLocalizations({ commit }) {
    let res = false;
    await axios
      .get(`/main-api/translations/legal-pages/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          res = true;
          commit("SET_LEGAL_LOCALIZATIONS", response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return res;
  },
  async awaitCreateLegalLocalizations({ commit, dispatch }, localization) {
    let res = false;
    const localData = {
      language_code: localization.language_code,
      json_data: JSON.parse(localization.json_data),
    };
    await axios
      .post(`/main-api/translations/legal-pages/`, localData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          res = true;
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 409) {
          error = "Resource already exists";
        }
      });
    return res;
  },
  async awaitUpdateLegalLocalizations(
    { commit, dispatch },
    { language_code, localData }
  ) {
    let res = false;
    const data = {
      language_code: language_code,
      json_data: localData.json_data,
    };
    // console.log("localData", localData.json_data);
    await axios
      .put(`/main-api/translations/legal-pages/${language_code}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          res = true;
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 409) {
          error = "Resource already exists";
        }
      });
    return res;
  },
  async deleteLegalLocalization({ commit, dispatch }, localizationCode) {
    let res = false;
    try {
      const token = localStorage.getItem("jwt");
      await axios.delete(
        `/main-api/translations/legal-pages/${localizationCode}`,
        {
          data: {
            language_code: localizationCode,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return (res = true);
    } catch (error) {
      return (res = false);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
