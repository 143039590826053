//promocodes.js;
import axios from "axios";
const state = {
  promocodes: [],
  isEditPromo: false,
};

const mutations = {
  SET_PROMOCODES(state, promocodes) {
    state.promocodes = promocodes;
  },
  SET_EDIT_PROMO(state, value) {
    state.isEditPromo = value;
  },
};

const getters = {
  getPromocodes: (state) => state.promocodes,
};

const actions = {
  async awaitGetPromocodes({ commit }) {
    let err = false;
    await axios
      .get(`/main-api/promocodes/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          const promoData = response.data.map((promocode) => ({
            ...promocode,
            loadingItem: false,
          }));
          commit("SET_PROMOCODES", promoData);
        }
      })
      .catch((error) => {
        console.log(error);
        err = true;
      });
    return err;
  },
  async awaitCreatePromocode({ commit, dispatch }, promo) {
    const promoData = {
      code: promo.code,
      description: promo.description,
      is_active: promo.is_active,
      expiration_date: promo.expiration_date,
      dep_bonus_id: promo.dep_bonus_id,
      free_rounds_bonus_id: promo.free_rounds_bonus_id,
      max_uses: promo.max_uses,
    };
    await axios
      .post(`/main-api/promocodes/`, promoData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
        }
      })
      .catch((err) => {
        throw err;
        if (err.response && err.response.status === 409) {
          error = "Resource already exists";
        }
      });
  },
  async awaitUpdatePromocode({ commit, dispatch }, promo) {
    const promoData = {
      description: promo.description,
      is_active: promo.is_active,
      expiration_date: promo.expiration_date,
      dep_bonus_id: promo.dep_bonus_id,
      free_rounds_bonus_id: promo.free_rounds_bonus_id,
      max_uses: promo.max_uses,
    };
    await axios
      .put(`/main-api/promocodes/${promo.code}`, promoData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {})
      .catch((err) => {
        throw err;
      });
  },
  async deletePromocode({ commit, dispatch }, promocodeId) {
    try {
      const token = localStorage.getItem("jwt");

      await axios.delete(`/main-api/promocodes/${promocodeId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
