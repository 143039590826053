import axios from "axios";
export default {
  namespaced: true,
  state: {
    segments: {
      items: [],
    },
    selectedSegment: {},
    conditionFields: null,
    traitsToExport: [],
  },
  getters: {
    getSegments: (state) => state.segments,
    getSegment: (state) => state.selectedSegment,
    getConditionsFields: (state) => state.conditionFields,
    getTraitsToExport: (state) => state.traitsToExport,
  },
  mutations: {
    set(state, { type, items }) {
      state[type] = items;
    },
    UPDATE_SEGMENT(state, segment) {
      const index = state.segments.items.findIndex((s) => s.id === segment.id);
      if (index !== -1) {
        state.segments.items.splice(index, 1, segment);
      } else {
        console.error("Segment not found");
      }
    },
  },
  actions: {
    async awaitGetTraitsToExport({ commit }) {
      if (localStorage.getItem("jwt")) {
        await axios
          .get(`/api/segments/info/traits_to_export`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              commit("set", {
                type: "traitsToExport",
                items: response.data,
              });
            }
          })
          .catch((error) => {
            console.log(error);
            throw error;
          });
      }
    },
    async awaitGetConditionFields({ commit }) {
      if (localStorage.getItem("jwt")) {
        await axios
          .get(`/api/segments/info/condition_fields`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              commit("set", {
                type: "conditionFields",
                items: response.data,
              });
            }
          })
          .catch((error) => {
            console.log(error);
            throw error;
          });
      }
    },
    async awaitGetSegments({ commit }) {
      if (localStorage.getItem("jwt")) {
        await axios
          .get(`/api/segments/`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              const segments = response.data.items.map((s) => {
                return { ...s, loadingItem: false };
              });
              const data = { ...response.data, items: segments };
              commit("set", {
                type: "segments",
                items: data,
              });
            }
          })
          .catch((error) => {
            console.log(error);
            throw error;
          });
      }
    },
    async awaitGetSegmentByID({ commit }, id) {
      if (localStorage.getItem("jwt")) {
        await axios
          .get(`/api/segments/${id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              commit("set", {
                type: "selectedSegment",
                items: response.data,
              });
            }
          })
          .catch((error) => {
            console.log(error);
            throw error;
          });
      }
    },
    async createSegment({}, segment) {
      await axios
        .post(`/api/segments/`, segment, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
    async updateSegment({ commit }, segment) {
      await axios
        .patch(`/api/segments/${segment.id}`, segment, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            commit("UPDATE_SEGMENT", segment);
          }
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
    async updateUsersBySegment({ commit }, id) {
      let status = "";
      await axios
        .post(`/api/segments/${id}/sync`, null, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            status = response.data.total_users_in_segment;
          }
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
      return status;
    },
    async awaitExportTraits({ commit }, { segment, traits }) {
      await axios
        .post(`/api/segments/export_to_customerio/${segment}`, traits, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
          }
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
    async deleteSegment({}, id) {
      await axios
        .delete(`/api/segments/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
  },
  modules: {},
};
