//jackpots.js;
import axios from "axios";
const state = {
  jackpots: [],
};

const mutations = {
  set(state, { type, items }) {
    state[type] = items;
  },
};

const getters = {
  getJackpots: (state) => state.jackpots,
};

const actions = {
  async awaitGetJackpots({ commit }, agent) {
    const header = {
      Authorization: `Bearer ${localStorage.getItem("jwt")}`,
    };
    let endpoint;
    if (agent) {
      endpoint = `/api/jackpots/${agent}`;
    } else {
      endpoint = "/api/jackpots/my";
    }
    await axios
      .get(endpoint, { headers: header })
      .then((response) => {
        if (response && response.status === 200) {
          const formattedData = response.data.map((el) => ({
            ...el,
            loadingItem: false,
          }));
          commit("set", { type: "jackpots", items: formattedData });
        }
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  async awaitCreateJackpot({ commit }, data) {
    const header = {
      Authorization: `Bearer ${localStorage.getItem("jwt")}`,
    };
    await axios
      .post("/api/jackpots/", data, {
        headers: header,
      })
      .then((response) => {})
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  async awaitEditJackpot({ commit }, data) {
    const header = {
      Authorization: `Bearer ${localStorage.getItem("jwt")}`,
    };
    await axios
      .put(`/api/jackpots/${data.id}`, data, {
        headers: header,
      })
      .then((response) => {})
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  async awaitDeleteJackpot({ commit }, id) {
    const header = {
      Authorization: `Bearer ${localStorage.getItem("jwt")}`,
    };
    await axios
      .delete(`/api/jackpots/${id}`, {
        headers: header,
      })
      .then((response) => {})
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
