<template>
  <div>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col >
            <v-text-field label="от" type="date" v-model="filters.minDate"></v-text-field>
          </v-col>
          <v-col >
            <v-text-field label="до" type="date" v-model="filters.maxDate"></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions style="text-align: right; padding-bottom: 0px">
        <v-row style="align-content: space-between">
          <v-col>
            <v-btn size="lg" @click="clearFilters" style="text-transform: none">
              <v-icon>
                delete
              </v-icon>
              {{ $i18n.t('clear') }}
            </v-btn>
          </v-col>
          <v-col style="max-width: max-content">
            <v-btn size="lg" @click="applyFilters" color="primary" style="text-transform: none" class="mr-2">
              <v-icon>search</v-icon>
              {{ $i18n.t('find') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-data-table
        :items="stats"
        :headers="headers"
        :loading="loading"
        fixed-header
        height="40vh"
        style="max-height: 40%"
        class="mt-4"
    >
      <template v-slot:item.date="{item}">
        <div style="white-space: nowrap">
          {{new Date(item.date).toLocaleDateString()}}
        </div>
      </template>
    </v-data-table>

  </div>
</template>

<script>
  import Api from "../api";
  export default {
    name: "StatisticTab",
    props: {
      affiliate: {
        required: true,
        default: null
      }
    },
    data() {
      return {
        headers:[
          { value: 'date', text: 'Date', sortable: true, sort: function (a, b) {
              return new Date(a) - new Date(b);
            }    },
          { value: 'clicks', text: 'Clicks', sortable: true,   },
          { value: 'uniqueClicks', text: 'Hit', sortable: true,   },
          { value: 'regs', text: 'Reg', sortable: true,   },
          { value: 'regs/clicks', text: 'Regs/clicks', sortable: true,   },
          { value: 'firstDeposits', text: 'FD count', sortable: true,   },
          { value: 'fd/reg', text: 'Fd/reg', sortable: true,   },
          { value: 'allDeposits', text: 'Dep count', sortable: true,   },
          { value: 'firstDepositsSum', text: 'FD sum', sortable: true,   },
          { value: 'depositsSum', text: 'Dep sum', sortable: true,   },
          { value: 'withdraws', text: 'Withdraw count', sortable: true,   },
          { value: 'withdrawSum', text: 'Withdraws sum', sortable: true,   },
          { value: 'bet', text: 'Bet', sortable: true,   },
          { value: 'win', text: 'Win', sortable: true,   },
          { value: 'bonus', text: 'Bonus', sortable: true,   },
          { value: 'revenue', text: 'WM profit', sortable: true,   },
        ],
        providerOptions: [],
        filters: {
          aff: '',
          minDate: new Date().toISOString().split('T')[0],
          maxDate: new Date(Date.now() + 86400 * 3 * 1000).toISOString().split('T')[0],
        },
        loading: false,
        stats: [],

      }
    },
    async mounted() {
      await this.applyFilters();
    },
    methods: {
      async applyFilters() {

        this.loading = true;
        console.log('mount stat tab!', this.loading)
        this.filters.aff = this.affiliate.id;
        this.stats = await Api.getAffiliateStat({filters: this.filters});
        this.loading = false;
      },
      async clearFilters() {
        this.filters.minDate =  new Date().toISOString().split('T')[0];
        this.filters.maxDate = new Date().toISOString().split('T')[0];
        //await this.$store.dispatch('playersFilters', {filters: this.filters});
      }
    }
  }
</script>

<style scoped>

</style>
