<template>
    <v-dialog
          v-model="dialog"
          max-width="450px"
          :persistent="formLoading"
          content-class="br-16"
        >
          <v-card
          v-if="showAdd"
          >
            <v-card-title class="font-weight-bold flex-column align-start">
              <span>{{ $i18n.t("add_new_player") }}</span>
            </v-card-title>
            <ValidationObserver ref="observer" v-slot="{ invalid, validate }">
              <v-card-text class="pb-0 pt-1 text-left space-y-2 form-block">
                <ValidationProvider
                  name="username"
                  rules="required|min3"
                  v-slot="{ errors, validate, validated }"
                > 
                  <h4 class="c-text white-space title-input">
                    {{ $t('username') }}<span class="c-red">*</span>:
                  </h4>
                  <div class="d-flex flex-column wrapper-input">
                    <InputText 
                    autocomplete="off"
                    :maxlength="12"
                    v-model="addUser.username" 
                    class="p-inputtext-sm fw"
                    :class="{ 'p-invalid' : errors.length > 0 }"
                      :placeholder="$t('username')" 
                      @keydown.enter="confirmAddPlayer" 
                      
                      />
                    <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                  </div>
              </ValidationProvider>
                <ValidationProvider
                  name="currency"
                  rules="required"
                  v-slot="{ errors, validate, validated }"
                > 
                  <h4 class="c-text white-space title-input">
                    {{ $t('currency') }}<span class="c-red">*</span>:
                  </h4>
                  <v-menu v-model="currencyMenu" 
                  offset-y
                  :close-on-content-click="false" 
                      nudge-left="0" 
                      nudge-right="0"
                      >
                      <template v-slot:activator="{ on }">
                        <div class="d-flex flex-column wrapper-input" v-on="on">
                        <span class="p-input-icon-right"  >
                          <i class="pi pi-angle-down" />
                            <InputText 
                            v-model="addUser.currency"
                            readonly class="p-inputtext-sm fw" :placeholder="$t('currency')">
                            </InputText>
                            </span>
                            </div>
                      </template>
                        <Listbox v-model="addUser.currency" :options="currencies"
                        :filter="true"
                        @change="currencyMenu = false"
                        class="custom-dropdown"
                        :emptyFilterMessage="$t('no_data')"
                        style="width:auto" />
          
                    </v-menu>
                  </ValidationProvider>
            </v-card-text>
            <v-card-actions>
              <v-col style="text-align: right" class="pa-0">
                <Button @click="handleSubmit(validate)" :disabled="formLoading" :loading="formLoading"
                   :label="$t('save')"
                    icon="pi pi-save" class="p-button-sm p-button-success fw" />
              </v-col>
            </v-card-actions>
            </ValidationObserver>
          </v-card>
          <info-about-new-player
          :newPlayer="addUser"
          @add-new="openAdd"
          @close-popup="closeAll"
          v-else
          />
        </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import InfoAboutNewPlayer from './InfoAboutNewPlayer'
    export default {
        name: 'AddNewPlayer',
        components:{
          InfoAboutNewPlayer
        },
        props: {
            toAddDialog: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                formLoading: false,
                dialog: false,
                showAdd: true,
                currencyMenu: false,
                addUser: {
                    username: '',
                    currency: 'USD',
                    password: ''
                }
            }
        },
        computed:{
          ...mapGetters({
            currencies: 'getFilteredCurrencies',
          })
        },
        watch:{
          'addUser.currency'(newVal, oldVal){
            if (!newVal) {
              this.addUser.currency = oldVal || 'USD';

            }
          },
          dialog: {
            handler(newVal) {
              if (newVal === false) {
                this.$emit('dialog');
              }
            },
            immediate: true
          },
          toAddDialog: {
            handler(newVal) {
              this.dialog = newVal;
            },
            immediate: true
          }
        },
        methods: {
          openAdd(){
            this.showAdd = true;
            this.addUser.username = '';
          },
          closeAll(){
            this.dialog = false;
            this.$emit('dialog');
            this.addUser.username = '';
            this.showAdd = true;
          },
          handleSubmit(validate) {
            validate().then(valid => {
              if (valid) {
                this.confirmAddPlayer();
              }
            })
          },
            async confirmAddPlayer(){
              this.formLoading = true;
          try {
            const password = await this.$store.dispatch('admins/awaitAddNewPlayer', this.addUser.username);
            this.$toast.add({severity: 'success', summary: this.$t('player_added'), life: 4000}) 
            this.addUser.password = password;
            this.showAdd = false;
            this.$emit('filter-new-player', this.addUser.username);
          } catch (error) {
            if (error.response.data && error.response.status === 409) {
              this.$toast.add({severity: 'error', summary: this.$t('player_already_exists'), life: 4000}) 
              this.addUser.username = '';
            } else{
              this.$toast.add({severity: 'error', summary: this.$t('just_error'), life: 4000}) 
              
            }
          } finally{
            this.formLoading = false;
          }
          
        },

        },
    }
</script>

<style lang="scss" scoped>

</style>