<template>
  <v-main>
    <div class="main-content">
    <Card class="sticky-filters mb-90 card-custom" v-on:keyup.enter="applyFilters">
        <template #title>
          <Toolbar>
            <template #start>
              <div class="d-flex justify-center">
                <h3>{{ $i18n.t('search') }}
      
                  {{ $i18n.t('found') }}: {{ sessions.total ? sessions.total : 0 }}</h3>
              </div>
            </template>
            <template #end>
                <div class="filters-column">
                  <span class="p-buttonset ml-2">
                    <Button @click="clearFilters" :disabled="loadingFlag" :loading="loadingFlag" :label="$t('clear')"
                      icon="pi pi-trash" class="p-button-sm p-button-danger" />
                    <Button @click="applyFilters" :disabled="loadingFlag" :loading="loadingFlag" :label="$t('search')"
                      icon="pi pi-search" class="p-button-sm p-button-success" />
                  </span>
                </div>
              </template>
            </Toolbar>
                <v-chip-group>
                  <v-chip class="active-filter-chip" v-for="(value, key) in filteredActiveFilters" :key="'filter' + key"
                  >
                  <template>
                    {{ getFilterValueTitle(key) }}: {{ getFilterValueText(key, value) }}
                  </template>
                  <v-icon @click.stop="removeFilter(key)">mdi-close</v-icon>
                </v-chip>
              </v-chip-group>
          </template>
          <template #content>
        <DataTable :value="sessions.items" data-key="id" :rows="sessions.size" :loading="loadingFlag"
        :filters.sync="filters" 
        responsiveLayout="scroll"
        filterDisplay="row" :scrollable="true" scrollHeight="flex" stripedRows
        class="p-datatable-sm">
            <template #empty>
              {{ $t('no_data') }}
            </template>
        <Column field="user_id" header="ID" :sortable="true" :showFilterMenu="false" :styles="{'min-width': '12rem'}">
          <template #body="{ data }">
            <router-link class="c-main-flamingo" :to="`player/${data.user_id}`">
              {{ data.user_id }}
            </router-link>
            <div class="ml-1" v-if="data.username">({{ data.username }})</div>
          </template>
          <template #filter>
            <InputText type="text" @input="debounceFilters"  v-model="filters.user_id" class="p-inputtext-sm"
              placeholder="ID" />
          </template>
        </Column>
        <Column field="date" :header="$t('date')" :showFilterMenu="false" :styles="{'min-width': '14rem'}">
          <template #body="{ data }">
            <div style="white-space: nowrap">
              {{ new Date(data.date).toLocaleString() }}
            </div>
          </template>
          <template #filter>
            <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on }">
                  <InputText v-on="on" readonly class="p-inputtext-sm" style="width: 200px;" :placeholder="$t('date')"
                  v-model="getFilteredDates">
                </InputText>
                </template>
                <Calendar class="calendar-menu" :placeholder="$t('date')" :inline="true" :maxDate="today" id="icon"
                dateFormat="mm.dd.yy" v-model="datesToFilter" selectionMode="range" />
              </v-menu>
          </template>
        </Column>
        <Column field="ip" header="IP" :showFilterMenu="false" :styles="{'min-width': '17rem'}">
          <template #body="{ data }">
            <div>
              {{ data.ip }} 
            </div>
          </template>
          <template #filter>
            <InputText type="text" @input="debounceFilters"  style="width: 200px;" v-model="filters.ip" class="p-inputtext-sm"
              placeholder="IP" />
          </template>
        </Column>
        <Column field="multiaccs_count" 
        v-if="!isAgent"
        :sortable="true" :header="$t('multi_accounts')" :showFilterMenu="false" :styles="{ 'width': '6rem' }">
          <template #body="{ data }">
            <v-chip v-if="data.multiaccs_count" 
            small 
            label
            :disabled="data.loadingItem"
            color="var(--light-flamingo)"
            style="color: var(--white);cursor: pointer;"
            @click="findMultiaccs(data)"
            >
              {{ data.multiaccs_count }}
            </v-chip>
          </template>
          <template #filter>
            <InputNumber
            :min="1"
            :step="1"
            showButtons
             @input="debounceFilters"  v-model="filters.multiaccs_num" 
              :placeholder="$t('multi_accounts')" />
            
          </template>
        </Column>
        <Column field="hash" header="Finger hash" :showFilterMenu="false" :styles="{ 'min-width': '40rem' }">
          <template #body="{ data }">
            <div>
              {{ data.hash }}
            </div>
          </template>
          <template #filter>
            <InputText type="text" @input="debounceFilters"  style="width: 200px;" v-model="filters.hash" class="p-inputtext-sm"
            placeholder="Finger hash" />
          </template>
        </Column>
        <games-paggination 
                              :pagginationObject="filters" 
                              :requestData="sessions" 
                              @apply-filters="setAndSubmitFilters" 
                              :isStatic="false"
                              />
      </DataTable>
      </template>
    </Card>
  </div>
  </v-main>
</template>

<script>
import { debounce } from 'lodash'
import {mapGetters} from 'vuex';
import GamesPaggination from '@/views/GamesPaggination'
export default {
  name: "AllAuthSessions",
  components:{
    GamesPaggination,
  },
  computed: {
    ...mapGetters({
      sessions: 'allAuthSessions',
      gameFilters: 'paggination/getFilters',
      multiaccs: 'getMultiaccs',
      tokenUser: 'tokenUser',
    }),
    isAgent() {
      return this.tokenUser === "agent";
    },
    filteredActiveFilters() {
  return Object.fromEntries(
    Object.entries(this.activeFilters).filter(
      ([key, value]) => key !== 'size' && key !== 'page' && (key === 'is_active' || value !== false)
    )
  );
},
getFilteredDates(){
      if (this.datesToFilter.length > 0) {
        let one;
        let two;
          const formattedDates = this.datesToFilter.map(dateStr => {
            const date = new Date(dateStr);
            return date.toISOString().split('T')[0];
          });
          const formattedDateOne = formattedDates[0];
          const formattedDateTwo = formattedDates[1];
          if (this.datesToFilter.length === 2 && this.datesToFilter[1]) {
            // both data
            one = formattedDateOne;
            two = formattedDateTwo;
            return `${one} - ${two}`
          } else {
            // only one
            one = formattedDateOne;
            return `${one} - ${one}`
          }
        } else{
          return ''
        }
    },
  },
  data() {
    return {
      loadingFlag: false,
      filters: {
          user_id: '',
          ip: '',
          hash: '',
          //multiaccs_num: '',
      },
      dateMenu: false,
      datesToFilter: [],
      today: new Date(),
      currentLocale: this.$i18n.locale || 'en',
      startPage: 1,
      page: 1,
      perPage: 100,
      size: 25,
      filterPages: 25,
      pageOptions: [10, 25, 100],
      activeFilters: {},
    }
  },
  beforeRouteEnter(to, from, next){
    next(vm => {
      vm.$store.commit('setPageName', 'players_authorization_logs')
    });
    },
  async mounted() {
    this.applyFilters();
  },
  watch:{
    datesToFilter(newValue, oldValue){
            if (newValue[0] && newValue[1]) {
              this.applyFilters()
            }
          },
  },
  methods: {
    async findMultiaccs(item) {
      // console.log(item);
      item.loadingItem = true;
      const dataId = {
        id: item.user_id,
      }
      // return;
      try {
      await this.$store.dispatch('getMultiAccounts', dataId);
      // console.log('ids', ids);
      this.$nextTick(() => 
      this.$router.push({
          path: '/players',
          params: { user_ids: this.multiaccs.multiaccs },
        })
      );
    } catch (error) {
      this.$toast.add({ severity: 'error', summary: this.$t('just_error'), life: 3000 })
    } finally {
      item.loadingItem = false;
      
    }
    },
    debounceFilters: debounce(async function() {
      await this.applyFilters();
    }, 750),
    getFilterValueTitle(key) {
      // chip keys
      switch (key) {
        case 'user_id':
          return 'id'
        case 'multiaccs_num':
          return this.$t('num_of_multi')
        case 'start_date':
          return this.$t('min_date')
        case 'end_date':
          return this.$t('max_date')
      }

      return key;
    },
    getFilterValueText(key, value) {
      // chip values
      switch (value) {
        case true:
          return this.$t('yes')
        case false:
          return this.$t('no')
      }

      return value;
    },
    getPageRangeText() {
      const currentPage = this.page; 
      const itemsPerPage = this.filterPages; 
      const totalItems = this.sessions.total;
      const startItem = (currentPage - 1) * itemsPerPage + 1;
      const endItem = Math.min(currentPage * itemsPerPage, totalItems);

      return `${startItem}-${endItem} ${this.$t('in')} ${totalItems}`;
    },
    async setAndSubmitFilters() {
      this.loadingFlag = true;
      const err = await this.$store.dispatch('getAllAuthSessions', { filters: this.gameFilters });
      err ? this.$toast.add({ severity: 'error', summary: this.$t('just_error'), life: 3000 }) : '';
      for (const [key, value] of Object.entries(this.filters)) {
        if (value !== '') {
          this.$set(this.activeFilters, key, value);
        } else {
          this.$delete(this.activeFilters, key, value);
        }
      }
      this.$store.commit('paggination/SET_LOADING_FLAG', false);
      this.loadingFlag = false;
    },
    applyFilters() {
      this.filters.page = this.page = this.startPage;
      this.filters.size = this.filterPages;
      this.getFormattedDate();
      this.$store.commit('paggination/SET_FILTERS', this.filters);
      this.setAndSubmitFilters();
    },
    getFormattedDate(){
      if (this.datesToFilter.length > 0) {
          const formattedDates = this.datesToFilter.map(dateStr => {
            const date = new Date(dateStr);
            return date.toISOString().split('T')[0];
          });
          const formattedDateOne = formattedDates[0];
          const formattedDateTwo = formattedDates[1];
          if (this.datesToFilter.length === 2) {
            // both data
            this.filters.start_date = formattedDateOne;
            this.filters.end_date = formattedDateTwo;
          } else if (this.datesToFilter.length === 1) {
            // only one
            this.filters.start_date = formattedDateOne;
          }
        }
    },
    clearFilters(){
      this.loadingFlag = true;
        Object.keys(this.filters).forEach((key, index) =>{
          this.filters[key] = ''
        })
        Object.keys(this.activeFilters).forEach((key, index) => {
        this.$delete(this.activeFilters, key);
        })
        this.datesToFilter =[],
        this.filters.size = this.filterPages;
        this.filters.page = this.page = this.startPage;
        this.setAndSubmitFilters();
    },
    removeFilter(key) {
      this.loadingFlag = true;
      // Delete chip
      this.$delete(this.activeFilters, key);
      // empty string
      if (typeof this.filters[key] === 'boolean') {
        this.$set(this.filters, key, false);
      } else {
        this.$set(this.filters, key, '');
      }
      if (key === 'start_date') {
        this.datesToFilter.splice(0, 1);
      }
      if (key === 'end_date') {
        this.datesToFilter.splice(1, 1); // $delete(this.datesToFilter[1]);
      }
      this.setAndSubmitFilters();
    },
  }
}
</script>

<style lang="scss" scoped>
.sticky-card{
  position: sticky;
  top: 60px;
  background-color: var(--white);
  z-index: 2;
  @media screen  and (max-width: 960px), (max-height: 500px) and (orientation: landscape) {
    position: static;
  }
}
.input-menu{
  color: rgba(0,0,0,.87);
  border: 1px solid rgba(0,0,0,.38);
  border-radius: 8px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  padding: 0 12px;
  cursor: pointer;
  align-items: center;
  position: relative;
  &__menu{
    background-color: var(--white);
    box-shadow: none;
    padding: 10px;
    z-index: 3;
    position: relative;
    width: 100%;
    .v-input{
      margin-top: 8px;
      &:first-child{
        margin-top: 0px;
      }
    }
  }
}
.item{
  @media (max-width:600px){
      flex: 0 1 50%;
      max-width: none!important;
  }
}
</style>
