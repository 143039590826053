//aggregators.js;
import axios from "axios";
const state = {
  aggregators: [],
};

const mutations = {
  set(state, { type, items }) {
    state[type] = items;
  },
};

const getters = {
  getAggregators: (state) => state.aggregators,
};

const actions = {
  async getAggregators({ commit }) {
    return (
      axios
        /*
         */
        .get(`/api/aggregators/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            const aggregators = response.data.map((el) => ({
              ...el,
              loadingItem: false,
            }));
            commit("set", { type: "aggregators", items: aggregators });
          }
        })
        .catch((error) => {
          console.log(error);
        })
    );
  },
  async editAggregator({ commit }, aggregator) {
    await axios
      .put(`/api/aggregators/${aggregator.id}`, aggregator, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {})
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  async refreshAggregatorGames({ commit }, id) {
    await axios.post(`/api/aggregators/${id}/refresh-games`, null, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
