import Vue from "vue";
import App from "./App.vue";
import router from "./router/router.js";
import VueRouter from "vue-router";
import store from "./store/store.js";
import VueMobileDetection from "vue-mobile-detection";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import PrimeVue from "./plugins/vueprime";
import ToastService from "primevue/toastservice";
import close_modal_button from "./components/close_modal_button.vue";
import back_button from "./components/back_button.vue";
import PagginationListbox from "./components/PagginationListbox.vue";
import ItemLoading from "./components/ItemLoading.vue";
import NoWorkPage from "./components/NoWorkPage";
import GamesPaggination from "./views/GamesPaggination";
Vue.config.productionTip = false;
import { sync } from "vuex-router-sync";
Vue.use(VueRouter);
sync(store, router);
// interceptor response
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      if (
        router.currentRoute.path !== "/" &&
        router.currentRoute.path !== "/login"
      ) {
        store.commit("logOut");
        router.push("/login");
      } else {
        location.reload();
      }
    }
    return Promise.reject(error);
  }
);
import { i18n } from "./plugins/i18n";

import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import {
  required,
  min_value,
  max_value,
  email,
  min,
} from "vee-validate/dist/rules";
extend("required", {
  ...required,
  message: i18n.t("required_field"),
});
// extend("decimal", decimal);
extend("min_value", min_value);
extend("max_value", max_value);
// extend("min", {
//   ...min,
//   message: i18n.t("not_valid_password"),
// });
extend("min3", {
  ...min,
  params: ["length"],
  message: i18n.t("min_length_3"),
  validate(value, { length }) {
    return value.length >= 3;
  },
});
extend("min_less_than_max", {
  params: ["min", "max"],
  validate(value, { min, max }) {
    return min <= max;
  },
  message: (fieldName, placeholders) => {
    return `${i18n.t("min_dep")} ${i18n.t("min_less_than_max")} ${
      placeholders.max
    }`;
  },
});
// min:8
extend("min8", {
  ...min,
  params: ["length"],
  message: i18n.t("not_valid_password"),
  validate(value, { length }) {
    return value.length >= 8;
  },
});
extend("min", min);
extend("email", {
  ...email,
  message: i18n.t("not_valid_email"),
});
// import DisableAutocompleteMixin from "vue-disable-autocomplete";
// Vue.use(DisableAutocompleteMixin);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

Vue.use(VueMobileDetection);
Vue.use(PrimeVue);
Vue.use(VueToast);
Vue.use(ToastService);
Vue.component("VueCtkDateTimePicker", VueCtkDateTimePicker);
Vue.component("PagginationListbox", PagginationListbox);
Vue.component("backButton", back_button);
Vue.component("closeModalButton", close_modal_button);
Vue.component("NoWorkPage", NoWorkPage);
Vue.component("ItemLoading", ItemLoading);
Vue.component("GamesPaggination", GamesPaggination);
new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
