import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VRow,{staticStyle:{"padding":"10px"}},[_c(VCol,{staticClass:"text-lg-right text-md-right text-sm-center",attrs:{"cols":"12"}},[_c(VBtn,{staticStyle:{"text-transform":"none"},attrs:{"size":"lg"},on:{"click":function($event){_vm.modalFlag = true}}},[_c(VIcon,[_vm._v("add")]),_vm._v(" "+_vm._s(_vm.$i18n.t('add'))+" ")],1)],1)],1),_c(VDataTable,{staticStyle:{"margin-top":"10px"},attrs:{"items":_vm.affiliate.offers,"headers":_vm.fields,"id":"players_table_id","sticky-header":"58vh","loading":_vm.loadingFlag,"no-data-text":_vm.$i18n.t('offers_not_available')}}),_c(VDialog,{attrs:{"max-width":"fit-content"},model:{value:(_vm.modalFlag),callback:function ($$v) {_vm.modalFlag=$$v},expression:"modalFlag"}},[_c(VCard,{staticStyle:{"width":"300px"}},[_c(VCardTitle,[_c('h3',[_vm._v(_vm._s(_vm.$i18n.t('add_offer')))])]),_c(VCardText,[_c('div',[_c(VRow,{staticStyle:{"padding":"10px"}},[_c(VCol,[_c(VSelect,{staticStyle:{"max-width":"600px"},attrs:{"label":_vm.$i18n.t('choose_offer'),"items":[{value: 0, text: 'Revshare'}]}})],1)],1)],1)]),_c(VCardActions,[_c(VCol,[_c(VBtn,{staticStyle:{"text-transform":"none"},attrs:{"size":"lg"},on:{"click":function($event){_vm.modalFlag = false}}},[_vm._v(" "+_vm._s(_vm.$i18n.t('add'))+" ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }