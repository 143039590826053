//maintenance.js;
import axios from "axios";
const state = {
  is_blocked: false,
};

const mutations = {};

const getters = {};

const actions = {
  async awaitGetMaintenance({ commit, state }) {
    await axios
      .get(`/api/geo-settings/maintenance_block`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          state.is_blocked = response.data.blocked;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async awaitToggleMaintenance({ commit, state }, data) {
    let res = false;
    let stateMaintenance;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    };
    // const response = await axios.put(`/main-api/banners/${banner_id}`, data, {
    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //     Authorization: `Bearer ${localStorage.getItem("jwt")}`,
    //   },
    // });
    await axios
      .put(`/api/geo-settings/maintenance_block?blocked=${data}`, null, config)
      .then((response) => {
        if (response.status === 200) {
          res = true;
          stateMaintenance = response.data.blocked;
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return [res, stateMaintenance];
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
