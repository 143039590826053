import Vue from "vue";
import PrimeVue from "primevue/config";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Toolbar from "primevue/toolbar";
import Dropdown from "primevue/dropdown";
import Card from "primevue/card";
import Calendar from "primevue/calendar";
import Paginator from "primevue/paginator";
import Textarea from "primevue/textarea";
import FileUpload from "primevue/fileupload";
import AutoComplete from "primevue/autocomplete";
import Toast from "primevue/toast";
import Checkbox from "primevue/checkbox";
import TabPanel from "primevue/tabpanel";
import TabView from "primevue/tabview";
import Listbox from "primevue/listbox";
import Message from "primevue/message";
import RadioButton from "primevue/radiobutton";
import Skeleton from "primevue/skeleton";
import Knob from "primevue/knob";
import Chart from "primevue/chart";
import Chip from "primevue/chip";
import Tag from "primevue/tag";
import Password from "primevue/password";
import Divider from "primevue/divider";
import ColumnGroup from "primevue/columngroup";
import Row from "primevue/row";
import DataView from "primevue/dataview";
import ImagePreview from "primevue/imagepreview";
import Dialog from "primevue/dialog";
import ConfirmationService from "primevue/confirmationservice";
import ConfirmPopup from "primevue/confirmpopup";
import OverlayPanel from "primevue/overlaypanel";
import BadgeDirective from "primevue/badgedirective";
import Badge from "primevue/badge";
import Tooltip from "primevue/tooltip";
import SelectButton from "primevue/selectbutton";
import InputNumber from "primevue/inputnumber";
import ProgressBar from "primevue/progressbar";
import InputSwitch from "primevue/inputswitch";
import ColorPicker from "primevue/colorpicker";
import TriStateCheckbox from "primevue/tristatecheckbox";
import Panel from "primevue/panel";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import ProgressSpinner from "primevue/progressspinner";
import Menu from "primevue/menu";
import TreeTable from "primevue/treetable";
import InputMask from "primevue/inputmask";
import MultiSelect from "primevue/multiselect";
Vue.use(ConfirmationService);
Vue.use(PrimeVue);
Vue.directive("tooltip", Tooltip);
Vue.directive("badge", BadgeDirective);

Vue.component("MultiSelect", MultiSelect);
Vue.component("InputMask", InputMask);
Vue.component("TreeTable", TreeTable);
Vue.component("Menu", Menu);
Vue.component("ProgressSpinner", ProgressSpinner);
Vue.component("AccordionTab", AccordionTab);
Vue.component("Accordion", Accordion);
Vue.component("Panel", Panel);
Vue.component("TriStateCheckbox", TriStateCheckbox);
Vue.component("ColorPicker", ColorPicker);
Vue.component("InputSwitch", InputSwitch);
Vue.component("ProgressBar", ProgressBar);
Vue.component("InputNumber", InputNumber);
Vue.component("SelectButton", SelectButton);
Vue.component("Badge", Badge);
Vue.component("OverlayPanel", OverlayPanel);
Vue.component("ConfirmPopup", ConfirmPopup);
Vue.component("Dialog", Dialog);
Vue.component("ImagePreview", ImagePreview);
Vue.component("DataView", DataView);
Vue.component("ColumnGroup", ColumnGroup);
Vue.component("Row", Row);
Vue.component("Divider", Divider);
Vue.component("Password", Password);
Vue.component("Tag", Tag);
Vue.component("Chip", Chip);
Vue.component("Chart", Chart);
Vue.component("Knob", Knob);
Vue.component("Skeleton", Skeleton);
Vue.component("RadioButton", RadioButton);
Vue.component("Message", Message);
Vue.component("Listbox", Listbox);
Vue.component("TabPanel", TabPanel);
Vue.component("TabView", TabView);
Vue.component("Checkbox", Checkbox);
Vue.component("Toast", Toast);
Vue.component("AutoComplete", AutoComplete);
Vue.component("FileUpload", FileUpload);
Vue.component("Textarea", Textarea);
Vue.component("Paginator", Paginator);
Vue.component("Calendar", Calendar);
Vue.component("Card", Card);
Vue.component("Dropdown", Dropdown);
Vue.component("Toolbar", Toolbar);
Vue.component("DataTable", DataTable);
Vue.component("Column", Column);
Vue.component("Button", Button);
Vue.component("InputText", InputText);

export default PrimeVue;
