import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":_vm.formLoading,"content-class":"br-16","scrollable":"","max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_c('h3',[_vm._v(_vm._s(_vm.$t('edit_aggregator'))+" - "+_vm._s(_vm.aggregator.name))])]),_c(VCardText,{staticClass:"pb-0 py-2"},[_c('div',{staticClass:"bonus-block__header"},[_c(VCheckbox,{staticClass:"mt-0 mb-2",attrs:{"color":"var(--main-flamingo)","hide-details":"","label":_vm.$t('all_currencies')},model:{value:(_vm.isAllAllowedCurrencies),callback:function ($$v) {_vm.isAllAllowedCurrencies=$$v},expression:"isAllAllowedCurrencies"}}),_c(VSelect,{staticClass:"auto",attrs:{"hide-details":"auto","rules":[v => _vm.selectedAllowedCurrencies.length > 0 || _vm.$t('required_field')],"multiple":"","disabled":_vm.isAllAllowedCurrencies,"menu-props":{contentClass: 'main-list'},"items":_vm.filteredCurrencies,"label":_vm.$t('allowed_currencies'),"outlined":"","dense":"","color":"var(--main-flamingo)"},model:{value:(_vm.selectedAllowedCurrencies),callback:function ($$v) {_vm.selectedAllowedCurrencies=$$v},expression:"selectedAllowedCurrencies"}})],1)]),_c(VCardActions,[_c(VSpacer),_c('Button',{staticClass:"p-button-sm p-button-success fw",attrs:{"icon":"pi pi-save","loading":_vm.formLoading,"label":_vm.$t('save')},on:{"click":function($event){return _vm.updateAggregator(_vm.aggregator)}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }