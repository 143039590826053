// linksHref.js
const state = {
  linkHref: "",
};

const mutations = {
  setLinkHref(state, value) {
    state.linkHref = value;
  },
};

const getters = {};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
