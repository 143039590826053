//geo.js;
import axios from "axios";
const state = {
  geoSettings: {
    items: [],
  },
};

const mutations = {};

const getters = {
  getGeoSettings: (state) => state.geoSettings,
};

const actions = {
  async awaitGetGeoSettings({ state }, filters) {
    const params = new URLSearchParams();
    for (const key in filters) {
      if (Object.hasOwnProperty.call(filters, key)) {
        const value = filters[key];
        // Check value
        if (value != null && value !== "") {
          params.append(key, value);
        }
      }
    }
    const queryString = `?${params.toString()}`;
    await axios
      .get(`/api/geo-settings/${queryString}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          // console.log("response.data", response.data);
          state.geoSettings = response.data;
        }
      })
      .catch((error) => {
        console.log("error = ", error);
      });
  },
  async updateGeoSetting({ state }, data) {
    let res = false;
    const countryData = {
      license: data.license,
      redirect_url: data.redirect_url,
      blocked: data.blocked,
    };
    try {
      const response = await axios.put(
        `/api/geo-settings/${data.id}`,
        countryData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      if (response.status === 200) {
        res = true;
      }
    } catch (error) {
      console.error("error =", error);
    }

    return res;
  },
  async updateActiveGeo({ state }, data) {
    let res = false;
    const countryData = {
      license: data.license,
      redirect_url: data.redirect_url,
      blocked: !data.blocked,
    };
    try {
      const response = await axios.put(
        `/api/geo-settings/${data.id}`,
        countryData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      if (response.status === 200) {
        res = true;
      }
    } catch (error) {
      console.error("error =", error);
    }

    return res;
  },
  async deleteGeoSetting({ commit, dispatch }, ids) {
    const params = new URLSearchParams();
    for (const key in ids) {
      if (Object.hasOwnProperty.call(ids, key)) {
        const value = ids[key];
        params.append("id", value);
      }
    }
    const queryString = `?${params.toString()}`;
    const token = localStorage.getItem("jwt");

    await axios.delete(`/api/geo-settings/${queryString}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
