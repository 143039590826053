//transaction.js;
import axios from "axios";
const state = {
  transaction: [],
};

const mutations = {
  SET_TRANSACTION(state, transaction) {
    state.transaction = transaction;
  },
};

const getters = {
  getTransaction: (state) => state.transaction,
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
