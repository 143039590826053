//Role.js;
import axios from "axios";
const state = {
  roles: [],
  permissions: null,
};

const mutations = {
  SET_ROLES(state, roles) {
    state.roles = roles;
  },
  set(state, { type, items }) {
    state[type] = items;
  },
};

const getters = {
  getPermissions: (state) => state.permissions,
  getRole: (state) => state.roles,
  elementsPlayerPermission(state) {
    const token = state.permissions;
    if (!token) {
      return;
    }
    return token.ui_permissions
      .filter(
        (permission) =>
          permission.section === "player" ||
          (permission.section === "all" && permission.elements)
      )
      .reduce((acc, permission) => acc.concat(permission.elements), []);
  },
};

const actions = {
  async awaitGetRoles({ commit }) {
    return axios
      .get(`/api/roles/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          let formattedRole = response.data.map((item) => {
            return {
              ...item,
              isActive_loading: false,
            };
          });
          commit("set", { type: "roles", items: formattedRole });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async awaitGetPermissions({ commit, rootState }) {
    const tokenUser = rootState.tokenUser;
    let res;
    await axios
      .get(`/api/roles/permissions?role=${tokenUser}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          commit("set", { type: "permissions", items: response.data });
          res = response.data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return res;
  },
  async awaitCreateRole({ commit, dispatch }, role) {
    let res = false;
    const roleData = {
      role: role.role,
      ui_permissions: role.ui_permissions,
      is_active: role.is_active,
    };
    await axios
      .post(`/api/roles`, roleData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          res = true;
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log("error create role: ", err);
        }
      });
    return res;
  },
  // async awaitUpdateRole({ commit, dispatch }, role) {
  //   let res = false;
  //   const roleData = {
  //     role: role.role,
  //     ui_permissions: role.ui_permissions,
  //     is_active: role.is_active,
  //   };
  //   await axios
  //     .put(`/api/roles/${role.id}`, roleData, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("jwt")}`,
  //       },
  //     })
  //     .then((response) => {
  //       if (response.status === 200) {
  //         res = true;
  //       }
  //     })
  //     .catch((err) => {
  //       if (err.response) {
  //         console.log("error create role: ", err);
  //       }
  //     });
  //   return res;
  // },
  // async awaitDeleteRole({ commit, dispatch }, id) {
  //   let res = false;
  //   await axios
  //     .delete(`/api/roles/${id}`, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("jwt")}`,
  //       },
  //     })
  //     .then((response) => {
  //       if (response.status === 200) {
  //         res = true;
  //       }
  //     })
  //     .catch((err) => {
  //       if (err.response) {
  //         console.log("error delete role: ", err);
  //       }
  //     });
  //   return res;
  // },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
