//gameBlocks.js;
import axios from "axios";
const state = {
  gameBlocks: [],
  providersArray: [],
  gamesArray: [],
};

const mutations = {
  set(state, { type, items }) {
    state[type] = items;
  },
};

const getters = {
  getGamesArray: (state) => state.gamesArray,
  getGameBlocks: (state) => state.gameBlocks,
  getProvidersArray: (state) => state.providersArray,
};

const actions = {
  async awaitGetGameBlocks({ commit }) {
    await axios
      .get(`/api/game_blocks/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          const gameBlocks = response.data;
          const formattedGameBlocks = gameBlocks.map((block) => ({
            block_id: block.id,
            block_name: block.name,
            block_order: block.block_order,
            // gameLoading: false,
            games: [],
            is_active: block.is_active,
            loadingBlock: false,
          }));
          commit("set", { type: "gameBlocks", items: formattedGameBlocks });
        }
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },

  async awaitGetGameBlockGames({ commit }, params) {
    let games = [];
    const queryString = Object.keys(params)
      .filter((key) => params[key] !== undefined && params[key] !== null)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      )
      .join("&");
    await axios
      .get(`/main-api/games/admin/filter?${queryString}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          const { items, ...responseWithoutItems } = response.data;
          const paggination = responseWithoutItems;
          games = items.map(({ Game }) => Game);
        }
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
    return games;
  },
  async awaitCreateGameBlocks({ commit, dispatch }, block) {
    const blockData = {
      name: block.name,
      block_order: block.block_order,
      is_active: block.is_active,
    };
    await axios
      .post(`/api/game_blocks/`, blockData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {})
      .catch((err) => {
        throw err;
      });
  },
  async awaitUpdateGameBlocks({ commit, dispatch }, block) {
    // const blockData = {
    //   block_name: block.block_name,
    //   order: block.order,
    //   block_id: block.block_id,
    //   is_active: block.is_active,
    // };
    await axios
      .post(`/api/game_blocks/update_order`, block, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {})
      .catch((err) => {
        throw err;
      });
  },
  async awaitUpdateGameOrders({ commit, dispatch }, games) {
    await axios
      .post(`/api/game_blocks/update_order/games`, games, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {})
      .catch((err) => {
        throw err;
      });
  },
  async awaitAddGameToGameBlocks({ commit, dispatch }, newGame) {
    let res = false;
    // let error = false;
    const newGameData = {
      block_id: newGame.block_id,
      game_id: newGame.game_id,
    };
    await axios
      .post(`/api/game_blocks/games`, newGameData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 204) {
          res = true;
        }
      })
      .catch((err) => {});
    return res;
  },
  async awaitDeleteGameToGameBlocks({ commit, dispatch }, newGame) {
    let res = false;
    const newGameData = {
      block_id: newGame.block_id,
      game_id: newGame.game_id,
    };
    await axios

      .delete(`/api/game_blocks/games`, {
        data: {
          block_id: newGame.block_id,
          game_id: newGame.game_id,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 204) {
          res = true;
        }
      })
      .catch((err) => {});
    return res;
  },
  async deleteGameBlock({ commit, dispatch }, blockId) {
    let res = false;
    try {
      await axios.delete(`/api/game_blocks/${blockId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      return (res = true);
    } catch (error) {
      return (res = false);
    }
  },
  async getProvidersArray({ commit }, filters) {
    const params = new URLSearchParams();
    for (const key in filters) {
      if (Object.hasOwnProperty.call(filters, key)) {
        const value = filters[key];

        // Check value
        if (value != null && value !== "") {
          params.append(key, value);
        }
      }
    }
    const queryString = `?${params.toString()}`;
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
    };
    await axios
      .get(`/api/providers${queryString}`, config)
      .then((response) => {
        if (response.status === 200) {
          const providers = response.data.map((providerData) => ({
            id: providerData.provider.id,
            value: providerData.provider.name,
            fee: providerData.provider.fee,
            is_enabled: providerData.provider.is_enabled,
            order: providerData.provider.order,
            name: providerData.provider.name,
            logo: providerData.provider.logo,
            active_games: providerData.active_games,
            inactive_games: providerData.inactive_games,
            total_games: providerData.total_games,
            aggregator: providerData.aggregator,
            string_id: providerData.provider.string_id,
            producer: providerData.provider.producer,
          }));
          commit("set", { type: "providersArray", items: providers });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async getGamesArray({ commit }, filters) {
    let res = false;
    try {
      const params = new URLSearchParams();
      for (const key in filters) {
        if (Object.hasOwnProperty.call(filters, key)) {
          const value = filters[key];

          // Check value
          if (value != null && value !== "" && value !== "All Games") {
            params.append(key, value);
          }
        }
      }
      const queryString = `?${params.toString()}`;

      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
      };
      const response = await axios.get(
        `/main-api/games/admin/filter${queryString}`,
        config
      );

      if (response.status === 200) {
        const gamesWithGameProperty = response.data.items;
        const gamesWithoutGameProperty = gamesWithGameProperty.map((item) => ({
          ...item.Game,
          loadingItem: false,
        }));

        const formattedData = {
          items: gamesWithoutGameProperty,
          page: response.data.page,
          pages: response.data.pages,
          size: response.data.size,
          total: response.data.total,
        };
        commit("set", { type: "gamesArray", items: formattedData });
      }
    } catch (error) {
      return res;
    }
  },
  async awaitGetAvailableGamesForGameBlock({ commit }, filters) {
    let res = false;
    try {
      const params = new URLSearchParams();
      for (const key in filters) {
        if (Object.hasOwnProperty.call(filters, key)) {
          const value = filters[key];

          // Check value
          if (value != null && value !== "" && value !== "All Games") {
            params.append(key, value);
          }
        }
      }
      const queryString = `?${params.toString()}`;

      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
      };
      const response = await axios.get(
        `/main-api/games/admin/filter${queryString}`,
        config
      );

      if (response.status === 200) {
        const gamesWithGameProperty = response.data.items;
        const gamesWithoutGameProperty = gamesWithGameProperty.map((item) => ({
          ...item.Game,
          loadingItem: false,
        }));

        const formattedData = {
          items: gamesWithoutGameProperty,
          page: response.data.page,
          pages: response.data.pages,
          size: response.data.size,
          total: response.data.total,
        };
        commit("set", { type: "gamesArray", items: formattedData });
      }
    } catch (error) {
      return res;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
