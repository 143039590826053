<template>
    <v-dialog
          v-model="dialog"
          max-width="600px"
          :persistent="formLoading"
          transition
        >
          <v-card
          >
            <v-card-title class="font-weight-bold justify-center">
              <span v-if="isEditAgent" class="c-text ml-1">
                {{ `${$t('agent_edit')} - ${agent.login}` }}
              </span>
              <div v-else-if="isDeleteAgent" class="d-flex flex-column">
               <span> {{ $i18n.t("delete_agent") }}</span>
               <div class="c-red word-break-normal lh-1" style="max-width: 300px;">
                {{ $t('confirm_delete_agent') }}
               </div>
              </div>
              <span v-else-if="isSubAgent">{{ $i18n.t("new_sub_agent") }}</span>
              <span v-else>{{ $i18n.t("new_agent") }}</span>
            </v-card-title>
            <ValidationObserver ref="observer" v-slot="{ invalid, validate }">
              <v-card-text class="pb-0 pt-1 text-left space-y-2">
              <template v-if="!isEditAgent ">
                <template v-if="isAgent && !isDeleteAgent && !isSubAgent">
                  <div>
                    <Message v-if="errorCount" severity="error" :closable="false">
      
                      {{ $t('no_have_balance') }}
                  </Message>
                  <Message icon="null" severity="info" :closable="false">
                      <span class="c-text">
                         {{$t('available_balance')}}:
                      
                      </span>
                      <button type="button" @click="addAgent.credits_balance = admin.credits_balance">
                          {{ admin.credits_balance }} {{ getCurrencyValue }}
                      </button>
                  </Message>
                  </div>
                </template>
                <template v-else-if="isSubAgent">
                  <div>
                    <Message v-if="errorCount" severity="error" :closable="false">
      
                      {{ $t('no_have_balance') }}
                  </Message>
                  <Message icon="null" severity="info" :closable="false">
                      <span class="c-text">
                         {{$t('available_balance')}}:
                      
                      </span>
                      <button type="button" @click="addAgent.credits_balance = getAvailableBalance">
                          {{ getAvailableBalance }} {{ getCurrencyValue }}
                      </button>
                  </Message>
                  </div>
                </template>
                <span v-if="!isNewAgent">
                  <h4 class="c-text white-space d-flex align-center">
                    {{ $t('parent_agent') }}
                    <span class="c-red">*</span>
                    :
                  </h4>
                  <v-menu v-model="parentMenu" 
                  offset-y
                  :close-on-content-click="false" 
                      nudge-left="0" 
                      nudge-right="0"
                      
                      >
                      <template v-slot:activator="{ on }">
                        <div class="d-flex flex-column" v-on="on">
                        <span class="p-input-icon-right"  >
                          <i class="pi pi-angle-down" v-if="!isSubAgent && !isDeleteAgent" />
                          <!-- :disabled="isAgent || isDeleteAgent || isSubAgent" -->
                            <InputText 
                            disabled
                            :value="getParentName"
                            type="text" readonly class="p-inputtext-sm fw" :placeholder="$t('parent_agent')">
                            </InputText>
                            </span>
                            </div>
                      </template>
                        <Listbox v-model="addAgent.parent" :options="adminAgents"
                        @change="parentMenu = false"
                        :filter="true"
                        class="custom-dropdown"
                        :emptyFilterMessage="$t('no_data')"
                        optionLabel="name" 
                        optionValue="id"
                        
                        style="width:auto">
                      <!-- <template #value="slotProps">
                        
                    </template>
                    <template #option="slotProps">
                       
                    </template> -->
                        </Listbox>
          
                    </v-menu>
                </span>
                <ValidationProvider
                  name="login"
                  rules="required|min3"
                  v-slot="{ errors, validate, validated }"
                > 
                  <h4 class="c-text white-space">
                    {{ $t('login') }}<span class="c-red">*</span>:
                  </h4>
                  <div class="d-flex flex-column">
                    <InputText 
                    autocomplete="off"
                     :disabled="isDeleteAgent"
                    :maxlength="12"
                    v-model="addAgent.login" 
                    class="p-inputtext-sm fw"
                    :class="{ 'p-invalid' : errors.length > 0 }"
                      :placeholder="$t('login')" 
                      
                      />
                    <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                  </div>
              </ValidationProvider>
                <ValidationProvider
                v-if="!isDeleteAgent"
                  :name="$t('password')"
                  rules="required|min8"
                  v-slot="{ errors, validate, validated }"
                > 
                  <h4 class="c-text white-space">
                    {{ $t('password') }}<span class="c-red">*</span>:
                  </h4>
                  <div class="d-flex flex-column">
                    <Password 
                    autocomplete="off"
                    :disabled="isDeleteAgent"
                    v-model="addAgent.password"
                    :class="{ 'p-invalid' : errors.length > 0 }"
                    class="fw" 
                    :placeholder="$t('password')" 
                    toggleMask 
                    :feedback="false"
                    />
                    <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                  </div>
              </ValidationProvider>
              <ValidationProvider
                  name="name"
                  rules="required"
                  v-slot="{ errors, validate, validated }"
                > 
                  <h4 class="c-text white-space">
                    {{ $t('agent_name') }}<span class="c-red">*</span>:
                  </h4>
                  
                  <div class="d-flex flex-column">
                    <InputText 
                     :disabled="isDeleteAgent"
                    :maxlength="12"
                    v-model="addAgent.name" 
                    class="p-inputtext-sm fw"
                    :class="{ 'p-invalid' : errors.length > 0 }"
                      :placeholder="$t('agent_name')" 
                      
                      />
                    <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                  </div>
              </ValidationProvider>
              <ValidationProvider
                  name="email"
                  rules="email"
                  v-slot="{ errors, validate, validated }"
                > 
                  <h4 class="c-text white-space">{{ $t('email') }}:</h4>
                  <div class="d-flex flex-column">
                    <InputText 
                    v-model="addAgent.email" 
                    class="p-inputtext-sm fw"
                    :class="{ 'p-invalid' : errors.length > 0 }"
                      :placeholder="$t('email')" 
                      
                      />
                    <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                  </div>
              </ValidationProvider>
              <ValidationProvider
                  name="phone"
                  rules=""
                  v-slot="{ errors, validate, validated }"
                > 
                  <h4 class="c-text white-space">{{ $t('tel') }}:</h4>
                  <div class="d-flex flex-column">
                    <InputMask 
                    mask="(999) 999-9999" 
                    v-model="addAgent.phone" 
                    class="p-inputtext-sm fw"
                     placeholder="(999) 999-9999" />
                    <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                  </div>
              </ValidationProvider>
              <ValidationProvider
                  name="share"
                  rules="required|min_value:0"
                  v-slot="{ errors, validate, validated }"
                > 
                  <h4 class="c-text white-space">{{ $t('share') }}:</h4>
                  <div class="d-flex flex-column">
                    <InputNumber 
                    class="fw"
                    :class="{ 'p-invalid' : errors.length > 0 }"
                     :disabled="isDeleteAgent"
                    :placeholder="$t('share')" 
                    v-model="addAgent.share" 
                    showButtons  
                    :step="1"
                    :min="0"
                    :max="100"
                    suffix=" %"
                    />
                    <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                  </div>
              </ValidationProvider>
  
                <ValidationProvider
                  name="balance"
                  rules="required|min_value:0"
                  v-slot="{ errors, validate, validated }"
                > 
                  <h4 class="c-text white-space">{{ $t('balance') }}:</h4>
                  <div class="d-flex flex-column">
                    <InputNumber 
                    locale="en-US"
                    class="fw"
                    :class="{ 'p-invalid' : errors.length > 0 }"
                     :disabled="isDeleteAgent"
                     currencyDisplay="code"
                     mode="currency" 
                     :currency="getCurrencyValue"
                    :placeholder="$t('balance')" 
                    v-model="addAgent.credits_balance" 
                    showButtons  
                    :step="1"
                    :min="0"
                    />
                    <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                  </div>
              </ValidationProvider>
              <span>
                <h4 class="c-text white-space">
                  <p class="mb-0">{{ $t('currency') }}:</p>
                </h4>
                <v-menu v-model="currencyMenu" 
                offset-y
                :close-on-content-click="false" 
                    nudge-left="0" 
                    nudge-right="0"
                    >
                    <template v-slot:activator="{ on }">
                      <div class="d-flex flex-column" v-on="on">
                      <span class="p-input-icon-right"  >
                        <i class="pi pi-angle-down" />
                          <InputText 
                          :disabled="isDeleteAgent || isAgent || isSubAgent"
                          :value="getCurrencyValue"
                          type="text" readonly class="p-inputtext-sm fw" :placeholder="$t('currency')">
                          </InputText>
                          </span>
                          </div>
                    </template>
                      <Listbox v-model="addAgent.currency" :options="currencies"
                      :filter="true"
                      @change="currencyMenu = false"
                      class="custom-dropdown"
                      :emptyFilterMessage="$t('no_data')"
                      style="width:auto" />
        
                  </v-menu>
              </span>
              </template>
              <template v-else-if="isEditAgent">
                <ValidationProvider
                  name="login"
                  rules="required|min3"
                  v-slot="{ errors, validate, validated }"
                > 
                  <h4 class="c-text white-space">
                    {{ $t('login') }}<span class="c-red">*</span>:
                  </h4>
                  <div class="d-flex flex-column">
                    <InputText 
                    autocomplete="off"
                    :maxlength="12"
                    v-model="addAgent.login" 
                    class="p-inputtext-sm fw"
                    :class="{ 'p-invalid' : errors.length > 0 }"
                      :placeholder="$t('login')" 
                      
                      />
                    <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                  </div>
              </ValidationProvider>
                <ValidationProvider
                  :name="$t('password')"
                  rules="min8"
                  v-slot="{ errors, validate, validated }"
                > 
                  <h4 class="c-text white-space">
                    {{ $t('password') }}:
                  </h4>
                  <div class="d-flex flex-column">
                    <Password 
                    autocomplete="off"
                    v-model="addAgent.password"
                    :class="{ 'p-invalid' : errors.length > 0 }"
                    class="fw" 
                    :placeholder="$t('password')" 
                    toggleMask 
                    :feedback="false"
                    />
                    <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                  </div>
              </ValidationProvider>
              <ValidationProvider
                  name="name"
                  rules=""
                  v-slot="{ errors, validate, validated }"
                > 
                  <h4 class="c-text white-space">
                    {{ $t('agent_name') }}<span class="c-red">*</span>:
                  </h4>
                  
                  <div class="d-flex flex-column">
                    <InputText 
                    :maxlength="50"
                    v-model="addAgent.name" 
                    class="p-inputtext-sm fw"
                    :class="{ 'p-invalid' : errors.length > 0 }"
                      :placeholder="$t('agent_name')" 
                      
                      />
                    <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                  </div>
              </ValidationProvider>
              <ValidationProvider
              name="email"
              rules="email"
              v-slot="{ errors, validate, validated }"
            > 
              <h4 class="c-text white-space">{{ $t('email') }}:</h4>
              <div class="d-flex flex-column">
                <InputText 
                v-model="addAgent.email" 
                class="p-inputtext-sm fw"
                :class="{ 'p-invalid' : errors.length > 0 }"
                  :placeholder="$t('email')" 
                  
                  />
                <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
              </div>
          </ValidationProvider>
          <ValidationProvider
              name="phone"
              rules=""
              v-slot="{ errors, validate, validated }"
            > 
              <h4 class="c-text white-space">{{ $t('tel') }}:</h4>
              <div class="d-flex flex-column">
                <InputMask 
                mask="(999) 999-9999" 
                v-model="addAgent.phone" 
                class="p-inputtext-sm fw"
                 placeholder="(999) 999-9999" />
                <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
              </div>
          </ValidationProvider>
                <template v-if="admin.id && agent.id !== admin.id">
                  <span
                  v-if="!isEditAdminAgent && (agent.parent_agent && agent.parent_agent.is_active) || (agent.is_active && !agent.parent_agent) || (!agent.is_active && !agent.parent_agent)"
                > 
                  <h4 class="c-text white-space">{{ $t('active') }}:</h4>
                  <div class="d-flex flex-column">
                    <InputSwitch v-model="addAgent.is_active" class="p-inputswitch-success" />
                  </div>
              </span>
                </template>
            <!-- <v-switch v-model="addAgent.is_active" hide-details :label="$t('active')" color="green accent-4" inset
            class="mt-1 mb-3 custom-switch "></v-switch> -->
              </template>
            </v-card-text>
            <v-card-actions>
                <Button 
                @click="handleSubmit(validate)"
                :disabled="formLoading"
                :loading="formLoading"
                   :label="isEditAgent ? $t('edit') : isDeleteAgent ? $t('delete') : $t('create')"
                    :icon="isDeleteAgent ? 'pi pi-trash' : 'pi pi-save'" 
                    :class="isDeleteAgent ? 'p-button-danger ' : 'p-button-success'"
                    class="p-button-sm fw" />
            </v-card-actions>
          </ValidationObserver>
          </v-card>
          <!-- <info-about-new-player 
          @add-new="showAdd = true"
          @close-popup="closeAll"
          v-else
          /> -->
        </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import disableAutocomplete from '@/disable-autocomplete';
// import disableAutocomplete from 'vue-disable-autocomplete';
// import InfoAboutNewPlayer from './InfoAboutNewPlayer'
    export default {
        name: 'ModalActionsAgent',
        components:{
          // InfoAboutNewPlayer
        },
        props: {
            toAddDialog: {
                type: Boolean,
                default: false
            },
            parentId: {
                type: Number,
                default: 0
            },
            isEditAgent: {
              type: Boolean,
            },
            isEditAdminAgent: {
              type: Boolean,
            },
            isSubAgent: {
              type: Boolean,
            },
            isNewAgent: {
              type: Boolean,
            },
            isDeleteAgent: {
              type: Boolean,
            },
            agent: {
              type: Object,
            }
        },
        computed:{
          ...mapGetters({
            agents: "agents/getAgents",
            currencies: 'getFilteredCurrencies',
            adminAgents: 'agents/getAllAgents',
            tokenAgent: 'tokenUser',
            admin: 'admin',
          }),
          getCurrencyValue() {
            if (this.isAgent) {
                return this.admin.currency;
              } else if (!this.isAgent && this.adminAgents.length > 0) {
                const parentAgent = this.adminAgents.find(agent => agent.id === this.parentId);
                        // console.log('parentAgent', parentAgent);
                if (!parentAgent) {
                  if (!this.isEditAgent && this.isNewAgent) {
                  return this.addAgent.currency;
                } else if (this.isNewAgent && this.isAgent) {
                    return this.admin.currency;
                  } else{
                    return 'USD';
                  }
                } else if (parentAgent) {
                  return parentAgent.currency;
                } else{
                  return 'USD';
                }
              }
          },
          isAgent(){
            if (this.tokenAgent === 'agent') {
              return 'agent';
            } else{
              return null;
            }
          },
          getParentName(){
            if (this.parentId) {
              if (this.adminAgents && this.adminAgents.length > 0) {
      const parentAgent = this.adminAgents.find(agent => agent.id === this.parentId);
      if (parentAgent) {
        return parentAgent.login;
      }
    } else{
  
      return this.$t('no_parent');
    }
            } else if (this.isAgent) {
              return this.admin.name;
            } else{
              const parentAgent = this.adminAgents.find(agent => agent.id === this.addAgent.parent);
      if (parentAgent) {
        return parentAgent.login;
      }
            }
          },
          getAvailableBalance(){
            if (this.parentId) {
              if (this.adminAgents && this.adminAgents.length > 0) {
      const parentAgent = this.adminAgents.find(agent => agent.id === this.parentId);
      if (parentAgent) {
        return parentAgent.credits_balance;
      }
    } else{
  
      return 0;
    }
            } else if (this.isAgent) {
              return this.admin.credits_balance;
            } else{
              return 0;
            }
          },
        },
        data() {
            return {
                isSubAgentEdit: false,
                formLoading: false,
                currencyMenu: false,
                parentMenu: false,
                dialog: false,
                errorCount: false,
                showAdd: true,
                addAgent: {
                    name: '',
                    login: '',

                    password: '',
                    email: '',
                    phone: '',
                    parent: '',
                    currency: 'USD',
                    share: 100,
                    credits_balance: 0,
                    is_active: true
                },
                holdNewAgent:{},
                holdSubAgent:{},
            }
        },
        async mounted() {
          this.holdNewAgent = {...this.addAgent};
          this.holdSubAgent = {...this.addAgent};
        },
        watch:{
          'addAgent.currency': {
            handler(newVal, oldValue) {
              //console.log('newVal', newVal);
              //console.log('oldValue', oldValue);
              if (!newVal) {
                this.addAgent.currency = oldValue;
              }
            }
          },
          isDeleteAgent: {
            handler(newVal) {
              if (newVal) {
                this.addAgent = this.agent
              }
            },
            immediate: true
          },
          isSubAgent: {
            handler(newVal) {
              if (newVal && this.holdSubAgent) {
                this.addAgent = this.holdSubAgent
              }
            },
            immediate: true
          },
          isNewAgent: {
            handler(newVal) {
              if (newVal && this.holdNewAgent) {
                  this.addAgent = this.holdNewAgent
              }
            },
            immediate: true
          },
          isEditAgent: {
            handler(newVal) {
              if (newVal) {
                this.addAgent = this.agent
              }
            },
            immediate: true
          },
          agent: {
            deep: true,
            handler(newVal) {
              //console.log(newVal);
              if (newVal.id) {
                this.addAgent = newVal;
              }
            },
            // immediate: true
          },
          parentId: {
            handler(newVal) {
              if (newVal) {
                this.addAgent.parent = newVal
              } else{
                //this.addAgent = this.holdAgent
              }
            },
            immediate: true
          },
          dialog: {
            handler(newVal) {
              if (newVal === false) {
                this.$emit('dialog');
                this.errorCount = false;
                if (this.toAddDialog) {
                  if (this.isNewAgent) {
                  this.holdNewAgent = this.addAgent
                  this.toClearAgentData();
                  } else if (this.isSubAgent) {
                    this.holdSubAgent = this.addAgent
                    this.toClearAgentData();
                  }
                  this.$nextTick(() => {
                    this.$refs.observer.reset();
                  })
                }
              }
            },
            immediate: true
          },
          toAddDialog: {
            handler(newVal) {
              if (newVal) {
                this.$nextTick(() => {
                    this.$refs.observer.reset();
                  })
                this.addAgent.parent = this.parentId;
                if (this.agent.parent_agent) {
                  this.isSubAgentEdit = true;
                } else{
                  this.isSubAgentEdit = false;
                }
                this.$nextTick(() => {
                  disableAutocomplete.disable();
                })
                setTimeout(() => {
                  
                  disableAutocomplete.disable();
                }, 0);
              }
              this.dialog = newVal;
            },
            immediate: true
          }
        },
        methods: {
          toClearAgentData() {
             this.addAgent =  {
                      name: '',
                      login: '',
                      password: '',
                      parent: '',
                      currency: 'USD',
                      share: 100,
                      credits_balance: 0,
                      is_active: true
                  };
                  //this.holdNewAgent = this.addAgent;
                  //this.holdSubAgent = this.addAgent;
          },
          handleSubmit(validate) {
            validate().then(valid => {
              if (this.isDeleteAgent) {
                this.toDeleteAgent();
                return
              }
        if (valid) {
          this.errorCount = false;
            if (this.isNewAgent) {
              if (!this.isAgent) {
                this.toAddAgent();
              } else if (this.addAgent.credits_balance > this.getAvailableBalance) {
                    this.errorCount = true;
                } else{
                    this.errorCount = false
                    this.toAddAgent();
                }
              
            } else if (this.isSubAgent) {
              if (this.addAgent.credits_balance > this.getAvailableBalance) {
                this.errorCount = true;
              } else{
                this.errorCount = false
                this.toAddAgent();
              }
            } else if (this.isEditAgent) {
              this.toAddAgent();
            } else{
            this.toAddAgent();
          }
      }
      });
    },
          closeAll(){
            this.data = false;
            this.$emit('dialog');
            this.showAdd = true;
          },
            async toAddAgent(){
          this.formLoading = true;
          try {
            const create = this.addAgent;
            let agentData = {
              is_active: create.is_active,
              name: create.name,
              login: create.login,
            }
            if (create.password) {
              agentData.password = create.password
            }
            if (create.phone) {
              agentData.phone = create.phone
            }
            if (create.email) {
              agentData.email = create.email
            }
            if (create.credits_balance && !this.isEditAgent) {
              agentData.credits_balance = create.credits_balance
            }
            if (create.parent) {
              agentData.parent_agent_id = create.parent
            } 
            let method
            if(!this.isEditAgent){
              agentData.share = create.share
              agentData.currency = this.getCurrencyValue;
              method = 'awaitAddNewAgentByAdmin';
            } else{
              method = 'awaitEditAgent';
            }
            await this.$store.dispatch(`agents/${method}`, {type: this.isAgent, agent: agentData, id: create.id});
            if (this.isAgent){
              await this.$store.dispatch('getAgentStatus');
            }
            this.$toast.add({severity: 'success', summary: this.isEditAgent ? this.$t('agent_updated') : this.$t('agent_created'), life: 3000}) 
            this.$emit('added')
            //if (this.isSubAgentEdit) {
              //this.$emit('added-sub-agent', this.agent.parent_agent.id)
              
            //} else{
              // if (this.isEditAgent) {
              //   this.$emit('update-agent', this.agent.id)
              // } else{

              // }
            //}
            this.$nextTick(() => {
                    
                    this.$refs.observer.reset();
                  })
            this.holdNewAgent = {
              share: 100,
              credits_balance: 0,
              currency: 'USD',
            };
            this.holdSubAgent = {
              share: 100,
              credits_balance: 0,
              currency: 'USD',
            };
          } catch (error) {
            console.log(error);
            if (error.response.status === 409) {
              if (error.response.data.detail.loc === "login") {
                this.$toast.add({severity: 'error', summary: this.$t('agent_already_exists'), life: 3000})
                if (this.isNewAgent) {
                  this.holdNewAgent.login = '';
                } else if (this.isSubAgent) {
                  this.holdSubAgent.login = '';
                }
                this.addAgent.login = '';
              } else if (error.response.data.detail.loc === 'password') {
                this.$toast.add({severity: 'error', summary: this.$t('use_another_password'), life: 3000})
                if (this.isNewAgent) {
                  this.holdNewAgent.password = '';
                } else if (this.isSubAgent) {
                  this.holdSubAgent.password = '';
                }
                this.addAgent.password = '';
              } else if (error.response.data.detail.loc === 'phone') {
                this.$toast.add({severity: 'error', summary: this.$t('tel_already_exists'), life: 3000})
                this.addAgent.phone = '';
              } else if (error.response.data.detail.loc === 'email') {
                this.$toast.add({severity: 'error', summary: this.$t('email_already_exists'), life: 3000})
                this.addAgent.email = '';
              } 
            } else{
              this.$toast.add({severity: 'error', summary: this.$t('just_error'), detail: error.message, life: 3000}) 
            }
            
          } finally{

            this.formLoading = false; 
          }
            // this.showAdd = false;
        },
            async toDeleteAgent(){
          this.formLoading = true;
          try {
            const id = this.addAgent.id;
            const type = await this.$store.dispatch(`agents/awaitDeleteAgent`, id);
            let isDeletedAgent
            if (type === 'removed') {
              isDeletedAgent = true
            }
            this.$toast.add(
              {
            severity: isDeletedAgent ? 'success' : 'info', 
            summary:  isDeletedAgent ? this.$t('agent_deleted') : this.$t('agent_deactivated'), 
            life: 4000
          }
          ) 
            if (this.isAgent){
              await this.$store.dispatch('getAgentStatus');
            }
            this.$emit('added')
          } catch (error) {
            if (error.response && error.response.status === 409) {
              this.$toast.add({severity: 'error', summary: this.$t('cant_delete_agent'), detail: error.message, life: 3000}) 
              this.$emit('dialog')
            } else{
              this.$toast.add({severity: 'error', summary: this.$t('just_error'), detail: error.message, life: 3000}) 
            }
          } finally{
            this.formLoading = false; 
          }
        },

        },
    }
</script>

<style lang="scss" scoped>
.v-card__text{
  >span{
    display: flex;
    align-items: center;
    //justify-content: space-between;
    h4{
      flex: 1 1 auto;
    }
    >div{
      flex: 0 0 300px;
    }
    .p-component{
      max-width: 300px;
      //flex: 0 0 300px;
    }
  }
}
</style>