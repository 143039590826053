
import axios from "axios";
export default {
  namespaced: true,
  state: {
    url: 'https://example.com/',
    token: localStorage.getItem('jwt'),
    mirrors: {
      active: [],
      deleted: []
    },
    redirectorUrl: ''
  },
  getters:{
    token(state) {
      return state.token;
    },
    mirrors(state) {
      return state.mirrors;
    },
    redirectorUrl(state) {
      return state.redirectorUrl;
    }
  },
  mutations: {

  },
  actions: {

    async auth({state, dispatch}, data) {
      //
      let payload = {
        login: data.login,
        pass: data.pass
      }
      let res = false;
      await axios
        .post(`/api/admin/auth`, payload)
        .then((response) => {
          if(response.status === 200 && 'token' in response.data) {
            state.token = response.data.token;
            res = true;
          }
        }).catch(error =>{
          console.log(error)
        })
      if (res) {
        await dispatch('getAllMirrors');
      }
      return res;
    },
    async addMirror({state, dispatch, rootState}, data) {
      if (rootState.token !== null) {
        let payload = {
          token: state.token,
          url: data.url
        }
        let res = false;
        await axios
          .post(`/api/addMirror`, payload)
          .then((response) => {
            if(response.status === 200) {
              res = true;
            }
          }).catch(error =>{
            console.log(error)
          })
        if (res) {
          await dispatch('getAllMirrors');
        }
        return res;
      } else {
        return false;
      }
    },
    async deleteMirror({state, dispatch, rootState}, data) {
      if (localStorage.getItem('jwt')) {
        let payload = {
          token: state.token,
          id: data.id
        }
        let res = false;
        await axios
          .post(`/api/deleteMirror`, payload)
          .then((response) => {
            if(response.status === 200) {
              res = true;
            }
          }).catch(error =>{
            console.log(error)
          })
        if (res) {
          await dispatch('getAllMirrors');
        }
        return res;
      } else {
        return false;
      }
    },
    async getAllMirrors({state, rootState}) {
      if (localStorage.getItem('jwt')) {
        await axios.get(`/api/getMirrors?token=${localStorage.getItem('jwt')}`)
          .then((response) => {
            if(response.status === 200) {
              state.mirrors.active = response.data;
            }
          }).catch(error =>{
            console.log(error)
          });
        await axios.get(`/api/getDeleteMirrors?token=${localStorage.getItem('jwt')}`)
          .then((response) => {
            if(response.status === 200) {
              state.mirrors.deleted = response.data;
            }
          }).catch(error =>{
            console.log(error)
          });
        await axios.get(`/api/getRedirectorUrl?token=${localStorage.getItem('jwt')}`)
          .then((response) => {
            if(response.status === 200) {
              state.redirectorUrl = response.data.redirector;
            }
          }).catch(error =>{
            console.log(error)
          });
      }
    },
    async changeBaseUrl({state, rootState}, data) {
      let res = false;
      if (localStorage.getItem('jwt')) {
        let payload = {
          token: localStorage.getItem('jwt'),
          url: data.url,
        }
        await axios
          .post(`/api/updateRedirectorUrl`, payload)
          .then((response) => {
            if(response.status === 200 && 'status' in response.data) {
              state.redirectorUrl = payload.url;
              res = true;
            }
          }).catch(error =>{
            console.log(error)
          });
      }
      return res;
    }


  },
  modules: {
  }
}
