import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),(_vm.isCallInProgress)?_c('div',{staticClass:"phoneWidget"},[_c(VChip,{staticStyle:{"height":"max-content"},attrs:{"color":"green","text-color":"white"}},[_c(VContainer,[_c(VRow,[_vm._v(" "+_vm._s(_vm.$i18n.t('active_call'))+" ")]),_c(VRow,[_vm._v(" "+_vm._s(new Date(_vm.callDuration * 1000).toISOString().substr(11, 8))+" ")]),_c(VRow,[_c('router-link',{attrs:{"to":`/player/${_vm.callingUserId}`}},[_vm._v(" ID:"+_vm._s(_vm.callingUserId)+" ")])],1),_c(VRow,[_c(VCol,[(!_vm.isMuted)?_c(VBtn,{attrs:{"icon":"","color":"white"},on:{"click":_vm.changeMute}},[_c(VIcon,[_vm._v("fas fa-microphone-slash")])],1):_vm._e(),(_vm.isMuted)?_c(VBtn,{attrs:{"icon":"","color":"white"},on:{"click":_vm.changeMute}},[_c(VIcon,[_vm._v("fas fa-microphone")])],1):_vm._e()],1),_c(VCol,[_c(VBtn,{attrs:{"icon":"","color":"red"},on:{"click":_vm.hangup}},[_c(VIcon,[_vm._v("phone")])],1)],1)],1)],1)],1)],1):_vm._e(),_c(VDialog,{attrs:{"persistent":"","max-width":"fit-content"},model:{value:(_vm.ringDialog),callback:function ($$v) {_vm.ringDialog=$$v},expression:"ringDialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.$i18n.t('income_call'))+" ")]),_c(VCardText,[_c(VRow,[_c(VCol,[_c(VBtn,{attrs:{"icon":"","color":"red"},on:{"click":_vm.rejectCall}},[_c(VIcon,{staticStyle:{"transform":"rotate(135deg)"}},[_vm._v("phone")])],1)],1),_c(VCol,[_c(VBtn,{attrs:{"icon":"","color":"green"},on:{"click":_vm.answerCall}},[_c(VIcon,[_vm._v("phone")])],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('audio',{staticStyle:{"display":"none"},attrs:{"id":"remoteAudio","controls":""}},[_c('p',[_vm._v("Your browser doesn't support HTML5 audio.")])])
}]

export { render, staticRenderFns }