import axios from "axios";
export default {
  namespaced: true,
  state: {
    depositBonuses: {
      items: [],
      page: 1,
      pages: 1,
      size: 1,
      total: 1,
    },
    spinsBonuses: {
      items: [],
      page: 1,
      pages: 1,
      size: 1,
      total: 1,
    },
    depositAllListBonuses: {
      items: [],
      page: 1,
      pages: 1,
      size: 1,
      total: 1,
    },
    spinsAllListBonuses: {
      items: [],
      page: 1,
      pages: 1,
      size: 1,
      total: 1,
    },
    selectedSpinsBonus: {
      id: null,
    },
    selectedDepositBonus: {
      id: null,
    },
  },
  getters: {
    getDepositBonuses: (state) => state.depositBonuses,
    getSpinsBonuses: (state) => state.spinsBonuses,
    //========================================================================================================================================================

    getDepositAllListBonuses: (state) => state.depositAllListBonuses,
    getSpinsAllListBonuses: (state) => state.spinsAllListBonuses,
    //========================================================================================================================================================

    getSelectedDepositBonus: (state) => state.selectedDepositBonus,
    getSelectedSpinsBonus: (state) => state.selectedSpinsBonus,
  },
  mutations: {
    set(state, { type, items }) {
      state[type] = items;
    },
    APPEND_BONUSES(state, { type, newItem }) {
      // console.log("newItem", newItem);
      state[type].items = state[type].items.concat(newItem.items);
      state[type].page = newItem.page;
      state[type].pages = newItem.pages;
      state[type].size = newItem.size;
      state[type].total = newItem.total;
    },
  },
  actions: {
    async awaitGetDepositBonuses({ commit }, filters) {
      const params = new URLSearchParams();
      for (const key in filters) {
        if (Object.hasOwnProperty.call(filters, key)) {
          const value = filters[key];
          if (value != null && value !== "") {
            params.append(key, value);
          }
        }
      }
      const queryString = `?${params.toString()}`;
      if (localStorage.getItem("jwt")) {
        await axios
          .get(`/api/bonuses/deposit${queryString}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              if (filters && filters.page && filters.page >= 2) {
                commit("APPEND_BONUSES", {
                  type: "depositAllListBonuses",
                  newItem: response.data,
                });
                return;
              }
              commit("set", {
                type: "depositAllListBonuses",
                items: response.data,
              });
              commit("set", {
                type: "depositBonuses",
                items: response.data,
              });
            }
          })
          .catch((error) => {
            console.log(error);
            throw error;
          });
      }
    },
    async getDepositBonusByID({ commit }, id) {
      if (localStorage.getItem("jwt")) {
        await axios
          .get(`/api/bonuses/deposit/${id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              commit("set", {
                type: "selectedDepositBonus",
                items: response.data,
              });
            }
          })
          .catch((error) => {
            console.log(error);
            throw error;
          });
      }
    },
    async createDepositBonus({}, depBonus) {
      const data = {
        ...depBonus,
      };
      await axios
        .post(`/api/bonuses/deposit`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
    async updateDepositBonus({}, depBonus) {
      const data = {
        ...depBonus,
      };
      await axios
        .put(`/api/bonuses/deposit/${depBonus.id}`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
    async deleteDepositBonus({}, id) {
      await axios
        .delete(`/api/bonuses/deposit/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
    //========================================================================================================================================================

    async awaitGetSpinsBonuses({ commit }, filters) {
      const params = new URLSearchParams();
      for (const key in filters) {
        if (Object.hasOwnProperty.call(filters, key)) {
          const value = filters[key];
          if (value != null && value !== "") {
            params.append(key, value);
          }
        }
      }
      const queryString = `?${params.toString()}`;
      await axios
        .get(`/api/bonuses/free-rounds${queryString}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            if (filters && filters.page && filters.page >= 2) {
              commit("APPEND_BONUSES", {
                type: "spinsAllListBonuses",
                newItem: response.data,
              });
              return;
            }
            commit("set", {
              type: "spinsBonuses",
              items: response.data,
            });
            commit("set", {
              type: "spinsAllListBonuses",
              items: response.data,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
    async getSpinsBonusByID({ commit }, id) {
      if (localStorage.getItem("jwt")) {
        await axios
          .get(`/api/bonuses/free-rounds/${id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              commit("set", {
                type: "selectedSpinsBonus",
                items: response.data,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    async createSpinsBonus({}, bonus) {
      const data = {
        ...bonus,
      };
      await axios
        .post(`/api/bonuses/free-rounds`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
    async updateSpinsBonus({}, bonus) {
      const data = {
        ...bonus,
      };
      await axios
        .put(`/api/bonuses/free-rounds/${bonus.id}`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((response) => {})
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
    async deleteSpinsBonus({}, id) {
      await axios
        .delete(`/api/bonuses/free-rounds/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
    //========================================================================================================================================================

    // async getPlayerBonuses({ state }, user_id) {
    //   if (localStorage.getItem("jwt")) {
    //     await axios
    //       .get(`/api/bonuses/user/active/${user_id}`, {
    //         headers: {
    //           Authorization: `Bearer ${localStorage.getItem("jwt")}`,
    //         },
    //       })
    //       .then((response) => {

    //         if (response.status === 200) {
    //           state.playerBonuses = response.data;
    //         }
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //       });
    //   }
    // },
    async addBonusToUser({ state }, data) {
      let success = false;
      let error = false;
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
      };
      await axios
        .post(
          `/api/bonuses/assign`,
          {
            dep_bonus_id: data.dep_bonus_id,
            free_rounds_bonus_id: data.free_rounds_bonus_id,
            user_id: data.user_id,
          },
          config
        )
        .then((response) => {
          if (response.status === 200) {
            success = true;
          }
        })
        .catch((err) => {
          console.log(err);
          // Handle specific error codes, if needed
          if (err.response && err.response.status === 404) {
            error = 404;
          } else if (err.response && err.response.status === 400) {
            error = 400;
          } else if (err.response && err.response.status === 403) {
            error = 403;
          }
        });
      return [success, error];
    },
    async deletePlayerBonus({ state }, bonus_id) {
      let success = false;
      let error = false;
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
      };
      await axios
        .delete(`/api/bonuses/user/${bonus_id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            success = true;
          }
        })
        .catch((err) => {
          // Handle specific error codes, if needed
          if (err.response && err.response.status === 404) {
            error = 404;
          } else if (err.response && err.response.status === 400) {
            error = "Can't remove active bonus from user";
          } else if (err.response && err.response.status === 403) {
            error = 403;
          }
        });
      return [success, error];
    },
  },
  modules: {},
};
