<template>
  <v-main >
      <div class="main-content">
        <Card class="sticky-filters mb-15 card-custom" v-on:keyup.enter="applyFilters">
          <template #title>
            <Toolbar>
              <template #start>
                  <h3 class="column-three" st>{{ $i18n.t('search') }}</h3>
              </template>
              <template #end>
                <span class="p-buttonset">
                  <Button @click="clearFilters" :disabled="loadingFlag" :loading="loadingFlag" :label="$t('delete')" icon="pi pi-trash" class="p-button-sm p-button-danger" />
                  <Button @click="applyFilters" :disabled="loadingFlag" :loading="loadingFlag" :label="$t('search')" icon="pi pi-search" class="p-button-sm p-button-success" />
              </span>
              </template>
            </Toolbar>
            <v-row>
              <v-col>
                <v-chip-group>
                  <v-chip class="active-filter-chip" v-for="(value, key) in filteredActiveFilters"  :key="key" @click="removeFilter(key)">
                    <template>
                    {{ getFilterValueTitle(key) }}: {{ getFilterValueText(key,value) }}
                    </template>
                    <v-icon @click.stop="removeFilter(key)">mdi-close</v-icon>
                  </v-chip>
                </v-chip-group>
              </v-col>
            </v-row>
        </template>
        <template #content>
                <DataTable :value="payments.items" 
      data-key="id"
      :rows="payments.size"
      :loading="loadingFlag" 
      :header="fields"
      :filters.sync="filters"
      filterDisplay="row"
      :scrollable="true" 
      scrollHeight="flex"
        stripedRows 
        class="p-datatable-sm footer-table"
        >
        <template #empty>
          <div class="font-weight-bold">{{ $i18n.t('trans_not_found') }} </div>
       </template>
        <Column field="transaction_id" :header="$t('transaction_id')" :showFilterMenu="false" :styles="{'width':'12rem'}">
          <template #body="{data}">
            
            <v-col class="pa-0" style="color: var(--main-flamingo);cursor:pointer;line-height: 1;" @click="getTransactionItem(data)">
              {{ data.transaction_id }}
            </v-col>
          </template>
          <template #filter>
            <InputText type="text" @keydown.enter="applyFilters" v-model="filters.transaction_id" class="p-inputtext-sm" :placeholder="$t('transaction_id')" />
        </template>
        </Column>
        <Column field="user_id" :header="$t('player_id')" :showFilterMenu="false" :styles="{'min-width':'12rem'}">
          <template #body="slotProps">
            <router-link style="color: var(--main-flamingo)" :to="`/player/${slotProps.data.user_id}`">
              {{ slotProps.data.user_id }}
            </router-link>
        </template>
        <template #filter>
          <InputText type="text" @keydown.enter="applyFilters" v-model="filters.user_id" class="p-inputtext-sm" :placeholder="$t('player_id')" />
      </template>
        </Column>
        <Column field="payment_type" :header="$t('type')" :showFilterMenu="false" :styles="{'min-width':'12rem'}">
          <template #body="slotProps">
            <div>{{translatedType(slotProps.data.payment_type)}}</div>
        </template>
            <template #filter>
              <Dropdown  v-model="filters.payment_type" @change="applyFilters" optionLabel="text" optionValue="value" :placeholder="$t('type_transaction')" :options="transactionTypes"
              :emptyFilterMessage="$t('no_data')">
            </Dropdown>
          </template>
        </Column>
        <Column field="payment_sub_type" :header="$t('payment_type')" :showFilterMenu="false" :styles="{'min-width':'12rem'}">
          <template #body="slotProps">
            <div>{{translatedPaymentType(slotProps.data.payment_sub_type)}}</div>
        </template>
        <template #filter>
      </template>
        </Column>
        <Column field="amount" :header="$t('amount')" :showFilterMenu="false" :styles="{'min-width':'12rem'}">
          <template #body="slotProps">
            <div class="d-flex align-center">
              <span>{{ slotProps.data.amount }} </span>
              <span class="ml-1">{{ slotProps.data.currency }}</span>
            </div>
        </template>
        <template #filter>
          <v-menu
                v-model="balanceMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                nudge-left="0" 
                nudge-right="0" 
              >
                <template v-slot:activator="{ on }">
                <span v-on="on" class="p-float-label p-input-icon-right" >
                  <i class="pi pi-money-bill" />
                  <InputText
                  type="text"
                  readonly
                  class="p-inputtext-sm"
                  :placeholder="(filters.min_amount === 0 || filters.min_amount == '' || filters.min_amount === null) && 
                  (filters.max_amount === 0 || filters.max_amount == '' || filters.max_amount === null) ? 
                  $t('amount')
                  : $t('from') + ' ' + filters.min_amount + ' ' + $t('to') + ' ' + filters.max_amount"
                  
                  >
                </InputText>
              </span>
                </template>
                <div class="input-menu__menu">
                  <InputText type="text" v-model="filters.min_amount" class="p-inputtext-sm" :placeholder="$t('amount_from')" />
                  <InputText type="text" v-model="filters.max_amount" class="p-inputtext-sm" :placeholder="$t('amount_to')" />
                </div>
                
              </v-menu>
        </template>
        </Column>
        <Column field="created_at" :header="$t('date')" :showFilterMenu="false" :styles="{'min-width':'12rem'}">
          <template #body="slotProps">
            <div style="white-space: nowrap">
              {{ new Date(slotProps.data.created_at).toLocaleString() }}
            </div>
        </template>
        <template #filter>
          <!-- <Calendar :placeholder="$t('date')" :touchUI="$isMobile() ? true : false" :maxDate="today" id="icon" dateFormat="mm.dd.yy" v-model="datesToFilter" selectionMode="range" :showIcon="true" /> -->
          <v-menu
          v-model="dateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on }">
            
            <InputText
            v-on="on"
                  type="text"
                  readonly
                  class="p-inputtext-sm"
                  :placeholder="$t('date')"
                  v-model="datesToFilter"
                  dateFormat="mm.dd.yy"
                  >
                </InputText>
          
          </template>
          <Calendar class="calendar-menu"  :placeholder="$t('date')" :inline="true" :maxDate="today" id="icon" dateFormat="mm.dd.yy" v-model="datesToFilter" selectionMode="range" />
        </v-menu>
        </template>
        </Column>
        <Column field="method" :header="$t('method')" :showFilterMenu="false" :styles="{'min-width':'12rem'}">
          <template #filter>
            <!-- <Dropdown v-model="filters.provider" @change="applyFilters" :placeholder="$t('method')" :options="providers"
                    :emptyFilterMessage="$t('no_data')">
                    <template #value="slotProps">
                      <div v-if="slotProps.value">
                          <span>{{`${slotProps.value.codename} (${slotProps.value.type})`}}</span>
                      </div>
                      <span v-else>
                          {{slotProps.placeholder}}
                      </span>
                  </template>
                  <template #option="slotProps">
                  <div class="p-dropdown-car-option">
                    <span>{{`${slotProps.option.codename} (${slotProps.option.type})`}}</span>
                  </div>
                </template>
                  
                  </Dropdown> -->
          </template>
        </Column>
        <Column field="status" :header="$t('status')" :showFilterMenu="false" :styles="{'min-width':'12rem'}">
          <template #body="{data}">
            <v-chip small label
            :color="statusLabelColor(data.status)"
            style="color: var(--surface-a)"
            >
              {{ statusText(data.status) }}
            </v-chip>
        </template>
        <template #filter>
          <Dropdown  v-model="filters.status" @change="applyFilters" optionLabel="text" optionValue="value" :placeholder="$t('type_transaction')" :options="statusOptions"
                    :emptyFilterMessage="$t('no_data')">
                    <template #option="slotProps">
                      <v-chip small label
            :color="statusLabelColor(slotProps.option.value)"
            style="color: var(--surface-a)"
            >
              {{ statusText(slotProps.option.text) }}
            </v-chip>
                  </template>
                  </Dropdown>
        </template>
        </Column>
        <games-paggination 
        :pagginationObject="filters" 
        :requestData="payments" 
        @apply-filters="setFilters" 
        :isStatic="false"
        />
      </DataTable>
        </template>
        </Card>
      
      
      </div>
  </v-main>
</template>

<script>
import GamesPaggination from '@/views/GamesPaggination'
import {mapGetters} from "vuex";
export default {
  name: "FinancialTransactions",
  components:{
    GamesPaggination
  },
  computed: {
    ...mapGetters({
          payments: 'getPlayerPayments',
          gameFilters: 'paggination/getFilters',
        },
    ),
    filteredActiveFilters() {
      return Object.fromEntries(
        Object.entries(this.activeFilters).filter(
          ([key, value]) => key !== 'size' && key !== 'page' && (key === 'is_active' || value !== false)
        )
      );
    },
    
  },
  data() {
    return {
      fields: [
        // {value: 'account', text: this.$i18n.t('account'), sortable: true},
        {value: 'transaction_id', text: this.$i18n.t('transaction_id'), sortable: true},
        {value: 'user_id', text: this.$i18n.t('player_id'), sortable: true},
        {value: 'payment_type', text: this.$i18n.t('type'), sortable: true},
        {value: 'payment_sub_type', text: this.$i18n.t('payment_type'), sortable: true},
        {value: 'amount', text: this.$i18n.t('amount'), sortable: true},
        {
          value: 'created_at', text: this.$i18n.t('date'), sortable: true, sort: function (a, b) {
            return new Date(a) - new Date(b);
          }
        },
        {value: 'method', text: this.$i18n.t('method'), sortable: true},
        {value: 'status', text: this.$i18n.t('status'), sortable: true}

      ],
      //payments: [],
      statusOptions: [
        {value: 0, text: this.$t('processing')},
        {value: 2, text: this.$t('processed')},
        {value: 1, text: this.$t('completed')},
        {value: 3, text: this.$t('canceled')}
      ],
      transactionTypes: [{value: 'deposit', text: this.$i18n.t('deposit')}, {
        value: 'withdraw',
        text: this.$i18n.t('withdraw')
      }],
      balanceMenu: false,
      dateMenu: false,
      today: new Date(),
      datesToFilter: [],
      currentLocale: this.$i18n.locale || 'en',
      modalFlag: false,
      moreMenu: false,
      modalData: {},
      loadingFlag: false,
      loadingConfirm: false,
      modalLoadingFlag: false,
      providers: [],
      transaction: [],
      filters: {
        transaction_id: '',
        min_amount: '',
        max_amount: '',
        user_id: '',
        payment_type: '',
        date_from: '',
        date_to: '',
        provider: '',
        status: '',
      },
      page: 1,
      size: 25,
      pageOptions: [10, 25, 100],
      filterPages: 25,
      activeFilters: {},
    }
  },
  async mounted() {
    
    await this.applyFilters();

  },
  beforeRouteEnter(to, from, next){
    next(vm => {
      vm.$store.commit('setPageName', 'financial_transactions')
    });
    },
  methods: {
    getFilterValueTitle(key) {
      // titles
      switch (key) {
        case 'status':
          return this.$t('status')
        case 'min_amount':
          return this.$t('amount_from')
        case 'max_amount':
          return this.$t('amount_to')
        case 'user_id':
          return 'id'
        case 'provider':
          return this.$t('method')
        case 'payment_type':
          return this.$t('type_transaction')
        case 'date_from':
          return this.$t('date_from')
        case 'date_to':
          return this.$t('date_to')
      }

      return key;
    },
    getFilterValueText(key, value) {
      // text
      
      switch (value) {
        
        case 0:
          return this.$t('processing')
        case 1:
          return this.$i18n.t('completed');
        case 2:
          return this.$i18n.t('processed');
        case 3:
          return  this.$i18n.t('canceled');
        case 'deposit':
          return this.$i18n.t('deposit');
        case 'withdraw':
          return  this.$i18n.t('withdraw');
      }

      return value;
    },
    async getTransactionItem(transaction) {
      if (this.$route.path !== `/payments/${transaction.transaction_id}`) {
        this.$router.push({ 
         name: 'PlayerTransaction', 
         params: { 
          playerId: transaction.user_id,
          paymentId: transaction.transaction_id,
         },
        });
      }
    },
    statusText(status) {
      switch (status) {
        case 0:
          return this.$i18n.t('processing');
        case 1:
          return this.$i18n.t('completed');
        case 2:
          return this.$i18n.t('completed_with_fee');
        case 3:
          return  this.$i18n.t('canceled');
          default: 
          return status;
      }
    },
    statusLabelColor(status) {
      switch (status) {
        case 0:
          return 'var(--yellow-500)';
        case 1:
          return 'var(--green-600)';
        case 2:
          return 'var(--yellow-500)';
        case 3:
          return  'var(--red-500)';
          default: 
          return status
      }
    },
    translatedType(payment_type) {
      switch (payment_type) {
        case 'deposit':
          return this.$t('deposit');
        case 'withdraw':
          return this.$t('withdraw');
        default:
          return payment_type;
      }
    },
      translatedPaymentType(payment_sub_type) {
      switch (payment_sub_type) {
        case 'auto':
          return this.$t('auto');
        case 'manual':
          return this.$t('manual');
        default:
          return payment_sub_type;
      }
    },
    async applyFilters() {
      this.loadingFlag = true;
      await this.$store.dispatch('getPaymentWays');
      if (this.datesToFilter.length > 0) {
        const formattedDates = this.datesToFilter.map(dateStr => {
          const date = new Date(dateStr);
          return date.toISOString().split('T')[0];
        });
        const formattedDateOne = formattedDates[0];
        const formattedDateTwo = formattedDates[1];
      if (this.datesToFilter.length === 2) {
        // both data
        this.filters.date_from = formattedDateOne;
        this.filters.date_to = formattedDateTwo;
      } else if (this.datesToFilter.length === 1) {
        // only one
        this.filters.date_from = formattedDateOne;
      } 
    }
      this.filters.page = this.page;
      this.filters.size = this.filterPages;
        this.$store.commit('paggination/SET_FILTERS', this.filters);
      this.setFilters();
    },
    async setFilters() {
      this.loadingFlag = true;
      const err = await this.$store.dispatch('playerPayments', this.gameFilters);
      err ? this.$toast.add({ severity: 'error', summary: this.$t('just_error'), life: 3000 }) : '';
      for (const [key, value] of Object.entries(this.filters)) {
        if (value !== '') {
          this.$set(this.activeFilters, key, value);
        } else {
          this.$delete(this.activeFilters, key, value);
        }
      }
      this.$store.commit('paggination/SET_LOADING_FLAG', false);
      this.loadingFlag = false;
    },
    async clearFilters() {
      Object.keys(this.filters).forEach((key, index) => {
        this.filters[key] = '';
      })
      Object.keys(this.activeFilters).forEach((key, index) => {
        this.$delete(this.activeFilters, key);
      })
      this.datesToFilter = [];
      this.filters.size = this.filterPages;
      this.filters.page = this.page = 1;
      await this.$store.dispatch('playerPayments', {filters: this.filters});
    },
    async removeFilter(key) {
      // Delete active filters
      this.$delete(this.activeFilters, key);
     // Submit empty string
      if (typeof this.filters[key] === 'boolean') {
        this.$set(this.filters, key, false);
      } else {
        this.$set(this.filters, key, '');
      }
      await this.$store.dispatch('playerPayments', {filters: this.filters});
    },
  }
}
</script>

<style lang="scss" scoped>
.input-menu{
  position: relative;
  &__menu{
    display: flex;
    flex-direction: column;
    background-color: var(--surface-a);
    box-shadow: none;
    padding: 10px;
    z-index: 3;
    position: relative;
    width: 100%;
    .p-component{
      &:not(:last-child) {
  
        margin-bottom: 5px;
      }
    }
  }
}

.sticky-filters {
  width: 100%;
  z-index: 3;
  position: -webkit-sticky;
  position: sticky;
  top: 65px;
  @media screen and (max-width: 960px), (max-height: 500px) and (orientation: landscape) {
      position: static !important;
  }
}

</style>
