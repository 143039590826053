//dashboard.js;
import axios from "axios";
const state = {
  selectedCurrency: "USD",
  selectedStat: "all_players",
  loadingFlag: false,
  dashboardUsersInfo: [],
  dashboardTotalsUsersInfo: [],
  dashboardPaymentsInfo: [],
  dashboardTotalsPaymentsInfo: [],
  dashboardPaymentsTableInfo: [],
  dashboardTotalGamesInfo: [],
  dashboardTopTenInfo: [],
  dashboardOnlineInfo: [],
  doNotRepeatInterval: null,
};

const mutations = {
  SET_SELECTED_STAT(state, value) {
    state.selectedStat = value;
  },
  SET_LOADING_FLAG(state, value) {
    state.loadingFlag = value;
  },
  set(state, { type, items }) {
    state[type] = items;
  },
};

const getters = {
  getSelectedCurrency: (state) => state.selectedCurrency,
  getDashboardUsersInfo: (state) => state.dashboardUsersInfo,
  getDashboardTotalsUsersInfo: (state) => state.dashboardTotalsUsersInfo,
  getDashboardPaymentsInfo: (state) => state.dashboardPaymentsInfo,
  getDashboardTotalsPaymentsInfo: (state) => state.dashboardTotalsPaymentsInfo,
  getDashboardPaymentsTableInfo: (state) => state.dashboardPaymentsTableInfo,
  getDashboardOnlineInfo: (state) => state.dashboardOnlineInfo,
  getDashboardTopTenInfo: (state) => state.dashboardTopTenInfo,
  getDashboardTotalGamesInfo: (state) => state.dashboardTotalGamesInfo,
};

const actions = {
  async awaitGetDashboardUsersInfo({ state, commit }, filters) {
    let res = false;
    // const filters = data.filters;
    const params = new URLSearchParams(filters);
    const queryString = `?${params.toString()}`;
    await axios
      .get(`/reports-api/admin_dashboard/casino/users${queryString}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          res = true;
          const data = response.data;
          const totalsData = Object.keys(data)
            .filter((key) => key.includes("total_"))
            .reduce((obj, key) => {
              obj[key] = data[key];
              return obj;
            }, {});
          commit("set", {
            type: "doNotRepeatInterval",
            items: filters.interval_type,
          });
          commit("set", {
            type: "dashboardTotalsUsersInfo",
            items: totalsData,
          });
          commit("set", { type: "dashboardUsersInfo", items: response.data });
        }
      })
      .catch((error) => {
        console.log("error = ", error);
      });
    return res;
  },
  async awaitGetDashboardPaymentsInfo({ state, commit }, filters) {
    let res = false;
    // const filters = data.filters;
    const params = new URLSearchParams(filters);
    const queryString = `?${params.toString()}`;
    await axios
      .get(`/reports-api/admin_dashboard/casino/payments${queryString}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          res = true;
          const data = response.data;
          const totalsData = Object.keys(data)
            .filter((key) => key.includes("total_"))
            .reduce(
              (obj, key) => {
                obj[key] = data[key];
                return obj;
              },
              { currency: filters.currency }
            );
          const paymentsTableData = Object.keys(data)
            .filter((key) => key.includes("payments_by"))
            .reduce((obj, key) => {
              obj[key] = data[key];
              return obj;
            }, {});

          commit("set", {
            type: "selectedCurrency",
            items: filters.currency,
          });
          commit("set", {
            type: "dashboardTotalsPaymentsInfo",
            items: totalsData,
          });
          commit("set", {
            type: "dashboardPaymentsTableInfo",
            items: paymentsTableData,
          });
          commit("set", {
            type: "dashboardPaymentsInfo",
            items: response.data,
          });
        }
      })
      .catch((error) => {
        console.log("error = ", error);
      });
    return res;
  },
  async awaitGetDashboardGamesInfo({ state, commit }, filters) {
    let res = false;
    // const filters = data.filters;
    const params = new URLSearchParams(filters);
    const queryString = `?${params.toString()}`;
    await axios
      .get(`/reports-api/admin_dashboard/casino/games${queryString}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          res = true;
          const data = response.data;
          const totalsData = Object.keys(data)
            .filter((key) => key.includes("total_"))
            .reduce(
              (obj, key) => {
                obj[key] = data[key];
                return obj;
              },
              {}
            );
          const topTen = Object.keys(data)
            .filter((key) => key.includes("top_10"))
            .reduce((obj, key) => {
              obj[key] = data[key];
              return obj;
            }, {});
          commit("set", {
            type: "dashboardTotalGamesInfo",
            items: totalsData,
          });
          commit("set", {
            type: "dashboardTopTenInfo",
            items: topTen,
          });
        }
      })
      .catch((error) => {
        console.log("error = ", error);
      });
    return res;
  },
  async awaitGetDashboardOnlineInfo({ state, commit }) {
    let res = false;
    await axios
      .get(`/reports-api/admin_dashboard/casino/users-online`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          res = true;
          const data = response.data;
          commit("set", {
            type: "dashboardOnlineInfo",
            items: data,
          });
        }
      })
      .catch((error) => {
        console.log("error = ", error);
      });
    return res;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
