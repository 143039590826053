<template>
  <v-main>
<div class="main-content">
  <Card class="mb-15 card-custom">
    <template #content>
          <DataTable :value="payMethods" 
          data-key="id"
          :rows="10"
          :loading="loadingFlag"
            stripedRows 
            class="p-datatable-sm footer-table"
            >
            <Column field="name" :header="$t('payment_gateway')">
              <template #body="{ data }">
                <v-chip small label 
                :color="data.name == 'Cryptobot' ? 'var(--light-flamingo)' : 'var(--main-flamingo)'"
                style="color: var(--white);"
                ><span class="text-capitalize">{{ data.name }}</span></v-chip>
              </template>
            </Column>
            <Column field="type" :header="$t('type')">
              <template #body="{ data }">
                {{ getMethodType(data.type) }}
              </template>
            </Column>
            <Column field="fee" :header="`${$t('processing')} fee`">
              <template #body="{ data }">
                {{ `${data.fee} %` }}
              </template>
            </Column>
            <Column field="fix" :header="`${$t('transaction')} fee`">
            </Column>
            <Column field="currencies" :header="$t('currencies')" :styles="{'min-width':'15rem'}">
              <template #body="{data}">
                <span class="" v-for="(currency, index) in data.currencies" :key="index">
                  <span class="ml-1">{{ currency }}</span>
                </span>
              </template>
            </Column>
            <Column field="active" :header="$t('active')" :styles="{'min-width':'10rem'}">
              <template #body="{data}">
                <div class="d-flex align-center">
                  <span class="mr-3">{{$t('off')}}</span>
                  <v-switch
                  v-model="data.is_active"
                  :loading="data.isActive_loading"
                  hide-details
                  color="green accent-4" inset
                  class="banners__active mt-0 custom-switch "
                  @change="toggleMethod(data)"
                  ></v-switch>
                  
                  <span >{{$t('on')}}</span>
                </div>
              </template>
            </Column>
            <Column field="autopayout" :header="$t('autopayout')" :styles="{'min-width':'10rem'}">
              <template #body="{data}">
                <div class="d-flex align-center" v-if="data.type === 'WITHDRAW'">
                  <span class="mr-3">{{$t('off')}}</span>
                  <v-switch
                  v-model="data.autopayout"
                  hide-details
                  :loading="data.isActive_loading"
                  color="green accent-4" inset
                  class="banners__active mt-0 custom-switch "
                  @change="toggleMethod(data)"
                  ></v-switch>
                  <span >{{$t('on')}}</span>
                </div>
              </template>
            </Column>
            <Column :exportable="false" :styles="{'min-width':'10rem'}">
              <template #body="{data}">
                  <div class="d-flex align-center">
                    <Button icon="pi pi-pencil" class="p-button-sm p-button-rounded p-button-warning mr-2" @click="addNewCurrency(data)" />
                  </div>
                </template>
          </Column>
          </DataTable>
  </template>
  </Card>

</div>
<v-dialog v-model="toEditMethod" scrollable content-class="br-16" max-width="550px" :persistent="formLoading">
      <v-card style="min-width: 300px">
        <v-card-title class="font-weight-bold">
          {{ $i18n.t('pay_method') }}
        </v-card-title>
        <v-card-text class="pb-0 pt-2">
          <v-form
              v-model="isMethodValid"
          >
            <v-text-field
                v-model="selectedMethod.name"
                :rules="[v => !!v || $t('required_field')]" 
                outlined 
                dense  
                color="var(--main-flamingo)"
                :label="$t('name')"
            >
            </v-text-field>
            <v-col class="d-flex align-center pa-0">
            <v-text-field prefix="%" type="number" 
            color="var(--main-flamingo)" 
            :label="`${$t('processing')} fee`" 
            outlined 
            dense 
            class="mb-4"
            hide-details
            v-model="selectedMethod.fee" 
            />
            </v-col>
            <v-col class="d-flex align-center pa-0">
              <v-text-field type="number" 
            color="var(--main-flamingo)" 
            :label="`${$t('transaction')} fee`"
            outlined 
            class="mb-4"
            hide-details
            dense 
            v-model="selectedMethod.fix" 
            />
            </v-col>
            <v-checkbox color="var(--main-flamingo)" class="mt-0 mb-2" hide-details 
              v-model="isAllCurrencies"
                :label="this.$t('all_currencies')"></v-checkbox>
            <v-autocomplete
            :disabled="isAllCurrencies"
              :items="filteredCurrencies"
              :rules="[v => selectedMethod.currencies.length > 0 || $t('required_field')]"
              required
              item-text="name" 
              item-value="id"
              :no-data-text="this.$t('no_data')"
              v-model="selectedMethod.currencies"
              multiple
              outlined 
              dense  
              color="var(--main-flamingo)"
              :label="$i18n.t('available_currencies')"
              :menu-props="{contentClass: 'main-list'}"
              >
      </v-autocomplete>
            <v-col class="d-flex align-center pa-0">
              <v-text-field type="number" 
            color="var(--main-flamingo)" 
            :label="`${$t('payment_min_limit')}`"
            outlined 
            class="mb-4"
            hide-details
            dense 
            v-model="selectedMethod.payment_min_limit" 
            />
            <v-text-field type="number" 
            color="var(--main-flamingo)" 
            :label="`${$t('payment_max_limit')}`"
            outlined 
            dense 
            class="ml-1 mb-4"
            hide-details
            v-model="selectedMethod.payment_max_limit" 
            />
            </v-col>
            
            <div v-for="(limits, currency) in selectedMethod.currencies" :key="currency" class="d-flex align-center">
              <v-text-field
              v-if="selectedCurrencies.includes(currency)"
                v-model="selectedMethod.currencies[currency].payment_min_limit"
                outlined
                :rules="[v => v > 0 || $t('payment_min_limit_limit'), () => checkMaxDep(currency , true)]"
                dense
                color="var(--main-flamingo)"
                :label="$t('payment_min_limit')"
                required
                :suffix="currency"
              ></v-text-field>
              <v-text-field
              class="ml-1"
              v-if="selectedCurrencies.includes(currency)"
              v-model="selectedMethod.currencies[currency].payment_max_limit"
              :rules="[() => checkMaxDep(currency, true)]"
              required
              :label="$t('payment_max_limit')"
              outlined
              dense
              color="var(--main-flamingo)"
              :suffix="currency"
                ></v-text-field>
                </div>
          </v-form>
          
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!isMethodValid"
              :loading="formLoading"
              class="success-btn"
              @click="updateMethod"
          >
            {{ $i18n.t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-main>

</template>

<script>
import { mapGetters } from 'vuex';
  export default {
    name: "PayMethods",
    computed:{
      ...mapGetters({
        payMethods: 'getPaymentWays',
        currencies: 'getCurrencies',
      }),
      filteredCurrencies(){
        return this.currencies.filter(currency => currency !== 'ALL');
      },
      isWithdraw(){
        return this.payMethods.filter(method => method.type === 'WITHDRAW');
      },
    },
    data(){
      return{

        fields: [
          { value: 'name', text: this.$i18n.t('method'), sortable: true },
          { value: 'type', text: this.$i18n.t('type'), sortable: true },
          //{ value: 'payment_count', text: this.$i18n.t('payment_count'), sortable: true },
          //{ value: 'payment_sum', text:  this.$i18n.t('payment_sum'), sortable: true },
          { value: 'fee', text: `${this.$t('processing')} fee`, sortable: true },
          { value: 'fix', text: `${this.$t('transaction')} fee`, sortable: true },
          { value: 'currencies', text: this.$i18n.t('currencies'), sortable: true },
          { value: 'active', text: this.$i18n.t('active'), sortable: true },
          { value: 'autopayout',  text: this.$t('autopayout'), sortable: false },
          { value: 'edit', align: 'right', text: '', sortable: false },
        ],
        options:[
          {value: 0, text: this.$i18n.t('active')},
          {value: 1, text:  this.$i18n.t('not_active')}
        ],
        loadingFlag: false,
        toEditMethod: false,
        isMethodValid: true,
        formLoading: false,
        selectedMethod: {
          
        },
        selectedCurrencies:[],
        isAllCurrencies: false,
      }
    },
    beforeRouteEnter(to, from, next){
    next(vm => {
      vm.$store.commit('setPageName', 'pay_methods')
    });
    },
    async mounted(){
      this.loadingFlag = true
      await this.$store.dispatch('getPaymentWays');
      this.loadingFlag = false
    },
    watch:{
      selectedCurrencies(newCurrencies) {
      if (!this.selectedMethod.currencies) {
      this.$set(this.selectedMethod, 'currencies', {});
    }
      for (const currency in this.selectedMethod.currencies) {
    if (!newCurrencies.includes(currency)) {
      // delete from selectedCurrencies and currencies
      delete this.selectedMethod.currencies[currency];
    }
  }
      newCurrencies.forEach(currency => {
        if (!this.selectedMethod.currencies[currency]) {
          this.$set(this.selectedMethod.currencies, currency, { payment_min_limit: 100, payment_max_limit: 500 });
        }
      });
    },
    selectedMethod: {
    handler(newMethod, oldMethod) {
      // selectedMethod
      // selectedCurrencies
      this.selectedCurrencies = [];
      Object.keys(newMethod.currencies).forEach(currency => {
        this.selectedCurrencies.push(currency);
      });
    },
    deep: true 
  },
  isAllCurrencies(newValue) {
    if (newValue) {
      // add currencies
        const currencies = Object.keys(this.filteredCurrencies).map(currency => {
        return this.filteredCurrencies[currency]; // Используйте название валюты вместо индекса
      });
      this.selectedCurrencies = currencies;
    } else {
      // to delete from selectedCurrencies
      this.selectedCurrencies = [];
    }
  },
    },
    methods: {
      checkMaxDep(currency, edit) {
        if (edit) {
          const minDep = this.selectedMethod.currencies[currency].payment_min_limit;
          const maxDep = this.selectedMethod.currencies[currency].payment_max_limit;
          return maxDep >= minDep || this.$t('min_max_greater');
        } else {
          const minDep = this.selectedMethod.currencies[currency].payment_min_limit;
          const maxDep = this.selectedMethod.currencies[currency].payment_max_limit;
          return maxDep >= minDep || this.$t('min_max_greater');
        }
      },
      getMethodType(type){
        switch(type){
          case 'DEPOSIT':
            return this.$t('deposit')
          case 'WITHDRAW':
            return this.$t('withdraw')
        }
      },
      addNewCurrency(method){
        this.toEditMethod = true;
        this.selectedMethod = method;
    },
      async toggleMethod(method){
        method.isActive_loading = true;
        const data = {
          id: method.id,
          status: method.is_active,
          autopayout: method.autopayout,
        }
        const res = await this.$store.dispatch('updatePaymentSystemStatus', data);
        if (res) {
          this.$toast.add({
            severity: 'success', summary: this.$t("method_success_updated"), life: 4000
          })
          this.toEditMethod = false;
        } else{
          this.$toast.add({
            severity: 'error', summary: this.$t("just_error"), life: 4000
          })
        }
        method.isActive_loading = false;
        await this.$store.dispatch('getPaymentWays');
      },
      async updateMethod(){
        this.formLoading = true;
        const method = {
          currencies: this.selectedMethod.currencies,
          fix: this.selectedMethod.fix,
          fee: this.selectedMethod.fee,
          payment_min_limit: Number(this.selectedMethod.payment_min_limit),
          payment_max_limit: Number(this.selectedMethod.payment_max_limit),
          id: this.selectedMethod.id,
        };
        const res = await this.$store.dispatch('awaitUpdateMethod', method);
        if (res) {
          this.$toast.add({
            severity: 'success', summary: this.$t("method_success_updated"), life: 4000
          })
          this.toEditMethod = false;
        } else{
          this.$toast.add({
            severity: 'error', summary: this.$t("just_error"), life: 4000
          })
        }
        this.formLoading = false;
      },
      toggleAutopayout(method){
        const data = {
          id: method.id,
          status: method.is_active,
        }
        //this.$store.dispatch('updatePaymentSystemStatus', data)
      },
    },
  }
</script>

<style scoped>
  .card_style{
    margin-top: 10px;
    box-shadow: 0 0 5px #2c3e50;
    border-radius: 20px
  }
</style>
