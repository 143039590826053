//paggination.js;
import axios from "axios";
const state = {
  loadingFlag: false,
  filters: {},
};

const mutations = {
  SET_LOADING_FLAG(state, value) {
    state.loadingFlag = value;
  },
  SET_FILTERS(state, value) {
    state.filters = value;
  },
};

const getters = {
  getFilters: (state) => state.filters,
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
