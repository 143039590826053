//customScript.js;
import axios from "axios";
const state = {
  customScripts: [],
  selectedScript: null,
};

const mutations = {};

const getters = {
  getScripts: (state) => state.customScripts,
  getSelectedScript: (state) => state.selectedScript,
};

const actions = {
  async awaitGetScripts({ state }) {
    await axios
      .get(`/api/seo-settings/ `, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          // console.log("response.data", response.data);
          const scriptData = response.data.map((script) => ({
            ...script,
            loadingItem: false,
          }));
          state.customScripts = scriptData;
        }
      })
      .catch((error) => {
        console.log("error = ", error);
      });
  },
  async getScriptById({ state }, id) {
    await axios
      .get(`/api/seo-settings/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          const scriptData = response.data;
          const formattedScript = {
            id: scriptData.id,
            active: scriptData.active,
            code: scriptData.code,
            locale: scriptData.locale,
            type: scriptData.type,
          };
          state.selectedScript = formattedScript;
        }
      })
      .catch((error) => {
        console.log("error = ", error);
      });
  },
  async createCustomScript({ state }, data) {
    let res = false;
    // name: data.name,
    const scriptData = {
      code: data.code,
      locale: data.locate,
      type: data.type,
      active: data.active,
    };
    try {
      const response = await axios.post(`/api/seo-settings/`, scriptData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });

      if (response.status === 200) {
        res = true;
      }
    } catch (error) {
      console.error("error =", error);
    }

    return res;
  },
  async updateCustomScript({ state }, data) {
    let res = false;
    const scriptData = {
      code: data.code,
      locale: data.locale,
      type: data.type,
      active: data.active,
    };
    try {
      const response = await axios.put(
        `/api/seo-settings/${data.id}`,
        scriptData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      if (response.status === 200) {
        res = true;
      }
    } catch (error) {
      console.error("error =", error);
    }

    return res;
  },
  async deleteCustomScript({ commit, dispatch }, scriptId) {
    let res = false;
    try {
      const token = localStorage.getItem("jwt");

      await axios.delete(`/api/seo-settings/${scriptId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return (res = true);
    } catch (error) {
      return (res = false);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
