//gameFee.js;
import axios from "axios";
const state = {
  gamesByProvider: {
    items: [],
    page: 1,
    pages: 1,
    size: 1,
    total: 1,
  },
};

const mutations = {
  set(state, { type, items }) {
    state[type] = items;
  },
  APPEND_GAMES(state, { newGames }) {
    state.gamesByProvider.items = state.gamesByProvider.items.concat(
      newGames.items
    );
    state.gamesByProvider.page = newGames.page;
    state.gamesByProvider.pages = newGames.pages;
    state.gamesByProvider.size = newGames.size;
    state.gamesByProvider.total = newGames.total;
  },
};

const getters = {
  getGamesByProvider: (state) => state.gamesByProvider,
};

const actions = {
  async getGamesByProvider({ state, commit, dispatch }, filters) {
    let res = false;
    try {
      const params = new URLSearchParams();
      for (const key in filters) {
        if (Object.hasOwnProperty.call(filters, key)) {
          const value = filters[key];

          // Check value
          if (value != null && value !== "") {
            params.append(key, value);
          }
        }
      }
      const queryString = `?${params.toString()}`;

      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
      };
      const response = await axios.get(
        `/main-api/games/admin/filter${queryString}`,
        config
      );

      if (response.status === 200) {
        const gamesWithGameProperty = response.data.items;
        const gamesWithoutGameProperty = gamesWithGameProperty.map(
          (item) => item.Game
        );

        const formattedData = {
          items: gamesWithoutGameProperty,
          page: response.data.page,
          pages: response.data.pages,
          size: response.data.size,
          total: response.data.total,
        };
        // state.games = formattedData;
        if (filters.page >= 2) {
          commit("APPEND_GAMES", {
            newGames: formattedData,
          });
        } else {
          commit("set", { type: "gamesByProvider", items: formattedData });
        }
        return formattedData;
      }
    } catch (error) {
      // console.error(error);
      state.gameArray = null;
      return res;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
