//minBetSettings.js;
import axios from "axios";
const state = {
  settings: [],
};

const mutations = {};

const getters = {
  getMinBetSettings: (state) => state.settings,
};

const actions = {
  async awaitGetMinBetSettings({ commit }) {
    return axios
      .get(`/int-api/min_bet_settings/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          let formattedSettings = response.data.map((item) => {
            return {
              ...item,
              settingLoading: false,
            };
          });
          commit("SET_ROLES", formattedSettings);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async awaitCreateMinBetSettings({ commit, dispatch }, setting) {
    let res = false;
    const settingData = {
      currency: setting.currency,
      min_bet: setting.min_bet,
    };
    await axios
      .post(`/int-api/min_bet_settings/`, settingData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          res = true;
        }
      })
      .catch((err) => {});
    return res;
  },
  async awaitUpdateMinBetSettings({ commit, dispatch }, setting) {
    let res = false;
    const settingData = {
      currency: setting.currency,
      min_bet: setting.min_bet,
    };
    await axios
      .put(`/int-api/min_bet_settings/${setting.item_id}`, settingData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          res = true;
        }
      })
      .catch((err) => {});
    return res;
  },
  async awaitDeleteRole({ commit, dispatch }, item_id) {
    let res = false;
    await axios
      .delete(`/int-api/min_bet_settings/${item_id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          res = true;
        }
      })
      .catch((err) => {
        if (err.response) {
        }
      });
    return res;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
