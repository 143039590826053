<template>
  <div>
    <v-row style="padding: 10px">
      <v-col cols="12" class="text-lg-right text-md-right text-sm-center">
        <v-btn
            size="lg"
            @click="modalFlag = true"
            style="text-transform: none"
        >
          <v-icon>add</v-icon>
          {{ $i18n.t('add') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
        :items="affiliate.offers"
        :headers="fields"
        id="players_table_id"
        style="margin-top: 10px"
        sticky-header="58vh"
        :loading="loadingFlag"
        :no-data-text=" $i18n.t('offers_not_available')"
    >
    </v-data-table>
    <v-dialog v-model="modalFlag" max-width="fit-content" >
      <v-card style="width: 300px">
        <v-card-title>
          <h3>{{ $i18n.t('add_offer') }}</h3>
        </v-card-title>
        <v-card-text>
          <div >
            <!--
            <v-col style="padding-left: 0px">
                <div class="modal_th" style="border-bottom: solid 1px #dee2e6">
                    Информация:
                </div>
            </v-col>
            Аффилейт, аккаунт выплат, email, баланс, telegram, skype, status, дата регистрации
            -->
            <v-row style="padding: 10px">
              <v-col>
                <v-select :label="$i18n.t('choose_offer')" style="max-width: 600px" :items="[{value: 0, text: 'Revshare'}]"></v-select>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions >
          <v-col>
            <v-btn size="lg" @click="modalFlag = false" style="text-transform: none">
              {{ $i18n.t('add') }}
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: "OffersModalTab",
    props: {
      affiliate: {
        required: true,
        default: null
      }
    },

    data(){
      return{
        /*
        * сумма депозитов, сумма выводов, сумма ставок, доход*/
        fields: [
          { value: 'product', text:  this.$i18n.t('product'), sortable: false },
          { value: 'offer', text: this.$i18n.t('offer'), sortable: false },
        ],
        typeOptions:[{value: 0, text:  this.$i18n.t('win')},{value: 1, text:  this.$i18n.t('loss')}],
        providerOptions:[],
        filters:{
          type: '',
          provider: '',
          minDate:'',
          maxDate:'',
        },
        loadingFlag: false,
        loadingModalFlag: false,
        modalFlag: false

      }
    },
    /*
    id - айди юзера
provider - название провайдера игры (список заранее задан для каждого юзера, сейчас делаю запрос)
type - значения возможнве: 0 - означает спины выигрышные, 1 - проигрышные спины (со знаком минуса идут)
minDate - дата от
maxDate - дата до
    * */
    async mounted(){

    },
    methods:{
      async applyFilters(){

      },
      async clearFilters(){

        //await this.$store.dispatch('playersFilters', {filters: this.filters});
      }
    }
  }
</script>

<style scoped>

</style>
