<template>
    <v-card>
        <v-card-title class="font-weight-bold justify-center">
          <span>{{ $i18n.t("info_new_player") }}</span>
        </v-card-title>
        <v-card-text class="pb-0 pt-1">
            <div class="d-flex align-center" 
            v-for="(key, index) in info"
            :key="index"
            >
                <span class="font-weight-bold">
                    {{ $t(key.title) }}:
                </span>
                <v-spacer></v-spacer>
                <span class="font-weight-bold c-text">
                    <span v-if="key.value !== 0">{{ key && key.value ? key.value : '---' }}</span>
                    <span v-else>{{ key && key.value ? key.value : '0' }}</span>
                </span>
            </div>
        </v-card-text>
        <v-card-actions>
            
            <span class="p-buttonset fw">
                <Button @click="addMorePlayer"  
                :label="$t('add_more')"
                   class="p-button-sm p-button-warning _set" />
                  <Button @click="saveAndClose" :label="$t('save_close')"
                   class="p-button-sm p-button-success _set" />
                </span>
        </v-card-actions>
      </v-card>
</template>

<script>
    export default {
        name: 'InfoAboutNewPlayer',
        props: {
          newPlayer: {
            type: Object,
            required: true,
            default: () => {
              return {
                username: '',
                password: ''
              }
            }
          }
        },
        computed: {
          // newPlayer(){
          //   return this.$store.state.admins.newPlayer;
          // },
          info() {
    const newPlayer = this.newPlayer;
    if (!newPlayer) {
        return false;
    }
    return Object.keys(newPlayer)
    .filter(key => key 
    === 'username' || key === 'password'
    || key === 'currency'
  )
    .map(key => {
      return {
        title: key,
        value: newPlayer[key]
      };
    });
  },
        },
        methods: {
            addMorePlayer(){
                this.$emit('add-new')
              },
            saveAndClose(){
              this.copy();
              this.$emit('close-popup')
            },
            copy() {

  const textToCopy = this.newPlayer.username.toString() + ' ' + this.newPlayer.password.toString();

    navigator.clipboard.writeText(textToCopy)
}
        },
    }
</script>

<style lang="scss" scoped>

</style>