//admins.js;
import axios from "axios";
import qs from "query-string";
const state = {
  agents: {
    items: [],
  },
  playerLogs: {
    items: [],
  },
  agentSessions: {
    items: [],
  },
  adminAgents: [],
  allAgents: [],
  currentUser: {},
  agentUsers: {
    items: [],
    size: 0,
  },
  paggination: {
    total: 0,
    size: 0,
    page: 1,
    pages: 1,
  },
};

const mutations = {
  set(state, { type, items }) {
    state[type] = items;
  },
  CONCAT_ADMIN_AGENTS(state, { items }) {
    state.allAgents = state.allAgents.concat(items);
  },
  APPEND_AGENTS(state, { type, newItem }) {
    state[type].items = state[type].items.concat(newItem.items);
    state[type].page = newItem.page;
    state[type].pages = newItem.pages;
    state[type].size = newItem.size;
    state[type].total = newItem.total;
  },
};

const getters = {
  getPaggination: (state) => state.paggination,
  getPlayerLogs: (state) => state.playerLogs,
  getAgentSessions: (state) => state.agentSessions,
  getCurrentUser: (state) => state.currentUser,
  getAgentUsers: (state) => state.agentUsers,
  getAgents: (state) => state.agents,
  getAdminAgents: (state) => state.adminAgents,
  getAllAgents: (state) => state.allAgents,
  treeData: (state) => {
    // console.log("treeData", state.adminAgents);
    return state.adminAgents.map((agent) => ({
      key: agent.id.toString(),
      data: {
        id: agent.id,
        name: agent.name,
        login: agent.login,
        email: agent.email,
        phone: agent.phone,
        is_active: agent.is_active,
        level: agent.level,
        share: agent.share,
        parent_agent: agent.parent_agent,
        credits_balance: agent.credits_balance,
        credits_on_players_balances: agent.credits_on_players_balances,
        first_level_subagents_count: agent.first_level_subagents_count,
        jackpot_amount: agent.jackpot_amount,
        ggr: agent.ggr,
        currency: agent.currency,
        total_subagents_count: agent.total_subagents_count,
        total_players: agent.total_players,
      },
      children: [], // Изначально пустой, будет заполняться при раскрытии
      leaf: agent.first_level_subagents_count === 0,
    }));
  },
};

const actions = {
  async loadAgentChildren({ commit }, parentId) {
    return axios
      .get(
        `/api/agent_module/agents?is_active=true&parent_agent_id=${parentId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          commit("CONCAT_ADMIN_AGENTS", { items: response.data.items });
          return response.data;
        }
      });
  },
  async awaitGetAgents({ commit }, filters) {
    const params = new URLSearchParams();
    for (const key in filters) {
      if (Object.hasOwnProperty.call(filters, key)) {
        const value = filters[key];
        if (value != null && value !== "") {
          params.append(key, value);
        }
      }
    }
    const queryString = `?${params.toString()}`;
    return axios
      .get(`/api/agent_module/agents${queryString}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          if (filters.page && filters.page >= 2) {
            commit("APPEND_AGENTS", { type: "agents", newItem: response.data });
          } else {
            commit("set", { type: "agents", items: response.data });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async awaitGetAllAdminAgents({ commit }, { type, filters, isSearch }) {
    const params = new URLSearchParams();
    for (const key in filters) {
      if (Object.hasOwnProperty.call(filters, key)) {
        const value = filters[key];
        if (value != null && value !== "") {
          params.append(key, value);
        }
      }
    }
    const queryString = `?${params.toString()}`;
    return axios
      .get(`/api/agent_module/agents${queryString}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          let formattedAgents;
          if (type === "agent") {
            formattedAgents = response.data.items;
          } else {
            formattedAgents = response.data.items.filter(
              (agent) => !agent.parent_agent
            );
          }
          const paggination = {
            total: response.data.total,
            size: response.data.size,
            page: response.data.page,
            pages: response.data.pages,
          };
          // console.log("response.data.items", response.data.items);
          if (isSearch) {
            commit("set", { type: "allAgents", items: response.data.items });
            commit("set", { type: "adminAgents", items: response.data.items });
            commit("set", { type: "paggination", items: paggination });
            return;
          }
          commit("set", { type: "allAgents", items: formattedAgents });
          commit("set", { type: "adminAgents", items: formattedAgents });
          commit("set", { type: "paggination", items: paggination });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async awaitAddNewAgentByAdmin({ commit }, { type, agent }) {
    //let endpoint;
    // if (type === "agent") {
    //   endpoint = `agent_module/agents`;
    // } else {
    //   endpoint = `agent_module/agents`;
    // }
    await axios
      .post(`/api/agent_module/agents`, agent, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  //========================================================================================================================================================

  async awaitCreateNewUserAgent({ commit }, { id, type, data }) {
    let userAgent = {};
    await axios
      .post(`/api/agent_module/users`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          userAgent = response.data;
        }
      });
    return userAgent;
  },
  async awaitEditAgentUser({ commit }, { id, type, data }) {
    // console.log("data", data);

    await axios.put(`/api/agent_module/users/${id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
  },
  //========================================================================================================================================================

  async awaitBalanceAgentByAdmin({ commit }, data) {
    // console.log("data", data);
    await axios.put(`/api/agent_module/agents/balance`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
  },
  async awaitBalanceUser({ commit }, data) {
    // console.log("data", data);
    await axios.put(`/api/agent_module/users/balance`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
  },
  async awaitManageUser({ commit }, data) {
    await axios
      .post(`/main-api/users/set_block`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  //========================================================================================================================================================

  async awaitEditAgent({ commit }, { type, agent, id }) {
    // console.log("data", data);
    // let endpoint;
    // if (type === "agent") {
    //   endpoint = `agent_module/agents`;
    // } else {
    //   endpoint = `agent_module/agents`;
    // }
    await axios.put(`/api/agent_module/agents/${id}`, agent, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
  },
  async awaitGetAgentUsers({ commit }, filters) {
    const params = new URLSearchParams();
    for (const key in filters) {
      if (Object.hasOwnProperty.call(filters, key)) {
        const value = filters[key];
        if (value != null && value !== "") {
          params.append(key, value);
        }
      }
    }
    const queryString = `?${params.toString()}`;
    return axios
      .get(`/api/agent_module/users${queryString}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          commit("set", { type: "agentUsers", items: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  //========================================================================================================================================================
  async awaitGetAgentPlayerPayments({ commit }, filters) {
    const params = new URLSearchParams();
    for (const key in filters) {
      if (Object.hasOwnProperty.call(filters, key)) {
        const value = filters[key];
        if (value != null && value !== "") {
          params.append(key, value);
        }
      }
    }
    const queryString = `?${params.toString()}`;
    return axios
      .get(`/api/agent_module/users/transfer_logs${queryString}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          commit("set", { type: "playerLogs", items: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  // async awaitGetUserInfoForAgent({ commit }, id) {
  //   let player = {};
  //   await axios
  //     .get(`/api/agent_module/users/${id}`, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("jwt")}`,
  //       },
  //     })
  //     .then((response) => {
  //       if (response.status === 200) {
  //         player = response.data;
  //         commit("set", { type: "currentUser", items: response.data });
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  //   return player;
  // },
  //========================================================================================================================================================
  async awaitDeleteAgent({ commit }, id) {
    let type;
    await axios
      .delete(`/api/agent_module/agents/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        type = response.data.result;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
    return type;
  },
  //========================================================================================================================================================
  async awaitGetAgentSessions({ commit }, filters) {
    const params = new URLSearchParams();
    for (const key in filters) {
      if (Object.hasOwnProperty.call(filters, key)) {
        const value = filters[key];
        if (value != null && value !== "") {
          params.append(key, value);
        }
      }
    }
    const queryString = `?${params.toString()}`;
    return axios
      .get(`/api/agent_module/auth_sessions${queryString}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          commit("set", { type: "agentSessions", items: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
