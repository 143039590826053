//banners.js;
import axios from "axios";
const state = {
  mainSliderBanners: [],
  userRegBanners: [],
};

const mutations = {
  set(state, { type, items }) {
    state[type] = items;
  },
};

const getters = {
  getMainSliderBanners: (state) => state.mainSliderBanners,
  getUserRegBanners: (state) => state.userRegBanners,
};

const actions = {
  async awaitGetBanners({ commit }, banners) {
    await axios
      .get(`/main-api/banners/admin?banner_type=${banners}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          let formattedBanners = response.data.map((item) => {
            return {
              ...item,
              isActive_loading: false,
            };
          });
          let type;
          if (banners === "main_slider") {
            type = "mainSliderBanners";
          } else {
            type = "userRegBanners";
          }
          commit("set", { type: type, items: formattedBanners });
        }
      })
      .catch((error) => {
        console.log("error = ", error);
      });
  },
  async createBanner({ state, dispatch, rootGetters }, data) {
    let res = false;
    //const bannerData = {
    //  name: banner.name,
    //  link: banner.link,
    //  active: banner.active,
    //  device_type: banner.device_type,
    //};
    try {
      const response = await axios.post(`/main-api/banners/`, data.banner, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });

      if (response.status === 200) {
        res = true;
      }
    } catch (error) {
      console.error("error =", error);
    }

    return res;
  },
  async updateBanner({ state, dispatch, rootGetters }, { data, banner_id }) {
    let res = false;
    try {
      const response = await axios.put(`/main-api/banners/${banner_id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });

      if (response.status === 200) {
        res = true;
      }
    } catch (error) {
      console.error("error =", error);
    }

    return res;
  },
  async toggleBanner({ state, dispatch }, { data, banner_id }) {
    let res = false;
    try {
      const response = await axios.put(`/main-api/banners/${banner_id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });

      if (response.status === 200) {
        res = true;
      }
    } catch (error) {
      console.error("error =", error);
    }

    return res;
  },
  async deleteBanner({ commit, dispatch }, bannerId) {
    let res = false;
    try {
      // Получаем токен из локального хранилища
      const token = localStorage.getItem("jwt");

      // Отправляем запрос на удаление промокода с использованием заголовка авторизации
      await axios.delete(`/main-api/banners/${bannerId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return (res = true);
    } catch (error) {
      console.error("Ошибка при удалении промокода:", error);
      return (res = false);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
