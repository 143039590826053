<template>
  <v-main>
    <!-- <game-providers />
    <game-aggregators /> -->
    <div class="main-content">
      <Card class="card-custom">
        <template #title>
          <v-tabs
          v-model="selectedTab"
          class="main-tabs"
          background-color="var(--white)"
      >
        <v-tab to="/game-fee-settings/providers">
          {{ $i18n.t('game_studios') }}
        </v-tab>
        <v-tab to="/game-fee-settings/aggregators">
          {{ $i18n.t('aggregators') }}
        </v-tab>
      </v-tabs>
          </template>
        <template #content>
            <router-view>
            </router-view>
    
    </template>
    </Card>
    </div>

</v-main>
</template>

<script>
import GameProviders from './GameProviders'
import GameAggregators from './GameAggregators'
import axios from 'axios';
import { mapGetters } from 'vuex';
export default {
  name: "AllGameProvidersSetting",
  components:{
    GameProviders,
    GameAggregators,
  },
  computed: {
  },
  data() {
    return {
      selectedTab: 0,
    }
  },
  beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.$store.commit('setPageName', 'game_studio_settings')
      });
    },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
