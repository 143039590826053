//Role.js;
import axios from "axios";
const state = {
  styles: [],
};

const mutations = {
  set(state, { type, items }) {
    state[type] = items;
  },
};

const getters = {
  getUI: (state) => state.styles,
};

const actions = {
  async awaitGetStyles({ commit }, domain) {
    return axios
      .get(`/main-api/ui_style/?domain=${domain}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          commit("set", { type: "styles", items: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async awaitUpdateStyles({ commit }, styles) {
    return axios
      .put(`/main-api/ui_style/`, styles, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.status === 200) {
        }
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
