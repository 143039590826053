<template>
  <div>
    <!--   -->
    <v-app-bar app temporary color="var(--main-flamingo)" class="c-white">
      <div class="item-header">
        <div class="header-button"  style="cursor: pointer;" icon @click="drawer = !drawer" v-if="admin.id">
          <v-icon color="white">
            list
          </v-icon>
      </div>
        <router-link to="/dashboard" class="logo logo-header">
        <img src="@/assets/images/ADMIN_LOGO.png" alt="logo">
      </router-link>
      <h4 class="header-link font-weight-bold ml-1 text-left">
        <template v-if="isAgent">
          <span >{{$t('agent_admin')}} 
          </span>
          <a v-if="domain.includes('kelt')" href="https://keltgames.com/" target="_blank">keltgames.pro</a>
          <a v-else href="https://vip-casino.pro/" target="_blank">vip-casino.pro</a>
      </template>
      <template v-else-if="admin.id">
        <span >Admin panel: </span>
        <a href="https://takemybet.pro/" target="_blank">takemybet.pro</a>
      </template>
      </h4>
      </div>
    <div class="item-header justify-center">
      <div class="d-flex flex-column">
        
                <span class="font-weight-bold title-size">
                  {{ $t(pageName) }}
                </span>
        </div>
    </div>
      <div class="item-header justify-end">
        <div class="time mr-1 white-space">
          <ServerTime />
        </div>
        <div v-if="admin.id" class="admin-info mr-1 font-weight-bold white-space">
          <div>
            {{ `${$t('login')}: ${admin.login}` }}
          </div>
          <div v-if="isAgent">
            {{ `${$t('balance')}: ${admin.credits_balance} ${admin.currency}` }}
          </div>
        </div>
        <button type="button" class="header-button " 
        @click="toggleLang" aria-haspopup="true" aria-controls="overlay_menu"
        style="max-width: 80px">
        <span class="text-uppercase font-weight-bold">{{ $i18n.locale }}</span>
          <Menu id="overlay_menu" ref="menu" :model="itemsLanguage" :popup="true" />
          <!-- <v-select 
          :items="['en', 'ru']" 
          v-model="$i18n.locale" 
          @change="changeLanguage"
          hide-details
          class="select-white"
          ></v-select> -->
        </button>
        <div class="d-flex align-center" v-if="admin.id">
          <button type="button" class="header-button" 

          @click="toggle" aria:haspopup="true" aria-controls="overlay_panel">
            <v-icon color="var(--white)">
              {{ isAgent ? 'mdi-account-tie' : 'person' }}
            </v-icon>
          </button>
          <OverlayPanel ref="op" appendTo="body" id="overlay_panel" class="admin-overlay" v-if="admin.id">
            <div class="admin-overlay__title"  v-if="isAgent">
              <div class="column-three column-three_no-padding"></div>
              <div class="column-three column-three_no-padding">{{ $t('agent_admin') }}</div>
              <div class="column-three column-three_no-padding"
              style="text-align: right;"
              >
                
                <Button
                @click='toAddDialog = !toAddDialog'
                icon="pi pi-cog" class="p-button-rounded p-button-secondary" /></div>
            </div>
            <div class="admin-overlay__title "
            style="justify-content: center;"
            v-else>
              {{ $t('admin_data') }}
            </div>
            <div class="admin-overlay__content">
              <div class="admin-overlay__item">
                <div class="admin-overlay__name">
                  ID
                </div>
                <div class="admin-overlay__value">
                  {{ admin.id }}
                </div>
              </div>
              <div class="admin-overlay__item">
                <div class="admin-overlay__name">
                  {{ $t('login') }}
                </div>
                <div class="admin-overlay__value">
                  {{ admin.login }}
                </div>
              </div>
              <div class="admin-overlay__item" v-if="admin.role">
                <div class="admin-overlay__name">
                  {{ $t('role') }}
                </div>
                <div class="admin-overlay__value">
                  {{ admin.role.name }}
                </div>
              </div>
              <div class="admin-overlay__item" v-if="admin.name">
                <div class="admin-overlay__name">
                  {{ $t('name') }}
                </div>
                <div class="admin-overlay__value">
                  {{ admin.name }}
                </div>
              </div>
              <div class="admin-overlay__item" v-if="admin.email">
                <div class="admin-overlay__name">
                  {{ $t('email') }}
                </div>
                <div class="admin-overlay__value">
                  {{ admin.email ? admin.email : '-' }}
                </div>
              </div>
              <div class="admin-overlay__item" v-if="admin.phone">
                <div class="admin-overlay__name">
                  {{ $t('phone') }}
                </div>
                <div class="admin-overlay__value">
                  {{ admin.phone ? admin.phone : '-' }}
                </div>
              </div>
              <div class="admin-overlay__item" v-if="admin.credits_balance || admin.credits_balance === 0">
                <div class="admin-overlay__name">
                  {{ $t('balance') }}
                </div>
                <div class="admin-overlay__value d-flex align-center">
                  {{ admin.credits_balance }} {{ admin.currency }}
                </div>
              </div>
              <div class="admin-overlay__item" v-if="admin.level || admin.level === 0">
                <div class="admin-overlay__name">
                  {{ $t('level') }}
                </div>
                <div class="admin-overlay__value">
                  {{ admin.level }}
                </div>
              </div>
            </div>
          </OverlayPanel>
        
        <!-- <button @click="toggleTheme">Переключить тему</button> -->
          <!-- {{ $store.getters.admin.login }} -->
          <button type="button" 
          @click="$router.push('/logout')"
          class="header-button header-button_hide">
            <v-icon color="var(--white)" small>fa fa-sign-out-alt</v-icon>
        </button>
        </div>
      </div>

    </v-app-bar>
    <v-navigation-drawer color="primary" v-model="drawer" temporary app>
      <v-list class="list-menu" nav dark>
        <v-row class="px-5 pt-2">
          <v-col cols="12" class="pb-0 pr-0 d-flex align-center">
            
              <div>
                <template v-if="isAgent">
                  <h3>{{$t('agent_admin')}} 
                  </h3>
                  <a v-if="domain.includes('kelt')" 
                  class="link-hover font-weight-bold"
                  href="https://keltgames.com/" target="_blank">keltgames.pro</a>
                  <a v-else 
                  class="link-hover font-weight-bold"
                  href="https://vip-casino.pro/" target="_blank">vip-casino.pro</a>
              </template>
              <template v-else>
                <h3>Admin panel: </h3>
                <a href="https://takemybet.pro/" class="link-hover font-weight-bold" target="_blank">takemybet.pro</a>
              </template>
              </div>
            
            <v-spacer></v-spacer>
            <div class="logo ml-1">
              <img src="@/assets/images/ADMIN_LOGO.png" alt="logo">
            </div>
          </v-col>
          
          <v-col cols="12" class="pt-0 pr-0 d-flex flex-column align-end">
            <div class="time-menu">
              <ServerTime class=""/>
            </div>
            <span style="font-size: 10px;">
              BLESSED CORE: V.1.0.32
            </span>
            <span style="font-size: 10px;">
              BONUS CONTROL SYSTEM: V.1.01
            </span>
            <span style="font-size: 10px;">
              AI CONTROL SYSTEM: V.0.2
            </span>
            
          </v-col>
        </v-row>
        <v-row class="px-5 mb-0" v-if="tokenUser">
          <v-col class="">
            <maintenance-state @open="openMenu" />
          </v-col>
        </v-row>
        <template v-for="(link, index) in filteredLinkHaveLength" >
            <v-list-group v-if="link.subItems && link.subItems.length > 0" :key="'group-' + index" :prepend-icon="link.icon"  >
              <template v-slot:activator>
                <div class="link-title">{{ link.title }}</div>
              </template>
              <v-list-item @click.stop="checkGameManagerSave(subItem)" :to="getSubItemHref(subItem)" v-for="(subItem, subIndex) in link.subItems" 
              :key="'subitem-' + subIndex" 
              class="sublist-item" :class="{ 'no-active': subItem && subItem.noactive }">
                <v-list-item-icon>
                  <v-icon>{{ subItem.icon }}</v-icon>
                </v-list-item-icon>
                <div class="item__content">
                  <div class="item__title">{{ subItem.title }}</div>
                </div>
              </v-list-item>
            </v-list-group>
            <v-list-item v-else :key="'item-' + index"
            @click.stop="checkGameManagerSave(link)"
            :to="getItemHref(link)"
            :style="{backgroundColor: link.href === '/logout' ? 'var(--red)' : ''}"
            :class="{ 'no-active': link && link.noactive }"
            >
            <v-list-item-icon>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ link.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
    <modal-actions-agent 
    @dialog="toAddDialog = false"
    @added="toAddDialog = false"
              :agent="admin"
              :isEditAgent="true"
              :isEditAdminAgent="true"
              :toAddDialog="toAddDialog" 
              />
  </div>
</template>

<script>
import MaintenanceState from './MaintenanceState.vue'
import {mapGetters} from 'vuex';
import ServerTime from '@/components/Time/ServerTime'
import ModalActionsAgent from '@/components/Agents/AgentsList/ModalActionsAgent.vue';
export default {
  name: "Header",
  components:{
    ModalActionsAgent,
    ServerTime,
    MaintenanceState
  },
  computed: {
    ...mapGetters({
      admin: 'admin',
      tokenUser: 'roles/getPermissions',
      tokenAgent: 'tokenUser',
    }),
    isAgent(){
      return this.tokenAgent === "agent";
    },
    filteredLinkHaveLength(){
      const perms = this.tokenUser;
      const agentPerms = this.tokenAgent;
  if (!perms && !agentPerms) {
    return;
  }
  if (perms) {
    
    return this.filteredLinks.filter(link => {
      return Object.values(link).some(value => !!value);
    });
  } else{
    return this.filteredAgentLinks.filter(link => {
      return Object.values(link).some(value => !!value);
    });

  }
    },
    filteredLinks() {
  // const perms = JSON.parse(localStorage.getItem("tokenUser")) || [];
  const token = this.tokenUser;
  if (!token) {
    return;
  }
  // const perms = token.ui_permissions.section;
  const perms = token.ui_permissions.reduce((acc, permission) => {
  return acc.concat(permission.section);
}, []);
  return this.links.map(link => {
    if (link.subItems && link.subItems.length > 0) {
      let subItems = link.subItems;
      // console.log(subItems);
      if (!perms.some((token) => token === "all")) {
        subItems = subItems.filter(subItem => {

          return !subItem.isEternal
          && !subItem.isAgentFlag
          && subItem.roleCode !== "agent_jackpots" && perms.some((token) => token === (subItem.roleCode));
        });
      } else{
        subItems = subItems.filter(subItem => {
          return !subItem.isEternal && !subItem.isAgentFlag && subItem.roleCode !== "agent_jackpots";
        });
      }
      if (subItems.length === 0) {
        return null;
      }
      return { ...link, subItems };
    } else {
      if (link.isEternal) {
        return link;
      } else if (!link.isEternal) {
        if (!perms.some((token) => token === "all")) {
        const linkArray = [link];
        const filteredLinks = linkArray.filter(item => {
          return perms.some((token) => token === (item.roleCode));
        });
        const filteredLinksObject = Object.assign({}, ...filteredLinks);
        return filteredLinksObject;
      } else{
        return link;
      }
      }
      
      return !perms.some((token) => token === "all") && perms.some((token) => token === (link.roleCode)) ? link : null;
      // console.log(link);
    }
  }).filter(Boolean); 
},
filteredAgentLinks() {
  const perms = this.tokenAgent; 
  if (!perms) {
    return [];
  }
  return this.links.map(link => {
    if (link.subItems && link.subItems.length > 0) {
      const subItems = link.subItems.filter(subItem => {
        // console.log('subItem', subItem);
        return subItem.roleCode && subItem.roleCode.startsWith(perms) || subItem.isAgentFlag
        || subItem.isAgentSetting
        || (this.admin.level === 0 && subItem.isAgentLevel) 
        ;
      });
      if (subItems.length === 0) {
        return null;
      }
      return { ...link, subItems };
    } else {
      if (link.roleCode && (perms === "all" 
      || link.roleCode.startsWith(perms)) || link.isAgentFlag
      || link.isLogout || link.isAgentSetting 
      || (this.admin.level === 0 && link.isAgentLevel) 
    ) {
        return link;
      }
      return null;
    }
  }).filter(Boolean);
},

    links() {
      return [
        {
          href: '/dashboard',
          title: this.$i18n.t('dashboard'),
          icon: 'mdi-home',
          isEternal: true,
        },
        {
          href: '/affiliates',
          title: this.$i18n.t('affiliates'),
          icon: 'fa fa-handshake',
          isEternal: true,
        },
        {
          href: '/multiacc-setup',
          title: this.$i18n.t('multiaccs'),
          icon: 'mdi-account-cog',
          roleCode: 'MultiSetup',
        },
        {
          href: '/geo-restrictions',
          title: this.$i18n.t('geo_restrictions'),
          icon: 'mdi-earth',
          roleCode: 'Geo',
        },
        {
          
          title: 'CMS',
          icon: 'mdi-cogs',
          subItems: [
            {
              href: '/notifications',
              title: this.$t('notifications'),
              icon: 'mdi-bell',
              roleCode: 'Notifications',
            },
            {
              // noactive: true,
              href: this.isAgent ? '/themes/agent' : '/themes',
              title: this.$i18n.t('theme_logo'),
              icon: 'mdi-palette',
              roleCode: 'Themes',
              isAgentLevel: true,
              // isAgentSetting: true,
            },
            {
              href: '/banners',
              title: this.$i18n.t('banners'),
              icon: 'mdi-flag',
              roleCode: 'Banners',
            },
            {
              href: this.isAgent ? '/localization/agent' : '/localization',
              title: this.$i18n.t('local'),
              icon: 'mdi-google-translate',
              roleCode: 'Localizations',
              isAgentLevel: true,
              // isAgentSetting: true,
            },
            {
              href: '/custom-scripts/view',
              title: this.$i18n.t('custom_js'),
              icon: 'mdi-script',
              roleCode: 'CustomJs',
            },
            {
              href: '/avatars',
              title: this.$i18n.t('players_ava'),
              icon: 'mdi-camera-account',
              roleCode: 'players_ava',
            },
          ],
        },
        {
          href: '/players',
          title: this.$i18n.t('players_list'),
          icon: 'fa fa-users',
          roleCode: 'players',
        },
        {
          href: '/segments',
          title: this.$i18n.t('segments'),
          icon: 'mdi-chart-pie',
          roleCode: 'segments',
        },
        {
          
          title: this.$i18n.t('agents'),
          icon: 'mdi-badge-account-horizontal',
          subItems: [
            {
              href: '/agents',
              title: this.$i18n.t('agents'),
              icon: 'mdi-account-multiple-check',
              roleCode: 'agents',
            },
            {
              href: '/agent-players',
              title: this.$i18n.t('agent_players_list'),
              icon: 'mdi-account-cash',
              roleCode: 'agent_players',
            },
            {
              href: '/jackpot-settings',
              title: this.$i18n.t('jackpot_settings'),
              icon: 'fas fa-trophy',
              roleCode: 'agent_jackpots',
            },
            
          ],
        },
        {
          title: this.$i18n.t('games'),
          icon: 'fa fa-gamepad',
          subItems: [
          {
            href: '/game-manager',
            title: this.$i18n.t('games_manager'),
            icon: 'fa fa-file-signature',
            roleCode: 'Games',
            isAgentLevel: true,
          },
          {
            href: '/game-fee-settings',
            title: this.$i18n.t('game_studio_settings'),
            icon: 'fa fa-toolbox',
            roleCode: 'GameFee',
          },
          // {
          //   href: '/game-settings',
          //   title: this.$i18n.t('game_settings'),
          //   icon: 'mdi-gamepad',
          //   roleCode: 'GameFee',
          // },
          ],
        },
        {
          title: this.$i18n.t('finances'),
          icon: 'mdi-finance',
          subItems: [
                {
              href: '/cashflow',
              title: this.$i18n.t('cash_flow'),
              icon: 'fa fa-cash-register',
              roleCode: 'Cashflow',
            },
            {
              href: '/financial-transactions',
              title: this.$i18n.t('financial_transactions'),
              icon: 'fa fa-money-bill-alt',
              roleCode: 'FinTransactions',
            },
            {
              href: '/paymethods',
              title: this.$i18n.t('pay_methods'),
              icon: 'fa fa-credit-card',
              roleCode: 'PayMethods',
            },
          ]
        },
        {
          title: this.$i18n.t('player_engagement'),
          icon: 'mdi-account-multiple-plus-outline',
          subItems: [
            {
          href: '/deposit-bonuses/view',
          title: this.$i18n.t('dep_bonuses'),
          icon: 'fas fa-award',
          roleCode: 'DepBonuses',
        },
        {
          href: '/spins-bonuses/view',
          title: this.$i18n.t('free_rounds'),
          icon: 'mdi-sync',
          roleCode: 'FreeRounds',
        },
        {
          href: '/player-levels',
          title: this.$i18n.t('player_levels'),
          icon: 'fas fa-trophy',
          roleCode: 'player_levels',
        },
        {
          href: '/promocodes',
          title: this.$i18n.t('promo'),
          icon: 'mdi-qrcode',
          roleCode: 'Promocodes',
        },
      ]
    },
    {
      
      title: this.$i18n.t('access_control'),
      icon: 'mdi-lock-open-variant',
      subItems: [
        {
          href: '/role-admins',
          title: this.$i18n.t('manage_admins'),
          icon: 'mdi-account-key',
          roleCode: 'ManageUsers',
        },
        {
          href: '/role-settings',
          title: this.$i18n.t('roles_settings'),
          icon: 'mdi-crown',
          roleCode: 'RoleSettings',
        },
        
      ],
    },
    {
      
      title: this.$i18n.t('reports'),
      icon: 'mdi-account-voice',
      subItems: [
        {
          href: '/general-report',
          title: this.$i18n.t('general_report'),
          icon: 'mdi-bullhorn',
          roleCode: 'Reports',
        },
        {
          href: '/games-report',
          title: this.$i18n.t('games_report'),
          icon: 'fa fa-file-signature',
          roleCode: 'games_report',
          isAdminFlag: true,
        },
        ],
      },
    {
      
      title: this.isAgent ? this.$i18n.t('reports') : this.$i18n.t('agent_reports'),
      icon: 'mdi-account-voice',
      subItems: [
        // {
        //   href: '/agents-rtp-report',
        //   title: this.$i18n.t('agents_rtp_report'),
        //   icon: 'mdi-bullhorn',
        //   roleCode: 'agent_rtp_report',
        // },
        {
          href: '/agents-financial-report',
          title: this.$i18n.t('agents_financial_report'),
          icon: 'mdi-bullhorn',
          roleCode: 'agent_financial_report',
        },
        {
          href: '/games-report',
          title: this.$i18n.t('games_report'),
          icon: 'fa fa-file-signature',
          roleCode: 'games_report',
          isAgentFlag: true,
        },
        ],
      },
      {
          title: this.$i18n.t('authentications'),
          icon: 'mdi-account-lock',
          subItems: [
            {
              href: '/auth',
              title: this.$i18n.t('players_authorization_logs'),
              icon: 'fas fa-user-shield',
              roleCode: 'AllAuthLogs',
              isAgentSetting: true,
            },
            {
              href: this.isAgent ? '/authentications/agent' : '/authentications',
              title: this.$i18n.t('admin_authorization_logs'),
              icon: 'fas fa-user-shield',
              roleCode: 'AdminLogs',
              isAgentLevel: true,
            },
          ],
        },
        // {
        //   href: '/redirector',
        //   title: this.$i18n.t('redirector'),
        //   icon: 'fas fa-exchange-alt',
        //   isEternal: true,
        // },
        {
          href: '/logout',
          title: this.$i18n.t('sign_out'),
          icon: 'fa fa-sign-out-alt',
          isEternal: true,
          isLogout: true
        },
      ]
    },
    pageName(){
      return this.$store.state.pageName;
    }
  },
  data() {
    return {
      toAddDialog: false,
      drawer: false,
      blockToRedirect: false,
      linkHref: '',
      domain: window.location.hostname.includes("take")
      ? process.env.VUE_APP_API_DOMAIN_FIRST
      : process.env.VUE_APP_API_DOMAIN_SECOND,
      itemsLanguage: [

                {
                    label: this.$t('select_language'),
                    items: [{
                        label: 'English',
                        // icon: 'pi pi-external-link',
                        command: () => {
                            this.changeLanguage('en');
                        }
                    },
                    {
                        label: 'Russian',
                        // icon: 'pi pi-upload',
                        command: () => {
                            this.changeLanguage('ru')
                        }
                    }
                ]}
            ],
      items: [
        {
          title: 'Users with Submenu',
          icon: 'mdi-account-circle',
          subItems: [
            { title: 'Admin' },
            { title: 'User' },
          ],
        },
        {
          title: 'User without Submenu',
          icon: 'mdi-account',
        },
      ],
    }
  },
  created(){
    this.$root.$on('layoutOpenMenu', this.openMenuFromLayout);
    this.$root.$on('setBlock', this.setBlock);
    this.$root.$on('setUnblock', this.setUnblock);
  },
  methods: {
    toggleLang(event) {
            this.$refs.menu.toggle(event);
        },
    toggle(event){
            this.$refs.op.toggle(event);
    },
    toggleTheme() {
      const themeLink = document.querySelector('#theme-link');
      if (themeLink.getAttribute('href') === 'assets/themes/lara-light-indigo/theme.css') {
        themeLink.setAttribute('href', 'assets/themes/lara-dark-indigo/theme.css');
        this.$store.commit('setIsDark', true);
      } else {
        themeLink.setAttribute('href', 'assets/themes/lara-light-indigo/theme.css');
        this.$store.commit('setIsDark', false);
      }
    },
    checkGameManagerSave(link){
      this.$root.$emit('headerButtonClick');
      if (this.blockToRedirect) {
        this.$store.commit('linksHref/setLinkHref', link.href)
      }
    },
    getSubItemHref(subItem) {
      return this.blockToRedirect ? null : subItem.href;
    },
    getItemHref(link) {
      if (this.blockToRedirect) {
        //this.linkHref = link.href
        //console.log('this.linkHref', this.linkHref);
        return null
      } else{
        return link.href
      }
    },
    setBlock(){
      this.blockToRedirect = true;
    },
    setUnblock(){
      this.blockToRedirect = false;
    },
    openMenuFromLayout(){
      this.blockToRedirect = false;
      setTimeout(() => {
        
        this.drawer = false;
      }, 0);
    },
    openMenu(){
      setTimeout(() => {
        this.drawer = true
      }, 0);
    },
    changeLanguage(value) {
      this.$i18n.locale = value;
      window.localStorage.setItem('locale', value); // Store the selected language in localStorage
      location.reload(); // Refresh the page
    },
  }
}
</script>

<style lang="scss" scoped>

.item-header{
  display: flex;
  align-items: center;
  flex: 0 1 33.333%;
  //&._half{
  //  flex: 0 1 50%;
  //}
}
.admin-info{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  @media (max-width:992px){
    display: none; 
  }
}
.title-size{
  font-size: 18px;
  @media (max-width:992px){
    font-size: 16px;
  }
  @media (max-width:600px){
    font-size: 12px;
  }
}
.time {
  @media (max-width:992px){
    display: none; 
  }
}
.time-menu {
  
  @media (min-width:768.98px){
      display: none;
  }
}

.sublist-item{
  background-color: var(--light-flamingo);
  align-items: center;
  text-align: left;
  padding-left: 25px!important;
  @media (any-hover: hover){
    &:hover{
      .item__title{
        text-decoration: underline;
      }
    }
  }
  .v-list-item__icon{
    max-width: 30px;
    
    i{
      font-size: 20px;
      margin-right: 8px;
    }
  }
  .item__content{
    display: flex;
    
    align-items: center;
    .item__title{
      font-size: 14px;
      color: var(--white)
      
    }
  }
}
.link-title{
  color: var(--white);
  transition: font-weight 0.3s;
  font-size: 15px;
  flex: 1 1 100%;
  @media (any-hover: hover){
    &:hover{
      text-decoration: underline;
    }
  }
}
.link-hover{
  transition: all 0.3s;
  color: var(--white);
  text-decoration: none;
  @media (any-hover: hover){
    &:hover{
      text-decoration: underline;
    }
  }
}
.v-list-item__icon{
  margin-right: 5px!important;
}
.header-link {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  @media (max-width:650px){
  }
  color: var(--white);
  
  @media(max-width: 500px) {
    display: none;
}
a{
  
  color: var(--white);
  @media (any-hover: hover){
    &:hover{
     
        text-decoration: underline;
      
      
    }
  }
}
}

.v-list-item__title{
  font-size: 15px;
  transition: font-weight 0.3s;
  color: var(--white);
  @media (any-hover: hover){
    &:hover{
      text-decoration: underline;
    }
  }
}


</style>
