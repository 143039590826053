//reports.js;
import axios from "axios";
const state = {
  providers: [
    // items: [],
    // page: 1,
    // pages: 1,
    // size: 1,
    // total: 1,
  ],
};

const mutations = {
  set(state, { type, items }) {
    state[type] = items;
  },
};

const getters = {
  getAllProviders: (state) => state.providers,
  getFilteredProviders(state) {
    return state.providers.filter(
      (provider) => provider.string_id !== "all_games"
    );
  },
};

const actions = {
  async awaitGameProviders({ commit }, { filters, type }) {
    const params = new URLSearchParams();
    for (const key in filters) {
      if (Object.hasOwnProperty.call(filters, key)) {
        const value = filters[key];
        // Check value
        if (value != null && value !== "") {
          params.append(key, value);
        }
      }
    }
    const queryString = `?${params.toString()}`;
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
    };
    let endpoint;
    type
      ? (endpoint = `/api/providers/by_agent${queryString}`)
      : (endpoint = `/api/providers${queryString}`);
    await axios
      .get(endpoint, config)
      .then((response) => {
        if (response.status === 200) {
          //   const providers = {
          //     items: response.data.items.map((providerData) => ({
          //       id: providerData.provider.id,
          //       value: providerData.provider.name,
          //       fee: providerData.provider.fee,
          //       is_enabled: providerData.provider.is_enabled,
          //       order: providerData.provider.order,
          //       name: providerData.provider.name,
          //       logo: providerData.provider.logo,
          //       active_games: providerData.active_games,
          //       inactive_games: providerData.inactive_games,
          //       total_games: providerData.total_games,
          //       string_id: providerData.provider.string_id,
          //       producer: providerData.provider.producer,
          //       aggregator: providerData.aggregator,
          //     })),
          //     page: response.data.page,
          //     pages: response.data.pages,
          //     size: response.data.size,
          //     total: response.data.total,
          //   };
          let providers;
          if (type) {
            providers = response.data.items.map((providerData) => ({
              id: providerData.id,
              is_enabled: providerData.is_enabled,
              allowed_to_agents: providerData.allowed_to_agents,
              order: providerData.order,
              name: providerData.name,
              logo: providerData.logo,
              string_id: providerData.string_id,
              loadingSubmit: false,
              isChangeFee: false,
              isLoadingGame: false,
              settingMenu: false,
            }));
          } else {
            providers = response.data.map((providerData) => ({
              id: providerData.provider.id,
              allowed_currencies: providerData.provider.allowed_currencies,
              value: providerData.provider.name,
              fee: providerData.provider.fee,
              is_enabled: providerData.provider.is_enabled,
              allowed_to_agents: providerData.provider.allowed_to_agents,
              order: providerData.provider.order,
              name: providerData.provider.name,
              logo: providerData.provider.logo,
              active_games: providerData.active_games,
              inactive_games: providerData.inactive_games,
              total_games: providerData.total_games,
              string_id: providerData.provider.string_id,
              producer: providerData.provider.producer,
              aggregator: providerData.aggregator,
              loadingSubmit: false,
              isChangeFee: false,
              isLoadingGame: false,
              settingMenu: false,
            }));
          }
          commit("set", { type: "providers", items: providers });
          //   if (component === '') {

          //   }
        }
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
