<template>
        <div class="">
            <div class="d-flex mb-2 align-center">
                <span class="font-weight-bold" style="line-height: 1;">
                    {{ $t('status') }}:
                </span>
                <v-spacer></v-spacer>
                <div class="online-circle is-online" :class="{ 'is-offline' : isBlocked }">
                </div>
            </div>
            <Button 
            @click="confirmPopup($event)" 
            :loading="loadingFlag" 
            :disabled="loadingFlag" 
            :label="!isBlocked ? $t('stop_product') : $t('resume_product')" 
            :icon="!isBlocked ? 'pi pi-pause' : 'pi pi-caret-right'"
            :class="!isBlocked ? 'p-button-danger' : 'p-button-success'"
            class="p-button-sm fw" />
            <ConfirmPopup>
                <template #message>
                    <div class="p-confirm-popup-content">
                        <i class="p-confirm-popup-icon pi pi-exclamation-triangle">
    
                        </i>
                            <div class="p-confirm-popup-message" 
                            style="display: flex;flex-direction:column; max-width:400px"
                            >
                                <h3>{{ $t('attention_title') }}</h3>
                                <span v-if="!isBlocked">{{ $t('attention_disabled_text') }}</span>
                                <span v-else>{{ $t('attention_enabled_text') }}</span>
                                <h4 style="margin-top: 5px;">{{ $t('attention_question') }}</h4>
                            </div>
                    </div>
                </template>
            </ConfirmPopup>
        </div>
</template>

<script>
import { mapGetters } from 'vuex';

    export default {
        name: 'MaintenanceState',
        data() {
            return {
                loadingFlag: false,
            }
        },
        computed: {
            ...mapGetters({
                tokenUser: 'roles/getPermissions',
            }),
            isBlocked(){
                return this.$store.state.maintenance.is_blocked;
            },
        },
        methods: {
            async confirmPopup(event){
                this.$confirm.require({
                target: event.currentTarget,
                // message: 'test',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.toggleProduct();
                    this.$emit('open')
                },
                reject: () => {
                    this.$emit('open')
                }
            });
            },
            async toggleProduct(){
                if (!this.tokenUser) {
                    return;
                }
                
                this.loadingFlag = true;
                const [res, state] = await this.$store.dispatch('maintenance/awaitToggleMaintenance', !this.isBlocked);
                if (res) {
                    if (state) {
                        this.$toast.add({ severity: 'success', summary: this.$t('product_disabled'), life: 4000 })
                        
                    } else{
                        this.$toast.add({ severity: 'success', summary: this.$t('product_enabled'), life: 4000 })

                    }
                } else{
                    this.$toast.add({ severity: 'error', summary: this.$t('just_error'), life: 4000 })

                }
                await this.$store.dispatch('maintenance/awaitGetMaintenance');
                this.loadingFlag = false;
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>