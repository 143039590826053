import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sign_up"},[_c(VCard,{staticClass:"br-16 mx-auto bg-c-main-flamingo",staticStyle:{"width":"320px","position":"relative","z-index":"2"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.signUp.apply(null, arguments)}}},[_c('div',{staticClass:"nav"},[_c('router-link',{staticClass:"nav-item _log",attrs:{"to":{ path: '/login', query: { tab: '0' } }}},[_vm._v(" "+_vm._s(_vm.$t('admin'))+" ")]),_c('router-link',{staticClass:"nav-item _log",attrs:{"to":{ path: '/login', query: { tab: '1' } }}},[_vm._v(" "+_vm._s(_vm.$t('agent'))+" "+_vm._s(_vm.$route.props)+" ")])],1),_c(VCardTitle,{staticClass:"justify-center font-weight-bold c-white"},[_vm._v(" "+_vm._s(_vm.$i18n.t('authorization'))+" ")]),_c(VCardText,[_c(VCol,[_c('span',{staticClass:"p-input-icon-left mb-3 fw"},[_c('i',{staticClass:"pi pi-user"}),_c('InputText',{staticClass:"fw",attrs:{"type":"text","placeholder":_vm.$t('login')},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1),_c('span',{staticClass:"p-input-icon-left mb-3 fw"},[_c('Password',{staticClass:"fw",attrs:{"placeholder":_vm.$t('password'),"toggleMask":"","feedback":false},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('Button',{staticClass:"p-button-success p-button-sm fw",attrs:{"label":_vm.$t('authorization'),"disabled":_vm.loadingFlag,"loading":_vm.loadingFlag},on:{"click":_vm.signUp}})],1)],1)],1),_vm._m(0)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-bubble-main"},[_c('li'),_c('li'),_c('li'),_c('li'),_c('li'),_c('li'),_c('li'),_c('li'),_c('li'),_c('li')])
}]

export { render, staticRenderFns }