<template>
    <div class="nomobile">
        <div>
            <img src="../assets/images/phone.svg">
            <p> {{ $i18n.t('no_mobile') }}</p>
        </div>

    </div>
</template>

<script>
    export default {
        mounted() {
            this.$store.commit('set', {type: 'userInfo', items: false});
        },
        name: "NoMobile"
    }
</script>

<style lang="scss" scoped>
    .nomobile{
        left: -20px;
        top: 20%;
        position: absolute;
        width: 100vw;
        text-align: center;

    }
</style>