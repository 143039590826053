import * as Fingerprint2 from 'fingerprintjs2'
const fingerprint = {
  get : () => new Promise(resolve => {
    new Fingerprint2.get((components) => {
      let values = components.map(function (component) { return component.value });
      let hash = Fingerprint2.x64hash128(values.join(''), 31);
      resolve({components: components, hash: hash});
    })
  })
};


export default fingerprint;