<template>
  <div>
    <h2>{{$i18n.t('aff_info')}} {{affiliate.id}}</h2>
    <v-overlay v-if="loading">
      <v-progress-circular indeterminate size="140" color="primary">
        {{$i18n.t('loading')}}
      </v-progress-circular>
    </v-overlay>
    <v-alert type="error" v-if="errorFlag" style="max-width: 500px;margin: 0 auto" class="mt-4">
      {{$i18n.t('aff_not_found')}}
    </v-alert>
    <v-card v-if="!errorFlag">
      <v-card-text style="font-size: 18px">
        <div>
          <!--
          <v-col style="padding-left: 0px">
              <div class="modal_th" style="border-bottom: solid 1px #dee2e6">
                  Информация:
              </div>
          </v-col>
          Аффилейт, аккаунт выплат, email, баланс, telegram, skype, status, дата регистрации
          -->
          <v-row >
            <v-col cols="12" lg="3" md="3" sm="12">
              <b>ID</b>:
              <p> {{affiliate.id}}</p>
            </v-col>
            <v-col cols="12" lg="3" md="3" sm="12">
              <b> {{$i18n.t('pay_acc')}}</b>:
              <p>{{affiliate.payAccount}}</p>
            </v-col>
            <v-col cols="12" lg="3" md="3" sm="12">
              <b>{{$i18n.t('reg_date')}}:</b>
              <p> {{new Date(affiliate.regDate).toLocaleString()}}</p>
            </v-col>
            <v-col cols="12" lg="3" md="3" sm="12">
              <b>Email:</b>
              <p>{{affiliate.email}}</p>
            </v-col>
          </v-row>
          <v-row >
            <v-col cols="12" lg="3" md="3" sm="12">
              <div class="modal_th">
                {{$i18n.t('balance')}}:
              </div>
              <p>
                {{affiliate.balance}}
                <b style="font-family: Roboto;font-weight: normal">{{$store.getters.currency}}</b>
              </p>
            </v-col >
            <v-col cols="12" lg="3" md="3" sm="12" >
              <div class="modal_th">
                {{$i18n.t('traffic_source')}}:
              </div>
              <div>
                {{affiliate.source}}
                <!--
                <v-btn :variant="$store.getters.getSelectedPlayer.status==0?'danger':'success'">
                    {{$store.getters.getSelectedPlayer.status==0?'Заблокировать':'Подтвердить'}}
                </v-btn>
                -->
              </div>
            </v-col>
            <v-col cols="12" lg="3" md="3" sm="12">
              <div class="modal_th">
                Telegram:
              </div>
              <p>{{affiliate.telegram}}</p>
            </v-col>
            <v-col cols="12" lg="3" md="3" sm="12">
              <div class="modal_th">
                Skype:
              </div>
              <p>{{affiliate.skype}}</p>
            </v-col>
          </v-row>
          <!--
          <v-row>
            <v-col style="text-align: center">
              <v-btn style="width: 60%">Платежи</v-btn>
            </v-col>
            <v-col style="text-align: center">
              <v-btn style="width: 60%">Сессии</v-btn>
            </v-col>
            <v-col style="text-align: center">
              <v-btn style="width: 60%">Теги</v-btn>
            </v-col>
          </v-row>
          -->

          <!---->
          <div slot="modal-footer" style="width: 100%;">
            <v-tabs
                grow
                v-model="selectedTab"
            >
              <v-tab>
                {{$i18n.t('statistics')}}
              </v-tab>
              <v-tab>
                {{$i18n.t('offers')}}
              </v-tab>
            </v-tabs>
          </div>
          <v-tabs-items v-model="selectedTab" v-if="affiliate.id">
            <v-tab-item :transition="false" :reverse-transition="false">
              <StatisticTab :affiliate="affiliate"></StatisticTab>
            </v-tab-item>
            <v-tab-item :transition="false" :reverse-transition="false">
              <OffersModalTab :affiliate="affiliate"></OffersModalTab>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import StatisticTab from "../components/Affiliates/Modal_StatisticTab";
  import OffersModalTab from "../components/Affiliates/Modal_OffersTab";

  export default {
    name: "AffiliateInfo",
    components: {OffersModalTab, StatisticTab},
    props: {
      id: {
        required: true,
        default: null
      }
    },
    data() {
      return {
        loading: false,
        errorFlag: false,
        affiliate: {},
        selectedTab: 0
      }
    },
    async mounted() {
      this.loading = true;
      this.affiliate = await this.$store.dispatch('getAffiliateInfo', {id: this.id});
      if ('id' in this.affiliate) {
        console.log('aff data received')
      } else {
        this.errorFlag = true;
      }
      this.loading = false;
    }
  }
</script>

<style scoped>

</style>
