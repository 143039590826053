import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VCard,[_c(VCardText,[_c(VRow,[_c(VCol,[_c(VTextField,{attrs:{"label":"от","type":"date"},model:{value:(_vm.filters.minDate),callback:function ($$v) {_vm.$set(_vm.filters, "minDate", $$v)},expression:"filters.minDate"}})],1),_c(VCol,[_c(VTextField,{attrs:{"label":"до","type":"date"},model:{value:(_vm.filters.maxDate),callback:function ($$v) {_vm.$set(_vm.filters, "maxDate", $$v)},expression:"filters.maxDate"}})],1)],1)],1),_c(VCardActions,{staticStyle:{"text-align":"right","padding-bottom":"0px"}},[_c(VRow,{staticStyle:{"align-content":"space-between"}},[_c(VCol,[_c(VBtn,{staticStyle:{"text-transform":"none"},attrs:{"size":"lg"},on:{"click":_vm.clearFilters}},[_c(VIcon,[_vm._v(" delete ")]),_vm._v(" "+_vm._s(_vm.$i18n.t('clear'))+" ")],1)],1),_c(VCol,{staticStyle:{"max-width":"max-content"}},[_c(VBtn,{staticClass:"mr-2",staticStyle:{"text-transform":"none"},attrs:{"size":"lg","color":"primary"},on:{"click":_vm.applyFilters}},[_c(VIcon,[_vm._v("search")]),_vm._v(" "+_vm._s(_vm.$i18n.t('find'))+" ")],1)],1)],1)],1)],1),_c(VDataTable,{staticClass:"mt-4",staticStyle:{"max-height":"40%"},attrs:{"items":_vm.stats,"headers":_vm.headers,"loading":_vm.loading,"fixed-header":"","height":"40vh"},scopedSlots:_vm._u([{key:"item.date",fn:function({item}){return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(new Date(item.date).toLocaleDateString())+" ")])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }