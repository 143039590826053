//reports.js;
import axios from "axios";
const state = {
  general_report: [],
  general_report_totals: [],
  games_report: [],
  games_report_aggregators_totals: [],
  games_report_providers_totals: [],
  games_report_overall_totals: [],
  agents_average_rtp_report: [],
  agents_financial_report: [],
  agents_financial_report_totals: [],
};

const mutations = {
  set(state, { type, items }) {
    state[type] = items;
  },
};

const getters = {
  getGeneralReport: (state) => state.general_report,
  getGeneralReportTotals: (state) => state.general_report_totals,
  getGamesReport: (state) => state.games_report,
  //========================================================================================================================================================
  getGamesReportProvidersTotals: (state) => state.games_report_providers_totals,
  getGamesReportAggregatorsTotals: (state) =>
    state.games_report_aggregators_totals,
  getGamesReportOverallTotals: (state) => state.games_report_overall_totals,
  //========================================================================================================================================================
  getAgentsAverageRTPReport: (state) => state.agents_average_rtp_report,
  getAgentsFinancialReport: (state) => state.agents_financial_report,
  getAgentsFinancialReportTotals: (state) =>
    state.agents_financial_report_totals,
};

const actions = {
  async awaitGetAgentsAverageRTPReport({ commit }, filters) {
    const params = new URLSearchParams();
    for (const key in filters) {
      if (Object.hasOwnProperty.call(filters, key)) {
        const value = filters[key];
        if (value != null && value !== "") {
          params.append(key, value);
        }
      }
    }
    const queryString = `?${params.toString()}`;
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
      "Content-Type": "application/json",
    };
    await axios
      .get(`/reports-api/agent_module/average_rtp${queryString}`, config)
      .then((response) => {
        if (response.status === 200) {
          if (response.data === "[]") {
            return;
          }
          if (filters.result === "pdf") {
            const blob = new Blob([response.data], { type: "application/pdf" });
            // Create new URL
            const url = URL.createObjectURL(blob);

            // PDF-file in new window
            window.open(url);
            return;
          }

          const parseredJSON = response.data;
          const transformedDataArray = [];
          transformedDataArray.push({
            name: "general",
            values: [],
          });
          parseredJSON.forEach((item) => {
            let countsHeaderAddedForItem = false;
            let totalsHeaderAddedForItem = false;
            Object.keys(item).forEach((key) => {
              if (key !== "created_at") {
                const existingItem = transformedDataArray.find(
                  (element) => element.name === key
                );
                if (existingItem) {
                  existingItem.values.push({
                    created_at: item.created_at,
                    value: item[key],
                  });
                } else {
                  transformedDataArray.push({
                    name: key,
                    values: [
                      {
                        created_at: item.created_at,
                        value: item[key],
                      },
                    ],
                  });
                  if (key.includes("_count") && !countsHeaderAddedForItem) {
                    transformedDataArray.push({
                      name: "counts",
                      values: [],
                    });
                    countsHeaderAddedForItem = true;
                  }
                  if (key.includes("total_") && !totalsHeaderAddedForItem) {
                    transformedDataArray.push({
                      name: "totals",
                      values: [],
                    });
                    totalsHeaderAddedForItem = true;
                  }
                }
              }
            });
          });
          function sortItems(a, b) {
            const getKeyOrder = (key) => {
              if (key.includes("total_")) return 6;
              if (key === "totals") return 5;
              if (key.includes("_count")) return 4;
              if (key === "counts") return 3;
              if (key.includes("_sum")) return 2;
              if (key.includes("_percent")) return 1;
              return 0;
            };

            const orderA = getKeyOrder(a.name);
            const orderB = getKeyOrder(b.name);

            if (orderA < orderB) return -1;
            if (orderA > orderB) return 1;
            return 0;
          }
          transformedDataArray.sort(sortItems);
          const result = { items: transformedDataArray };
          commit("set", { type: "agents_average_rtp_report", items: result });
        }
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  async awaitGetAgentsFinancialReport({ commit }, filters) {
    const params = new URLSearchParams();
    for (const key in filters) {
      if (Object.hasOwnProperty.call(filters, key)) {
        const value = filters[key];
        if (value != null && value !== "") {
          params.append(key, value);
        }
      }
    }
    const queryString = `?${params.toString()}`;
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
      "Content-Type": "application/json",
    };
    await axios
      .get(`/reports-api/agent_module/financial/detailed${queryString}`, config)
      .then((response) => {
        if (response.status === 200) {
          if (response.data === "[]") {
            return;
          }
          if (filters.result === "pdf") {
            const blob = new Blob([response.data], { type: "application/pdf" });
            // Create new URL
            const url = URL.createObjectURL(blob);

            // PDF-file in new window
            window.open(url);
            return;
          }
          const records = response.data.records;
          const totals = response.data.totals;
          const totalsArray = Object.entries(totals).map(([name, value]) => ({
            name,
            value,
          }));

          const transformedData = {};

          const parseredJSON = records;

          parseredJSON.forEach((item) => {
            Object.keys(item).forEach((dateKey) => {
              if (!transformedData[dateKey]) {
                transformedData[dateKey] = [];
              }

              const valuesArray = item[dateKey];
              valuesArray.forEach((valuesObj) => {
                const agentName = valuesObj.agent; // Используем 'agent' как ключ для имени агента
                let existingAgent = transformedData[dateKey].find(
                  (element) => element.name === agentName
                );

                if (!existingAgent) {
                  existingAgent = {
                    name: agentName,
                    bet: null,
                    win: null,
                    ggr: null,
                    agent_ggr_share: null,
                  };
                  transformedData[dateKey].push(existingAgent);
                }

                Object.keys(valuesObj).forEach((key) => {
                  if (key !== "agent") {
                    let value = valuesObj[key].toString();
                    if (
                      [
                        "bet",
                        "deposit",
                        "withdraw",
                        "win",
                        "ggr",
                        "jackpot",
                        "deposit_to_users",
                        "agent_ggr_commission",
                        "agent_ggr_share",
                        "subagents_ggr_share",
                        "parent_agent_ggr_commission",
                        "from_subagents_ggr_commission",
                      ].includes(key)
                    ) {
                      value = `${value} ${filters.currency}`;
                    } else if (
                      [
                        "my_ggr_share",
                        // "agent_ggr_share",
                        "share",
                      ].includes(key)
                    ) {
                      value = `${value} %`;
                    }

                    existingAgent[key] = value;
                  }
                });
              });
            });
          });

          const dateColumns = Object.keys(transformedData).sort();

          function sortItems(a, b) {
            const getKeyOrder = (key) => {
              if (key.includes("total_")) return 6;
              if (key === "totals") return 5;
              if (key.includes("_count")) return 4;
              if (key === "counts") return 3;
              if (key.includes("_sum")) return 2;
              if (key.includes("_percent")) return 1;
              return 0;
            };

            const orderA = getKeyOrder(a.name);
            const orderB = getKeyOrder(b.name);

            if (orderA < orderB) return -1;
            if (orderA > orderB) return 1;
            return 0;
          }
          // transformedData.sort(sortItems);
          const result = { items: transformedData, dateColumns };
          commit("set", { type: "agents_financial_report", items: result });
          commit("set", {
            type: "agents_financial_report_totals",
            items: totalsArray,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  async getGeneralReport({ state, commit }, data) {
    const filters = data.filters;
    const params = new URLSearchParams(filters);
    const queryString = `?${params.toString()}`;
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
      "Content-Type": "application/json",
    };
    await axios
      .get(`/reports-api/stat_reports/general/${queryString}`, config)
      .then((response) => {
        if (response.status === 200) {
          if (response.data === "[]") {
            return res;
          }
          if (data.filters.result === "pdf") {
            const blob = new Blob([response.data], { type: "application/pdf" });
            // Create new URL
            const url = URL.createObjectURL(blob);

            // PDF-file in new window
            window.open(url);
            res = true;
            return res;
          }
          // const items = [];
          // console.log("parseredJSON", parseredJSON);
          // for (let key in parseredJSON) {
          //   if (Array.isArray(parseredJSON[key])) {
          //     items.push({
          //       name: {
          //         key: key,
          //         values: parseredJSON[key],
          //       },
          //     });
          //   }
          // }
          const totals = response.data.totals;
          const totalsArray = Object.entries(totals).map(([name, value]) => {
            let formattedValue = value;

            if (name.includes("sum")) {
              formattedValue = `${value} ${filters.currency}`;
            }

            if (name.includes("percent")) {
              formattedValue = `${value}%`;
            }

            return {
              name,
              value: formattedValue,
            };
          });

          const parseredJSON = response.data.records;
          const transformedDataArray = [];
          transformedDataArray.push({
            name: "general",
            values: [],
          });
          parseredJSON.forEach((item) => {
            let countsHeaderAddedForItem = false;
            let totalsHeaderAddedForItem = false;
            Object.keys(item).forEach((key) => {
              if (key !== "created_at") {
                let value = item[key];

                if (key.includes("sum")) {
                  value = `${value} ${filters.currency}`;
                } else if (key.includes("percent")) {
                  value = `${value}%`;
                }
                const existingItem = transformedDataArray.find(
                  (element) => element.name === key
                );
                if (existingItem) {
                  existingItem.values.push({
                    created_at: item.created_at,
                    value: value,
                  });
                } else {
                  transformedDataArray.push({
                    name: key,
                    values: [
                      {
                        created_at: item.created_at,
                        value: value,
                      },
                    ],
                  });
                  if (key.includes("_count") && !countsHeaderAddedForItem) {
                    transformedDataArray.push({
                      name: "counts",
                      values: [],
                    });
                    countsHeaderAddedForItem = true;
                  }
                  if (key.includes("total_") && !totalsHeaderAddedForItem) {
                    transformedDataArray.push({
                      name: "totals",
                      values: [],
                    });
                    totalsHeaderAddedForItem = true;
                  }
                }
              }
            });
          });
          function sortItems(a, b) {
            const getKeyOrder = (key) => {
              if (key.includes("total_")) return 6;
              if (key === "totals") return 5;
              if (key.includes("_count")) return 4;
              if (key === "counts") return 3;
              if (key.includes("_sum")) return 2;
              if (key.includes("_percent")) return 1;
              return 0;
            };

            const orderA = getKeyOrder(a.name);
            const orderB = getKeyOrder(b.name);

            if (orderA < orderB) return -1;
            if (orderA > orderB) return 1;
            return 0;
          }
          transformedDataArray.sort(sortItems);
          //console.log(transformedDataArray);
          // state.general_report = [{ items: items }, { values: response.data }];
          const result = { items: transformedDataArray };
          commit("set", { type: "general_report", items: result });
          commit("set", { type: "general_report_totals", items: totalsArray });
          // state.general_report = transformedDataObject;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async getGamesReport({ state, commit }, { filters, agent }) {
    const params = new URLSearchParams();
    for (const key in filters) {
      if (Object.hasOwnProperty.call(filters, key)) {
        const value = filters[key];
        if (value != null && value !== "") {
          params.append(key, value);
        }
      }
    }
    const queryString = `?${params.toString()}`;
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
      "Content-Type": "application/json",
    };
    await axios
      .get(`/reports-api/stat_reports/game-studios/${queryString}`, config)
      .then((response) => {
        if (response.status === 200) {
          if (response.data === "[]") {
            return;
          }
          if (filters.result === "pdf") {
            const blob = new Blob([response.data], { type: "application/pdf" });
            // Create new URL
            const url = URL.createObjectURL(blob);

            // PDF-file in new window
            window.open(url);
            return;
          }
          const records = response.data.records;
          //========================================================================================================================================================

          const totalsAggregator = response.data.totals.aggregator_totals;
          let totalsAggregatorArray = [];
          if (totalsAggregator) {
            totalsAggregatorArray = totalsAggregator.map((item) => ({
              name: item.aggregator,
              sessions_count: item.sessions_count,
              win: `${item.win} ${filters.currency}`,
              bet: `${item.bet} ${filters.currency}`,
              ggr: `${item.ggr} ${filters.currency}`,
              rtp: `${item.rtp} %`,
              provider_fee_sum: item.provider_fee_sum,
              provider_fee_percent: `${item.provider_fee_percent}%`,
            }));
          }
          //console.log("totalsAggregatorArray", totalsAggregatorArray);
          //========================================================================================================================================================
          const totalsProvider = response.data.totals.provider_totals;
          //console.log("agent", agent);
          const totalsProviderArray = totalsProvider.map((item) => {
            const result = {
              name: item.provider,
              sessions_count: item.sessions_count,
              win: `${item.win} ${filters.currency}`,
              bet: `${item.bet} ${filters.currency}`,
              ggr: `${item.ggr} ${filters.currency}`,
              rtp: `${item.rtp} %`,
            };

            if (!agent) {
              result.aggregator = item.aggregator;
              result.provider_fee_sum = item.provider_fee_sum;
              result.provider_fee_percent = `${item.provider_fee_percent}%`;
            }

            return result;
          });
          // if (!agent) {
          //   totalsProviderArray.map(item => {
          //     provider_fee_sum: item.provider_fee_sum,
          //   provider_fee_percent: item.provider_fee_percent,
          //   })
          // }
          //console.log("totalsProviderArray", totalsProviderArray);
          //========================================================================================================================================================
          const totalsOverall = response.data.totals.overall_totals;
          const totalsOverallArray = {
            sessions_count: totalsOverall.sessions_count,
            win: `${totalsOverall.win} ${filters.currency}`,
            bet: `${totalsOverall.bet} ${filters.currency}`,
            ggr: `${totalsOverall.ggr} ${filters.currency}`,
            rtp: `${totalsOverall.rtp} %`,
          };
          if (!agent) {
            totalsOverallArray.provider_fee_sum =
              totalsOverall.provider_fee_sum;
            totalsOverallArray.provider_fee_percent = `${totalsOverall.provider_fee_percent}%`;
          }
          //========================================================================================================================================================
          const transformedDataArray = [];
          transformedDataArray.push({
            name: "general",
            values: [],
          });
          //========================================================================================================================================================

          const parseredJSON = response.data.records;
          parseredJSON.forEach((item) => {
            const gameName = item.game;
            const existingGame = transformedDataArray.find(
              (element) => element.game === gameName
            );
            item.win = `${item.win} ${filters.currency}`;
            item.bet = `${item.bet} ${filters.currency}`;
            item.ggr = `${item.ggr} ${filters.currency}`;
            item.rtp = `${item.rtp} %`;
            if (existingGame) {
              existingGame.records.push(item);
            } else {
              transformedDataArray.push({
                game: gameName,
                records: [item],
              });
            }
          });
          // console.log("parseredJSON", parseredJSON);
          const result = { items: parseredJSON };
          commit("set", { type: "games_report", items: result });
          commit("set", {
            type: "games_report_aggregators_totals",
            items: totalsAggregatorArray,
          });
          commit("set", {
            type: "games_report_providers_totals",
            items: totalsProviderArray,
          });
          commit("set", {
            type: "games_report_overall_totals",
            items: { items: [totalsOverallArray] },
          });
        }
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
