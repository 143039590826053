import { SimpleUser } from "sip.js/lib/platform/web";
import { UserAgent } from "sip.js";
export default {
  namespaced: true,
  state: {
    wsServerUrl: "wss://pbx.zadarma.com:4443",
    sipUser: null,
    login: "",
    password: "",
    audioElement: null,
    isConnecting: false,
    isRegistered: false,
    isCallInProgress: false,
    isRinging: false,
    isMuted: false,
    callingUserId: null,
  },
  getters: {
    isConnecting: (state) => state.isConnecting,
    isRegistered: (state) => state.isRegistered,
    isCallInProgress: (state) => state.isCallInProgress,
    isMuted: (state) => state.isMuted,
    isRinging: (state) => state.isRinging,
    callingUserId: (state) => state.callingUserId,
  },
  mutations: {
    saveLogPass(state, data) {
      state.login = data.login;
      state.password = data.password;
    },
    setConnecting: (state, connecting) => (state.isConnecting = connecting),
    registeredHandler(state) {
      state.isRegistered = true;
      state.isConnecting = false;
      this._vm.$toast.open({
        message: `Подключение к АТС установлено`,
        type: "info",
        duration: 2000,
      });
    },
    unregHandler(state) {
      state.isRegistered = false;
      if (state.isConnecting) {
        state.isConnecting = false;
        this._vm.$toast.open({
          message: `Не удалось подключиться к АТС. Проверьте логин и пароль`,
          type: "error",
          duration: 2000,
        });
      }
    },
    callEndHandler(state) {
      state.isCallInProgress = false;
    },
    setMute: (state, value) => (state.isMuted = value),
    setRinging: (state, value) => (state.isRinging = value),
    callCreatedHandler: (state) => {
      state.isCallInProgress = true;
    },
    setCallingUserId: (state, userId) => (state.callingUserId = userId),
  },
  actions: {
    async connect({ commit, state }, data) {
      if (data.login && data.password) {
        commit("saveLogPass", data);
        commit("setConnecting", true);
        const audio = document.getElementById("remoteAudio");
        if (audio) {
          const uri = UserAgent.makeURI(`sip:${data.login}@pbx.zadarma.com`);
          const eventHandler = {
            onRegistered: function () {
              commit("registeredHandler");
            }.bind(this),
            onUnregistered: function () {
              commit("unregHandler");
            }.bind(this),
            onCallCreated: function () {
              commit("callCreatedHandler");
            }.bind(this),
            onCallHangup: function () {
              commit("callEndHandler");
            }.bind(this),
            onMute: function () {
              commit("setMute", true);
            }.bind(this),
            onUnmute: function () {
              commit("setMute", false);
            }.bind(this),
            onRinging: function () {
              commit("setRinging", true);
            }.bind(this),
          };
          const userOptions = {
            media: {
              remote: {
                audio: audio,
              },
            },
            userAgentOptions: {
              authorizationPassword: data.password,
              authorizationUser: data.login,
              uri: uri,
            },
            delegate: eventHandler,
          };
          state.sipUser = new SimpleUser(state.wsServerUrl, userOptions);
          await state.sipUser.connect().catch((err) => {
            console.log(err);
          });
          await state.sipUser.register();
          setTimeout(
            () => console.log(state.sipUser, state.sipUser.state),
            1500
          );
        } else {
          console.log("Ошибка, не удалось найти DOM элемент аудио");
          commit("setConnecting", false);
        }
      }
    },
    async call({ state, commit }, data) {
      if (state.sipUser && data.number && data.playerId) {
        commit("setCallingUserId", data.playerId);
        //await state.sipUser.call(`sip:+${data.number}@pbx.zadarma.com`)
        let number = data.number.replace("+", "");
        if (number.startsWith("8")) {
          number = `7${number.substr(1)}`;
        }
        //console.log('Calling TO ', number)
        await state.sipUser.call(`sip:${number}@pbx.zadarma.com`);
      }
    },
    async hangup({ state }) {
      if (state.sipUser) {
        await state.sipUser.hangup();
      }
    },
    async mute({ commit, state }) {
      if (state.sipUser) {
        await state.sipUser.mute();
        commit("setMute", true);
      }
    },
    async unmute({ commit, state }) {
      if (state.sipUser) {
        await state.sipUser.unmute();
        commit("setMute", false);
      }
    },
    async rejectCall({ commit, state }) {
      if (state.sipUser) {
        await state.sipUser.reject();
        commit("setRinging", false);
      }
    },
    async answerCall({ commit, state }) {
      if (state.sipUser) {
        await state.sipUser.answer();
        commit("setRinging", false);
      }
    },
  },
  modules: {},
};
