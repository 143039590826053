//levels.js;
import axios from "axios";
const state = {
  lvlItems: [],
  level: {
    name: "",
    description: "",
    dep_bonus_id: 0,
    free_rounds_bonus_id: 0,
    is_active: false,
    id: null,
  },
};

const mutations = {
  set(state, { type, items }) {
    state[type] = items;
  },
};

const getters = {
  getLevels: (state) => state.lvlItems,
  getSelectedLevel: (state) => state.level,
};

const actions = {
  async awaitGetLevels({ commit }, data) {
    const filters = data.filters;
    const params = new URLSearchParams(filters);
    const queryString = `?${params.toString()}`;
    return (
      axios
        /*
         */
        .get(`/api/levels${queryString}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            const levelData = response.data.map((item) => {
              return {
                ...item,
                dep_bonus_id:
                  item.dep_bonus && item.dep_bonus.id
                    ? item.dep_bonus.id
                    : null,
                free_rounds_bonus_id:
                  item.free_rounds_bonus && item.free_rounds_bonus.id
                    ? item.free_rounds_bonus.id
                    : null,
              };
            });
            commit("set", { type: "lvlItems", items: levelData });
          }
        })
        .catch((error) => {
          console.log(error);
        })
    );
  },
  async awaitGetLevel({ commit }, id) {
    await axios
      .get(`/api/levels/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          commit("set", { type: "level", items: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  async awaitCreateLevel({ commit, dispatch }, level) {
    await axios
      .post(`/api/levels`, level, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {})
      .catch((err) => {
        //if (err.response && err.response.status === 409) {
        //  error = "Resource already exists";
        //}
        throw err;
      });
  },
  async awaitUpdateLevel({ commit, dispatch }, { level, level_id }) {
    await axios
      .put(`/api/levels/${level_id}`, level, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {})
      .catch((err) => {
        throw err;
      });
  },
  async awaitDeleteLevel({ commit, dispatch }, level_id) {
    try {
      const token = localStorage.getItem("jwt");
      await axios.delete(`/api/levels/${level_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
