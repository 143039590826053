<template>
    <Card class="card-custom">
      <template #content>
        <v-chip-group>
          <Chip class="active-filter-chip mr-1" v-for="(value, key) in filteredActiveFilters" 
          :key="'filter' + key"
            >
            <template>
              {{ getFilterValueTitle(key) }}: {{ getFilterValueText(key, value) }}
            </template>
            <v-icon @click.stop="removeFilter(key)">mdi-close</v-icon>
          </Chip>
        </v-chip-group>
        <DataTable 
        :value="adminAuthSessions.items"
        :rows="adminAuthSessions.size" 
        :loading="loadingFlag" 
          filterDisplay="row" 
          stripedRows
          removableSort
          class="p-datatable-sm">
          <template #empty>
            {{ $t('no_data') }}
          </template>
          
          <Column sortable field="updated_at" :showFilterMenu="false" :styles="{ 'min-width': '12rem' }">
            <template #header>
              {{ $t('date') }}  
              <!-- <Badge value="?" severity="info" class="info-badge ml-1" v-tooltip.top="$t('registered_hint')"></Badge> -->
            </template>
            <template #body="{ data }">
              <div style="white-space: nowrap">
                {{ new Date(data.updated_at).toLocaleString() }}
              </div>
            </template>
            <template #filter>
                <v-menu 
                v-model="dateMenu" :close-on-content-click="false" transition="scale-transition" offset-y>
                  <template v-slot:activator="{ on }">
      
                    <InputText v-on="on" type="text" @input="debounceFilters" readonly class="p-inputtext-sm" :placeholder="$t('date')"
                      v-model="datesToFilter">
                    </InputText>
      
                  </template>
                  <Calendar class="calendar-menu" :placeholder="$t('date')" :inline="true" :maxDate="today" id="icon"
                    dateFormat="mm.dd.yy" v-model="datesToFilter" selectionMode="range" />
                </v-menu>
            </template>
          </Column>
          <Column sortable field="login" :showFilterMenu="false" :styles="{ 'min-width': '6rem' }">
            <template #header>
              {{ $t('login') }}
            </template>
            <template #body="{data}">
                {{ data.login }}
            </template>
            <template #filter>
              <InputText type="text" @input="debounceFilters" @keydown.enter="applyFilters" v-model="filters.login" class="p-inputtext-sm"
                :placeholder="$t('login')" />
            </template>
          </Column>
          <Column sortable field="ip" :showFilterMenu="false" :styles="{ 'min-width': '6rem' }">
            <template #header>
              IP
            </template>
            <template #body="{data}">
                {{ data.ip }}
            </template>
            <template #filter>
              <InputText type="text" @input="debounceFilters" @keydown.enter="applyFilters" v-model="filters.ip" class="p-inputtext-sm"
                placeholder="IP" />
            </template>
          </Column>
          <Column sortable field="role" :showFilterMenu="false" :styles="{ 'min-width': '10rem' }">
            <template #header>
              {{ $t('role') }}
            </template>
            <template #body="{data}">
              <v-chip
              label
              :color="getLabelColor(data.role)"
              class="c-white"
              small>
              {{ setRolesName(data.role) }}
                </v-chip>
            </template>
            <template #filter>
              <!-- <InputText type="text" @input="debounceFilters" @keydown.enter="applyFilters" v-model="filters.login" class="p-inputtext-sm"
                placeholder="$t('login')" /> -->
                <div id="select-option"
            >
            <!-- attach="#select-option" -->
              <v-menu v-model="roleMenu" 
              offset-y
              :close-on-content-click="false"
                  nudge-left="0" nudge-right="0">
                  <template v-slot:activator="{ on }">
                   
                      <span class="p-input-icon-right" v-on="on" >
                        <i class="pi pi-angle-down" />
                        <InputText 
                        :value="setRolesName(filters.role)"
                        type="text" @input="debounceFilters" readonly class="p-inputtext-sm" :placeholder="$t('role')">
                        </InputText>
                      </span>
                  </template>
                  <!-- <div class="input-menu__menu">
                  </div> -->
                    <Listbox 
                    v-model="filters.role" 
                    optionValue="value"
                    
                    :options="getFormattedRoles"
                    @change="handleChange($event)"
                    :emptyFilterMessage="$t('no_data')"
                    optionLabel="text" 
                    style="width:15rem" />
      
                </v-menu>
            </div>
            </template>
          </Column>
          <Column sortable field="finger_hash" :showFilterMenu="false" :styles="{ 'min-width': '12rem' }">
            <template #header>
              {{ $t('fingerprint_hash') }}
            </template>
            <template #body="{data}">
              {{ data.finger_hash }}
            </template>
            <template #filter>
              <InputText type="text" @input="debounceFilters" @keydown.enter="applyFilters" v-model="filters.hash" 
              style="max-width: 200px;"
              class="p-inputtext-sm"
                :placeholder="$t('fingerprint_hash')" />
            </template>
          </Column>
        </DataTable>
        <games-paggination 
                            :pagginationObject="filters" 
                            :requestData="adminAuthSessions" 
                            @apply-filters="setAndSubmitFilters" 
                            :isStatic="false"
                            />
      </template>
    </Card>
</template>

<script>
import { debounce } from 'lodash'
import GamesPaggination from '@/views/GamesPaggination'
  import {mapGetters} from 'vuex';
  export default {
    name: "AdminAuthSessions",
    components:{
      GamesPaggination
    },
    computed: {
      ...mapGetters({
        adminAuthSessions: 'adminAuthSessions',
        roles: 'roles/getRole',
      }),
        getFormattedRoles(){
          return [
            // {
            //   text: this.$t('all'),
            //   value: null,
            // },
            ...this.roles.map(role => {
              return {
                text: this.setRolesName(role.name),
                value: role.name
              }
            })
          ]
        },
      filteredActiveFilters() {
  return Object.fromEntries(
    Object.entries(this.activeFilters).filter(
      ([key, value]) => key !== 'size' && key !== 'page' && (key === 'is_active' || value !== false)
    )
  );
      },
    },
    data() {
      return {
        loadingFlag: false,
        filters: {
          login: '',
          ip: '',
          hash: '',
        },
        dateMenu: false,
        datesToFilter: [],
        today: new Date,
        currentLocale: this.$i18n.locale || 'en',
        page: 1,
        size: 25,
        startPage: 1,
        pageOptions: [10, 25, 100],
        filterPages: 25,
        activeFilters: {},
        roleMenu: false,
      }
    },
    watch:{
      datesToFilter(newValue, oldValue){
            if (newValue[0] && newValue[1]) {
              this.applyFilters()
            }
          },
          '$route.params.login':{
      handler(newLogin) {
      if (newLogin) {
        this.filters.login = newLogin;
        this.applyFilters();
      }
    },
    immediate: true,
  },
    },
    async mounted() {
      //this.getRouteParams();
      await this.applyFilters();
      if (this.roles.length === 0) {
            this.$store.dispatch('roles/awaitGetRoles');
        }
    },
    methods: {
      debounceFilters: debounce(async function() {
      await this.applyFilters();
    }, 750),
      setRolesName(role){
            switch(role){
              case 'SUPERUSER':
              return 'Super user';
              case 'ADMIN':
              return 'Admin';
              case 'PROMO_MANAGER':
              return 'Promo manager';
              case 'FINANCIAL_MANAGER':
              return 'Financial manager';
              case 'GAME_MANAGER':
              return 'Game manager';
              case 'PRODUCT_MANAGER':
              return 'Product manager';
              case 'CFO':
              return 'CFO';
              case 'CRM_MANAGER':
              return 'CRM manager';
            }
            return role;
          },
      handleChange(){
        this.roleMenu = false;
        this.applyFilters();
      },
      getFilterValueTitle(key) {
      switch (key) {
        case 'user_id':
          return 'id'
        case 'login':
          return this.$t('login')
        case 'role':
          return this.$t('role')
      }

      return key;
    },
    getFilterValueText(key, value) {
      switch (value) {
        case true:
          return this.$t('yes')
        case false:
          return this.$t('no')
      }

      return this.setRolesName(value);
    },
    getLabelColor(role){
      switch(role){
        case 'SUPERUSER':
        return 'var(--red-500)';
        case 'ADMIN':
        return 'var(--red-200)';
        case 'PROMO_MANAGER':
        return 'var(--indigo-500)';
        case 'FINANCIAL_MANAGER':
        return 'var(--pink-500)';
        case 'GAME_MANAGER':
        return 'var(--orange-500)';
        case 'PRODUCT_MANAGER':
        return 'var(--cyan-500)';
        case 'CFO':
        return 'var(--blue-500)';
        case 'CRM_MANAGER':
        return 'var(--yellow-500)';
      }
    },
    
    async getFormattedDate(){
            if (this.datesToFilter.length > 0) {
          const formattedDates = this.datesToFilter.map(dateStr => {
            const date = new Date(dateStr);
            return date.toISOString().split('T')[0];
          });
          const formattedDateOne = formattedDates[0];
          const formattedDateTwo = formattedDates[1];
          if (this.datesToFilter.length === 2) {
            // both data
            this.filters.start_date = formattedDateOne;
            this.filters.end_date = formattedDateTwo;
          } else if (this.datesToFilter.length === 1) {
            // only one
            this.filters.start_date = formattedDateOne;
          }
        }
          },
          async setAndSubmitFilters() {
            this.loadingFlag = true;
            try {
              await this.$store.dispatch('getAdminAuthSessions', {filters: this.filters});
              for (const [key, value] of Object.entries(this.filters)) {
        if (value !== '') {
          this.$set(this.activeFilters, key, value);
        } else {
          this.$delete(this.activeFilters, key);
        }
      }
            } catch (error) {
              this.$toast.add({ severity: 'error', summary: this.$t('just_error'), life: 4000 });
            } finally{
              this.$store.commit('paggination/SET_LOADING_FLAG', false);
        this.$store.commit('paggination/SET_FILTERS', this.filters);
        this.loadingFlag = false;
            }
          },
      async applyFilters() {
        this.filters.page = this.page = this.startPage;
        this.filters.size = this.filterPages;
        this.getFormattedDate();  
        this.$store.commit('paggination/SET_FILTERS', this.filters);
        this.setAndSubmitFilters();
      },
      async clearFilters(){
        this.loadingFlag = true;
        Object.keys(this.filters).forEach((key, index) =>{
          this.filters[key] = ''
        })
        Object.keys(this.activeFilters).forEach((key, index) => {
        this.$delete(this.activeFilters, key);
        })
        this.datesToFilter = [];
        this.setAndSubmitFilters();
      },
      async removeFilter(key) {
        this.loadingFlag = true;
      this.$delete(this.activeFilters, key);
      if (typeof this.filters[key] === 'boolean') {
        this.$set(this.filters, key, false);
      } else {
        this.$set(this.filters, key, '');
      }
      this.setAndSubmitFilters();
    },
    }
  }
</script>

<style lang="scss" scoped>
.filtersCard {
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  z-index: 2;
  top: 55px;
  border-radius: 0!important;
  @media screen  and (max-width: 960px), (max-height: 500px) and (orientation: landscape) {
    position: static;
  }
}
.input-menu{
  color: rgba(0,0,0,.87);
  border: 1px solid rgba(0,0,0,.38);
  border-radius: 8px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  padding: 0 12px;
  cursor: pointer;
  align-items: center;
  position: relative;
  &__menu{
    background-color: var(--white);
    box-shadow: none;
    padding: 10px;
    z-index: 3;
    position: relative;
    width: 100%;
    .v-input{
      margin-top: 8px;
      &:first-child{
        margin-top: 0px;
      }
    }
  }
}
</style>
