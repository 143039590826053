<template>
  <div>
    <audio id="remoteAudio" controls style="display: none">
      <p>Your browser doesn't support HTML5 audio.</p>
    </audio>
    <div class="phoneWidget" v-if="isCallInProgress">
      <v-chip color="green" text-color="white" style="height: max-content">
        <v-container>
          <v-row>
            {{ $i18n.t('active_call') }}
          </v-row>
          <v-row>
            {{new Date(callDuration * 1000).toISOString().substr(11, 8)}}
          </v-row>
          <v-row>
            <router-link :to="`/player/${callingUserId}`">
              ID:{{callingUserId}}
            </router-link>
          </v-row>
          <v-row>
            <v-col>
              <v-btn icon color="white" v-if="!isMuted" @click="changeMute">
                <v-icon>fas fa-microphone-slash</v-icon>
              </v-btn>
              <v-btn icon color="white" v-if="isMuted" @click="changeMute">
                <v-icon>fas fa-microphone</v-icon>
              </v-btn>
            </v-col>
            <v-col>
              <v-btn icon  color="red" @click="hangup">
                <v-icon>phone</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-chip>
    </div>
    <v-dialog persistent v-model="ringDialog" max-width="fit-content">
      <v-card>
        <v-card-title>
          {{ $i18n.t('income_call') }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-btn icon  color="red" @click="rejectCall">
                <v-icon style="transform: rotate(135deg)">phone</v-icon>
              </v-btn>
            </v-col>
            <v-col>
              <v-btn icon  color="green" @click="answerCall">
                <v-icon>phone</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';

  export default {
    name: "Phone",
    computed: {
      ...mapGetters({
        isCallInProgress: 'phone/isCallInProgress',
        isMuted: 'phone/isMuted',
        isRinging: 'phone/isRinging',
        callingUserId: 'phone/callingUserId',
      })
    },
    data() {
      return {
        callDuration: 0,
        intervalObject: null,
        ringDialog: false,
      }
    },
    watch: {
      isCallInProgress(val, oldVal) {
        if (val && !oldVal) {
          this.callDuration = 0;
          this.intervalObject = setInterval(() => this.callDuration += 1, 1000);
        } else {
          clearInterval(this.intervalObject);
        }
      },
      isRinging(val, oldVal) {
        if (val) {
          this.ringDialog = true;
        }
      }
    },
    methods: {
      ...mapActions('phone', [
        'hangup',
        'rejectCall',
        'answerCall',
      ]),
      async changeMute() {
        if (!this.isMuted) {
          await this.$store.dispatch('phone/mute');
        } else {
          await this.$store.dispatch('phone/unmute');
        }
      },
    }
  }
</script>

<style scoped lang="scss">
  .phoneWidget{
    position: fixed;
    top: 70px;
    left: calc(100vw - 155px);
    z-index: 600;
  }

</style>
