//playerAvatars.js;
import axios from "axios";
const state = {
  playerAvatars: [],
  selectedAvatar: null,
};

const mutations = {
  set(state, { type, items }) {
    state[type] = items;
  },
};

const getters = {
  getSelectedAvatar: (state) => state.selectedAvatar,
  getAvatars: (state) => state.playerAvatars,
};

const actions = {
  async getAvatars({ commit }) {
    await axios
      .get(`/main-api/avatars/ `, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          // console.log("response.data", response.data);
          const formattedData = response.data.map((el) => ({
            ...el,
            loadingItem: false,
          }));
          commit("set", { type: "playerAvatars", items: formattedData });
        }
      })
      .catch((error) => {
        console.log("error = ", error);
      });
  },
  async getAvatarById({ commit }, id) {
    let avatar = null;
    await axios
      .get(`/main-api/avatars/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          // console.log("response.data", response.data);
          avatar = response.data;
        }
      })
      .catch((error) => {
        console.log("error = ", error);
      });
    return avatar;
  },
  async createAvatar({ state }, data) {
    try {
      await axios.post(`/api/avatars/`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {
      console.error("error =", error);
    }
  },
  async editAvatar({ state }, { data, id }) {
    try {
      await axios.put(`/api/avatars/avatar/${id}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {
      console.error("error =", error);
    }
  },
  async editDefaultAvatar({ state }, id) {
    try {
      await axios.put(`/api/avatars/set_default/${id}`, null, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
    } catch (error) {
      console.error("error =", error);
    }
  },
  async deleteAvatar({ commit, dispatch }, id) {
    try {
      const token = localStorage.getItem("jwt");
      await axios.delete(`/api/avatars/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
