import VueI18n from "vue-i18n";
import Vue from "vue";

Vue.use(VueI18n, {});
export const i18n = new VueI18n({
  locale: window.localStorage.getItem("locale") || "en",
  fallbackLocale: "en",
  messages: {
    ru: {
      allow: "Разрешить",
      updated_geo: "Страна успешно обновлена",
      updated_script: "Скрипт успешно обновлен",
      deleted_script: "Скрипт успешно удален",
      deleted_geo: "Страна успешно удалена",
      save_geo: "Страна успешно сохранена",
      license: "Лицензия",
      country_code: "Код страны",
      where_on_page: "Где на странице",
      on_head: "В элементе head",
      on_body: "В элементе body",
      locate: "Расположение",
      author: "Автор",
      code: "Код",
      all_players: "Все игроки",
      custom_js: "Произвольный JS",
      all_countries: "Всем странам",
      is_wagerable: "Учитывать в вейджере",
      all_permissions: "Все права",
      custom_head: "Мета теги и скрипты",
      add_head: "Добавить скрипт",
      edit_head: "Редактировать скрипт",
      general: "Общие",
      separate: "Отдельные",
      bets_sum: "Сумма ставок",
      wagering_coefficient: "Коэффициент отыгрыша",
      geo_restrictions: "Ограничения гео",
      invalid_url: "Введен невалидный URL",
      invalid_url_detail: "URL должен содержать https:// или http://",
      counts: "Подсчеты",
      total_ggr_sum: "Общая сумма GGR",
      provider_comission: "Комиссия провайдера",
      payment_amount: "Сумма платежа",
      payment_amount_x: "Сумма платежа X",
      payment_amount_y: "Сумма платежа Y",
      total_commission: "Общая комиссия",
      total_commission_x: "Общая комиссия X",
      total_commission_y: "Общая комиссия Y",
      total_commission_percent: "Общий процент комиссионных",
      users_count: "Количество пользователей",
      affiliate_payouts: "Партнерские выплаты",
      platform_commission: "Комиссия платформы",
      total_ngr: "Общий NGR",
      deposit_total_commission_percent:
        "Общий процент комиссии при пополнении счета",
      withdraw_total_commission_percent: "Общий процент комиссии при снятии",
      positive_sum: "Положительная сумма",
      negative_sum: "Отрицательная сумма",
      withdraw_payment_commission_sum: "Сумма комиссии за снятие денег",
      deposit_payment_commission_sum: "Сумма комиссии за депозит",
      withdraw_payment_amount_sum: "Сумма платежа при снятии",
      deposit_payment_amount_sum: "Сумма платежа по депозиту",
      refresh: "Обновить",
      user_ava_count: "Количество игроков с аватаркой",
      game: "Игра",
      game_settings: "Настройки игр",
      device: "Устройство",
      started_ava: "Стартовая аватарка пользователя",
      multiacc_setup: "Настройки мультиаккаунтов",
      total_games: "Количество игр",
      cat: "Просто кот",
      just_error: "Произошла непредвиденная ошибка",
      roles_settings: "Настройка ролей",
      role_updated: "Роль успешно обновлена",
      role_created: "Роль успешно создана",
      role_deleted: "Роль успешно удалена",
      role_edit: "Редактирование роли",
      user_edit: "Редактирование пользователя",
      role_delete: "Удаление роли",
      role_delete_confirm: "Подтвердите удаление данной роли",
      promo_name_exist: "Указаное имя промокода уже существует",
      game_studios: "Игровые студии",
      game_blocks: "Игровые блоки",
      games: "Игры",
      require_time_and_date: "Укажите дату и время",
      require_bonus: "Выберите один из бонусов",
      games_managment: "Управление играми",
      games_manager: "Менеджер игр",
      game_provider_settings: "Настройки игрового провайдера",
      pc_resolution:
        "Устройство с разрешением экрана больше 1024px (включительно)",
      tablet_resolution:
        "Устройство с разрешением экрана меньше 1024px и больше 481px (включительно)",
      mobile_resolution: "Устройство с разрешением экрана меньше 481px",
      game_fee_settings: "Настройки игровых комиссий",
      game_fee_error:
        "К сожалению, на данный момент настройки оплат за игры недоступны",
      game_setting_error:
        "К сожалению, на данный момент игровые настройки недоступны",
      cashbox_error:
        "К сожалению, на данный момент страница денежных потоков недоступна",
      aggrregator_name: "Аггрегатор",
      aggrregators: "Аггрегаторы",
      no_game_to_add:
        "Не осталось игр, которые можно было бы добавить в этот блок",
      report_period: "Период отчета",
      promo: "Промокоды",
      promo_success_created: "Промокод успешно создан",
      bonus_player_deleted: "Бонус у игрока был успешно удален",
      bonus_player_cant_deleted: "Невозможно удалить активный бонус у игрока",
      promo_success_updated: "Промокод успешно обновлен",
      none: "None",
      method_success_updated: "Метод успешно обновлен",
      supported_providers: "Поддерживаемые провайдеры",
      search_game: "Поиск игр",
      logo: "Логотип",
      bonus_not_active_yet: "Бонус еще не был активирован",
      game_name: "Название игры",
      logo_select: "Выберите новый логотип (jpeg, png, svg. Максимум 15MB)",
      game_logo_select:
        "Выберите новый логотип для игры (jpeg, png, svg. Максимум 15MB)",

      drag_logo: "Или перетащите файлы сюда для загрузки",
      game_logo: "Лого игры",
      game_producer: "Продюсер игры",
      game_logo_exceed: "Превышен максимальный размер файлов: 15 МБ",
      game_type: "Тип игры",
      game_category: "Категория игры",
      game_category_empty: "Список категорий пуст",
      game_blocks_empty: "Список игровых блоков пуст",
      game_sub: "Раздел игры",
      provider: "Провайдер",
      game_edit_success: "Игра была успешно обновлена",
      game_edit_error: "При обновлении игры произошла ошибка",
      provider_edit_success: "Провайдер был успешно обновлен",
      provider_edit_error: "При обновлении провайдера произошла ошибка",
      provider_not_found: "Провайдеры не найдены",
      gameBlocks_not_found: "Игровые блоки не найдены",
      no_data_submited: "Нет загруженных данных",
      no_multi: "Нет мультиаккаунтов",
      find: "Найти",
      create_new_ava: "Создать новую аватарку",
      reg_ip: "Ip регистрации",
      edit: "Редактировать",
      similarity: "Сходство",
      language: "Язык",
      languages: "Языки",
      lang_created: "Локализация успешно создана",
      lang_updated: "Локализация успешно обновлена",
      lang_deleted: "Локализация успешно удалена",
      json_with_translation: "JSON с переводом",
      select_provider: "Выберите провайдера, чтобы увидеть список игр",
      payment_min_limit: "Мин. сумма",
      payment_max_limit: "Макс. сумма",
      json_label: "Здесь будет JSON выбранной локализации",
      platform: "Платформа",
      timezone: "Часовой пояс",
      user_agent: "Браузер",
      clear: "Очистить",
      wins: "Выигрыши",
      losses: "Проигрыши",
      active: "Активен",
      not_active: "Неактивен",
      admin_auth: "Авторизации в админке",
      player_auth: "Авторизации игроков",
      search: "Поиск",
      affs_search: "Поиск аффилиейтов",
      login: "Логин",
      password: "Пароль",
      date: "Дата",
      role: "Роль",
      new_p: "Новых",
      level: "Уровень",
      image: "Изображение",
      name: "Название",
      to_edit: "Редактировать",
      banned: "Заблокированы",
      player_details: "Детали о игроке",
      banners: "Баннеры",
      banner_success_created: "Баннер успешно создан",
      banner_success_updated: "Баннер успешно обновлен",
      add_new_lang: "Добавить новую локализацию",
      add_new_local: "Добавить еще",
      create_new_banner: "Создать новый баннер",
      create_new_level: "Создать новый уровень",
      edit_level: "Редактирование уровня",
      level_settings: "Настройка ролей",
      level_updated: "Уровень успешно обновлен",
      level_created: "Уровень успешно создан",
      level_deleted: "Уровень успешно удален",
      user_data: "Данные пользователя",
      level_edit: "Редактирование уровня",
      user_delete: "Удаление пользователя",
      user_delete_confirm: "Подтвердите удаление выбранного пользователя",
      level_delete: "Удаление уровня",
      level_delete_confirm: "Подтвердите удаление данного уровня",
      create_promo: "Создать промокод",
      create_new_promocode: "Создание промокода",
      promo_edit: "Редактирование промокода",
      add_user: "Добавить пользователя",
      add_role: "Добавить роль",
      cancel_new_banner: "Отменить создание",
      save_new_banner: "Сохранить новый баннер",
      new_players: "Новые игроки",
      new: "Новый",
      searchCom: "Поиск комментариев",
      addNewCom: "Добавить новый комментарий",
      textCom: "Текст комментария",
      addCom: "Добавить комментарий",
      isCom: "Комментарий",
      addBy: "Добавил",
      itWas: "Сотрудник",
      dataCreate: "Дата создания",
      auth_empty: "Авторизации админов отсутствуют",
      game_provider_empty: "Игровые провайдеры отсутствуют",
      auth_player_empty: "Авторизации игроков отсутствуют",
      logs_player_empty: "Логи игроков отсутствуют",
      loading: "загрузка",
      aff_info: "Подробности аффилейта",
      aff_not_found: "Не удалось получить данные об аффилейте",
      pay_acc: "Аккаунт выплат",
      payment_type: "Тип платежа",
      min_reg_date: "Мин. дата рег",
      max_reg_date: "Макс. дата рег",
      reg_date: "Дата регистрации",
      reg_date_short: "Дата регистрации",
      balance: "Баланс",
      total_balance: "Общий баланс",
      cashout_balance: "Доступно для вывода",
      bonus_balance: "Остаток бонуса",
      gender: "Пол",
      traffic_source: "Источник траффика",
      statistics: "Статистика",
      offers: "Офферы",
      requests: "Запросы",
      affiliates: "Аффилейты",
      found: "найдено",
      number: "Кол-во мультиаккаунтов",
      num_of_multi: "Кол-во. мульт-акков",
      bonuses: "Бонусы",
      added_bonus_success: "Бонус начислен",
      bonus_created: "Бонус успешно создан",
      bonus_updated: "Бонус успешно обновлен",
      bonus_deleted: "Бонус успешно удален",
      reports: "Отчеты",
      success_report: "Отчет успешно сгенерирован",
      general_report: "Общий отчет",
      agents_rtp_report: "Отчет по RTP",
      users_reports: "Отчет пользователей",
      generate_report: "Сгенерировать отчет",
      player_engagement: "Вовлечение игроков",
      added_bonus_error: "Произошла ошибка. Бонус не был начислен",
      user_have_bonus_error:
        "Данный бонус уже привязан к игроку. Пожалуйста, выберите другой",
      basic_bonus_create: "Создать базовый бонус",
      basic_bonuses: "Базовые бонусы",
      basic_bonus: "Базовый бонус",
      dep_bonus: "Бонус на депозит",
      dep_bonus_create: "Создать бонус на депозит",
      select_deposit_bonus: "Выберите бонус на депозит",
      select_freespins_bonus: "Выберите бонус на фриспины",
      free_rounds: "Фриспины",
      undefined: "Неизвестный",
      welcome: "Приветственный бонусный набор",
      next_deposit: "Следующий депозит",
      bonus_info: "Параметры бонуса",
      added_at: "Дата добавления",
      active_date: "Дата активации",
      order: "Номер",
      wager_progress: "Прогресс по отмытию",
      all_multi_get_ban: "Все мультиаккаунты забанены",
      no_deposit: "Без депозита",
      no_bonuses: "Бонусы не найдены",
      dep_bonuses: "Бонусы на депозит",
      bonuses_list: "Список бонусов игрока",
      first: "Первый",
      spin_bonus_create: "Создать спин бонус",
      spin_bonuses: "Спин бонусы",
      spins_bonus: "Спин бонус",
      bonus_delete: "Удаление бонуса",
      bonus_edit: "Редактирование бонуса",
      edit_freespins: "Редактирование фриспина",
      deleting_bonus: "Вы уверены что хотите удалить бонус?",
      delete: "Удалить",
      basic_bonus_creation: "Создание базового бонуса",
      max_sum_limit: "Макс. сумм вывода должна быть больше 0",
      max_sum_withdraw: "Макс. сумм вывода",
      wager_sum_limit: "Wager должен быть больше 0",
      draggble_hint: "Перетащите элементы для изменения их порядка",
      save: "Сохранить",
      discard: "Отказаться",
      discard_layout: "Изменения расположения отменены",
      min_date: "Мин. дата",
      max_date: "Макс. дата",
      alert_add_role:
        "Управление ролями и разрешениями не включено в вашей учетной записи. <b>Свяжитесь с нами в чате,</b> чтобы получить возможность управлять ролями и разрешениями.",
      save_layout: "Сохранить расположение",
      dep_bonus_creation: "Создание бонуса на депозит",
      choose_bonus: "Выберите бонус",
      choose_game: "Выберите игру",
      choose_deposit_bonus: "Выберите бонус на депозит",
      choose_freespins_bonus: "Выберите бонус на фриспины",
      choose_basic_bonus: "Выберите базовый бонус",
      max_win: "Макс. выигрыш",
      any: "Любой кроме первого",
      gameNotFound: "Игры не найдены",
      game_studios_not_found: "Игровые студии не найдены",
      game_studio_settings: "Настройки игровых студий",
      dep_type: "Тип депозита",
      choose_dep_type: "Выберите тип депозита",
      min_dep_limit: "Мин. депозит должен быть больше 0",
      time_period: "Временной промежуток",
      select_period: "Выберите период",
      day: "День",
      month: "Месяц",
      three_month: "Три месяца",
      half_year: "Полгода",
      custom: "Кастомный",
      year: "Год",
      week: "Неделя",
      color_gamma: "Внесите код с переменными цветов",
      add_admin: "Добавление администратора",
      edit_admin: "Редактирование администратора",
      min_max_greater:
        "Макс. значение депозита должно быть больше или равно мин. значению",
      min_dep: "Мин. депозит",
      max_dep: "Макс. депозит",
      theme_logo: "Темы",
      logo_descr: "Установите логотип, который будет веднеться на всем сайте",
      expire_in_days: "Истекает через (в днях)",
      expiration_date: "Дата истечения",
      bonus_type: "Тип бонуса",
      add_role_user: "Добавление пользователя",
      edit_role_user: "Редактирование пользователя",
      user_roles: "Роли пользователей",
      manage_users: "Управление пользователями",
      manage_admins: "Пользователи админ. панели",
      select_all: "Выбрать все",
      blocked: "Заблокирован",
      select_all_currencies: "Выбрать все валюты",
      all_currencies: "Все валюты",
      redirect_url: "Перенаправляющая ссылка",
      blocked_country: "Блокирование страны",
      access_control: "Контроль доступа",
      deposit_source: "Источник депозита",
      vendor_type: "Тип вендора",
      min_bonus_limit: "Бонус должен быть больше 0",
      min_bonus_time: "Количество дней должно быть больше 0",
      bonus_percent: "Бонусный процент",
      max_lim_description: "Макс. длина описания 100 символов",
      description: "Описание",
      spin: "Спин",
      promo_deleted: "Промокод удален",
      banner_deleted: "Баннер удален",
      spin_bonus_creation: "Создание спин бонуса",
      min_lim_price: "Цена должна быть больше 0",
      price: "Цена",
      min_lim_bet: "Ставка должна быть больше 0",
      bet: "Ставка",
      edit_game: "Редактировать игру",
      edit_provider: "Редактировать провайдера",
      all_provider_games: "Общее количество игр",
      active_provider_games: "Активных",
      not_active_provider_games: "Неактивных",
      bet_amount: "Сумма ставки",
      alert: "Внимание",
      toConfrim: "Подтверждение",
      other_settings: "Другие настройки",
      confirm_layout: "Сохранить текущее расположение игр?",
      edit_spins_bonus: "Редактирование Фриспина",
      edit_deposit_bonus: "Редактирование депозит бонуса",
      min_bet: "Мин. Ставка",
      max_bet: "Макс. Ставка",
      lines_greater_than_zero: "Кол-во линий должно быть больше 0",
      bet_greater_than_zero: "Ставка должна быть больше 0",
      max_win_than_zero: "Макс. выйгрышь должен быть больше 0",
      expire_in_days_than_zero: "Укажите количество дней больше 0",
      lines: "Кол-во линий",
      spins_greater_than_zero: "Кол-во спинов должно быть больше 0",
      spins: "Кол-во спинов",
      multiplier_greater_than_zero: "Множитель должен быть больше 0",
      multiplier: "Множитель",
      cash_flow: "Движение денег",
      players: "Игроки",
      players_ava: "Аватарки",
      create_players_ava: "Создать аватарку",
      edit_players_ava: "Редактировать аватарку",
      players_list: "Список игроков",
      players_authorization_logs: "Логи игроков",
      admin_authorization_logs: "Логи  админов",
      online: "Онлайн",
      manual: "Мануальный",
      auto: "Авто",
      show_all_games: "Показать все игры",
      show_detailed_info: "Показать подробную информацию",
      autopayout: "Автовыплата",
      each_ava: "Каждая аватарка позволяет подчеркнуть индивидуальность игрока",
      today: "Сегодня",
      total: "Всего",
      all: "Все",
      casino_revenue: "Разница депозитов и выводов",
      choose_period: "Выберите период",
      deposits: "Депозиты",
      with_deposits: "С депозитами",
      fee: "Комиссия",
      commission: "Комиссия",
      commission_fee: "Комиссия",
      pending_verification: "Требует верификации",
      withdraws: "Выводы",
      yesterday: "Вчера",
      game_block_hint:
        "Игровые блоки - неотъемлемая часть нашей платформы. Они содержат определенные игры, которые можно менять местами, добавлять или удалять из игрового блока. Это также относится к конкретным блокам, все они поддаются CRUD-изменениям",
      ava: "Аватарка",
      ip_detection: "Обнаружение IP",
      game_block_error:
        "К сожалению, на данный момент игровые блоки недоступны",
      game_provider_hint:
        "Игры по провайдерам - место, где вы можете контролировать порядок игр и провайдеров, а также иметь настройки, позволяющие менять логотип, комиссию, отключать элементы и многое другое",
      multi_bonus_reward:
        "Бонусное вознаграждение и активации промокодов при наличии мультиаккаунта",
      multi_bonus_reward_hint:
        "Если OFF - после регистрации пользователь получит сообщение «извините, у вас есть другие аккаунты в нашей системе, и вы не можете претендовать на бонусы». Все бонусы будут отключены для этого пользователя. То же самое с ПРОМО-КОДАМИ - НИ ОДИН ПРОМО-КОД не может быть активирован. Если пользователь попытается применить ПРОМО-КОД - он получит такое же сообщение, как и описанное ранее.",
      multi_auto_block: "Автоматическая блокировка мультиаккаунтов",
      multi_auto_block_hint:
        "Если ON - статус всех обнаруженных Мультиаккаунтов будет автоматически установлен на «BLOCKED». И основной сайт получит флаг для отображения сообщения",
      multi_rule_hint:
        "Вкладка, позволяющая установить правила, по которым CORE будет работать с профилями игроков, обнаруженных как мультиаккаунты",
      id_dt_hint:
        "Если эта опция установлена в ON - игроки с одинаковым публичным IP адресом будут определяться как Мультиаккаунт",
      last_30_days: "Последние 30 дней",
      fingerprint_detection_hint:
        "Таблица со всеми доступными параметрами отпечатков пальцев. Вы можете отметить любой параметр, который я хочу чтобы был обнаружен. Любой аккаунт игрока с похожим хэшем Fingerprint будет обнаружен как мультиаккаунт.",
      fingerprint_detection_setting: "Fingerprint настройки обнаружения",
      multiacc_rule_logic: "Логика правил мультиаккаунтов",
      ava_descr:
        "Добавьте два изображение, одно должно иметь хорошее качество (1), а второе быть максимально сжато (2). <br> <b>Важно!</b> При добавлении только одного изображения, оно будет продублировано в обе ячейки",
      last_month: "Последний месяц",
      more: "Еще",
      second_last_month: "Предпоследний месяц",
      registrations: "Регистраций",
      firstDeposits: "Первые депозиты",
      applyLabel: "Применить",
      cancelLabel: "Отменить",
      weekLabel: "Нед.",
      customRangeLabel: "Задать период",
      last_7_days: "Последние 7 дн.",
      this_month: "Этот месяц",
      previous_month: "Предыдущий месяц",
      first_deposits: "Первые депозиты",
      deposit_count: "Количество депозитов",
      quantity: "Количество",
      max_uses: "Количество использований",
      deposit_sum: "Сумма депозитов",
      withdraw_sum: "Сумма выводов",
      payment_fee: "Комиссия платежей",
      country: "Страна",
      countries: "Стран",
      region: "Регион",
      address: "Адрес",
      city: "Город",
      saved_layout_success: "Новый порядок успешно применен",
      saved_layout_error: "Произошла ошибка при установке нового порядка",
      unsaved_layout:
        "У вас не сохранены изменения в порядке вывода игр или провайдеров",
      yes: "Да",
      no: "Нет",
      ggr: "GGR",
      ngr: "NGR",
      revenue: "Доход",
      mon: "Пн",
      tue: "Вт",
      wed: "Ср",
      thu: "Чт",
      fri: "Пт",
      sat: "Сб",
      sun: "Вс",
      jan: "Янв",
      feb: "Фев",
      mar: "Мар",
      apr: "Апр",
      may: "Май",
      jun: "Июн",
      jul: "Июл",
      aug: "Авг",
      sep: "Сен",
      oct: "Окт",
      nov: "Ноя",
      dec: "Дек",
      sign_up: "Вход",
      sign_in: "Войти",
      authorization: "Авторизация",
      sign_out: "Выйти",
      success_increase: "Баланс успешно пополнен",
      success_balance_changed: "Баланс успешно изменен",
      payment_count: "Количество платежей",
      payment_sum: "Сумма платежа",
      invalid_value: "Неправильное значение",
      error_message: "Неверный логин / пароль",
      no_mobile: "Мобильная версия сайта не поддерживается",
      no_data: "Нет данных",
      pay_methods: "Платежные методы",
      open_affiliate_admin: "Открыть админ панель партнерки",
      compensation: "Компенсация",
      pay_method: "Платежный метод",
      amount: "Сумма",
      max_withdraw_amount: "Макс. размер вывода",
      wager: "Wager",
      actions: "Действия",
      deposit_number: "Номер депозита",
      deposit: "Депозит",
      min_deposit: "Мин. депозит",
      spin_count: "Кол-во спинов",
      redirector_addr: "Адрес редиректора",
      redirector: "Редиректора",
      redirect: "Ссылка для перехода",
      deletedMirrors: "Удаленные зеркала",
      activeMirrors: "Активнные зеркала",
      addLink: "Добавить ссылку",
      add: "Добавить",
      added: "Добавлен",
      deleteLink: "Удалить ссылку",
      tel: "Телефон",
      tel_number: "Номер",
      changeBaseUrl: "Изменение адреса редиректора",
      enterNewRedirectorLink: "Введите новую ссылку редиректора",
      support: "Поддержка",
      message: "Сообщение",
      response: "С ответа",
      phone: "Номер",
      responsible: "Ответственный",
      user: "Пользователь",
      status: "Статус",
      select: "Выбрать",
      request: "При обработке запроса",
      error_occured: "возникла ошибка",
      active_call: "Активный звонок",
      income_call: " Входящий звонок",
      session_spins: "Спины в сессии",
      session_spins_load: "Загрузка спинов сессии",
      financial_transactions: "Финансовые транзакции",
      transaction: "Транзакция",
      average: "Среднее",
      finances: "Финансы",
      top_games: "Топ 10 игр",
      top_players: "Топ 10 игроков",
      top_provider: "Топ 10 провайдеров",
      sum: "Cумма",
      value: "Значение",
      type_transaction: "Тип транзакции",
      method: "Метод",
      payments_empty: "Платежи отсутствуют",
      replenishment: "Пополнение",
      withdraw: "Вывод",
      game_session_id: "Id Сессии",
      transaction_details: "Детали транзакции",
      transaction_id: "ID Транзакции",
      time: "Время",
      types: "Типы",
      ga: "игр",
      player: "Игрок",
      account: "Аккаунт",
      type: "Тип",
      date_from: "Дата от",
      date_to: "Дата до",
      date_n_time: "Дата и время",
      accept: "Принять",
      not_confirmed: "Не подтвержден",
      confirmed: "Подтвержден",
      approve: "Подтвердить",
      decline: "Отменить",
      block_order: "Порядок блока",
      check: "Проверка",
      completed: "Выполнен",
      canceled: "Отменен",
      game_id: "Игровой id",
      processed: "Обработанный",
      processing: "В обработке",
      processing_with_fee: "В обработке с fee",
      completed_with_fee: "Выполнено с fee",
      player_info: "Подробная информация об игроке",
      player_id: "ID игрока",
      general_info: "Общая информация",
      username: "Ник",
      registration_date: "Дата регистрации",
      registered: "Зарегестрирован",
      affiliate: "Аффилейт",
      phone2: "Телефон",
      additional_info: "Доп. информация",
      name: "Имя",
      surname: "Фамилия",
      levels_not_found: "Уровни не найдены",
      reward: "Награда",
      no_reward: "Нет наград",
      amount_to_the_next_level: "Сумма до следующего уровня",
      player_status: "Уровень игрока",
      player_levels: "Уровни игрока",
      min_player_status: "Mин. уровень игрока",
      max_player_status: "Mакс. уровень игрока",
      birth_date: "Дата рождения",
      email: "E-mail",
      local: "Локализация",
      local_legal: "Юридическая",
      local_general: "Общая",
      bar_graph_descr: "График изменений количества новых игроков",
      line_graph_descr: "Динамика изменений категорий игроков",
      pie_graph_descr: "Диаграмма категорий",
      title: "Название",
      localTitle: "Редактор переводов",
      payment_info: "Платежная информация",
      wager_playthrough: "Отыгрыш вейджера",
      withdrawals: "Выводы",
      block_withdraw: "Заблокировать вывод",
      unblock_withdraw: "Разблокировать вывод",
      verification: "Верификация",
      no_any_photos: "Нет загруженных фотографий",
      face_card_photo: "Фото лица с картой",
      face_passport_photo: "Фото лица с паспортом",
      passport: "Паспорт",
      verified: "Подтверджено",
      rejected: "Отклонено",
      account_management: "Управление аккаунтом",
      account_status: "Статус аккаунта",
      verif_status: "Статус верификации",
      not_defined: "не определен",
      unblock: "Разблокировать",
      block_forever: "Заблокировать навсегда",
      blocked_until: "Заблокирован до",
      temporary_block: "Блокировка на время",
      select_time: "Выберите время",
      block: "Заблокировать",
      operations: "Операции",
      change_balance: "Изменить баланс",
      sessions: "Сессии",
      comments: "Комменты",
      comments_not_found: "Комментарии удовлетворяющие запросу не найдены",
      addFilters: "Добавить 3 фильтра",
      removeFilters: "Удалить 3 фильтра",
      authorizations: "Авторизации",
      logs: "Логи",
      balance_corrections: "Корректировка баланса",
      key: "Ключ",
      multi_accounts: "Мульти-акк",
      failToGetData: "Не удалось получить данные",
      reject: "Отклонить",
      enterAmount: "Введите сумму",
      debit: "Уменьшить баланс",
      credit: "Увеличить баланс",
      addBonus: "Добавить бонус",
      selectBonus: "Выберите бонус",
      temporary_blocked: "Заблокирован на время",
      temp_blocked: "Врем. блок",
      forever_blocked: "Заблокирован навсегда",
      withdraw_blocked: "Вывод заблокирован",
      withd_blocked: "Блок. вывод",
      withdraw_unblocked: "Вывод разблокирован",
      withdraw_block_failed: "Не удалось заблокировать вывод",
      invalid_withdrawal_value:
        "Недопустимое значение списания, максимальное значение {maxValue}",
      phone_only: "Только телефон",
      balance_from: "Баланс от",
      balance_to: "Баланс до",
      amount_from: "Сумма от",
      amount_to: "Сумма до",
      bet_amount_from: "Сумма ставки от",
      bet_amount_to: "Сумма ставки до",
      withdrawals_to: "Выводы от",
      withdrawals_from: "Выводы до",
      from: "От",
      to: "До",
      result: "Результат",
      players_not_found: "Игроки не найдены",
      roles_not_found: "Роли не найдены",
      promo_not_found: "Промокоды не найдены",
      total_players: "Всего игроков",

      call: "Звонок",
      isCallInProgress: "Звонок в процессе",
      signDialog: "Диалог авторизации",
      in: "из",
      min: "Мин",
      max: "Макс",
      paymethods: "Платежные методы",
      multi_accounts_found: "Кол-во найденных мультиаккаунтов",
      linked_account: "Связанный аккаунт",
      fingerprint_hash: "Хэш отпечатка",
      gameBlock_changed: "Игры в блоке успешно редактированы",
      gameBlock_added: "Игра успешно добавлена в блок",
      gameBlock_game_deleted: "Игра успешно удалена из блока",
      gameBlock_deleted: "Игровой блок успешно удален",
      gameBlock_is_changed: "Игровой блок успешно изменен",
      required_field: "Обязательное поле",
      comment_change_balance: "Причина изменения баланса (обязательно)",
      on: "Вкл",
      off: "Выкл",

      parameter: "Параметр",
      all_fingerprint_parameters: "Все параметры отпечатка",
      fingerprint_not_available: "Отпечаток отсутствует",
      player_logs_not_found: "Логи игрока отсутствуют",
      player_balance_corrections_not_found:
        "Исправления баланса игрока не найдены",
      admin: "Админ",
      requests_not_available: "Запросы отсутствуют",
      confirmation_affiliate: "Подтверждение аффилейта",
      select_offers: "Выберите офферы:",
      manager: "Менеджер",
      confirm: "Подтвердить",
      add_new: "Добавить новый",
      offers_not_available: "Офферы отсутствуют",
      add_offer: "Добавить оффер",
      revenue_share: "Доля Выручки",
      cost_per_acquisition: "Стоимость Привлечения",
      tiered_revenue_share: "Доля Выручки по Тарифам",
      rate: "Ставка",
      product: "Продукт",
      search_by: "Искать по",
      option1: "option1",
      option0: "option0",
      currency: "Валюта",
      currencies: "Валюты",
      available_currencies: "Доступные валюты",
      activity_period: "Период активности",
      session_start: "Старт сессии",
      session_search: "Поиск сессий",
      gameBlock_no_game: "У выбранного блока еще нет игр",
      select_block_see_provider:
        "Выберите блок, чтобы увидеть список игровых студий",
      select_block_see_games: "Выберите блок, чтобы увидеть список его игр",
      win: "Выигрыш",
      reset: "Сброс",
      loss: "Проигрыш",
      tags: "Теги",
      choose_offer: "Выберите оффер",
      affs_not_found: "Аффилейты не найдены",
      pages: "Строк на странице",
      trans_not_found: "Транзакции не найдены",
      sessions_not_found: "Игровые сессии не найдены",
      sessions_loading: "Игровые сессии загружаются",
      spins_not_found: "Спины отсутствуют",
      detailed_info: "Детальная информация",
      add_bonus: "Добавить бонусы",
      block_all: "Заблокировать все",
      permissions: "Разрешения",
      by_providers: "По провайдерам",
      by_blocks: "По блокам",
      add_new_block: "Добавить новый блок",
      return_block_games: "Вернуться к играм блока",
      add_game: "Добавить игру",
      gameBlock_success_created: "Игровой блок успешно создан",
      return_to_list: "Вернуться к общему списку",
      add_more_attribute: "Добавить атрибут",
      attribute: "Атрибут",
      content: "Контент",
      for_example: "Например",
      choose_one_of_option: "Пожалуйста выберите тип кода",
      new_script_added: "Новый скрипт успешно добавлен",
      new_script_updated: "Новый скрипт успешно обновлен",
      dashboard: "Главная",
      format: "Формат данных",
      select_stat_for_graph:
        "Выберите нужную статистику для отображения подробной информации",
      slots: "Слоты",
      roulette: "Рулетки",
      bakkara: "Bakkara",
      crash: "Краш",
      fishing: "Fishing",
      set_new_min_bet: "Мин. макс. ставки для категории",
      first_deposits_sum: "Сумма первых депозитов",
      issued_bonuses_sum: "Сумма выданных бонусов",
      positive_correction_sum: "Сумма положительной коррекции",
      negative_correction_sum: "Сумма отрицательной коррекции",
      affiliate_payouts_sum: "Сумма партнерских выплат",
      platform_fee_sum: "Сумма комиссии платформы",
      new_registrations_count: "Количество новых регистраций",
      first_deposits_count: "Количество первых депозитов",
      total_deposit_sum: "Общая сумма депозитов",
      total_deposit_fee_sum: "Общая сумма комиссии за депозит",
      total_deposit_fee_percent: "Общая сумма комиссии за депозит в процентах",
      total_withdraw_sum: "Общая сумма снятия",
      total_withdraw_fee_sum: "Общая сумма комиссии за снятие",
      total_withdraw_fee_percent: "Общий процент комиссии за снятие",
      totals: "Всего",
      total_providers_fee_sum: "Общая сумма комиссии провайдеров",
      total_providers_fee_percent: "Общий процент комиссии провайдеров",
      total_ngr_sum: "Общая NGR сумма",
      total_users_count: "Общее количество зарегистрировавшихся",
      total_users_by_traffic_sources_count:
        "Общее количество пользователей по источникам трафика",
      total_ftd_count: "Общее количество FTD",
      total_ftd_by_traffic_sources_count:
        "Общее количество FTD по источникам трафика",
      total_users_balance_sum: "Общая сумма баланса пользователей",
      total_deposits_sum: "Общая сумма депозитов",
      total_withdraws_sum: "Общая сумма снятий",
      total_manual_corrections_sum: "Общая сумма ручных исправлений",
      users: "Пользователи",
      payments: "Платежи",
      total_users_online_count: "Общее количество пользователей онлайн",
      need_select_dates:
        "Выберите дату или смените интервал чтобы продолжить фильтрацию",
      reg_by_country: "Регистрации по странам",
      provider_name: "Имя провайдера",
      by_date: "Количество новых регистраций и FTD",
      total_by_country: "По стране",
      total_by_date: "По дате",
      map: "Карта",
      total_users_by_currency_count:
        "Общее количество пользователей по выбранной валюте",
      total_users_by_currency_and_date_count:
        "Общее количество пользователей по валюте и дате",
      total_users_bonus_balance_sum:
        "Сумма бонусного баланса всех пользователей",
      stop_product: "Остановить продукт",
      resume_product: "Остановить продукт",
      disabled: "Отключен",
      attention_disabled_text:
        "Продукт будет остановлен и отобразится страница технического обслуживания. Пока вы не нажмете эту кнопку снова.",
      attention_enabled_text:
        "Продукт будет запущен, и страница тех. обслуживания не будет отображаться",
      attention_title: "Внимание!",
      attention_question: "Вы уверены?",
      product_enabled: "Продукт успешно запущен",
      product_disabled: "Продукт успешно остановлен",
      categories: "Категории",
      categories_hint: "Игровые картегории",
      add_new_player: "Добавить нового игрока",
      player_added: "Новый игрок был успешно добавлен",
      save_close: "Сохранить и закрыть",
      add_more: "Добавить еще одного игрока",
      info_new_player: "Информация о добавленном игроке",
      min_withdrawal: "Мин. выводы",
      max_withdrawal: "Макс. выводы",
      username_error:
        "Никнейм игрока должен содержать от 3 до 12 символов и написан только латинскими буквами",
      online_hint:
        "Параметр показывающий игроков находившихся в сети за последние 10 минут",
      admin_data: "Информация об администраторе",
      admin_edited: "Администратор успешно обновлен",
      admin_deleted: "Администратор успешно удален",
      lang_order: "Порядок локализаций был успешно обновлен",
      no_game_by_block_add:
        "В данном блоке не найдено игр, чтобы добавить игру, нажмите на кнопку 'Добавить игру'",
      games_report: "Отчет по играм",
      select_at_least_one: "Выберите хотя бы одно изображение",
      add_photo_to_save: "Добавьте хотя бы одно изображение для сохранения",
      ava_edited: "Аватар успешно обновлен",
      ava_created: "Аватар успешно создан",
      ava_deleted: "Аватар успешно удален",
      default_ava_changed: "Аватар по умолчанию изменен",
      avatar_will_deleted:
        "Аватар будет навсегда удален после вашего подтверждения",
      color_will_deleted:
        "После удаления не забудьте нажать на кнопку 'Сохранить' чтобы применить изменения",
      upload_error: "Произошла ошибка при загрузке изображения",
      upload_less_image: "Вес изображения не должен превышать 1 МБ",
      no_permission: "У вас недостаточно прав на выполнение данной операции",
      user_not_found: "Выбранный пользователь не найден",
      invalid_amount: "Некорректная сумма",
      themes_updated: "Новые стили были успешно обновлены",
      providers: "Провайдеры",
      aggregators: "Агрегаторы",
      add_color: "Добавить цвет",
      edit_color: "Редактировать цвет",
      name_color: "Название цвета",
      req_field_or_wrong:
        "Обязательное поля. Имя должно начинаться с двух дефисов (--) ",
      same_name: "Переменная с таким именем уже существует",
      last_visit: "Последний визит",
      referral_id: "ID реферала",
      clear_all_links: "Очистить все ссылки",
      click_button_to_save:
        'После добавления цветов нажми на кнопку "Сохранить" чтобы применить изменения',
      aggregator: "Агрегатор",
      finish_balance: "Финальный баланс",
      min_finish_balance: "Мин. финальный баланс",
      max_finish_balance: "Макс. финальный баланс",
      lose: "Проигрыш",
      start_date: "Мин. дата",
      end_date: "Макс. дата",
      old_password: "Старый пароль",
      new_password: "Новый пароль",
      wrong_password: "Указан неверный пароль",
      doc_was_approved: "Документ был успешно подтвержден",
      doc_was_rejected: "Документ был отклонен",
      draw: "Ничья",
      tooltip_add_bonus: "Добавить бонус пользователю",
      tooltip_block_withdraw: "Запретить пользователю вывод средств",
      tooltip_unblock_withdraw: "Разрешить пользователю вывод средств",
      no_permission: "У вас нет прав на выполнение данной операции",
      tooltip_change_balance: "Увеличение или уменьшении баланса игрока",
      tooltip_delete_bonus:
        "Удалить бонус пользователя (если не был использован)",
      tooltip_block_forever: "Вечная блокировка игрока",
      tooltip_unblock_forever: "Разблокировать игрока",
      tooltip_temp_block:
        "Временная блокировка игрока (Не забудь указать дату)",
      tooltip_accept_doc: "Принять документ",
      tooltip_reject_doc: "Отклонить документ",
      tooltip_multi_block: "Заблокировать все обнаруженные мультиаккаунты",
      tooltip_add_comment: "Добавить примечание для игрока",
      tooltip_refresh_games:
        'Обновление игр выбранного агрегатора. (Все новые игры попадают в секцию "New")',
      payment_gateway: "Платежные шлюз",
      refresh_games: "Обновить игры",
      games_refreshed: "Игры были успешно обновлены",
      tooltip_add_game_to_block: "Добавить игру в выбранный блок",
      no_date: "Нет даты",
      agents: "Агенты",
      agent_players_list: "Список игроков агента",
      agents_report: "Отчёт по агентам",
      agent_name: "Имя агента",
      agent_level: "Уровень агента",
      share: "Доля",
      agent_credit_balance: "Кредитный баланс агента",
      credit_players_balance: "Кредитный баланс игроков агента",
      bonus_credit_balance: "Кредитный баланс бонусов агента",
      jackpot_amount: "Сумма выигрыша",
      buy_credits: "Купить кредиты",
      add_new_agent: "Добавить нового агента",
      add_agent: "Добавить агента",
      parent_agent: "Родительский агент",
      new_agent: "Новый агент",
      create: "Создать",
      tooltip_parent_agent: "Без указания агента будет установлен уровень 0",
      value_more_than_zero: "Значение должно быть больше 0",
      max_twelve_characters: "Максимум 12 символов",
      agent_created: "Агент был успешно создан",
      agent: "Агент",
      create_agent: "Создать агента",
      copied_to_clipboard: "Успешно скопировано в буфер обмена",
      available_balance: "Доступный баланс",
      agent_no_have_balance: "У Агента не недостаточно средств",
      no_have_balance: "Не достаточно средств",
      withdraw_balance: "Вывод средств",
      deposit_balance: "Пополнение средств",
      success_depositL: "Пополнение средств прошло успешно",
      select_language: "Выберите язык",
      success_deposit: "Пополнение средств прошло успешно",
      success_withdraw: "Вывод средств прошел успешно",
      agent_admin: "Агент админ",
      no_parent: "Нет родительского агента",
      create_user: "Создать пользователя",
      not_valid_email: "Некорректная почта",
      new_user: "Новый пользователь",
      select_agent: "Выберите агента чтобы увидеть игроков",
      player_edit: "Редактировать игрока",
      agent_edit: "Редактировать агента",
      agent_updated: "Агент был успешно обновлен",
      player_updated: "Игрок был успешно обновлен",
      no_active_agent: "Данный агент был отключен",
      agent_id: "ID Агента",
      promocode: "Промокод",
      sub_type: "Саб Тип",
      jackpot_settings: "Настройки джекпотов",
      jackpot_will_deleted: "Джекпот будет удалён",
      jackpot_deleted: "Джекпот был успешно удалён",
      jackpot_updated: "Джекпот был успешно обновлен",
      create_jackpot: "Создать джекпот",
      user_id: "ID игрока",
      new_jackpot: "Новый джекпот",
      jackpot_progress: "Прогресс джекпота",
      start_win_amount: "Стартовая сумма",
      bets_progress: "Прогресс ставок",
      target_win_amount: "Финальная сумма выигрыша",
      avg_historical_rtp_percent: "Средний RTP (%)",
      sessions_count: "Кол-во сессий",
      total_win_sum: "Общая выигрышная сумма",
      total_bet_sum: "Общая сумма ставок",
      select_player_and_confirm: "Выбери игрока и подтверди",
      agents_financial_report: "Отчет по финансовой статистике",
      progress: "Прогресс",
      jackpot_handled: "Джекпот обработан",
      clear_all: "Очистить все",
      spins_bonuses: "Фриспин бонусы",
      edit_jackpot: "Редактирование джекпота",
      bet_percent: "Процент ставок",
      delete_agent: "Удалить агента",
      confirm_delete_agent:
        "Агент будет удален если нет связанных с ним логов иначе - деактивирован",
      agent_deleted: "Агент был успешно удалён",
      agent_deactivated: "Агент был успешно деактивирован",
      cant_delete_agent: "Невозможно удалить данного агента",
      only_one_jackpot: "Вы можете создать всего один джекпот",
      providers_fee_percent: "Процент комиссии провайдеров",
      deposits_sum: "Сумма депозитов",
      withdraws_sum: "Сумма выводов",
      ggr_sum: "Сумма GGR",
      providers_fee_sum: "Сумма комиссии провайдеров",
      ngr_sum: "Сумма NGR",
      deposits_count: "Кол-во депозитов",
      withdraws_count: "Кол-во выводов",
      total_deposits_count: "Общее кол-во депозитов",
      total_withdraws_count: "Общее кол-во выводов",
      player_unbaned: "Игрок успешно разблокирован",
      player_baned: "Игрок успешно заблокирован",
      permanent_ban: "Вечный бан",
      player_managed: "Управление игроком",
      players_from_agent: "Игроки агента",
      log_type: "Тип лога",
      agent_to_user: "Агент к пользователю",
      players_with_deposit: "Игроки с депозитом",
      players_without_deposit: "Игроки без депозита",
      players_have_multiaccs: "Игроки с мультиаккаунтами",
      players_pending_verification: "Игроки ожидающие верификацию",
      promo_data: "Данные о промокоде",
      created: "Создан",
      ongoing: "Запущен",
      jackpot_status: "Статус джекпота",
      allowed_currencies: "Разрешённые валюты",
      edit_aggregator: "Редактирование агрегатора",
      cant_delete_default_avatar: "Невозможно удалить аватарку по умолчанию",
      min_bet_currency: "Валюта для минимальной ставки",
      close: "Закрыть",
      transaction_data: "Данные транзакции",
      aggregator_edit_success: "Агрегатор был успешно обновлен",
      subagent: "Сабагент",
      deposit_to_users: "Депозит игрокам",
      withdraw_from_users: "Выводы игрокам",
      total_deposit_to_users: "Общая сумма депозитов игрокам",
      total_withdraw_from_users: "Общая сумма выводов игрокам",
      total_bet: "Общая сумма ставок",
      total_win: "Общая сумма выигрыша",
      total_ggr: "Общий GGR",
      you: "Вы",
      agent_already_exists: "Агент с таким логином уже существует",
      player_already_exists: "Игрок с таким логином уже существует",
      use_another_password: "Используйте другой пароль",
      agent_not_active: "Агент был отключен",
      tel_already_exists: "Данный номер телефона уже используется",
      email_already_exists: "Данный почтовый адрес уже используется",
      not_valid_password: "Минимум 8 символов",
      allowed_to_agents: "Разрешён агентам",
      subagents: "Сабагенты",
      country_hint: "Страна регистрации игрока",
      id_hint:
        "уникальный номер игрока, по которому можно увидеть всю его статистику",
      deposits_hint: "Сумма депозитов игрока",
      balance_hint: "Сумма средств на счету игрока",
      withdrawals_hint: "Сумма средств, которую игрок вывел со счета",
      registered_hint: "Дата регистрации игрока",
      account_status_hint: "Статус аккаунта игрока",
      player_status_hint: "Достигнутый уровень игрока",
      pending_verification_hint: "Подтверждение личности игрока",
      only_agent_players: "Только игроки агентов",
      multi_accounts_hint:
        "Возможность проследить регистрацию нескольких аккаунтов у игрока",
      target_win_amount_less:
        "<b>Стартовая</b> сумма джекпота должна быть меньше <b>финальной</b> суммы",
      authentications: "Аутентификации",
      admins: "Админы",
      title_descr: "Установка фавиконки и названия сайта",
      have_active_jackpot: "Вы не можете два активных джекпота одновременно",
      finished: "Завершен",
      created_tooltip_jackpot:
        "Данный джекпот был создан и в настоящее время неактивен",
      ongoing_tooltip_jackpot:
        "Активный джекпот. Ставки игроков каждый раз заполняют его банк",
      finished_tooltip_jackpot:
        "Завершенный джекпот. Первый игрок, который заполнил банк, успешно получил свой джекпот",
      favicon: "Фавикон",
      temp_block: "Временная блокировка",
      min_length_3: "Минимальная длина 3 символа",
      player_winner_id: "Игрок победитель (ID)",
      not_active_agents: "Неактивен для агентов",
      create_deposit_bonus: "Создать бонус на депозит",
      create_spins_bonus: "Создать бонус на фриспины",
      bonus_data: "Данные бонуса",
      min_less_than_max: "должен быть меньше или равен",
      apply: "Применить",
      platform: "Платформа",
      type_users: "Тип игроков",
      comment: "Комментарий",
      not_enough_balance: "Недостаточно средств",
      level_data: "Данные уровня",
      create_level: "Создание уровня",
      sum_to_level: "Сумма для получения уровня",
      level_already_exists: "Уровень с таким именем уже существует",
      deposit_bonus_no_currencies:
        "Бонус на депозит должен содержать все валюты",
      freespins_bonus_no_currencies:
        "Бонус на фриспины должен содержать все валюты",
      level_currency_hint:
        "Для избежания ошибок, уровень создается со всеми валютами",
      agent_reports: "Отчеты агентов",
      provider_fee_sum: "Коммиссия за провайдера",
      provider_fee_percent: "Коммиссия за провайдера в процентах",
      overall: "Общий",
      main_slider: "Главный слайдер",
      user_reg: "При регистрации",
      no_banners:
        "К сожалению банеры не найдены, но вы всегда можете создать новый",
      bets_progress_hint: "Текущий прогресс джекпота (сумма)",
      bet_percent_hint:
        "Процент который будет зачисляться на сумму джекпота от каждой ставки",
      error_progress_start: "Прогресс не может быть меньше стартовой суммы",
      error_progress_target: "Прогресс не может быть больше финальной суммы",
      active_jackpot_hint: "Активный джекпот",
      activated_jackpot_hint:
        "Нажмите чтобы активировать джекпот. Одновременно можно активировать только один джекпот",
      jackpot_disabled: "Джекпот отключен",
      jackpot_enabled: "Джекпот включен",
      new_sub_agent: "Новый сабагент",
      cant_unban_player:
        "Невозможно разблокировать игрока, его агент неактивен",
      date_start: "Дата начала",
      date_end: "Дата окончания",
      jackpot: "Джекпот",
      deposit_from_jackpot: "Сумма джекпотов",
      jackpot_win: "Джекпот (победа)",
      file_too_large: "Изображение слишком большое",
      total_deposit_from_jackpot: "Общая сумма джекпотов",
      total_agent_ggr: "Общая сумма GGR",
      total_agent_ggr_commission: "Общая сумма комиссии GGR",
      total_parent_agent_ggr_commission:
        "Общая сумма комиссии родительского агента GGR",
      total_from_subagents_ggr_commission:
        "Общая сумма комиссии сабагентов GGR",
      total_jackpot: "Общее количество джекпотов",
      agent_ggr_commission: "Комиссия агента GGR",
      parent_agent_ggr_commission: "Комиссия родительского агента GGR",
      from_subagents_ggr_commission: "Комиссия сабагентов GGR",
      show_blank_data: "Показывать нулевые данные",
      bet_percent_limit: "Процент ставки не может превышать 50%",
      notifications: "Уведомления",
      is_viewed: "Просмотрено",
      notifications_not_found: "Уведомления не найдены",
      notification_added: "Уведомление добавлено",
      ban: "Блокировка",
      bonus: "Бонус",
      create_notification: "Создать уведомление",
      tooltip_add_notification: "Добавить уведомление для игрока",
      new_notification: "Новое уведомление",
      notification_will_deleted: "Уведомление будет удалено",
      notification_edited: "Уведомление изменено",
      notification_deleted: "Уведомление удалено",
      edit_notification: "Редактирование уведомления",
      update: "Обновить",
      my_ggr: "Мой GGR",
      my_ggr_share: "Мой GGR (share)",
      subagents_ggr: "Сабагенты GGR",
      subagents_ggr_share: "Сабагенты GGR (share)",
      agent_ggr_share: "Агент GGR (share)",
      segments: "Сегменты",
      create_segment: "Создать сегмент",
      segment_data: "Данные сегмента",
      segment_created: "Сегмент успешно создан",
      segment_deleted: "Сегмент удален",
      segment_will_delete: "Сегмент будет удален",
      segment_updated: "Сегмент обновлен",
      create_new_notification: "Создать новое уведомление",
      for_all_users: "Для всех игроков",
      segment: "Сегмент",
      conditions: "Условия",
      add_condition: "Добавить условие",
      add_rule: "Добавить правило",
      and: "И",
      or: "ИЛИ",
      condition: "Условие",
      field: "Поле",
      created_at: "Дата создания",
      equal: "Равно",
      not_equal: "Не равно",
      greater_than_or_equal: "Больше или равно",
      less_than_or_equal: "Меньше или равно",
      remove_rule: "Удалить правило",
      segment_refreshed: "Сегмент обновлен",
      segment_players: "Количество игроков сегмента",
      export_customer_io: "Экспорт в Customer.io",
      export: "Экспорт",
      add_field: "Добавить поле",
      export_success: "Экспорт успешно завершен",
      select_at_least_one_field: "Выберите хотя бы одно поле",
      id: "ID",
      multiaccs: "Мультиаккаунты",
      all_users_notif_hint:
        "Чтобы создать уведомление для конкретного игрока, необходимо сделать это в его профиле",
      type_players: "Тип игроков",
    },
    en: {
      type_players: "Type players",
      all_users_notif_hint:
        "To create a notification for a specific player, you need to make it in their profile",
      multiaccs: "Multiaccs",
      id: "ID",
      select_at_least_one_field: "Select at least one field",
      export_success: "Export successfully completed",
      add_field: "Add field",
      export: "Export",
      export_customer_io: "Export to Customer.io",
      segment_players: "Segment total players",
      segment_refreshed: "Segment refreshed",
      remove_rule: "Remove rule",
      less_than_or_equal: "Less than or equal",
      greater_than_or_equal: "Greater than or equal",
      equal: "Equal",
      not_equal: "Not equal",
      created_at: "Created at",
      field: "Field",
      condition: "Condition",
      and: "And",
      or: "Or",
      add_rule: "Add rule",
      add_condition: "Add condition",
      conditions: "Conditions",
      segment: "Segment",
      for_all_users: "For all players",
      create_new_notification: "Create new notification",
      segment_updated: "Segment updated",
      segment_will_delete: "Segment will be deleted",
      segment_deleted: "Segment deleted",
      segment_created: "Segment successfully created",
      segment_data: "Segment data",
      create_segment: "Create segment",
      segments: "Segments",
      agent_ggr_share: "Agent GGR (share)",
      subagents_ggr_share: "Subagents GGR (share)",
      subagents_ggr: "Subagents GGR",
      my_ggr_share: "My GGR (share)",
      my_ggr: "My GGR",
      update: "Update",
      edit_notification: "Edit notification",
      notification_deleted: "Notification deleted",
      notification_edited: "Notification edited",
      notification_will_deleted: "Notification will be deleted",
      new_notification: "New notification",
      tooltip_add_notification: "Add notification for player",
      create_notification: "Create notification",
      bonus: "Bonus",
      ban: "Ban",
      notification_added: "Notification added",
      notifications_not_found: "Notifications not found",
      is_viewed: "Viewed",
      notifications: "Notifications",
      bet_percent_limit: "Bet percentage cannot exceed 50%",
      show_blank_data: "Show blank data",
      from_subagents_ggr_commission: "Subagents GGR commission",
      parent_agent_ggr_commission: "Parent agent GGR commission",
      agent_ggr_commission: "Agent GGR commission",
      total_jackpot: "Total jackpots",
      total_from_subagents_ggr_commission:
        "Total from subagents GGR commission",
      total_parent_agent_ggr_commission: "Total parent agent GGR commission",
      total_agent_ggr_commission: "Total agent GGR commission",
      total_agent_ggr: "Total agent GGR",
      total_deposit_from_jackpot: "Total deposit from jackpot",
      file_too_large: "File too large",
      jackpot_win: "Jackpot (win)",
      deposit_from_jackpot: "Deposit from jackpot",
      jackpot: "Jackpot",
      date_start: "Start date",
      date_end: "End date",
      cant_unban_player: "Can't unban player, his agent is not active",
      new_sub_agent: "New subagent",
      jackpot_disabled: "Jackpot disabled",
      jackpot_enabled: "Jackpot enabled",
      activated_jackpot_hint:
        "Click to activate jackpot. You can only activate one jackpot at a time",
      active_jackpot_hint: "Active jackpot",
      error_progress_target: "Progress cannot be greater than target amount",
      error_progress_start: "Progress cannot be less than start amount",
      bet_percent_hint:
        "The percentage which will be charged on the jackpot amount",
      bets_progress_hint: "Current progress of the jackpot (sum)",
      no_banners: "No banners found, but you can always create new",
      user_reg: "Registration",
      main_slider: "Main slider",
      overall: "Overall",
      provider_fee_sum: "Provider fee sum",
      provider_fee_percent: "Provider fee percent",
      agent_reports: "Agent reports",
      level_currency_hint:
        "For avoiding errors, a level creating with all currencies",
      deposit_bonus_no_currencies:
        "The deposit bonus must contain all currencies",
      freespins_bonus_no_currencies:
        "The freespins bonus must contain all currencies",
      level_already_exists: "Level with this name already exists",
      sum_to_level: "Amount to get a level",
      create_level: "Create level",
      level_data: "Level data",
      not_enough_balance: "Not enough balance",
      comment: "Comment",
      type_users: "Type of users",
      platform: "Platform",
      apply: "Apply",
      min_less_than_max: "should be less than or equal to",
      bonus_data: "Bonus data",
      create_deposit_bonus: "Create deposit bonus",
      create_spins_bonus: "Create spins bonus",
      not_active_agents: "Not active for agents",
      player_winner_id: "Player winner (ID)",
      min_length_3: "Minimum length 3 characters",
      temp_block: "Temporary block",
      favicon: "Favicon",
      created_tooltip_jackpot:
        "This jackpot was created and is currently inactive",
      ongoing_tooltip_jackpot:
        "Active jackpot. Player bets fill its pot every time",
      finished_tooltip_jackpot:
        "Finished jackpot. The first player to fill his bank has successfully received his jackpot",
      finished: "Finished",
      have_active_jackpot:
        "You cannot have two active jackpots at the same time",
      title_descr: "Setting favicon and title",
      admins: "Admins",
      authentications: "Authentications",
      target_win_amount_less:
        "<b>Start</b> jackpot amount must be less than to the <b>target</b> amount",
      only_agent_players: "Only agent players",
      multi_accounts_hint:
        "The ability to track the registration of multiple accounts by a player",
      pending_verification_hint: "Player identity verification",
      player_status_hint: "Player's achieved level",
      account_status_hint: "Player account status",
      registered_hint: "Date of registration of player",
      withdrawals_hint:
        "Amount of funds the player has withdrawn from the account",
      balance_hint: "Amount of funds in the player's account",
      deposits_hint: "Total player deposits",
      id_hint:
        "A unique player number that can be used to view all their statistics",
      country_hint: "Country of player registration",
      subagents: "Subagents",
      allowed_to_agents: "Allowed to agents",
      not_valid_password: "Minimum 8 characters",
      email_already_exists: "This email already exists",
      tel_already_exists: "This phone number already exists",
      agent_not_active: "Agent is not active",
      use_another_password: "Use another password",
      agent_already_exists: "Agent with this login already exists",
      player_already_exists: "Player with this login already exists",
      you: "You",
      total_deposit_to_users: "Total deposit to users",
      total_withdraw_from_users: "Total withdraw from users",
      total_bet: "Total bet",
      total_win: "Total win",
      total_ggr: "Total GGR",
      withdraw_from_users: "Withdraw from users",
      deposit_to_users: "Deposit to users",
      subagent: "Subagent",
      aggregator_edit_success: "Aggregator successfully edited",
      transaction_data: "Transaction data",
      close: "Close",
      min_bet_currency: "Minimum bet currency",
      cant_delete_default_avatar: "You can't delete default avatar",
      edit_aggregator: "Edit aggregator",
      allowed_currencies: "Allowed currencies",
      jackpot_status: "Jackpot status",
      ongoing: "In progress",
      created: "Created",
      promo_data: "Promo data",
      players_pending_verification: "Players pending verification",
      players_have_multiaccs: "Players with multiple accounts",
      players_without_deposit: "Players without deposit",
      players_with_deposit: "Players with deposit",
      agent_to_user: "Agent to user",
      log_type: "Log type",
      players_from_agent: "Players from agent",
      player_managed: "Player management",
      permanent_ban: "Permanent ban",
      total_withdraws_count: "Total withdraws count",
      total_deposits_count: "Total deposits count",
      withdraws_count: "Withdraws count",
      deposits_count: "Deposits count",
      ngr_sum: "NGR sum",
      providers_fee_sum: "Providers fee sum",
      ggr_sum: "GGR sum",
      withdraws_sum: "Withdraws sum",
      deposits_sum: "Deposits sum",
      providers_fee_percent: "Providers fee percent",
      player_baned: "Player successfully baned",
      player_unbaned: "Player successfully unbaned",
      only_one_jackpot: "You can create only one jackpot",
      cant_delete_agent: "Can't delete this agent",
      agent_deleted: "Agent was deleted successfully",
      agent_deactivated: "Agent was deactivated successfully",
      confirm_delete_agent:
        "Agent will be deleted if there are no related logs else deactivated",
      delete_agent: "Delete agent",
      bet_percent: "Bet percent",
      edit_jackpot: "Edit jackpot",
      spins_bonuses: "Spins bonuses",
      clear_all: "Clear all",
      jackpot_handled: "Jackpot handled",
      progress: "Progress",
      agents_financial_report: "Financial report",
      select_player_and_confirm: "Select a player and confirm",
      total_bet_sum: "Total bet amount",
      total_win_sum: "Total win amount",
      sessions_count: "Count of sessions",
      avg_historical_rtp_percent: "Average RTP (%)",
      target_win_amount: "Target jackpot amount",
      bets_progress: "Bets progress",
      start_win_amount: "Start jackpot amount",
      new_jackpot: "New jackpot",
      jackpot_progress: "Jackpot progress",
      user_id: "Player ID",
      create_jackpot: "Create jackpot",
      jackpot_updated: "Jackpot was updated successfully",
      jackpot_deleted: "Jackpot was deleted successfully",
      jackpot_will_deleted: "Jackpot will be deleted",
      jackpot_settings: "Jackpot settings",
      sub_type: "Sub type",
      promocode: "Promocode",
      agent_id: "Agent ID",
      no_active_agent: "This agent was disabled",
      player_updated: "Player was updated successfully",
      agent_updated: "Agent was updated successfully",
      agent_edit: "Edit agent",
      player_edit: "Edit player",
      select_agent: "Select agent to see players",
      new_user: "New user",
      not_valid_email: "Invalid email",
      create_user: "Create user",
      no_parent: "No parent agent",
      agent_admin: "Agent admin",
      success_withdraw: "Withdraw was successful",
      success_deposit: "Deposit was successful",
      select_language: "Select language",
      success_depositL: "Deposit was successful",
      deposit_balance: "Deposit balance",
      withdraw_balance: "Withdraw balance",
      no_have_balance: "No have enough balance",
      agent_no_have_balance: "Agent does not have enough money",
      available_balance: "Available balance",
      copied_to_clipboard: "Success copied to clipboard",
      create_agent: "Create agent",
      agent: "Agent",
      agent_created: "Agent was created successfully",
      max_twelve_characters: "Maximum 12 characters",
      value_more_than_zero: "Value must be greater than 0",
      tooltip_parent_agent: "Without specifying an agent, level 0 will be set",
      create: "Create",
      new_agent: "New agent",
      parent_agent: "Parent agent",
      add_agent: "Add agent",
      add_new_agent: "Add new agent",
      buy_credits: "Buy credits",
      agent_name: "Agent name",
      agent_level: "Agent level",
      share: "Share",
      agent_credit_balance: "Agent credit balance",
      credit_players_balance: "Credit players balance",
      bonus_credit_balance: "Bonus credit balance",
      jackpot_amount: "Jackpot amount",

      agents_report: "Agents report",
      agent_players_list: "Agent players list",
      agents: "Agents",
      no_date: "No date",
      tooltip_add_game_to_block: "Add game to selected block",
      games_refreshed: "Games refreshed",
      refresh_games: "Refresh games",
      tooltip_refresh_games:
        'Updating the games of the selected aggregator. (All new games are placed in the "New" section)',
      payment_gateway: "Payment gateway",
      tooltip_add_comment: "Add player comment",
      tooltip_multi_block: "Block all detected multi-accounts",
      tooltip_reject_doc: "Reject document",
      tooltip_accept_doc: "Accept document",
      tooltip_delete_bonus: "Delete bonus from player (if not used)",
      tooltip_add_bonus: "Add bonus to player",
      tooltip_unblock_forever: "Unblock player forever",
      tooltip_block_forever: "Permanent block player",
      tooltip_change_balance: "Increase or decrease player balance",
      tooltip_temp_block: "Temporary block player (Specify date)",
      tooltip_unblock_withdraw: "Allow player to withdraw",
      no_permission: "You don't have enough permissions to perform this action",
      tooltip_block_withdraw: "Block player withdraw",
      draw: "Draw",
      doc_was_rejected: "The document was rejected",
      doc_was_approved: "The document was successfully approved",
      wrong_password: "Wrong password",
      old_password: "Old password",
      new_password: "New password",
      start_date: "Min. date",
      end_date: "Max. date",
      lose: "Lose",
      finish_balance: "Final balance",
      min_finish_balance: "Min. final balance",
      max_finish_balance: "Max. final balance",
      aggregator: "Aggregator",
      click_button_to_save:
        'After adding colors, click on the "Save" button to apply the changes',
      clear_all_links: "Clear all links",
      referral_id: "Referral ID",
      last_visit: "Last visit",
      same_name: "The variable with this name already exists",
      req_field_or_wrong:
        "Required field. Name must start with two hyphens (--)",
      name_color: "Name color",
      edit_color: "Edit color",
      add_color: "Add color",
      aggregators: "Aggregators",
      providers: "Providers",
      themes_updated: "Styles were successfully updated",
      user_not_found: "The selected user was not found",
      invalid_amount: "Invalid amount",
      no_permission:
        "You do not have enough permissions to perform this operation",
      upload_less_image: "The image size should not exceed 1 MB",
      upload_error: "An error occurred while uploading the image",
      avatar_will_deleted:
        "Avatar will be permanently deleted after your confirmation",
      color_will_deleted:
        "After deleting, don't forget to click on the 'Save' button to apply the changes",
      default_ava_changed: "Default avatar changed",
      ava_deleted: "Avatar successfully deleted",
      ava_created: "Avatar successfully created",
      ava_edited: "Avatar successfully updated",
      add_photo_to_save: "Add at least one image to save",
      select_at_least_one: "Select at least one image",
      games_report: "Games report",
      no_game_by_block_add:
        "No games found in this block, to add a game, click on the 'Add a game' button",
      lang_order: "The order of localizations has been successfully updated",
      admin_deleted: "Admin succesefully deleted",
      admin_edited: "Admin succesefully edited",
      admin_data: "Admin data",
      online_hint:
        "Parameter showing players who have been online for the last 10 minutes",
      username_error:
        "Player's nickname must be between 3 and 12 characters long and written in Latin letters only",
      info_new_player: "Info about added player",
      add_more: "Add one more player",
      save_close: "Save data and close",
      player_added: "New player succesefully added",
      add_new_player: "Add new player",
      categories_hint: "Game categories",
      categories: "Categories",
      product_enabled: "Product successfully launched",
      product_disabled: "Product successfully stopped",
      attention_title: "Attention!",
      attention_question: "Are you sure?",
      attention_enabled_text:
        "The product will be launched and tech. maintenance page will not be displayed.",
      attention_disabled_text:
        "The product will be stopped and tech. maintenance page be displayed until you will press this button again.",
      stop_product: "Stop the product",
      disabled: "Disabled",
      resume_product: "Launch the product",
      total_users_bonus_balance_sum: "Total users bonus balance sum",
      total_users_by_currency_and_date_count:
        "Total users by currency and date",
      total_users_by_currency_count: "Total users by selected currency",
      map: "Map",
      total_by_country: "By country",
      total_by_date: "By date",
      by_date: "Number of new registrations and FTD",
      reg_by_country: "Registration by country",
      need_select_dates:
        "Select a date or change the interval to continue filtering",
      total_users_online_count: "Total users online count",
      payments: "Payments",
      users: "Users",
      total_users_balance_sum: "Total users balance sum",
      total_deposits_sum: "Total deposits sum",
      total_withdraws_sum: "Total withdraws sum",
      total_manual_corrections_sum: "Total manual corrections sum",
      total_ngr_sum: "Total NGR sum",
      total_providers_fee_percent: "Total providers fee percent",
      total_providers_fee_sum: "Total providers fee sum",
      totals: "Totals",
      first_deposits_sum: "First deposits sum",
      issued_bonuses_sum: "Issued bonuses sum",
      positive_correction_sum: "Positive correction sum",
      negative_correction_sum: "Negative correction sum",
      affiliate_payouts_sum: "Affiliate payouts sum",
      platform_fee_sum: "Platform fee sum",
      new_registrations_count: "New registrations count",
      first_deposits_count: "First deposits count",
      total_deposit_sum: "Total deposit sum",
      total_deposit_fee_sum: "Total deposit fee sum",
      total_deposit_fee_percent: "Total deposit fee percent",
      total_withdraw_sum: "Total withdraw sum",
      total_withdraw_fee_sum: "Total withdraw fee sum",
      total_withdraw_fee_percent: "Total withdraw fee percent",
      set_new_min_bet: "Min max bet for category",
      fishing: "Fishing",
      crash: "Crash",
      bakkara: "Bakkara",
      slots: "Slots",
      roulette: "Roulettes",
      select_stat_for_graph:
        "Select the desired statistic to display detailed information",
      format: "Data format",
      dashboard: "Dashboard",
      new_script_added: "New script successfully added",
      new_script_updated: "New script successfully updated",
      choose_one_of_option: "Please select code type",
      for_example: "For example",
      content: "Content",
      attribute: "Attribute",
      add_more_attribute: "One more attribute",
      allow: "Allow",
      updated_geo: "Country successfully updated",
      deleted_geo: "Country successfully deleted",
      updated_script: "The script has been successfully updated",
      deleted_script: "Script successfully deleted",
      save_geo: "Country successfully saved",
      license: "License",
      country_code: "Country code",
      where_on_page: "Where on page",
      on_head: "In the head element",
      on_body: "In the body element",
      locate: "Locate",
      code: "Code",
      author: "Author",
      custom_js: "Custom JS",
      all_players: "All players",
      custom_head: "Meta tags and scripts",
      add_head: "Add script",
      edit_head: "Edit script",
      all_countries: "All countries",
      is_wagerable: "Factor in the wager",
      all_permissions: "All permissions",
      general: "General",
      separate: "Separate",
      return_to_list: "Return to general list",
      gameBlock_success_created: "Game block successfully created",
      add_new_block: "Add a new block",
      return_block_games: "Return to block games",
      add_game: "Add a game",
      permissions: "Permissions",
      block_all: "Block all",
      just_error: "An unexpected error occurred",
      roles_settings: "Role Settings",
      role_updated: "Role successfully updated",
      role_created: "Role successfully created",
      role_deleted: "Role successfully deleted",
      by_providers: "By providers",
      by_blocks: "By blocks",
      user_edit: "Edit user",
      role_edit: "Edit role",
      role_delete: "Delete role",
      role_delete_confirm: "Confirm the deletion of this role",
      promo_name_exist: "The specified promo code name already exists",
      game: "Game",
      games: "Games",
      game_settings: "Game settings",
      device: "Device",
      cat: "Just a cat",
      total_games: "Total games",
      multiacc_setup: "Multiaccounts setup",
      started_ava: "Default user avatar",
      refresh: "Refresh",
      user_ava_count: "Number of players with avatar",
      bets_sum: "Amount of bets",
      counts: "Counts",
      invalid_url: "An invalid URL has been entered",
      invalid_url_detail: "URL must contain https:// or http://",
      geo_restrictions: "Geo restrictions",
      wagering_coefficient: "Wagering coefficient",
      total_ggr_sum: "Total GGR sum",
      payment_amount: "Payment amount",
      payment_amount_x: "Payment amount X",
      payment_amount_y: "Payment amount Y",
      total_commission: "Total commission",
      total_commission_x: "Total commission X",
      total_commission_y: "Total commission Y",
      total_commission_percent: "Total commission percent",
      provider_comission: "Provider comission",
      provider_name: "Provider name",
      deposit_total_commission_percent: "Deposit total commission percent",
      withdraw_total_commission_percent: "Withdraw total commission percent",
      users_count: "Users count",
      affiliate_payouts: "Affiliate payouts",
      platform_commission: "Platform commission",
      total_ngr: "Total NGR",
      positive_sum: "Positive sum",
      negative_sum: "Negative sum",
      withdraw_payment_commission_sum: "Withdraw payment commission sum",
      deposit_payment_commission_sum: "Deposit payment commission sum",
      withdraw_payment_amount_sum: "Withdraw payment amount sum",
      deposit_payment_amount_sum: "Deposit payment amount sum",
      time: "Time",
      types: "Types",
      game_studios: "Game studios",
      game_studio_settings: "Game studio settings",
      game_blocks: "Game blocks",
      require_time_and_date: "Requires date and time",
      require_bonus: "Choose one of the bonuses",
      games_managment: "Games managment",
      games_manager: "Games manager",
      game_provider_settings: "Game provider settings",
      pc_resolution:
        "Device with screen resolution greater than 1024px (inclusive)",
      tablet_resolution:
        "Device with screen resolution less than 1024px and greater than 481px (inclusive)",
      mobile_resolution: "Device with screen resolution less than 481px",
      game_fee_error:
        "Unfortunately, game payment settings are currently unavailable",
      game_setting_error:
        "Unfortunately, game settings are currently unavailable",
      cashbox_error: "Unfortunately, cashflow are currently unavailable",
      game_fee_settings: "Game fee settings",
      aggrregator_name: "Aggrregator",
      aggrregators: "Aggrregators",
      no_game_to_add: "There are no games left to add to this block",
      report_period: "Report period",
      promo: "Promocodes",
      promo_success_created: "Promo code successfully created",
      bonus_player_deleted: "The player's bonus was successfully deleted",
      bonus_player_cant_deleted: "Unable to delete active bonus from player",
      promo_success_updated: "Promo code successfully updated",
      none: "None",
      method_success_updated: "Method successfully updated",
      supported_providers: "Supported providers",
      search_game: "Search games",
      logo: "Logo",
      bonus_not_active_yet: "The bonus has not been activated yet",
      game_name: "Game name",
      logo_select: "Select a new logo (jpeg, png, svg. Maximum 15MB)",
      game_logo_select:
        "Select a new logo for the game (jpeg, png, svg. Maximum 15MB)",
      drag_logo: "Or drag and drop files to here to upload",
      game_logo: "Game logo",
      game_producer: "Game Producer",
      game_type: "Game Type",
      game_category: "Game category",
      game_category_empty: "The category list is empty",
      game_blocks_empty: "The list of game blocks is empty",
      game_sub: "Game Sub",
      game_edit_success: "The game has been updated successfully",
      game_edit_error: "There was an error when updating the game",
      provider_edit_success: "The provider has been updated successfully",
      provider_edit_error: "There was an error when updating the provider",
      game_logo_exceed: "Maximum file size exceeded: 15MB",
      provider: "Provider",
      provider_not_found: "No providers found",
      gameBlocks_not_found: "Game Blocks not found",
      no_data_submited: "No data submitted",
      no_multi: "No multi-accounts",
      similarity: "Similarity",
      reg_ip: "Reg ip",
      edit: "Edit",
      find: "Find",
      create_new_ava: "Create a new avatar",
      language: "Language",
      languages: "Languages",
      lang_created: "Localization successfully created",
      lang_updated: "Localization successfully updated",
      lang_deleted: "Localization successfully deleted",
      json_with_translation: "JSON with translation",
      select_provider: "Select a provider to see a list of games",
      payment_min_limit: "Min. amount",
      payment_max_limit: "Max. amount",
      json_label: "Here will be the JSON of the selected localization",
      platform: "Platform",
      timezone: "Time zone",
      user_agent: "Browser",
      clear: "Clear",
      wins: "Wins",
      losses: "Losses",
      active: "Active",
      not_active: "Not active",
      admin_auth: "Admin login",
      player_auth: "Player login",
      search: "Search",
      affs_search: "Search affiliates",
      login: "Login",
      password: "Password",
      date: "Date",
      role: "Role",
      name: "Name",
      image: "Preview",
      to_edit: "Edit",
      new_p: "New",
      level: "Level",
      player_details: "Player details",
      banned: "Banned",
      banners: "Banners",
      banner_success_created: "Banner successfully created",
      banner_success_updated: "Banner successfully updated",
      create_new_banner: "Create new banner",
      create_new_level: "Create new level",
      edit_level: "Edit level",
      level_settings: "Role customization",
      level_updated: "Level successfully updated",
      level_created: "Level successfully created",
      level_deleted: "Level successfully deleted",
      user_data: "User data",
      user_delete: "Delete user",
      user_delete_confirm: "Confirm deletion of the selected user",
      level_edit: "Edit level",
      level_delete: "Deleting a level",
      level_delete_confirm: "Confirm deletion of this level",
      create_promocode: "Create promocode",
      create_new_promocode: "Create promocode",
      promo_edit: "Edit promocode",
      add_user: "Add user",
      add_role: "Add role",
      save_new_banner: "Save new banner",
      new_players: "New players",
      add_new_lang: "Add new localization",
      add_new_local: "Add more",
      cancel_new_banner: "Cancel the creation",
      new: "New",
      searchCom: "Search comments",
      addNewCom: "Add new comment",
      addCom: "Add comment",
      textCom: "Comment text",
      isCom: "Comment",
      addBy: "Added",
      itWas: "Employee",
      dataCreate: "Creation Date",
      auth_empty: "No admin logins found",
      auth_player_empty: "No player logins found",
      game_provider_empty: "No game providers found",
      logs_player_empty: "No player logs found",
      loading: "Loading",
      aff_info: "Affiliate details",
      aff_not_found: "Failed to retrieve affiliate data",
      pay_acc: "Payment account",
      payment_type: "Payment type",
      min_reg_date: "Min. reg date",
      max_reg_date: "Max. reg date",
      reg_date: "Registration date",
      reg_date_short: "Reg date",
      balance: "Balance",
      total_balance: "Total balance",
      cashout_balance: "Available for cashout",
      bonus_balance: "Bonus amount",
      gender: "Gender",
      traffic_source: "Traffic source",
      statistics: "Statistics",
      offers: "Offers",
      requests: "Requests",
      affiliates: "Affiliates",
      found: "found",
      number: "Number of multi-accounts",
      num_of_multi: "Num. multi-accs",
      bonuses: "Bonuses",
      added_bonus_success: "Bonus added",
      bonus_created: "Bonus successfully created",
      bonus_updated: "Bonus successfully updated",
      bonus_deleted: "Bonus successfully deleted",
      reports: "Reports",
      general_report: "General report",
      agents_rtp_report: "RTP report",
      success_report: "Report successfully generated",
      users_reports: "Users report",
      generate_report: "Generate report",
      player_engagement: "Player engagement",
      added_bonus_error: "Bonus was not added to an unspected error",
      user_have_bonus_error:
        "This bonus is already used. Please choose another one",
      basic_bonus_create: "Create basic bonus",
      basic_bonuses: "Basic bonuses",
      basic_bonus: "Basic bonus",
      dep_bonus_create: "Create deposit bonus",
      dep_bonus: "Deposit bonus",
      select_deposit_bonus: "Select deposit bonus",
      select_freespins_bonus: "Select freespins bonus",
      free_rounds: "Freespins",
      undefined: "Undefined",
      welcome: "Welcome Bonus pack",
      next_deposit: "Next deposit",
      bonus_info: "Bonus parameters",
      added_at: "Date added",
      order: "Number",
      active_date: "Activation date",
      wager_progress: "Wager progress",
      all_multi_get_ban: "All multi-accounts are banned",
      no_deposit: "No deposit",
      no_bonuses: "Bonuses not found",
      dep_bonuses: "Deposit bonuses",
      bonuses_list: "List of player bonuses",
      first: "First",
      spin_bonus_create: "Create spin bonus",
      spins_bonus: "Spins bonus",
      spin_bonuses: "Spin bonuses",
      bonus_delete: "Delete bonus",
      bonus_edit: "Edit bonus",
      edit_freespins: "Edit freespins",
      deleting_bonus: "Are you sure you want to delete the bonus?",
      delete: "Delete",
      basic_bonus_creation: "Basic bonus creation",
      max_sum_limit: "Max withdrawal amount must be greater than 0",
      max_sum_withdraw: "Max withdrawal amount",
      wager_sum_limit: "Wager must be greater than 0",
      draggble_hint: "Drag and drop items to sort their order",
      save: "Save",
      discard: "Discard",
      discard_layout: "Layout changes canceled",
      min_date: "Min. date",
      max_date: "Max. date",
      save_layout: "Save layout",
      alert_add_role:
        "Managing roles and permissions is not enabled in your account. <b>Chat with us</b> to upgrade to manage roles and permissions.",
      dep_bonus_creation: "Deposit bonus creation",
      choose_bonus: "Choose bonus",
      choose_game: "Choose game",
      choose_deposit_bonus: "Choose a deposit bonus",
      choose_freespins_bonus: "Choose freespins bonus",
      choose_basic_bonus: "Choose basic bonus",
      max_win: "Max win",
      any: "Any except first",
      gameNotFound: "Games not found",
      game_studios_not_found: "Game studios not found",
      dep_type: "Deposit type",
      bonus_type: "Bonus type",
      add_role_user: "Adding a user",
      edit_role_user: "Edit user",
      user_roles: "User roles",
      manage_users: "Manage Users",
      manage_admins: "Admin users",
      access_control: "Access control",
      redirect_url: "Redirect link",
      blocked_country: "Blocking country",
      blocked: "Blocked",
      select_all_currencies: "Select all currencies",
      all_currencies: "All currencies",
      select_all: "Select all",
      choose_dep_type: "Choose deposit type",
      min_dep_limit: "Min deposit must be greater than 0",
      time_period: "Time interval",
      select_period: "Select period",
      day: "Day",
      week: "Week",
      month: "Month",
      three_month: "Three months",
      half_year: "Half year",
      custom: "Custom",
      year: "Year",
      color_gamma: "Implement code with color variables",
      add_admin: "Add admin",
      edit_admin: "Edit admin",
      min_max_greater:
        "Max. deposit value must be greater than or equal to the min. value",
      min_dep: "Min. deposit",
      max_dep: "Max. deposit",
      theme_logo: "Themes",
      logo_descr: "Set a logo that will be embedded throughout the site",
      expire_in_days: "Expires in days",
      expiration_date: "Expiration date",
      deposit_source: "Deposit source",
      vendor_type: "Vendor type",
      min_bonus_limit: "Bonus must be greater than 0",
      min_bonus_time: "The number of days must be greater than 0",
      bonus_percent: "Bonus percentage",
      max_lim_description: "Max description length is 100 characters",
      description: "Description",
      spin: "Spin",
      banner_deleted: "Banner deleted",
      promo_deleted: "Promo code removed",
      spin_bonus_creation: "Spin bonus creation",
      min_lim_price: "Price must be greater than 0",
      price: "Price",
      min_lim_bet: "Bet must be greater than 0",
      bet: "Bet",
      edit_game: "Edit game",
      edit_provider: "Edit provider",
      all_provider_games: "Total number of games",
      active_provider_games: "Active",
      not_active_provider_games: "Inactive",
      bet_amount: "Bet amount",
      alert: "Attention",
      toConfrim: "Confirmation",
      other_settings: "Other settings",
      confirm_layout: "Save current game layout?",
      edit_spins_bonus: "Edit Freespin",
      edit_deposit_bonus: "Edit Deposit bonus",
      min_bet: "Min. Bet",
      max_bet: "Max. Bet",
      lines_greater_than_zero: "Number of lines must be greater than 0",
      bet_greater_than_zero: "The bet must be greater than 0",
      max_win_than_zero: "Max. win must be greater than 0",
      expire_in_days_than_zero: "Specify the number of days greater than 0",
      lines: "Number of lines",
      spins_greater_than_zero: "Number of spins must be greater than 0",
      spins: "Number of spins",
      multiplier_greater_than_zero: "Multiplier must be greater than 0",
      multiplier: "Multiplier",
      cash_flow: "Cashflow",
      players: "Players",
      players_list: "List of players",
      players_authorization_logs: "Players logs",
      admin_authorization_logs: "Admins logs",
      online: "Online",
      auto: "Auto",
      each_ava: "Each avatar allows you to emphasize the player's personality",
      autopayout: "Autopayout",
      show_all_games: "Show all games",
      show_detailed_info: "Show detailed info",
      players_ava: "Avatars",
      create_players_ava: "Create avatar",
      edit_players_ava: "Edit avatar",
      manual: "Manual",
      today: "Today",
      total: "Total",
      all: "All",
      choose_period: "Choose period",
      casino_revenue: "Casino revenue",
      deposits: "Deposits",
      with_deposits: "With deposits",
      fee: "Fee",
      withdraws: "Withdrawals",
      yesterday: "Yesterday",
      ava: "Avatars",
      fingerprint_detection_hint:
        "A table with all available fingerprint parameters. You can check any parameter that I want to be detected. Any player account with a similar fingerprint hash will be detected as a multi-account.",
      fingerprint_detection_setting: "Fingerprint detection settings",
      multiacc_rule_logic: "Multiacc rule logic",
      ava_descr:
        "Add two images, one should be good quality (1) and the other should be of maximally compressed (2). <br> <b>Important!</b> If you add only one image, it will be duplicated in both cells",
      last_30_days: "Last 30 days",
      multi_rule_hint:
        "TAB, which allows me to set up a rules what CORE will do with a player profiles which where detected as a multi accounts",
      id_dt_hint:
        "If this options is set to ON - players with a same public IP address will be detected as a Multiaccount",
      ip_detection: "IP detection",
      game_block_error: "Unfortunately game blocks are currently unavailable",
      game_block_hint:
        "Game blocks are an integral part of our platform. They contain specific games that can be swapped, added or removed from a game block. This also applies to the blocks specifically, they all lend themselves to CRUD changes",
      game_provider_hint:
        "Games by provider - a place where you can control the order of games and providers, as well as have customizations that allow you to change the logo, commission, turn off items, and more",
      multi_bonus_reward: "Multiaccounts bonus reward",
      multi_promo_reward: "Multiaccounts promocode reward",
      multi_bonus_reward_hint:
        "If OFF - after registration user will receive a message “Sorry, you have other accounts in our system and you cannot claim bonuses”. All bonuses will be disabled for this user. Same with Promo codes - NO PROMO CODE can be activated. If the user tries to apply a PROMO CODE - he will receive the same message as described above",
      multi_auto_block: "Multiaccounts auto blocking",
      multi_auto_block_hint:
        "If ON - the status of all detected Multiaccounts will be automatically set to “BLOCKED”. And the main site will receive a flag to display the message",
      last_month: "Last month",
      more: "More",
      second_last_month: "Second last month",
      registrations: "Registrations",
      firstDeposits: "First deposits",
      applyLabel: "Apply",
      cancelLabel: "Cancel",
      weekLabel: "Wk",
      customRangeLabel: "Custom range",
      last_7_days: "Last 7 days",
      this_month: "This month",
      previous_month: "Previous month",
      first_deposits: "First deposits",
      deposit_count: "Deposit count",
      quantity: "Quantity",
      max_uses: "Number of uses",
      deposit_sum: "Deposit sum",
      withdraw_sum: "Withdrawal sum",
      min_withdrawal: "Min. withdrawal",
      max_withdrawal: "Max. withdrawal",
      payment_fee: "Payment fee",
      address: "Address",
      city: "City",
      country: "Country",
      countries: "Countries",
      region: "Region",
      saved_layout_success: "The new order has been successfully applied",
      saved_layout_error: "An error occurred while setting the new order",
      unsaved_layout: "You have unsaved changes in games layout",
      yes: "Yes",
      no: "No",
      ggr: "GGR",
      ngr: "NGR",
      revenue: "Revenue",
      mon: "Mon",
      tue: "Tue",
      wed: "Wed",
      thu: "Thu",
      fri: "Fri",
      sat: "Sat",
      sun: "Sun",
      jan: "Jan",
      feb: "Feb",
      mar: "Mar",
      apr: "Apr",
      may: "May",
      jun: "Jun",
      jul: "Jul",
      aug: "Aug",
      sep: "Sep",
      oct: "Oct",
      nov: "Nov",
      dec: "Dec",
      sign_up: "Sign up",
      addFilters: "Add 3 filters",
      removeFilters: "Remove 3 filters",
      authorization: "Authorization",
      sign_in: "Sign in",
      sign_out: "Sign out",
      invalid_value: "Invalid value",

      payment_count: "Number of payments",
      payment_sum: "Payment amount",
      success_increase: "Balance successfully increased",
      success_balance_changed: "Balance successfully changed",
      error_message: "Invalid login/password",
      no_mobile: "Mobile version is not supported",
      no_data: "No data",
      open_affiliate_admin: "Open affiliate admin panel",
      compensation: "Compensation",
      pay_methods: "Payment methods",
      pay_method: "Payment method",
      amount: "Amount",
      max_withdraw_amount: "Max withdrawal amount",
      wager: "Wager",
      actions: "Actions",
      deposit_number: "Deposit number",
      deposit: "Deposit",
      min_deposit: "Min deposit",
      spin_count: "Spin count",
      redirector_addr: "Redirector address",
      redirector: "Redirector",
      redirect: "Banner on click URL",
      deletedMirrors: "Deleted mirrors",
      activeMirrors: "Active mirrors",
      addLink: "Add link",
      add: "Add",
      added: "Added",
      deleteLink: "Delete link",
      tel: "Phone",
      tel_number: "Number",
      changeBaseUrl: "Change Base URL",
      enterNewRedirectorLink: "Enter New Redirector Link",
      support: "Support",
      message: "Message",
      response: "Response",
      phone: "Phone",
      responsible: "Responsible",
      user: "User",
      status: "Status",

      total_users_count: "Total registration count",
      total_users_by_traffic_sources_count:
        "Total users by traffic sources count",
      total_ftd_count: "Total FTD count",
      total_ftd_by_traffic_sources_count: "Total FTD by traffic sources count",
      select: "Select",
      request: "Request",
      error_occured: "an error occurred",
      total_players: "Total players",
      active_call: "Active Call",
      income_call: "Incoming Call",
      session_spins: "Session Spins",
      session_spins_load: "Load Session Spins",
      top_games: "Top 10 games",
      top_players: "Top 10 players",
      top_provider: "Top 10 providers",
      transaction: "Transaction",
      player_transaction: "Player transaction",
      financial_transactions: "Financial transactions",
      finances: "Finances",
      sum: "Sum",
      average: "Average",
      value: "Value",
      type_transaction: "Transaction Type",
      method: "Method",
      payments_empty: "No payments available",
      replenishment: "Replenishment",
      withdraw: "Withdraw",
      transaction_details: "Transaction Details",
      game_session_id: "Id session",
      transaction_id: "Transaction ID",
      ga: "games",
      player: "Player",
      account: "Account",
      type: "Type",
      date_n_time: "Date and Time",
      date_from: "Date from",
      date_to: "Date to",
      accept: "Accept",
      not_confirmed: "Not Confirmed",
      confirmed: "Confirmed",
      approve: "Approve",
      decline: "Decline",
      block_order: "Block order",

      check: "Check",
      completed: "Completed",
      canceled: "Canceled",
      game_id: "Game id",
      processed: "Processed",
      processing_with_fee: "Processing with fee",
      completed_with_fee: "Completed with fee",
      processing: "Processing",
      player_info: "Player Information",
      player_id: "Player ID",
      general_info: "General Information",
      username: "Nickname",
      registration_date: "Registration Date",
      registered: "Registered",
      affiliate: "Affiliate",
      phone2: "Phone",
      additional_info: "Additional Information",
      name: "Name",
      surname: "Surname",
      levels_not_found: "Levels not found",
      reward: "Reward",
      no_reward: "No rewards",
      amount_to_the_next_level: "Amount to the next level",
      player_status: "Player level",
      player_levels: "Player levels",
      min_player_status: "Min. player level",
      max_player_status: "Max. player level",
      birth_date: "Birth Date",
      email: "Email",
      local: "Localization",
      bar_graph_descr: "Graph of changes in the number of new players",
      line_graph_descr: "Dynamics of changes in the categories of players",
      pie_graph_descr: "Category diagram",
      local_legal: "Legal",
      local_general: "General",
      title: "Title",
      localTitle: "Translation Editor",
      payment_info: "Payment Information",
      wager_playthrough: "Wager Playthrough",
      withdrawals: "Withdrawals",
      block_withdraw: "Block Withdraw",
      unblock_withdraw: "Unblock Withdraw",
      verification: "Verification",
      no_any_photos: "No photos uploaded",
      face_card_photo: "Face with Card Photo",
      face_passport_photo: "Face with Passport Photo",
      passport: "Passport",
      verified: "Verified",
      rejected: "Rejected",
      account_management: "Account Management",
      account_status: "Account Status",
      verif_status: "Verification status",
      not_defined: "Not Defined",
      unblock: "Unblock",
      block_forever: "Block Forever",
      blocked_until: "Blocked Until",
      temporary_block: "Temporary Block",
      select_time: "Select Time",
      block: "Block",
      operations: "Operations",
      change_balance: "Change Balance",
      sessions: "Sessions",
      comments: "Comments",
      authorizations: "Authorizations",
      logs: "Logs",
      multi_accounts: "Multi-Acc",
      failToGetData: "Failed to get data",
      reject: "Reject",
      enterAmount: "Enter Amount",
      debit: "Decrease balance",
      credit: "Increase balance",
      addBonus: "Add Bonus",
      selectBonus: "Select Bonus",
      temporary_blocked: "Temporarily Blocked",
      temp_blocked: "Temp. blocked",
      forever_blocked: "Blocked Forever",
      withdraw_blocked: "Withdrawal Blocked",
      withd_blocked: "Withd. blocked",
      withdraw_unblocked: "Withdrawal Unblocked",
      withdraw_block_failed: "Failed to block withdrawal",
      invalid_withdrawal_value:
        "Invalid withdrawal value, maximum value is {maxValue}",
      phone_only: "Phone Only",
      balance_from: "Balance from",
      balance_to: "Balance to",
      amount_from: "Amount from",
      withdrawals_to: "Withdrawals to",
      withdrawals_from: "Withdrawals from",
      amount_to: "Amount to",
      bet_amount_from: "Bet amount from",
      bet_amount_to: "Bet amount to",
      result: "Result",
      from: "From",
      to: "To",
      session_search: "Session search",
      reset: "Reset",
      gameBlock_no_game: "The selected game block does not have any games yet",
      select_block_see_provider: "Select a block to see a list of game studios",
      select_block_see_games: "Select a block to see a list of games",
      players_not_found: "Players not found",
      roles_not_found: "Roles not found",
      promo_not_found: "Promocodes not found",
      call: "Call",
      isCallInProgress: "Call in progress",
      signDialog: "Sign Dialog",
      in: "in",
      min: "Min",
      max: "Max",
      paymethods: "Payment Methods",
      balance_corrections: "Balance corrections",
      key: "Key",
      multi_accounts_found: "Number of multi-accs found",
      linked_account: "Linked Account",
      fingerprint_hash: "Fingerprint Hash",
      gameBlock_changed: "The games in the block have been successfully edited",
      gameBlock_added: "Game successfully added to the block",
      gameBlock_game_deleted: "Game successfully deleted from the block",
      gameBlock_deleted: "Game Block successfully deleted",
      gameBlock_is_changed: "The game block has been successfully changed",
      required_field: "Required field",
      comment_change_balance: "Reason for balance change (required)",
      all_fingerprint_parameters: "All Fingerprint Parameters",
      parameter: "Parameter",
      fingerprint_not_available: "Fingerprint not available",
      player_logs_not_found: "Player logs not found",
      player_balance_corrections_not_found:
        "Player balance corrections not found",
      on: "On",
      off: "Off",

      admin: "Admin",
      requests_not_available: "Requests not available",
      confirmation_affiliate: "Affiliate Confirmation",
      select_offers: "Select Offers:",
      manager: "Manager",
      confirm: "Confirm",
      add_new: "Add New",
      offers_not_available: "Offers not available",
      add_offer: "Add Offer",
      revenue_share: "Revenue Share",
      cost_per_acquisition: "Cost per Acquisition",
      tiered_revenue_share: "Tiered Revenue Share",
      rate: "Rate",
      product: "Product",
      search_by: "Search By",
      option1: "Option 1",
      option0: "Option 0",
      currency: "Currency",
      currencies: "Currencies",

      available_currencies: "Available currencies",
      activity_period: "Activity Period",
      session_start: "Session Start",
      win: "Win",
      loss: "Loss",
      tags: "Tags",
      choose_offer: "Choose Offer",
      affs_not_found: "Affiliates not found",
      commission: "Commission",

      commission_fee: "Commission fee",
      pending_verification: "Pending verification",
      pages: "Lines per page",
      comments_not_found: "Comments not found",
      trans_not_found: "Transactions not found",
      sessions_not_found: "Game session not found",
      sessions_loading: "Game session loading",
      spins_not_found: "Spins not found",
      detailed_info: "Detailed info",
      add_bonus: "Add bonuses",
    },
    tr: {},
  },
});
