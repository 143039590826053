<template>
    
    <v-dialog v-model="dialog" :persistent="formLoading" content-class="br-16" scrollable max-width="500px">
        <v-card>
          <v-card-title>
            <h3>{{ $t('edit_aggregator') }} - {{ aggregator.name }}</h3>

  
          </v-card-title>
          <v-card-text class="pb-0 py-2">
            <!-- Change currency -->
            <div class="bonus-block__header">
              <v-checkbox color="var(--main-flamingo)" class="mt-0 mb-2" hide-details 
          v-model="isAllAllowedCurrencies"
      :label="$t('all_currencies')"></v-checkbox>
          <v-select
          hide-details="auto"
            :rules="[v => selectedAllowedCurrencies.length > 0 || $t('required_field')]"
            multiple
            class="auto"
            :disabled="isAllAllowedCurrencies"
            :menu-props="{contentClass: 'main-list'}"
            v-model="selectedAllowedCurrencies"
            :items="filteredCurrencies"
            :label="$t('allowed_currencies')"
            outlined
            dense
            color="var(--main-flamingo)"
            >
          </v-select>
        </div>
          </v-card-text>
          <!-- Close and Save -->
          <v-card-actions>
            <v-spacer></v-spacer>
            <!-- <v-btn class="main-btn" text @click="closeAggregatorDialog">Close</v-btn> -->
            <!-- @click="updateaggregator" -->
              <Button class="p-button-sm p-button-success fw" icon="pi pi-save"
              :loading="formLoading" @click="updateAggregator(aggregator)" :label="$t('save')" />
          </v-card-actions>
        </v-card>
      </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
    export default {
        name: 'EditAggregator',
        props:{
          aggregatorDialog: Boolean,
          aggregator: Object,
        },
        data() {
            return {
                formLoading: false,
                dialog: false,
                selectedAllowedCurrencies: [],
                isAllAllowedCurrencies: false,
                // aggregator: {},
            }
        },
        computed: {
          ...mapGetters({
            filteredCurrencies: 'getFilteredCurrencies',
          })
        },
        watch:{
          // currentAggregator: {
          //   handler(newAggr) {
          //     console.log('newAggr', newAggr);
          //     if (newAggr) {
          //       this.aggreator = newAggr;
          //     }
          //   },
          //   deep: true,
          // },
          aggregator: {
      handler(newAggr, oldAggr) {
        // allowed_currencies is array
        if (newAggr && Array.isArray(newAggr.allowed_currencies)) {
          //  'ALL'
          if (newAggr.allowed_currencies.includes('ALL')) {
            this.isAllAllowedCurrencies = true;
            return;
          } else{
            this.selectedAllowedCurrencies = [];
  
            newAggr.allowed_currencies.forEach(currency => {
              // console.log('currency', currency);
              this.selectedAllowedCurrencies.push(currency);
            });
          }

        }
      },
      deep: true,
    },
  //========================================================================================================================================================
        //========================================================================================================================================================
    isAllAllowedCurrencies(newValue) {
            if (newValue) {
                // add currencies
                const currencies = Object.keys(this.filteredCurrencies).map(currency => {
                    return this.filteredCurrencies[currency];
                });
                this.selectedAllowedCurrencies = currencies;
            } else {
                // to delete from selectedCurrencies
                this.selectedAllowedCurrencies = [];
            }
        },
        //========================================================================================================================================================
        
          aggregatorDialog: {
            immediate: true,
            handler(newValue) {
              
              if (newValue) {
          this.dialog = newValue;        
              }
            }
          },
          dialog: {
            handler(newValue) {
              if (newValue === false) {
                this.$emit('aggregator-close');
                this.selectedAllowedCurrencies = [];
              this.isAllAllowedCurrencies = false;
              }
            }
          },
        },
        methods: {
          closeAggregatorDialog(){
            this.$emit('aggregator-close');
            this.dialog = false;
          },
            async updateAggregator(aggregator) {
      this.formLoading = true;
      let endpoint;
        endpoint = `/api/aggregators/${aggregator.id}`;
        const requestData = {
          is_enabled: aggregator.is_enabled,
          string_id: aggregator.string_id,
          name: aggregator.name,
          allowed_currencies: []
        };
      if (this.selectedAllowedCurrencies) {
        if (this.isAllAllowedCurrencies) {
          const allArray = ["ALL"];
          requestData.allowed_currencies = allArray;
        } else{
          requestData.allowed_currencies = this.selectedAllowedCurrencies;
          }
        }
        // console.log('requestData', requestData);
      try {
        const response = await axios.put(endpoint, requestData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            "Content-Type": "application/json",
          },
        });
        if (response) {
          this.$emit('aggregator-update');
          this.closeAggregatorDialog();
          this.$toast.add({
          severity: 'success', summary: this.$t(`aggregator_edit_success`), life: 4000,
        })
        }
      } catch (error) {
        console.error('Error updating entity:', error);
        this.$toast.add({
          severity: 'error', summary: this.$t(`just_error`),
          detail: error.response.data.detail,
          life: 4000,
        })
      } finally {
        this.formLoading = false;
      }
    },
        },
    }
</script>

<style lang="scss" scoped>
.load-photo {
  align-items: center;

  background: var(--maskbg) !important;
  border: 2px dashed var(--gray-900) !important;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 5px !important;
  span {
    cursor: pointer;
  }
}
</style>