<template>
  <v-btn
      v-scroll="onScroll"
      v-show="fab"
      fab
      dark
      fixed
      bottom
      right
      color="bg-c-red c-white"
      @click="toTop"
      style="width: 40px;height:40px;bottom: 45px;"
  >
    <v-icon>keyboard_arrow_up</v-icon>
  </v-btn>
</template>

<script>
  export default {
    name: "ScrollTopButton",
    data() {
      return{
        fab: false,
      }
    },
    methods: {
      onScroll (e) {
        if (typeof window === 'undefined') return
        const top = window.pageYOffset ||   e.target.scrollTop || 0
        this.fab = top > 50
      },
      toTop () {
        this.$vuetify.goTo(0)
      }
    }
  }
</script>

<style scoped>

</style>
