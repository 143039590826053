<template>
   <div>
        <div
            v-for="(i, index) in size"
            :key="index"
            class="
            main-item
            "
            >
            <Skeleton width="50px" height="50px"></Skeleton>
              <Skeleton 
              height="1rem" width="6rem" class="mx-auto"></Skeleton>
              <Skeleton width="3rem" height="2rem" borderRadius="4px"></Skeleton>
            </div
           >
   </div>
</template>
<script>
export default {
    props: {
        size: {
            type: Number,
            default: 10, 
        },
    },
}
</script>

<style lang="scss" scoped>
.main-item {
      padding: 3px 8px;
      display: flex;
      align-items: center;
      border: 1px solid rgba(225, 225, 225, 0.5);
      &:nth-of-type(even) {
        background-color: var(--surface-100);
      }
      &:nth-of-type(odd) {
        background-color:  var(--surface-300);
      }
  }

</style>