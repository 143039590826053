<template>
  <div id="app" :class="{'is-dark' : isDark}">
    <v-app>
      <!-- <Phone></Phone> -->
      <Header></Header>
      <!-- <SidebarNavMenu v-if="!$isMobile() && $store.getters.getUserToken && !loadingFlag"></SidebarNavMenu>
 -->
 <!-- $route.fullPath -->
      <keep-alive
      :max="5"
      include="AgentPlayersList,Agents,Localizations,AllGameProvidersSetting,PlayerAvatars,
      Dashboard,Players,AdminAuthSessions,AllAuthSessions,Cashbox,Games,Payments,PayMethods">
      <router-view 
      class="router_style" 
      :key="$route.fullPath"
        
        />
      </keep-alive>
    </v-app>
    <Toast></Toast>
  </div>
</template>
<script>
import axios from 'axios';
import Header from "./components/Header/Header.vue";
import Phone from "./components/Phone";
import { mapGetters } from 'vuex';

export default {
  components: {
    // Phone,
    Header
  },
  computed: {
    ...mapGetters({
      tokenUser: 'tokenUser',
      admin: 'admin',
    }),
    isDark(){
      return this.$store.state.isDark;
    },
  },
  data() {
    return {
      loadingFlag: true,
      cacheKey: this.$route.fullPath,
    }
  },
  watch:{
    async admin(val, oldVal) {

      if (val.id !== oldVal.id) {
        // console.log('val', val);
        // console.log('oldVal', oldVal);
        setTimeout(() => {

          this.cacheKey += 1;
        }, 400)
      }

    },
    // admin:{
    //   deep: true,
    //   handler(val, old) {
    //     // console.log(val);
    //     // console.log(old);
    //     if (val.id !== old.id) {
    //       this.cacheKey += 1;
    //     }
    //   }
    // }
  },
  async mounted() {
    await this.getAdminInfo();
    
    // document.addEventListener('contextmenu', event => event.preventDefault());
    if (localStorage.getItem('jwt')) {
      this.loadingFlag = true;
      if (this.tokenUser === 'agent') {
        await this.$store.dispatch('getCurrencies', 'agent');
      } else{
        await this.$store.dispatch('maintenance/awaitGetMaintenance');
        await this.$store.dispatch('getCurrencies');
      }
      this.loadingFlag = false;
    }
  },
 methods:{
  async getAdminInfo() {
    if (localStorage.getItem('jwt')) {
      if (this.tokenUser === 'agent') {
        // await this.$store.dispatch('getAgentStatus');
      } else{
        await this.$store.dispatch('getStatus');
      }
    }
  }
 }
}
</script>
<style lang="scss">
@import "../src/assets/scss/variables/variables";
html{
  overflow: auto!important;
}
.v-input{
  border-radius: 8px!important;
}
.calendar-menu{
  .p-datepicker{
    width: 100%!important;
  }
}
.mb-90{
  margin-bottom: 90px!important;
}
.report {

  &__header {
  }

  &__table {
  }
}
.header-report{
  margin-bottom: 4px;
  text-align: left;
  h3{
  }
  .p-divider{
    margin: 0;
    color: var(--text-color);
    &::before{
      border-top: 2px solid var(--text-color);

    }
  }
}
.table-report {
  .p-component{

    font-size: .9rem!important;
  }
  .p-datatable-wrapper{
    border: none!important;
  }
  .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-thead, .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-tfoot{
    background-color: transparent;
  }
  .p-datatable .p-datatable-tbody > tr{
    
    background-color: transparent;
    background: none;
  }
  .p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(2n){
    background-color: transparent;
    background: none;
  }
  .p-datatable.p-datatable-sm .p-datatable-thead > tr > th{
    background-color: transparent;
    background: none;
    padding: 0;
    border: none;
    border-bottom: 2px solid var(--text-color);
  }
  .p-datatable.p-datatable-sm .p-datatable-tbody > tr > td{
    padding: 0.1rem;
    border: none;
    line-height: 1.1;
  }
  .p-datatable-footer{
    padding: 0!important;
    //border-top: 1px solid var(--text-color);
    border-bottom: 2px solid var(--text-color);
    background-color: transparent;
    background: none;
  }
  .title{
    border-bottom: 1px solid var(--text-color-secondary);
    margin-top: 5px;
    td{
      > div{
        text-transform: uppercase;
        font-size: 16px;
        //margin-left: 7px;
  
      }
    }
  }
  .total{
    border-top: 1px solid var(--text-color);
    border-bottom: 1px solid var(--text-color);
    td{
      font-weight: 600;
      > div{
        margin-left: 7px;

      }
    }
    background-color: var(--black);
  }
}


.filters-column{
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  > *{
    margin-top: 2px;
  }
  .p-inputwrapper{
    margin-right: 5px;
  }
  .vue-daterange-picker{
    margin-right: 5px;
  }
  .p-calendar{
    margin-right: 5px;
  }
  .p-inputtext-sm{
    margin-right: 5px;
  }
  .p-dropdown{
    margin-right: 5px;
  }
}
.active-filter-chip{
  background: var(--red)!important;
  color: var(--white)!important;
  .v-icon{
    color: var(--white)!important;
    font-size: 18px!important;
    margin-left: 5px;
  }
}
.selectMenu{
  .v-list-item{
    color: var(--black)!important;
    justify-content:center;align-items: center;
    transition: background-color 0.3s;
    @media (any-hover: hover){
      &:hover{
        background-color: #cfcfcf;
      }
    }
    &::before{
      background-color: transparent;
    }
  }
}
*a{
  text-decoration: none;
}
.table{
  margin-bottom: 40px;
  @media (max-width:600px){
    margin-bottom: 70px;
  }
}
.paggination{
  background-color: var(--surface-ground);
  border-radius: 0px!important;
  color: var(--surface-900);
  backdrop-filter: blur(20px);
  z-index: 2;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  &_static{
    position: static;
  }
  .v-data-footer__select{
    @media (max-width:700px){
      margin: 0 8px 0 0!important;
  }
  .v-input{
    @media (max-width:500px){
      margin-left: 20px!important;
  }
  }
  }
  .v-data-footer__pagination{
    display: flex;
    justify-content: flex-end;
    min-width: 125px;
    padding: 0 8px;
    @media (max-width:700px){
    }
    
    margin: 0!important;
  }
 
  span{
    white-space: nowrap;
  }
  &__wrapper{
    display: flex;
    justify-content:flex-end;
    align-items: center;
    @media (max-width:500px){
      flex-direction: column-reverse;
  }
  }
  &__quantity-lines{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media (max-width:500px){
      justify-content:center;
  }
  }
  &__actions{
    display: flex;
    align-items: center;
  }
}
.providers{
  .v-list-item{
    min-height: 30px!important;
    padding: 0 5px;
  }
  .v-list-group__header__append-icon{
    display: none;
  }
  .v-list-group__items{
    margin-top: 5px!important;
    margin-left: 8px!important;
    border: 1px solid rgb(192, 192, 192);
    border-radius: 6px;
    overflow: auto;
    max-height: 500px;
  }
}
.notices{
    margin-top: 40px!important;
    margin-right: 5px;
  @media (max-width:700px){
    margin-top: 65px!important;
  }
}
.v-data-table{
  .v-data-table__wrapper{
    tbody{
      tr:nth-of-type(odd){
        background-color: rgb(251 251 251)!important;
    } 
      tr:nth-of-type(even){
        background-color: rgb(255, 255, 255)!important;
    } 
    }
  }
}
.v-data-table-header{
  .text-start{
    white-space: nowrap;
  }
}
.main-list{
  .v-list{
    .v-list-item--active{
      color: var(--main-flamingo)!important;
    }
  }
  .v-simple-checkbox{
    .v-icon{
      color: var(--main-flamingo)!important;
    }
  }
  
  
}
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper){
  background-color: #8d24aaa8!important;
  td{
    color: var(--white)!important;
  }
  a{
    color: var(--white)!important;
  }
}

  .local.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper){
    background-color: #ffffffa8!important;
    td{
      color: inherit!important;
    }
    a{
      color: inherit!important;
    }
  }
.v-data-table__mobile-row__cell{
  //max-width: 210px;
  //overflow: hidden;
  //text-overflow: ellipsis;
}

@import "@/assets/scss/main";
.no-active{
  background-color: var(--red)!important;
  opacity: 0.5;
}
.list-menu{
  padding: 0!important;
  background-color: var(--main-flamingo)!important;
  .v-list-item--active{
    background-color: var(--dark-flamingo)!important;
  }
  .v-list-item{
    border-radius: 0px!important;
    height: 40px;
    min-height: 40px;
    align-items: center;
    .v-list-item__content{
      color: var(--white)!important;
      font-weight: 500;
      
    }
    .v-list-item__title, .link-title{
      text-align: left;
      margin-left: 8px!important;
    }
    
    .v-list-item__icon{
      align-self: center;
      color: var(--white)!important;
      margin: 0!important;
      &:last-child{
        margin: 0!important;
        
      }
      .v-icon{

        font-size: 20px!important;
      }
    }
  }
}
.v-list-item{
  margin-bottom: 0!important;
  &::before{
    opacity: 0!important;
  }
}
.text-title{
  font-size: 18px!important;
  color: var(--white)!important;
  line-height: 1.2;
}
.vue-daterange-picker{
  .reportrange-text{
    border-radius: 6px;
    padding: 0.4rem 0.75rem!important;

  }
  .is-dark &{
    .reportrange-text{
      background: transparent;
    }
    .daterangepicker{
      background-color: var(--surface-ground);
    }
    .drp-calendar{
      border-left: 0!important;
    }
    .calendar-table{
      background-color: var(--surface-ground);
      td{
        border-radius: 0 !important;
      }
      th{
        border-radius: 0 !important;
      }
    }
    .table-condensed{
      color: var(--surface-ground);
      thead{
          .available{
            @media (any-hover: hover){
              &:hover{
                background-color: #eee!important;
              }
            }
          }
      }
      .month{

        
      }
      tr{

        //background-color: var(--surface-ground);
      }
      
    }
  }
}
.text-overflow{
  //&:last-child{
  //  max-width: 250px;
  //  overflow: hidden;
  //  text-overflow: ellipsis;
  //}
}
.custom-color{
  .v-list-group__header{
    background-color: var(--main-flamingo)!important;
  }
}
.table-main{
  .v-data-table__wrapper{
    //overflow: hidden!important;
  }
  .v-data-table__mobile-row__cell{
    max-width: 250px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.row{
  margin: -5px!important;
}
.col{
  padding: 5px!important;
  &_l{
    padding: 1px 5px!important;
    @media (max-width:992px){
      padding: 2px 5px!important;
    }
  }
}
.select-white{
  
  .v-input__control{
    
    .v-input__slot{
      &::before{
        display: none;
      }
      &::after{
        display: none;
      }
      .v-select__slot{
        
        .v-select__selections{
          justify-content: center;
          .v-select__selection{
            margin: 0!important;

          }
          color: var(--white)!important;
          font-weight: 600;
          input{
            display: none;
          }
        }
        .v-input__append-inner{
          display: none;
          .v-input__icon{
            .v-icon {
              color: var(--white)!important;
            }
            
          }
        }
      }
    }
  }
}
.p-toast-top-right{
  top: 70px!important;
}
.main-select{
  
  .v-input__control{
    
    .v-input__slot{
      &::before{
        display: none;
      }
      &::after{
        display: none;
      }
      .v-select__slot{
        
        .v-select__selections{
          justify-content: center;
          .v-select__selection{
            margin: 0!important;

          }
          color: var(--white)!important;
          font-weight: 600;
          input{
            display: none;
          }
        }
        .v-input__append-inner{
          display: none;
          .v-input__icon{
            .v-icon {
              color: var(--white)!important;
            }
            
          }
        }
      }
    }
  }
}
.select-white-out{
  
  .v-input__control{
    
    .v-input__slot{
      &::before{
        border-color: var(--white)!important;
      }
      &::after{
        display: none;
      }
      fieldset{
        border-color: var(--white);
      }
      .v-select__slot{
        .v-label{
          color: var(--white)!important;
        }
        .v-select__selections{
          input{
            color: var(--white)!important;
          }
          .v-select__selection{
            margin: 0!important;

          }
          color: var(--white)!important;
          font-weight: 600;
          
        }
        .v-input__append-inner{
          display: none;
          .v-input__icon{
            .v-icon {
              color: var(--white)!important;
            }
            
          }
        }
      }
    }
  }
}
.bet{
  &_min{
    border-radius: 8px 0 0 8px!important;
    &.v-input--is-focused{
      fieldset{
        border-right: 2px solid!important;
      }
    }
    fieldset{
      border-right: none!important;
    }
  }
  &_max{
    border-radius: 0 8px 8px 0px!important;
  }
}
.v-card__title, .v-expansion-panel-header{
  font-size: 16px!important;
}
.main-btn{
  background-color: var(--red)!important;
  color: var(--white)!important;
  &._square{
    min-width: auto!important;
    width: 40px;
  }
  &_fw{
    @media (max-width:600px){
      width: 100%;
    }
  }
}
.bonus-expansion{
  .v-expansion-panel-header{
    align-items: flex-start!important;
    overflow: auto;
    .v-expansion-panel-header__icon{
      margin-top: 12px;

    }
  }
}
.card-block{
  .form-block{
    display: flex;
    flex-direction: column;
  }
  .form-group{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &:not(:last-child) {
      margin-bottom: 15px;
  
    }
  }
  .v-input{
    //min-width: 800px;
  }
  .label{
    display: block;
    color: #70768f;
    font-weight: 600;
    margin-bottom: 5px;
    font-size: 18px;
    &_big{

    }
  }
}
.v-navigation-drawer__content::-webkit-scrollbar {
  @media (any-hover: hover) {
    height: 5px;
    width: 5px;
    background-color: var(--white);
  }
}
.v-navigation-drawer__content::-webkit-scrollbar-thumb {
  @media (any-hover: hover) {
    height: 5px;
    background-color: var(--dark-flamingo);
  }
}
// DONT TOUCH THIS...
._local{
  min-height: 100%;
  .card-custom{
    flex: 1 1 100%;
    .p-card-body{
      flex: 1 1 100%;  
      .p-card-content{
        display: flex;
        flex-direction: column;
        flex: 1 1 100%;
        .p-tabview{
          display: flex;
          flex-direction: column;
          flex: 1 1 100%;
          .p-tabview-panels{
            display: flex;
            flex-direction: column;
            flex: 1 1 100%;
            .p-tabview-panel{
              flex: 1 1 100%;
              display: flex;
              flex-direction: column;
              .local{
                flex: 1 1 100%;
              }
            }
          }
        }
      } 
    }
  }
}
.table-nostretched{
  min-width: auto;
  display: flex;
  justify-content:center;
  align-items: center;
}
.card-custom{
  //transition: all 0.3s;
  &._height{
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
  .p-card-title{
font-size: 1.2rem;

  }
  .p-card-body{
    display: flex;
    flex-direction: column;
  }
  .p-card-content{
    display: flex;
    flex-direction: column;
    min-height: 100%;
    .local{
  flex: 1 1 100%;

}
  }
  border: 1px solid var(--light-flamingo);
  .v-card__title{
    background-color: var(--main-flamingo);
    color: var(--white);
  }
  &._sel{
    border-width: 3px;
  }
}
.column-wrapper{
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
  @media (max-width:600px){
    flex-direction: column;
  }
}
.column-three{
  padding: 5px;
  &_no-padding{
    padding: 0px;

  }
  flex: 0 1 33.333%;
  @media (max-width:1200px){
      flex: 0 1 50%;
  }
  @media (max-width:600px){
      flex: 1 1 100%;
  }
 
}
.auto{
  &.v-input{
    margin-bottom: 6px!important;
  }
  &.error--text{
    margin-bottom: 0px!important;
  }
}
.column-two{
  padding: 5px;
  flex: 0 1 50%;
  
  @media (max-width:992px){
    flex: 1 1 auto;
  }
}
.column{
  padding: 5px;
  flex: 1 1 100%;
}
.card-view{
  border-radius: 6px!important;
  height: 100%;
  display: flex!important;
  flex-direction: column;
  justify-content: center;
  .v-card__text{
    
  }
  &._players{
    background-color: #1992d4!important;

  }
  &._deposits{
    background-color: var(--light-flamingo)!important;

  }
  &._cashouts{
    background-color: var(--green-success)!important;

  }
  &._bonus{
    background-color: #1f99a7!important;

  }
  &._wager{
    background-color: var(--red)!important;

  }
  &._total{
    background-color: var(--third-flamingo)!important;

  }
  color: var(--white)!important;
  
}

.box-column{
  flex: 0 0 50px;
  &._dash{

  }
}
.box-icon{
  width: 60px;
  height: 60px;
  background-color: rgba(255, 255, 255, 0.356);
  backdrop-filter: blur(10px);
  border-radius: 6px;
  ._dash &{
    background-color: var(--purple-400);
  }
  i{
    font-size: 40px!important;
  }
  @media (max-width:1200px){
    width: 50px;
  height: 50px;
    i{
      font-size: 30px!important;
    }
  }
}
.square-btn{
  background-color: var(--green-success)!important;
  cursor: pointer;
  display: flex;
  justify-content:center;
  align-items: center;
  min-width: 30px!important;
  height: 30px!important;
  flex: 0 0 30px;
  box-shadow: 0 0 5px rgba(67, 197, 28, 0.3);
  border-radius: 4px;
  i{
    font-size: 18px;
}
}
.success-btn{
  background-color: var(--green-success)!important;
  color: var(--white)!important;
  &_fw{
    @media (max-width:600px){
      width: 100%;
    }
  }
  &._square{
    min-width: auto!important;
    width: 40px;
  }
}
.v-input__slot{
}
.v-main{
  //overflow: hidden;
}
.v-input__append-inner{
  .v-input__icon{
    //padding-left: 10px!important;
    transform: translate(7px,0px);
  }
}
.field-input{
  min-height: 36px!important;
  height: 36px!important;
}
.datepicker{
  width: 280px!important;
  max-width: 280px!important;
  min-width: 280px!important;
}
.v-btn{
  padding: 0 6px!important;
  font-size: 12px!important;
  
}
.v-input--selection-controls__input{
  margin: 0!important;
}

.v-menu__content{
  //min-width: 200px!important;
  //position: fixed!important;
  //z-index: 4!important;
}
.menu-wrap{
  .v-list-item {
    .v-list-item__title{

      white-space: normal!important;
    }
  }
}
.players-row{}
.players-col{}
.main-tabs{
  .v-slide-group{
    .v-slide-group__prev{
      display: none!important;
    }
    .v-slide-group__wrapper{
      .v-slide-group__content{
        .v-tabs-slider-wrapper{
          color: var(--main-flamingo)!important;
        }
        .v-tab{
          font-weight: 600;
          color: var(--text-color)!important;
          .v-icon{
            color: var(--text-color)!important;
          }
        }
        .v-tab--active{
          color: var(--main-flamingo)!important;
          .v-icon{
            color: var(--main-flamingo)!important;
          }
        }

      }
      
    }
  }
}
.input-white{
  color: var(--white)!important;
  border-color: var(--white)!important;
  .v-input__slot{
    &::before{
      border-color: var(--white)!important;
    }
    &::after{
      border-color: var(--white)!important;
    }
    fieldset{
      color: var(--white)!important;
      border-color: var(--white)!important;
    }
    .v-text-field__slot{
      .v-label{
        color: var(--white)!important;
      }
      input{
        color: var(--white)!important;
        caret-color: var(--white)!important;
      }
    }
  }
}
.v-toolbar__content{
  padding: 4px 5px!important;
}
.logo{
  width: 75px;
  height: 75px;
  img{
    max-width: 100%;
    max-height: 100%;
  }
  &-header{
    transform: translate(0px,15px);
  }
  &._top{
    transform: translate(0px,7px);
  }
  margin-left: 8px;
  @media (max-width:768px){
    margin-left: 2px;
    width: 55px;
  height: 55px;
  }
}
.dataframe{
  thead{
      tr{
          text-align: unset!important;
      }
  }
}
.button-absolute{
  position: absolute;
  z-index:5;
  bottom: 15px;
  right: 0;
}
.header-button{
  display: flex;
  justify-content:center;align-items: center;
  background-color: var(--red);
  border-radius: 6px;
  cursor: pointer;
  padding: 5px;
  margin: 5px;
  min-width: 40px;
  min-height: 40px;
  box-shadow: 0 .01em .01em #2d2342,0 .1em .7em -.01em #2d2342,inset 0 -.01em 0 #3a416f;
  &_hide{
    @media (max-width:768px){
      display: none;
    }
  }
}
.providers-expansion{
  .v-expansion-panel{
    margin: 0!important;
    border-radius: 0px!important;
    //&:nth-of-type(even) {
    //  background-color: rgb(204, 204, 204) !important;
    //}

    //&:nth-of-type(odd) {
    //  background-color: rgb(255, 255, 255) !important;
    //}
    &._no-active{
      //background-color: var(--gray-300)!important;
      border: 1px solid var(--gray-500)!important;
      .v-expansion-panel-header{
        background-color: var(--gray-200)!important;
      }
      .provider-grid__game{
        background-color: var(--gray-200)!important;
      }
    }
    &:after{
      opacity: 1!important;
    }
  }
  .v-expansion-panel-header{
    .provider-grid__game{
    }
  }
  .v-expansion-panel--active>.v-expansion-panel-header{
    min-height: 48px;
  }
  .v-expansion-panel-content{
    .v-expansion-panel-content__wrap{
      max-height: 200px;
      overflow: auto;
      border: 1px solid silver;
      padding-bottom: 0px;
      padding-left: 5px;
      padding-right: 5px;
      border-radius: 6px;
      margin-bottom: 3px;
      margin-left: 110px;
      margin-right: 60px;
      max-width: none;
      @media (max-width:600px){
        margin-left: 10px;
        margin-right: 10px;
      }
      
    }
    &._scroll {
      .v-expansion-panel-content__wrap{
        margin-right: 55px;
      }
    }
    
  }
  
  .v-expansion-panel-header{
    padding: 0 5px 0 0;
  }
}
.main-item__image{
  .v-lazy{
    display: flex;
    .v-image{
      .v-image__image{
        border-radius: 16px!important;
        ._provider &{
          border-radius: 0px!important;

        }
      }
    }
  }
}
.footer-table{
  .p-datatable-footer{
    padding: 0!important;
    td{
      padding: 0.5rem 0.5rem;
    }
  }
  padding-bottom: 70px;
}
.main-content{
  max-width: 100%;
  //max-width: 1600px;
  margin: 0 auto;
  margin-top: 5px;
  margin-bottom: 60px;
  padding: 0px 5px;
  &_small{
    //max-width: 1200px;

  }
  &_fw{
    max-width: 100%;
  }
  .p-datatable-wrapper{
    border: 1px solid var(--surface-ground);
    //border-bottom: none;
  }
  &__container{
    background-color: var(--white);
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 5px 15px 30px;
    &._border-top-transparent{
      border-top-color: rgba(0, 0, 0, 0);
      border-radius: 0 0 6px 6px;
    }
    .v-data-table{
      border: 1px solid #eaeef1;
      border-radius: 0px;
    }
    
    &._with-header{
      padding-top: 0;
    }
  }
  &__header{
    margin: 0 -15px;
    border-radius: 6px 6px 0 0;
    overflow: hidden;
  }
}
.header-sticky{
  width: 100%;
  z-index: 3;
  position: sticky;
  background-color: var(--design-white);
  top: 63px;
}
.height-input{
  .v-input__slot{
    min-height: 34px!important;

  }
}
.nav {
  display: flex;
}
.nav-item {
  flex: 0 1 50%;
  display: flex;
  justify-content:center;
  align-items: center;
  padding: 10px 15px;
  border-radius: 6px 6px 0 0;
  border: 1px solid #ddd;
  border-bottom: 0;
  background-color: var(--white);
  &.router-link-exact-active{
    background-color: var(--main-flamingo);
    color: var(--white);
  }
  &._log{
    border: 1px solid var(--main-flamingo);
    border-radius: 16px 0 0 0;
    &:last-child{
      border-radius: 0 16px 0 0;
      
    }
  }
  &._no-active{
    cursor: pointer;
    transition: all 0.3s;
    background-color: #dce0ee;
    border-radius: 6px 6px 0 0;
    border: 0;
    @media (any-hover: hover){
      &:hover{
        background-color: #ddd;
      }
    }
  }
}
.v-alert__content{
  text-align: left;
}
#app {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  text-align: center;
  color: var(--text-color);
  background-color: var(--surface-ground);
}
.navbar_header {
  color: white;
  font-size: 20px;
  text-align: center;
}

.vsm--title {
  font-size: 20px;
}

.router_style {
  padding-right: 5px;
  padding-left: 5px;
}
.providers-expansion .v-expansion-panel-content__wrap::-webkit-scrollbar {
  @media (any-hover: hover) {
    height: 5px;
    width: 5px;
    background-color: var(--surface-a);
    border-radius: 6px;
  }
}
.providers-expansion .v-expansion-panel-content__wrap::-webkit-scrollbar-thumb {
  @media (any-hover: hover) {
    border-radius: 6px;
    height: 5px;
    background-color: var(--dark-flamingo);
  }
}
.main-item::-webkit-scrollbar {
  @media (any-hover: hover) {
    height: 5px;
    width: 5px;
    background-color: var(--surface-a);
  }
}
.main-item::-webkit-scrollbar-thumb {
  @media (any-hover: hover) {
    height: 5px;
    background-color: var(--dark-flamingo);
  }
}
</style>
