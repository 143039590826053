<template>
    <div>
        <v-card class="card_style" style="">
            <v-card-title class="card_header_style">
              {{ $i18n.t('support') }}
            </v-card-title>

        </v-card>
        <div style="overflow-x: hidden; margin-top: 10px">
            <b-table
                    :items="items"
                    :fields="fields"
                    head-variant="dark"
                    sticky-header="75vh"
                    hover
                    striped
            ></b-table>
        </div>
    </div>

</template>

<script>
    export default {
        name: "Support",
        data(){
            return{
                items: [
                    { player: 'Player 1', affiliate: '24142xdfs', registration: '20.02.2012', balance: '201232 RUB', status:'Не активен', method: 'test'},
                    { player: 'Player 1', affiliate: '24142xdf', registration: '20.02.2012', balance: '201232 RUB', status:'Не активен', test: 'test'},
                    { player: 'Player 1', affiliate: '24142xdfs', registration: '20.02.2012', balance: '201232 RUB', status:'Не активен', method: 'test'},
                    { player: 'Player 1', affiliate: '24142xdf', registration: '20.02.2012', balance: '201232 RUB', status:'Не активен', test: 'test'},
                    { player: 'Player 1', affiliate: '24142xdfs', registration: '20.02.2012', balance: '201232 RUB', status:'Не активен', method: 'test'},
                    { player: 'Player 1', affiliate: '24142xdf', registration: '20.02.2012', balance: '201232 RUB', status:'Не активен', test: 'test'},
                    { player: 'Player 1', affiliate: '24142xdfs', registration: '20.02.2012', balance: '201232 RUB', status:'Не активен', method: 'test'},
                    { player: 'Player 1', affiliate: '24142xdf', registration: '20.02.2012', balance: '201232 RUB', status:'Не активен', test: 'test'},
                    { player: 'Player 1', affiliate: '24142xdfs', registration: '20.02.2012', balance: '201232 RUB', status:'Не активен', method: 'test'},
                    { player: 'Player 1', affiliate: '24142xdf', registration: '20.02.2012', balance: '201232 RUB', status:'Не активен', test: 'test'},
                    { player: 'Player 1', affiliate: '24142xdfs', registration: '20.02.2012', balance: '201232 RUB', status:'Не активен', method: 'test'},
                    { player: 'Player 1', affiliate: '24142xdf', registration: '20.02.2012', balance: '201232 RUB', status:'Не активен', test: 'test'},
                    { player: 'Player 1', affiliate: '24142xdfs', registration: '20.02.2012', balance: '201232 RUB', status:'Не активен', method: 'test'},
                    { player: 'Player 1', affiliate: '24142xdf', registration: '20.02.2012', balance: '201232 RUB', status:'Не активен', test: 'test'},
                    { player: 'Player 1', affiliate: '24142xdfs', registration: '20.02.2012', balance: '201232 RUB', status:'Не активен', method: 'test'},
                    { player: 'Player 1', affiliate: '24142xdf', registration: '20.02.2012', balance: '201232 RUB', status:'Не активен', test: 'test'},
                    { player: 'Player 1', affiliate: '24142xdfs', registration: '20.02.2012', balance: '201232 RUB', status:'Не активен', method: 'test'},
                    { player: 'Player 1', affiliate: '24142xdf', registration: '20.02.2012', balance: '201232 RUB', status:'Не активен', test: 'test'},

                ],
                fields: [
                    { key: 'player', label: this.$i18n.t('message'), sortable: false },
                    { key: 'test', label: this.$i18n.t('response'), sortable: false },
                    { key: 'affiliate', label:this.$i18n.t('phone'), sortable: false },
                    { key: 'registration', label:this.$i18n.t('responsible'), sortable: false },
                    { key: 'method', label: this.$i18n.t('user'), sortable: false },
                    { key: 'balance', label: this.$i18n.t('status'), sortable: false },

                ],

            }
        },
    }
</script>

<style scoped>
    .card_style{
        margin-top: 10px;
        box-shadow: 0 0 5px #2c3e50;
        border-radius: 20px
    }
</style>