<template>
    <div class="d-flex align-center font-weight-bold">
      <div v-for="(timezone, index) in timezones" :key="index">
        {{ getTimeInTimezoneFormatted(timezone.zone) }}
      </div>
    </div>
  </template>
  
  <script>
  import moment from 'moment-timezone';
  import 'moment/locale/en-gb'; 
  
  export default {
    name: 'ServerTime',
    data() {
      return {
        timezones: [
          { name: 'CET', zone: 'Europe/Paris' },
        //   { name: 'New York', zone: 'America/New_York' },
        ],
      };
    },
    methods: {
    getTimeInTimezoneFormatted(timezone) {
      const formattedTime = moment().tz(timezone);
      const dayOfWeek = formattedTime.format('ddd');
      const month = formattedTime.format('MMM');
      const dayOfMonth = formattedTime.format('D');
      const time = formattedTime.format('HH:mm');
      const timezoneAbbr = formattedTime.format('z');

      return `${dayOfWeek}, ${month} ${dayOfMonth} · ${time} (${timezoneAbbr})`;
    },
    updateCurrentTime() {
      this.$forceUpdate();
    },
  },
  created() {
    this.timer = setInterval(this.updateCurrentTime, 60000); // every minute
  },
  destroyed() {
    clearInterval(this.timer);
    },
  };
  </script>